import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { SvgCheck, SvgCross } from "../../svg/";

export class CityForm extends Component {
  state = {
    city: this.props.city,
  };

  handleChange = (e) => {
    const city = { ...this.state.city };
    city[e.target.name] = e.target.value;
    this.setState({ city });
  };

  save = () => {
    this.props.saveCallback(this.state.city);
  };

  render() {
    const {
      city: {
        position,
        name,
        region,
        country_id: countryId,
        users_count: usersCount,
      },
    } = this.state;
    const { close, countries } = this.props;
    const disabled = !name || name.length === 0;

    return (
      <Fragment>
        <tr>
          <td className="text-center">
            <input
              type="number"
              name="position"
              placeholder="Позиция"
              value={position !== undefined ? position : ""}
              onChange={this.handleChange}
              className="form-control form-control-sm text-center"
            />
          </td>
          <td>
            <input
              type="text"
              name="name"
              placeholder="Название"
              value={name || ""}
              onChange={this.handleChange}
              className="form-control form-control-sm"
            />
          </td>
          <td>
            <input
              type="text"
              name="region"
              placeholder="Регион"
              value={region || ""}
              onChange={this.handleChange}
              className="form-control form-control-sm"
            />
          </td>
          <td>
            <select
              name="country_id"
              className="form-control form-control-sm"
              value={countryId}
              onChange={this.handleChange}
            >
              {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
          </td>
          <td className="text-center">{usersCount > 0 && usersCount}</td>
          <td className="text-right text-nowrap action-buttons">
            <button
              className="btn btn-success btn-sm"
              onClick={this.save}
              disabled={disabled}
              title="Сохранить"
            >
              <SvgCheck width={14} height={12} className="fill-white" />
            </button>
            <button
              className="btn btn-primary btn-sm ml-1"
              onClick={close}
              title="Отменить"
            >
              <SvgCross width={14} height={12} className="fill-white" />
            </button>
          </td>
        </tr>
      </Fragment>
    );
  }

  static propTypes = {
    city: PropTypes.object.isRequired,
    countries: PropTypes.arrayOf(PropTypes.object).isRequired,
    close: PropTypes.func.isRequired,
    saveCallback: PropTypes.func.isRequired,
  };
}
