import { DataLayer } from "../../libs/";

export const loadSettings = () => async () => {
  const newState = {
    loading: false,
    loadingError: false,
  };

  await DataLayer.get({ path: "/settings.json" })
    .then((response) => {
      newState.settings = response;
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
