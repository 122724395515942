import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export const CommentHeaderMenuFull = ({ comment, branchLink, reply }) => (
  <div className="pl-2 pt-1 pt-md-0">
    <Link to={branchLink} className="btn btn-link opacity p-0" target="_blank">
      #{comment.post_id}
    </Link>
    <Link
      to={`?category_id=${comment.category_id}`}
      className="btn btn-link opacity p-0"
      target="_blank"
    >
      {comment.category_name}
    </Link>
    {!comment.hidden && (
      <button type="button" className="btn btn-link p-0" onClick={reply}>
        Ответить
      </button>
    )}
  </div>
);

CommentHeaderMenuFull.propTypes = {
  comment: PropTypes.object.isRequired,
  branchLink: PropTypes.string.isRequired,
  reply: PropTypes.func.isRequired,
};
