import React, { Component } from "react";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";
import { connect } from "react-redux";
import _ from "lodash";

import { loadUser, loadPublisherPosts, setPageTitle } from "../../actions/";
import {
  Loading,
  LoadingError,
  EmptyList,
  EndOfList,
  NotFound,
  PageHeader,
  ResponsiveSidebar,
} from "../../components/common/";
import { PublisherFilters } from "../../components/publishers/";
import { PostListItem } from "../../components/posts/";

class Posts extends Component {
  state = {
    publisherId: parseInt(this.props.match.params.publisherId, 10),
    user: null,
    posts: [],
    end: false,
    loading: true,
    loadingError: false,
    filters: this.props.filters,
  };

  componentDidMount() {
    this.props.loadUser(this.state.publisherId).then((e) => this.setState(e));

    const {
      filters: { type, period },
    } = this.props;
    let pageTitle = `${
      type === "published" ? "Опубликованные" : "Удалённые"
    } посты`;
    if (period)
      pageTitle +=
        period === "this_month" ? " - этот месяц" : " - прошлый месяц";
    this.props.setPageTitle(pageTitle);
  }

  componentWillUnmount() {
    this.props.setPageTitle(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevState.filters, this.state.filters)) {
      this.props
        .loadPosts({ publisherId: this.state.publisherId })
        .then((e) => this.setState(e));
      // title never changes on any filter change
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.filters, state.filters)) {
      return { filters: props.filters, posts: [], loading: true, end: false };
    }
    return null;
  }

  loadPosts = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }

    const { publisherId, posts } = this.state;
    this.props.loadPosts({ publisherId, posts }).then((e) => this.setState(e));
  };

  render() {
    const { publisherId, user, posts, end, loading, loadingError } = this.state;

    if (!user) {
      return (
        <NotFound
          title="Пользователь"
          id={publisherId}
          loading={loading}
          loadingError={loadingError}
        />
      );
    }

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <PageHeader>
          <h3 className="mt-1">Опубликовал: {user.fullname_or_anonymus}</h3>
        </PageHeader>

        <div className="row p-static">
          <ResponsiveSidebar mobileTitle="Показать фильтры">
            <PublisherFilters />
          </ResponsiveSidebar>

          <div className="col-lg-8 col-12 order-lg-1">
            {posts.map((post) => (
              <PostListItem key={`post_${post.id}`} post={post} />
            ))}

            {!end && !loadingError && (
              <VisibilitySensor
                onChange={(isVisible) => isVisible && this.loadPosts()}
                children={<div className="visibility_sensor" />}
              />
            )}
            {loading && <Loading />}
            {loadingError && <LoadingError retry={this.loadPosts} />}
            {!loading && !loadingError && !posts[0] && <EmptyList />}
            {end && <EndOfList />}
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    loadUser: PropTypes.func.isRequired,
    loadPosts: PropTypes.func.isRequired,
    setPageTitle: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.publishers,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (userId) => dispatch(loadUser(userId)),
  loadPosts: (data) => dispatch(loadPublisherPosts(data)),
  setPageTitle: (title) => dispatch(setPageTitle(title)),
});

export const PublisherPosts = connect(
  mapStateToProps,
  mapDispatchToProps
)(Posts);
