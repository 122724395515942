import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { HeaderDesktop, HeaderMobile, NavLinks } from "./";
import { siteName } from "../../../libs";
import { setActiveNav } from "../../../actions";

class HeaderComponent extends Component {
  state = {
    activeNav: null,
  };

  componentDidMount() {
    this.updateActiveNav(this.props.path);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.path !== this.props.path) {
      this.updateActiveNav(this.props.path);
    }
  }

  updateActiveNav = (path) => {
    const activeNav = NavLinks.filter((item) => {
      if (!item.children) {
        return item.url === path || item.url + "/" === path;
      }

      return item.children.filter((child) => child.url === path).length;
    })[0];

    if (activeNav) {
      this.setState({ activeNav }, () => this.props.setActiveNav(activeNav));
    }
  };

  render() {
    const { activeNav } = this.state;
    const { pageTitle, path } = this.props;
    const sections = [];

    if (pageTitle) {
      sections.push(pageTitle);
    } else if (activeNav) {
      sections.push(activeNav.title);

      if (activeNav.children) {
        const activeChild = activeNav.children.filter(
          (item) => item.url === path
        )[0];
        if (activeChild) {
          sections.push(activeChild.title);
        }
      }
    }

    sections.push(siteName);

    return [
      <Helmet key="helmet" title={sections.join(" - ")} />,

      <MediaQuery key="headers" minWidth={992}>
        {(matches) => {
          if (matches) {
            return <HeaderDesktop />;
          }

          return (
            <HeaderMobile
              pageTitle={pageTitle || (activeNav ? activeNav.title : siteName)}
            />
          );
        }}
      </MediaQuery>,
    ];
  }

  static propTypes = {
    pageTitle: PropTypes.string,
    activeNav: PropTypes.object,
    setActiveNav: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
  };
}

const mapStateToProps = (state) => ({
  pageTitle: state.pageTitle,
  activeNav: state.activeNav,
  path: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveNav: (nav) => dispatch(setActiveNav(nav)),
});

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);
