import React, { Component } from "react";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";
import { connect } from "react-redux";
import _ from "lodash";

import {
  loadUser,
  loadUserComments,
  hideUserComments,
  setPageTitle,
} from "../../actions/";
import {
  Loading,
  LoadingError,
  EmptyList,
  EndOfList,
  NotFound,
  PageHeader,
  ResponsiveSidebar,
} from "../../components/common/";
import { CommentList } from "../../components/comments";
import {
  CommentFilters,
  CommentHide,
  ProfileLink,
} from "../../components/users/";

class Comments extends Component {
  state = {
    userId: parseInt(this.props.match.params.userId, 10),
    user: null,
    comments: [],
    end: false,
    loading: true,
    loadingError: false,
    filters: this.props.filters,
  };

  componentDidMount() {
    this.props.loadUser(this.state.userId).then((e) => this.setState(e));
    this.props.setPageTitle(`Комментарии пользователя`);
  }

  componentWillUnmount() {
    this.props.setPageTitle(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevState.filters, this.state.filters)) {
      this.props
        .loadComments({ userId: this.state.userId, comments: [] })
        .then((e) => this.setState(e));
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.filters, state.filters)) {
      return { filters: props.filters, loading: true, end: false };
    }
    return null;
  }

  loadComments = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }

    const { userId, comments } = this.state;
    this.props.loadComments({ userId, comments }).then((e) => this.setState(e));
  };

  hideLast = (quantity) => {
    this.props
      .hideComments({ userId: this.state.userId, quantity })
      .then(() => this.setState({ comments: [], loading: true }))
      .catch(() => {});
  };

  render() {
    const { userId, user, comments, end, loading, loadingError } = this.state;

    if (!user) {
      return (
        <NotFound
          title="Пользователь"
          id={userId}
          loading={loading}
          loadingError={loadingError}
        />
      );
    }

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <PageHeader>
          <h2>
            <ProfileLink user={user} />
          </h2>
        </PageHeader>

        <div className="row p-static">
          <ResponsiveSidebar mobileTitle="Показать фильтры и действия">
            <CommentFilters />
            <CommentHide hideLast={this.hideLast} />
          </ResponsiveSidebar>

          <div className="col-lg-8 col-12 order-lg-1">
            <CommentList comments={comments} showBanReason />

            {!end && !loadingError && (
              <VisibilitySensor
                onChange={(isVisible) => isVisible && this.loadComments()}
                children={<div className="visibility_sensor" />}
              />
            )}
            {loading && <Loading />}
            {loadingError && <LoadingError retry={this.loadComments} />}
            {!loading && !loadingError && !comments.length && <EmptyList />}
            {end && <EndOfList />}
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    loadUser: PropTypes.func.isRequired,
    loadComments: PropTypes.func.isRequired,
    hideComments: PropTypes.func.isRequired,
    setPageTitle: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  showActions: state.user.hasAdminAccess,
  filters: state.filters.user_comments,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (userId) => dispatch(loadUser(userId)),
  loadComments: (data) => dispatch(loadUserComments(data)),
  hideComments: (data) => dispatch(hideUserComments(data)),
  setPageTitle: (title) => dispatch(setPageTitle(title)),
});

export const UserComments = connect(
  mapStateToProps,
  mapDispatchToProps
)(Comments);
