import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";

import { Pagination, HistoryItem } from "../../components/common/";

export const History = ({ versions, pages, showObject }) => {
  if (versions.length < 1) {
    return <div className="alert alert-warning">Записей не найдено.</div>;
  }

  return (
    <div className="history-container">
      <MediaQuery maxWidth={767}>
        {(matches) => <Pagination total={pages} small={matches} />}
      </MediaQuery>

      <table className="table table-responsive table-bordered">
        <colgroup>
          <col width="180" />
          {showObject ? <col width="180" /> : null}
          <col />
          <col />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>Дата и автор</th>
            {showObject ? <th>Объект</th> : null}
            <th>Поле</th>
            <th>До</th>
            <th>После</th>
          </tr>
        </thead>
        <tbody>
          {versions.map((version) => (
            <HistoryItem
              key={version.id}
              version={version}
              showObject={showObject}
            />
          ))}
        </tbody>
      </table>

      <MediaQuery maxWidth={767}>
        {(matches) => <Pagination total={pages} small={matches} />}
      </MediaQuery>
    </div>
  );
};

History.propTypes = {
  versions: PropTypes.arrayOf(PropTypes.object).isRequired,
  pages: PropTypes.number.isRequired,
  showObject: PropTypes.string,
};

History.defaultProps = {
  showObject: "",
};
