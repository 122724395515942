import { DataLayer } from "../../libs/";

export const createBan =
  ({ userId, formData, callback }) =>
  async () => {
    const newState = { banError: null };

    await DataLayer.post({
      path: `/users/${userId}/bans.json`,
      body: formData,
      loader: true,
    })
      .then((response) => {
        newState.banned = response.banned;
        newState.bannedUntil = response.banned_until;
        newState.showBanModal = false;

        if (callback) {
          callback(response);
        }
      })
      .catch((response) => {
        newState.banError = response.data.error;
      });

    return newState;
  };
