import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setSortingParams } from "../../../actions";

class Sorting extends Component {
  setOrder = (order) => {
    const { field, setSorting } = this.props;
    setSorting({ field, order });
  };

  render() {
    const { field, sortBy, sortOrder } = this.props;
    const current = sortBy === field ? sortOrder : null;

    return (
      <div className="sorting-links">
        <div
          className={`asc${current === "asc" ? " active" : ""}`}
          onClick={() => this.setOrder("asc")}
        >
          &#9650;
        </div>
        <div
          className={`desc${current === "desc" ? " active" : ""}`}
          onClick={() => this.setOrder("desc")}
        >
          &#9660;
        </div>
      </div>
    );
  }
}

Sorting.propTypes = {
  field: PropTypes.string.isRequired,
  setSorting: PropTypes.func.isRequired,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
};

Sorting.defaultProps = {
  sortBy: null,
  sortOrder: null,
};

const mapStateToProps = (state) => ({
  sortBy: state.filters.sortBy,
  sortOrder: state.filters.sortOrder,
});

const mapDispatchToProps = (dispatch) => ({
  setSorting: (data) => dispatch(setSortingParams(data)),
});

export const SortingLinks = connect(
  mapStateToProps,
  mapDispatchToProps
)(Sorting);
