import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { DateFilters, Graph, PieChart, SwitchView } from "../_common";

const sourceLabels = {
  total: "Всего",
  by_underside: "Изнанкой",
  by_editors: "Редакторами",
};

export class PublishedSecrets extends Component {
  state = {
    tab: "graph",
  };

  switchTab = (e) => {
    this.setState({ tab: e.target.name });
  };

  render() {
    const { tab } = this.state;
    const { data, className } = this.props;

    return (
      <div className={className}>
        <div className="row justify-content-between align-items-baseline">
          <div className="col-auto">
            <h3 className="mb-1">Опубликованные секреты</h3>
          </div>
          <div className="col-auto">
            <SwitchView current={tab} callback={this.switchTab} />
          </div>
        </div>

        <DateFilters data={data} showGroups={tab === "graph"}>
          {tab === "graph" ? (
            <Graph id="published-secrets-graph" labels={sourceLabels} />
          ) : (
            ({ data }) => (
              <PieChart
                id="published-secrets-pie-chart"
                labels={sourceLabels}
                data={{
                  ...data,
                  series: _.filter(data.series, ({ name }) => name !== "total"),
                }}
              />
            )
          )}
        </DateFilters>
      </div>
    );
  }

  static propTypes = {
    data: PropTypes.shape({
      dates: PropTypes.arrayOf(PropTypes.string),
      series: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };
}
