import { DataLayer } from "../../libs/";

export const postToggleComments =
  ({ id, value }) =>
  () => {
    return DataLayer.post({
      path: `/posts/${id}/enable_comments.json`,
      body: {
        enable: value,
      },
      loader: true,
    });
  };
