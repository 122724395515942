import { loadReasons, loadCategories, loadBadFeedWords } from "../";

export const setCurrentUser = (user) => async (dispatch) => {
  localStorage.setItem("currentUser", JSON.stringify(user));
  await dispatch({ type: "SET_USER", payload: user });

  if (user.hasModeratorAccess) {
    await dispatch(loadReasons(true));
    await dispatch(loadBadFeedWords());
    await dispatch(loadCategories({}));
  }

  return true;
};
