import React from "react";

import Settings from "./settings";

export class UndersideSettings extends Settings {
  render() {
    const {
      data: { ranks },
    } = this.state;

    return (
      <div className="settings-item bg-white p-2">
        <h5>Настройки алгоритма изнанки</h5>

        <div className="row align-items-center mt-3">
          <div className="col">
            Da - Рейтинг секрета, при котором он вылетает из выборки как
            положительный
          </div>
          <div className="col-sm-5 col-6">
            {this.input("algorithm.da", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Db - Рейтинг секрета, при котором он вылетает из выборки как
            отрицаиельный
          </div>
          <div className="col-sm-5 col-6">
            {this.input("algorithm.db", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Dp - Количество просмотров секрета, после которых он вылетает из
            выборки как нейтральный
          </div>
          <div className="col-sm-5 col-6">
            {this.input("algorithm.dp", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Cp - Процент голосов за категорию секрета, необходимый для
            назначения секрету категории
          </div>
          <div className="col-sm-5 col-6">
            {this.input("algorithm.cp", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">Пропуск секретов</div>
          <div className="col-sm-5 col-6">
            {this.booleanRadio("algorithm.skip", ["Разрешён", "Запрещён"])}
          </div>
        </div>

        <h5 className="mt-5">Изнанка в ленте</h5>

        <div className="row align-items-center mt-3">
          <div className="col">Изнанка на Главной</div>
          <div className="col-sm-5 col-6">
            {this.booleanRadio("in_posts.enabled", ["Включена", "Отключена"])}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Количество закешированных секретов для показа
          </div>
          <div className="col-sm-5 col-6">
            {this.input("in_posts.cache_size", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">Период обновления кеша (в минутах)</div>
          <div className="col-sm-5 col-6">
            {this.input("in_posts.cache_expire_in_min", "integer")}
          </div>
        </div>

        <h5 className="mt-5">Настройки рангов пользователя</h5>

        <div className="row align-items-center mt-3">
          <div className="col-2">Ранг</div>
          <div className="col-5">Необходимый рейтинг</div>
          <div className="col-5">Доступное количество секретов из изнанки</div>
        </div>

        {ranks.map((rankElem) => (
          <div className="row align-items-center mt-3" key={rankElem.rank}>
            <div className="col-2">{rankElem.rank}</div>
            <div className="col-5">
              <input
                type="text"
                className="form-control"
                name={`ranks.${rankElem.rank}.rating`}
                value={rankElem.rating}
                onChange={(e) => this.handleInputChange(e, "integer")}
              />
            </div>
            <div className="col-5">
              <input
                type="text"
                className="form-control"
                name={`ranks.${rankElem.rank}.secrets`}
                value={rankElem.secrets}
                onChange={(e) => this.handleInputChange(e, "integer")}
              />
            </div>
          </div>
        ))}

        <div className="mt-7 text-center">
          <button
            className="btn btn-primary"
            onClick={() => this.save("underside")}
          >
            Сохранить
          </button>
        </div>
      </div>
    );
  }
}
