import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import { Comment } from "./";

class List extends Component {
  state = {
    prevComments: this.props.comments,
    comments: this.props.comments,
    highlightWords:
      typeof this.props.badFeedWords === "string"
        ? this.props.badFeedWords?.split(",")
        : [],
  };

  static getDerivedStateFromProps(props, state) {
    if (props.comments !== state.prevComments) {
      return { comments: props.comments, prevComments: props.comments };
    }
    return null;
  }

  handleReply = (newReply) => {
    const comments = [...this.state.comments];
    _.remove(comments, ["id", newReply.id]); // delete duplicate from ActionCable

    const parent = _.findIndex(comments, ["id", newReply.parent_id]);
    comments.splice(parent + 1, 0, newReply);
    this.setState({ comments });
  };

  handleHide = (comment) => {
    if (comment.hidden && comment.root_id === null) {
      const updatedCommentList = [...this.state.comments];
      updatedCommentList.map((currentComment) => {
        if (currentComment.id === comment.id) {
          currentComment.hidden = true;
          currentComment.removed_at = comment.removed_at;
          currentComment.remover = comment.remover;
        }
        if (currentComment.root_id === comment.id) {
          currentComment.hidden = true;
          currentComment.removed_at = comment.removed_at;
          currentComment.removed_by_worker = "HideCommentWorker";
        }
        return currentComment;
      });
      this.setState({ comments: updatedCommentList });
    }
  };

  render() {
    const { comments, highlightWords } = this.state;
    const { withIndents, showBanReason, highlightComment } = this.props;

    return comments.map((comment) => (
      <Comment
        key={comment.id}
        comment={comment}
        hidden={comment.hidden} // used only to force react to render if hidden changes
        handleReply={this.handleReply}
        handleHide={this.handleHide}
        highlightWords={highlightWords}
        withIndents={withIndents}
        showBanReason={showBanReason}
        highlight={highlightComment === `c${comment.id}`}
      />
    ));
  }

  static propTypes = {
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    badFeedWords: PropTypes.string,
    withIndents: PropTypes.bool,
    showBanReason: PropTypes.bool,
    highlightComment: PropTypes.string,
  };

  static defaultProps = {
    withIndents: false,
    showBanReason: false,
    highlightComment: null,
  };
}

const mapStateToProps = (state) => ({
  badFeedWords: state.badFeedWords,
});

export const CommentList = connect(mapStateToProps)(List);
