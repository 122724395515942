const reducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_USER":
      return action.payload;

    case "LOG_OUT":
      return {};

    default:
      return state;
  }
};

export default reducer;
