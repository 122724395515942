import React from "react";
import PropTypes from "prop-types";

import { Country } from "./";

export const CountrySettings = ({ data }) => {
  return (
    <div className="settings-item bg-white p-2">
      <div className="row mb-4">
        <h5 className="col">Страны</h5>
      </div>

      <table className="table table-sm table-hover">
        <colgroup>
          <col width="70" />
          <col />
          <col />
          <col width="90" />
        </colgroup>
        <thead>
          <tr>
            <th className="text-center">Позиция</th>
            <th>Название</th>
            <th className="text-center">Городов</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {data.map((country) => (
            <Country key={country.id} country={country} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

CountrySettings.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
};
