import React, { Component } from "react";
import MediaQuery from "react-responsive";

import { Modal } from "../common/";
import { SvgPlus } from "../../svg/";

import { PostForm } from "./";

export class PostAddFormButton extends Component {
  state = {
    showModal: false,
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    const { showModal } = this.state;

    return (
      <div>
        <MediaQuery minWidth={992}>
          {(matches) => {
            if (matches) {
              return (
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={this.toggleModal}
                >
                  Создать пост
                </button>
              );
            }

            return (
              <button
                type="submit"
                className="btn btn-link p-0"
                onClick={this.toggleModal}
              >
                <SvgPlus width={16} height={16} />
              </button>
            );
          }}
        </MediaQuery>

        {showModal && (
          <Modal
            title="Новый пост"
            toggleModal={this.toggleModal}
            className="new-post-modal"
          >
            <PostForm closeForm={this.toggleModal} />
          </Modal>
        )}
      </div>
    );
  }
}
