import { DataLayer } from "../../libs/";

export const loadFilterOptionsForSecrets = () => async () => {
  const newState = { loading: false };
  await DataLayer.get({ path: "/secrets/filter_options.json" })
    .then((response) => {
      newState.filterOptions = response;
    })
    .catch(() => {});
  return newState;
};
