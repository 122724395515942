import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import _ from "lodash";

import { loadUser, loadPublisherSecrets, setPageTitle } from "../../actions/";
import {
  Loading,
  LoadingError,
  Pagination,
  EmptyList,
  NotFound,
  PageHeader,
  ResponsiveSidebar,
} from "../../components/common/";
import { PublisherFilters } from "../../components/publishers/";
import { Secret } from "../../components/secrets/";

class Secrets extends Component {
  state = {
    publisherId: parseInt(this.props.match.params.publisherId, 10),
    type: this.props.match.params.type,
    user: null,
    secrets: [],
    end: false,
    loading: true,
    loadingError: false,
    pages: 0,
    filters: this.props.filters,
  };

  componentDidMount() {
    this.props.loadUser(this.state.publisherId).then((newState) => {
      if (newState.user) {
        newState.loading = true;
        this.props
          .loadSecrets(this.state.publisherId)
          .then((e) => this.setState(e));
      }
      this.setState(newState);
    });

    const {
      filters: { period },
    } = this.props;
    let pageTitle;
    if (this.state.type === "scheduled") {
      pageTitle = "Запланированные секреты";
      pageTitle +=
        period === "this_month" ? " - этот месяц" : " - следующий месяц";
    } else {
      pageTitle = "Добавленные секреты";
      pageTitle +=
        period === "this_month" ? " - этот месяц" : " - прошлый месяц";
    }
    this.props.setPageTitle(pageTitle);
  }

  componentWillUnmount() {
    this.props.setPageTitle(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevState.filters, this.state.filters)) {
      this.props
        .loadSecrets(this.state.publisherId)
        .then((e) => this.setState(e));
      // title never changes on any filter change
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.filters, state.filters)) {
      return { filters: props.filters, secrets: [], loading: true, end: false };
    }
    return null;
  }

  render() {
    const { publisherId, user, secrets, pages, loading, loadingError } =
      this.state;

    if (!user) {
      return (
        <NotFound
          title="Пользователь"
          id={publisherId}
          loading={loading}
          loadingError={loadingError}
        />
      );
    }

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <PageHeader>
          <h3 className="mt-1">Запланировал: {user.fullname_or_anonymus}</h3>
        </PageHeader>

        <div className="row p-static">
          <ResponsiveSidebar mobileTitle="Показать фильтры">
            <PublisherFilters />
          </ResponsiveSidebar>

          <div className="col-lg-8 col-12 order-lg-1">
            {loading && <Loading />}
            {loadingError && <LoadingError />}
            {!loading && !loadingError && !secrets.length && <EmptyList />}

            <MediaQuery maxWidth={767}>
              {(matches) => <Pagination total={pages} small={matches} />}
            </MediaQuery>

            {secrets.map((secret) => (
              <Secret key={secret.id} secret={secret} />
            ))}

            <MediaQuery maxWidth={767}>
              {(matches) => <Pagination total={pages} small={matches} />}
            </MediaQuery>
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    loadUser: PropTypes.func.isRequired,
    loadSecrets: PropTypes.func.isRequired,
    setPageTitle: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.publishers,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (userId) => dispatch(loadUser(userId)),
  loadSecrets: (userId) => dispatch(loadPublisherSecrets(userId)),
  setPageTitle: (title) => dispatch(setPageTitle(title)),
});

export const PublisherSecrets = connect(
  mapStateToProps,
  mapDispatchToProps
)(Secrets);
