import React, { Component } from "react";
import PropTypes from "prop-types";

import { CategoryForm } from "./";
import { SvgEdit } from "../../svg/";

export class Category extends Component {
  state = { showForm: false };

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  render() {
    const { showForm } = this.state;
    const { category } = this.props;

    if (showForm) {
      return <CategoryForm category={category} close={this.toggleForm} />;
    }

    return <CategoryRow category={category} onEditClick={this.toggleForm} />;
  }

  static propTypes = {
    category: PropTypes.objectOf(PropTypes.any).isRequired,
  };
}

const CategoryRow = ({ category, onEditClick }) => (
  <tr key={`category-${category.id}-form`}>
    <td>{category.id}</td>
    <td>{category.name}</td>
    <td>{category.alias}</td>
    <td title={category.description}>
      {(category.description || "").length > 20
        ? `${(category.description || "").slice(0, 20)}...`
        : category.description}
    </td>
    <td>
      {[
        category.hide_from_list ? "Прил " : null,
        category.hide_from_site ? "Сайт " : null,
        category.hide_from_settings ? "Пол " : null,
        category.hide_in_underside ? "Изн " : null,
        category.hide_for_vip ? "VIP " : null,
        category.hide_in_dashboard ? "Адм " : null,
      ]
        .filter(Boolean)
        .join(" / ")}
    </td>
    <td>{category.post_count}</td>
    <td
      title={`Ios: ${category.ios_excluded_percent}%; Android: ${category.android_excluded_percent}%; Windows: ${category.windows_excluded_percent}%; Huawei: ${category.huawei_excluded_percent}%; RuStore: ${category.rustore_excluded_percent}`}
    >
      {category.total_excluded_percent > 0 && category.total_excluded_percent}
    </td>
    <td className="text-right action-buttons">
      <button
        onClick={onEditClick}
        className="btn btn-sm btn-primary"
        title="Редактировать"
      >
        <SvgEdit width={14} height={12} className="fill-white" />
      </button>
    </td>
  </tr>
);

CategoryRow.propTypes = {
  category: PropTypes.objectOf(PropTypes.any).isRequired,
  onEditClick: PropTypes.func.isRequired,
};
