import React from "react";
import PropTypes from "prop-types";

import { highlightDiff } from "../../../libs/";

const FieldMap = {
  category_name: "Категория",
  note: "Текст",
  status: "Статус",
};

export const Diff = ({ data }) => {
  const fields = Object.keys(data);

  return (
    <ul className="list-unstyled">
      {fields.map((field) => {
        let diffHtml = `${data[field][0]} => ${data[field][1]}`;
        if (field.match(/^note.*$/)) {
          diffHtml = (
            <blockquote
              dangerouslySetInnerHTML={{
                __html: highlightDiff(data[field][0], data[field][1]),
              }}
            />
          );
        }
        return (
          <li key={field}>
            <b>&bull; {FieldMap[field] || field}</b>: {diffHtml}
          </li>
        );
      })}
    </ul>
  );
};

Diff.propTypes = {
  data: PropTypes.objectOf(PropTypes.array).isRequired,
};
