import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";

import {
  loadFilterOptionsForSecrets,
  toggleQueryParam,
  setQueryParam,
  loadUsers,
} from "../../actions/";
import {
  Loading,
  LoadingError,
  CategoryFilter,
  FilterItem,
  GroupedDropdown,
} from "../common/";
import moment from "moment";
import { GroupedDropdownUsersDeleted } from "../common/helpers/grouped-dropdown-users-deleted";

const authorGroupList = [
  { value: "vip", title: "VIP" },
  { value: "keeper", title: "Хранители" },
];

class Filters extends Component {
  state = {
    filterOptions: null,
    loading: true,
    usersDeletedGroupList: [],
  };

  componentDidMount() {
    this.props.load().then((e) => this.setState(e));

    const usersDeletedGroupTypes = ["admin", "editor", "moderator"];

    this.props.loadUsers(usersDeletedGroupTypes).then((e) => {
      const usersDeletedGroupList = e.users.map((user) => ({
        value: user.id.toString(),
        title: user.fullname || "Неизвестный",
      }));

      this.setState({ usersDeletedGroupList });
    });
  }

  onAuthorFilterChange = (selected) => {
    this.props.setQueryParam({
      name: "author_filter",
      value: selected.filter,
      defaultValue: "",
    });
    this.props.setQueryParam({
      name: "author_groups",
      value: selected.groups,
      defaultValue: [],
    });
  };

  onUsersDeletedFilterChange = (selected) => {
    this.props.setQueryParam({
      name: "users_deleted_filter",
      value: selected.filter,
      defaultValue: "",
    });
    this.props.setQueryParam({
      name: "users_deleted_groups",
      value: selected.groups,
      defaultValue: [],
    });
  };

  render() {
    const { filterOptions, loading } = this.state;
    const { filters, toggleQueryParam, setQueryParam } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (!filterOptions) {
      return <LoadingError />;
    }

    const {
      source,
      length,
      rating,
      weighted_rating: weightedRating,
      views,
      uniqueness,
      sort_by: sortBy,
    } = filterOptions;
    let sortingOptions =
      filters.type === "scheduled"
        ? sortBy.scheduled
        : filters.type === "rejected" || filters.type === "rejected_by_worker"
        ? sortBy.rejected
        : sortBy.default;
    if (filters.onlyChosen) {
      sortingOptions = sortBy.chosen;
    }

    return (
      <div className="block-sorting mb-4">
        <div className="row justify-content-between">
          <div className="col">
            <h3>
              Фильтрация
              {window.location.search && (
                <Fragment>
                  {" ("}
                  <Link to={window.location.pathname}>сбросить</Link>
                  {")"}
                </Fragment>
              )}
            </h3>
          </div>
        </div>

        {filters.type === "pending" && (
          <div className="row justify-content-between mb-2">
            <div className="col">Выбранные</div>
            <div className="col-auto">
              <label className="custom-checkbox-switcher">
                <input
                  type="checkbox"
                  name="only_chosen"
                  checked={filters.onlyChosen}
                  onChange={(e) =>
                    toggleQueryParam({
                      name: "only_chosen",
                      show: e.target.checked,
                      value: "yes",
                    })
                  }
                />
                <span className="brown" />
              </label>
            </div>
          </div>
        )}

        {filters.type === "scheduled" && (
          <div className="row justify-content-between mb-2">
            <div className="col">Только мои</div>
            <div className="col-auto">
              <label className="custom-checkbox-switcher">
                <input
                  type="checkbox"
                  name="scheduled_by_me"
                  checked={filters.scheduledByMe}
                  onChange={(e) =>
                    toggleQueryParam({
                      name: "scheduled_by_me",
                      show: e.target.checked,
                      value: "yes",
                    })
                  }
                />
                <span className="brown" />
              </label>
            </div>
          </div>
        )}

        <FilterItem
          title="Источник"
          name="source"
          options={source}
          current={filters.source}
        />

        <CategoryFilter mode="include" />
        <CategoryFilter mode="exclude" />

        <FilterItem
          title="Размер"
          name="secret_length"
          options={length}
          current={filters.secretLength}
        />
        <FilterItem
          title="Рейтинг"
          name="rating"
          options={rating}
          current={filters.rating}
        />
        <FilterItem
          title="Удельный рейтинг"
          name="weighted_rating"
          options={weightedRating}
          current={filters.weightedRating}
        />
        <FilterItem
          title="Просмотры"
          name="views"
          options={views}
          current={filters.views}
        />
        <FilterItem
          title="Уникальность"
          name="uniqueness"
          options={uniqueness}
          current={filters.uniqueness}
        />

        <div className="row justify-content-between mb-2">
          <div className="col">Авторство</div>
          <div className="col-auto">
            <GroupedDropdown
              groups={authorGroupList}
              selected={{
                filter: filters.authorFilter,
                groups: filters.authorGroups,
              }}
              callback={this.onAuthorFilterChange}
            />
          </div>
        </div>

        {(filters.type === "rejected" || filters.type === "") && (
          <div className="row justify-content-between mb-2">
            <div className="col">Кто удалил</div>
            <div className="col-auto">
              <GroupedDropdownUsersDeleted
                groups={this.state.usersDeletedGroupList}
                selected={{
                  filter: filters.usersDeletedFilter,
                  groups: filters.usersDeletedGroups,
                }}
                callback={this.onUsersDeletedFilterChange}
              />
            </div>
          </div>
        )}

        <div>
          Дата {filters.type === "scheduled" ? "публикации" : "создания"}:
        </div>

        <div className="row no-gutters mt-1 mb-2">
          <div className="col">
            <DatePicker
              name="date_from"
              dateFormat="dd.MM.yyyy"
              selected={
                filters.dateFrom
                  ? new Date(moment(filters.dateFrom, "DD.MM.YYYY"))
                  : null
              }
              onChange={(date) =>
                setQueryParam({
                  name: "date_from",
                  value: date ? moment(date).format("DD.MM.YYYY") : "",
                  defaultValue: "",
                })
              }
              placeholderText="от"
              className="form-control custom-select"
            />
          </div>

          <div className="col ml-1">
            <DatePicker
              name="date_to"
              dateFormat="dd.MM.yyyy"
              selected={
                filters.dateTo
                  ? new Date(moment(filters.dateTo, "DD.MM.YYYY"))
                  : null
              }
              onChange={(date) =>
                setQueryParam({
                  name: "date_to",
                  value: date ? moment(date).format("DD.MM.YYYY") : "",
                  defaultValue: "",
                })
              }
              placeholderText="до"
              className="form-control custom-select"
            />
          </div>
        </div>

        {["", "rejected", "rejected_by_worker"].includes(filters.type) && (
          <>
            <div>Дата удаления:</div>

            <div className="row no-gutters mt-1 mb-2">
              <div className="col">
                <DatePicker
                  name="removed_date_from"
                  dateFormat="dd.MM.yyyy"
                  selected={
                    filters.removedDateFrom
                      ? new Date(moment(filters.removedDateFrom, "DD.MM.YYYY"))
                      : null
                  }
                  onChange={(date) =>
                    setQueryParam({
                      name: "removed_date_from",
                      value: date ? moment(date).format("DD.MM.YYYY") : "",
                      defaultValue: "",
                    })
                  }
                  placeholderText="от"
                  className="form-control custom-select"
                />
              </div>

              <div className="col ml-1">
                <DatePicker
                  name="removed_date_to"
                  dateFormat="dd.MM.yyyy"
                  selected={
                    filters.removedDateTo
                      ? new Date(moment(filters.removedDateTo, "DD.MM.YYYY"))
                      : null
                  }
                  onChange={(date) =>
                    setQueryParam({
                      name: "removed_date_to",
                      value: date ? moment(date).format("DD.MM.YYYY") : "",
                      defaultValue: "",
                    })
                  }
                  placeholderText="до"
                  className="form-control custom-select"
                />
              </div>
            </div>
          </>
        )}

        {filters.type !== "published" && (
          <FilterItem
            title="Сортировка"
            name="sort_by"
            options={sortingOptions}
            current={filters.sortBy}
          />
        )}
      </div>
    );
  }

  static propTypes = {
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    load: PropTypes.func.isRequired,
    loadUsers: PropTypes.func.isRequired,
    toggleQueryParam: PropTypes.func.isRequired,
    setQueryParam: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.secrets,
});

const mapDispatchToProps = (dispatch) => ({
  load: () => dispatch(loadFilterOptionsForSecrets()),
  loadUsers: (types) => dispatch(loadUsers(types)),
  toggleQueryParam: (data) => dispatch(toggleQueryParam(data)),
  setQueryParam: (data) => dispatch(setQueryParam(data)),
});

export const SecretFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters);
