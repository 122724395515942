import { DataLayer } from "../../libs/";

export const cancelBan = (id) => async () => {
  const newState = {};

  try {
    const response = await DataLayer.put({
      path: `/bans/${id}/cancel.json`,
      loader: true,
    });

    newState.ban = response;
  } catch (err) {
    console.log(err);
  }

  return newState;
};
