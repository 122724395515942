import React, { Component } from "react";
import PropTypes from "prop-types";

import { SvgDown } from "../../svg/";

import { PostListItem } from "./";

export class PostsListScheduled extends Component {
  state = {
    show: false,
  };

  toggleScheduled = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    const { show } = this.state;
    const { posts } = this.props;

    return (
      <div className="posts-scheduled">
        {show &&
          posts.map((post) => (
            <PostListItem key={`post_${post.id}`} post={post} />
          ))}

        <button
          type="button"
          className={`btn-wide mb-sm-3${show ? " opened" : ""}`}
          onClick={this.toggleScheduled}
        >
          <span>{show ? "Скрыть" : "Показать"} запланированные</span>
          <SvgDown width={9} height={6} className="toggler" />
        </button>
      </div>
    );
  }

  static propTypes = {
    posts: PropTypes.arrayOf(PropTypes.object).isRequired,
  };
}
