import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";
import _ from "lodash";

import { loadChatMessageComplains, loadChatMessageStats } from "../../actions/";
import {
  Loading,
  LoadingError,
  PageHeader,
  Pagination,
  EmptyList,
} from "../../components/common/";

import {
  Complain,
  ChatMessageStats,
} from "../../components/chat_message_complains";

class ChatMessageComplains extends Component {
  state = {
    stats: null,
    complains: [],
    loading: true,
    loadingError: false,
    pages: 0,
    filters: this.props.filters,
  };

  componentDidMount() {
    this.props.loadStats().then((e) => this.setState(e));
    this.props.load().then((e) => this.setState(e));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevState.filters, this.state.filters)) {
      this.props.load().then((e) => this.setState(e));
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.filters, state.filters)) {
      return { filters: props.filters, loading: true, loadingError: false };
    }
    return null;
  }

  render() {
    const { stats, complains, loading, loadingError, pages } = this.state;

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <div className="row">
          <div className="col-xl-8 mx-auto">
            <PageHeader />

            {stats && <ChatMessageStats stats={stats} />}

            <MediaQuery maxWidth={575}>
              {(matches) => <Pagination total={pages} small={matches} />}
            </MediaQuery>

            {loading && <Loading className="mb-4" />}
            {loadingError && <LoadingError />}
            {!loading && !loadingError && !complains[0] && <EmptyList />}
            {complains.map((complain) => (
              <Complain key={complain.id} complain={complain} />
            ))}

            <MediaQuery maxWidth={575}>
              {(matches) => <Pagination total={pages} small={matches} />}
            </MediaQuery>
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    load: PropTypes.func.isRequired,
    loadStats: PropTypes.func.isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.chat_message_complains,
});

const mapDispatchToProps = (dispatch) => ({
  load: () => dispatch(loadChatMessageComplains()),
  loadStats: () => dispatch(loadChatMessageStats()),
});

export const ChatMessageComplainIndex = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatMessageComplains);
