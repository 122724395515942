import { push } from "connected-react-router";
import _ from "lodash";

export const setQueryParam =
  ({ name, value, defaultValue }) =>
  (dispatch, getState) => {
    const { search } = getState().router.location;
    const params = new URLSearchParams(search);

    if (name !== "page") {
      params.delete("page");
    }

    if (
      value === defaultValue ||
      (value instanceof Array &&
        defaultValue instanceof Array &&
        _.xor(value, defaultValue).length === 0)
    ) {
      params.delete(name);
    } else {
      params.set(name, value);
    }

    dispatch(push(`?${params.toString()}`));
  };
