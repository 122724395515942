import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { CategoryFilter, FilterItem } from "../../components/common/";
import { Link } from "react-router-dom";

const PeriodList = [
  { title: "Сутки", value: "day" },
  { title: "Неделя", value: "week" },
  { title: "Месяц", value: "month" },
  { title: "Год", value: "year" },
  { title: "Все время", value: "alltime" },
];

const CommentStatusList = [
  { title: "Все", value: "" },
  { title: "Открытые", value: "true" },
  { title: "Закрытые", value: "false" },
];

const Filters = ({ filters }) => {
  return (
    <div className="block-sorting mb-4">
      <h3>
        Фильтрация
        {window.location.search && (
          <Fragment>
            {" ("}
            <Link to={window.location.pathname}>сбросить</Link>
            {")"}
          </Fragment>
        )}
      </h3>
      {filters.type === "best" && (
        <FilterItem
          title="Период"
          name="period"
          options={PeriodList}
          current={filters.period}
        />
      )}
      <CategoryFilter />

      {filters.type === "published" && (
        <FilterItem
          title="Комментарии"
          name="enable_comments"
          options={CommentStatusList}
          current={filters.enableComments}
        />
      )}
    </div>
  );
};

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.filters.posts,
});

export const PostFilters = connect(mapStateToProps)(Filters);
