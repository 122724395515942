import React from "react";

import Settings from "./settings";

export class SystemSettings extends Settings {
  render() {
    return (
      <div className="settings-item bg-white p-2">
        <h5>Настройки игры</h5>

        <div className="row align-items-center mt-3">
          <div className="col">Игра</div>
          <div className="col-sm-5 col-6">
            {this.booleanRadio("show_game", ["Включена", "Отключена"])}
          </div>
        </div>

        <h5 className="mt-5">Версии клиентов</h5>

        <div className="row align-items-center mt-3">
          <div className="col">iOS</div>
          <div className="col-sm-5 col-6">
            {this.input("clients.ios_version")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">Android</div>
          <div className="col-sm-5 col-6">
            {this.input("clients.android_version")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">Huawei</div>
          <div className="col-sm-5 col-6">
            {this.input("clients.huawei_version")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">RuStore</div>
          <div className="col-sm-5 col-6">
            {this.input("clients.rustore_version")}
          </div>
        </div>

        <h5 className="mt-5">Настройки планировщика</h5>

        <div className="row align-items-center mt-3">
          <div className="col">Время начала публикации</div>
          <div className="col-sm-5 col-6">{this.input("scheduler.start")}</div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">Время окончания публикации</div>
          <div className="col-sm-5 col-6">{this.input("scheduler.stop")}</div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">Период между публикациями</div>
          <div className="col-sm-5 col-6">{this.input("scheduler.period")}</div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Предельный размер очереди при автоматическом добавлении выбранных из
            изнанки секретов
          </div>
          <div className="col-sm-5 col-6">
            {this.input("scheduler.queue_size")}
          </div>
        </div>

        <div className="mt-7 text-center">
          <button
            className="btn btn-primary"
            onClick={() => this.save("system")}
          >
            Сохранить
          </button>
        </div>
      </div>
    );
  }
}
