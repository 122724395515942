import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal } from "../";
import { baseUrl } from "../../../libs";

const ManualBanReasonId = "0";
const CommentLikeTypes = [
  { title: "Все", value: "all" },
  { title: "Лайки", value: "likes" },
  { title: "Дизлайки", value: "dislikes" },
];
const CommentLikePeriods = [
  { title: "За день", value: "day" },
  { title: "За неделю", value: "week" },
  { title: "За месяц", value: "month" },
  { title: "Всё время", value: "all_time" },
];

class ModalContainer extends Component {
  constructor(props) {
    super(props);
    const { ban } = props;

    let banReasonManual = ban.reason_text || "";
    if (ban.reason_id) {
      const banReason = props.banReasons.find(
        (el) => el.id === parseInt(ban.reason_id, 10)
      );
      banReasonManual = banReason ? banReason.description : "";
    }

    this.state = {
      banReasonId: ban.reason_id || ManualBanReasonId,
      banReasonManual: banReasonManual,
      note: ban.note || "",
      withComments: false,
      withCommentLikes: false,
      commentLikeType: "all",
      commentLikePeriod: "day",
      user: ban.banned_user,
      comment: ban.comment,
    };
  }

  componentDidMount() {
    this.setTextAreasHeight();
  }

  componentDidUpdate() {
    this.setTextAreasHeight();
  }

  setTextAreasHeight = () => {
    if (this.banReasonManualRef) {
      this.banReasonManualRef.style.height = "20px";
      this.banReasonManualRef.style.height = `${
        this.banReasonManualRef.scrollHeight + 2
      }px`;
    }
    if (this.noteRef) {
      this.noteRef.style.height = "20px";
      this.noteRef.style.height = `${this.noteRef.scrollHeight + 2}px`;
    }
  };

  handleBanReason = (e) => {
    const banReason = this.props.banReasons.find(
      (el) => el.id === parseInt(e.target.value, 10)
    );
    this.setState({
      banReasonId: e.target.value,
      banReasonManual: banReason ? banReason.description : "",
    });
  };

  handleBanReasonManual = (e) => {
    this.setState({
      banReasonId: ManualBanReasonId,
      banReasonManual: e.target.value,
    });
  };

  handleNote = (e) => {
    this.setState({ note: e.target.value });
  };

  handleCheckbox = (e) => {
    const newState = {};
    newState[e.target.name] = e.target.checked;
    this.setState(newState);
  };

  handleSelect = (e) => {
    const newState = {};
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  };

  banUser = (forever = false) => {
    const {
      banReasonId,
      banReasonManual,
      note,
      withComments,
      withCommentLikes,
      commentLikeType,
      commentLikePeriod,
      comment,
    } = this.state;
    const formData = {
      note: note,
      reason_id: banReasonId,
      reason_text: banReasonManual,
    };
    if (comment) formData.comment_id = comment.id;
    if (withComments) formData.with_comments = true;
    if (withCommentLikes) {
      formData.with_comment_likes = true;
      formData.comment_like_type = commentLikeType;
      formData.comment_like_period = commentLikePeriod;
    }
    if (forever) formData.forever = true;
    this.props.save(formData);
  };

  banUserForever = () => {
    this.banUser(true);
  };

  updateBan = () => {
    const { banReasonId, banReasonManual, note } = this.state;
    const formData = {
      note: note,
      reason_id: banReasonId,
      reason_text: banReasonManual,
    };
    this.props.save(formData);
  };

  render() {
    const {
      banReasonId,
      banReasonManual,
      note,
      withComments,
      withCommentLikes,
      commentLikeType,
      commentLikePeriod,
      user,
      comment,
    } = this.state;
    const { banReasons, ban, toggleModal, hasAdminAccess } = this.props;
    const disabled =
      banReasonId.length === 0 ||
      (banReasonId === ManualBanReasonId && banReasonManual.length === 0);

    return (
      <Modal
        title={`Бан пользователя ${user.fullname_or_anonymus}`}
        small
        toggleModal={toggleModal}
      >
        <div>
          {comment && (
            <div className="ban-reason">
              <div className="text-muted mb-1">
                {"За комментарий к посту "}
                <a
                  href={`${baseUrl}/posts/${comment.post_id}?branch=${
                    comment.root_id || comment.id
                  }#c${comment.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <b className="text-danger">#{comment.post_id}</b>
                </a>
              </div>
              {comment.text_fixed}
            </div>
          )}

          <div className="mt-25 mb-1">
            <b>Причина</b>
          </div>
          <select
            className="form-control custom-select"
            value={banReasonId}
            onChange={this.handleBanReason}
          >
            {banReasons.map((banReason) => (
              <option key={banReason.id} value={banReason.id}>
                {banReason.title}
              </option>
            ))}
            <option key={ManualBanReasonId} value={ManualBanReasonId}>
              Другая
            </option>
          </select>

          <div className="mt-25 mb-1">
            <b>Описание для пользователя</b>
          </div>
          <textarea
            rows="1"
            className="form-control mt-1"
            ref={(c) => {
              this.banReasonManualRef = c;
            }}
            name="banReasonManual"
            value={banReasonManual}
            onChange={this.handleBanReasonManual}
          />

          <div className="mt-25 mb-1">
            <b>Заметка</b>{" "}
            <small className="text-muted">(для администрации)</small>
          </div>
          <textarea
            rows="1"
            className="form-control"
            ref={(c) => {
              this.noteRef = c;
            }}
            name="note"
            value={note}
            onChange={this.handleNote}
          />

          {ban.id && (
            <div className="mt-25 text-right">
              <button
                type="button"
                onClick={toggleModal}
                className="btn btn-primary mr-15"
              >
                Отмена
              </button>
              <button
                type="button"
                onClick={this.updateBan}
                className="btn btn-secondary"
                disabled={disabled}
              >
                Изменить причину бана
              </button>
            </div>
          )}

          {!ban.id && (
            <Fragment>
              <label
                key="withComments"
                htmlFor="with-comments"
                className="custom-control custom-checkbox mt-25"
              >
                <input
                  type="checkbox"
                  id="with-comments"
                  className="custom-control-input"
                  name="withComments"
                  checked={withComments}
                  onChange={this.handleCheckbox}
                />
                <span className="custom-control-indicator" />
                <span className="custom-control-description">
                  Со всеми комментариями
                </span>
              </label>
              {hasAdminAccess && (
                <Fragment>
                  <br />
                  <label
                    key="withCommentLikes"
                    htmlFor="with-comment-likes"
                    className="custom-control custom-checkbox"
                  >
                    <input
                      type="checkbox"
                      id="with-comment-likes"
                      className="custom-control-input"
                      name="withCommentLikes"
                      checked={withCommentLikes}
                      onChange={this.handleCheckbox}
                    />
                    <span className="custom-control-indicator" />
                    <span className="custom-control-description">
                      С оценками комментариев
                    </span>
                  </label>
                  {withCommentLikes && (
                    <div className="row">
                      <div className="col-6">
                        <select
                          className="form-control custom-select"
                          name="commentLikeType"
                          value={commentLikeType}
                          onChange={this.handleSelect}
                        >
                          {CommentLikeTypes.map((likeType) => (
                            <option key={likeType.value} value={likeType.value}>
                              {likeType.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-6">
                        <select
                          className="form-control custom-select"
                          name="commentLikePeriod"
                          value={commentLikePeriod}
                          onChange={this.handleSelect}
                        >
                          {CommentLikePeriods.map((likePeriod) => (
                            <option
                              key={likePeriod.value}
                              value={likePeriod.value}
                            >
                              {likePeriod.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
              <div key="buttons" className="mt-25 text-right">
                <button
                  type="button"
                  onClick={(e) => this.banUserForever()}
                  className="btn btn-danger danger-light mr-15"
                  disabled={disabled}
                >
                  Забанить навсегда
                </button>
                <button
                  type="button"
                  onClick={(e) => this.banUser()}
                  className="btn btn-danger"
                  disabled={disabled}
                >
                  Забанить
                </button>
              </div>
            </Fragment>
          )}
        </div>
      </Modal>
    );
  }

  static propTypes = {
    ban: PropTypes.objectOf(PropTypes.any).isRequired,
    toggleModal: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    banReasons: PropTypes.arrayOf(PropTypes.any).isRequired,
    hasAdminAccess: PropTypes.bool.isRequired,
  };
}

const mapStateToProps = (state) => ({
  banReasons: state.banReasons,
  hasAdminAccess: state.user.hasAdminAccess,
});

export const BanModal = connect(mapStateToProps)(ModalContainer);
