import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";

import { loadUser, loadRatingAwards, setPageTitle } from "../../actions/";
import {
  Loading,
  LoadingError,
  EmptyList,
  Pagination,
  NotFound,
  PageHeader,
} from "../../components/common/";
import { baseUrl } from "../../libs/";
import { ProfileLink, RatingAward } from "../../components/users";

export class RatingAwards extends Component {
  state = {
    userId: parseInt(this.props.match.params.userId, 10),
    user: null,
    ratingAwards: [],
    loading: true,
    loadingError: false,
    pages: 0,
    filters: this.props.filters,
  };

  componentDidMount() {
    const { userId } = this.state;

    this.props.loadUser(userId).then((newState) => {
      if (newState.user) {
        newState.loading = true;
        this.props.loadRatingAwards(userId).then((e) => this.setState(e));
      }
      this.setState(newState);
    });

    this.props.setPageTitle(`История начисления рейтинга пользователя`);
  }

  componentWillUnmount() {
    this.props.setPageTitle(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevState.filters, this.state.filters)) {
      this.props
        .loadRatingAwards(this.state.userId)
        .then((e) => this.setState(e));
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.filters, state.filters)) {
      return { filters: props.filters, secrets: [], loading: true };
    }
    return null;
  }

  render() {
    const { userId, user, ratingAwards, loading, loadingError, pages } =
      this.state;

    if (!user) {
      return (
        <NotFound
          title="Пользователь"
          id={userId}
          loading={loading}
          loadingError={loadingError}
        />
      );
    }

    const baseLink = `${baseUrl}/users/${userId}/rating_awards`;

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <div className="bg-white p-2 p-sm-3">
          <PageHeader>
            <h2>
              <ProfileLink user={user} />
            </h2>
            <nav>
              <NavLink to={baseLink} exact>
                Все
              </NavLink>
              <NavLink to={`${baseLink}/complain_comment`}>Жалоба</NavLink>
              <NavLink to={`${baseLink}/post_secret`}>Публикация</NavLink>
              <NavLink to={`${baseLink}/like_comment`}>Лайк</NavLink>
              <NavLink to={`${baseLink}/top`}>Топ</NavLink>
              <NavLink to={`${baseLink}/self_registred`}>Регистрация</NavLink>
              <NavLink to={`${baseLink}/location_setup`}>Город</NavLink>
              <NavLink to={`${baseLink}/all_underside`}>Изнанка</NavLink>
              <NavLink to={`${baseLink}/admin_reward`}>
                От администратора
              </NavLink>
            </nav>
          </PageHeader>

          <MediaQuery maxWidth={575}>
            {(matches) => <Pagination total={pages} small={matches} />}
          </MediaQuery>

          {loading && <Loading />}
          {loadingError && <LoadingError />}
          {!loading && !loadingError && !ratingAwards.length && <EmptyList />}
          {ratingAwards.length > 0 && (
            <table className="table table-responsive table-striped table-sm table-hover">
              <colgroup>
                <col width="220" />
                <col width="100" />
                <col />
                <col width="140" />
              </colgroup>
              <thead>
                <tr>
                  <th>Тип</th>
                  <th>Количество</th>
                  <th>Примечание</th>
                  <th>Дата</th>
                </tr>
              </thead>
              <tbody>
                {ratingAwards.map((ratingAward) => (
                  <RatingAward key={ratingAward.id} ratingAward={ratingAward} />
                ))}
              </tbody>
            </table>
          )}

          <MediaQuery maxWidth={575}>
            {(matches) => <Pagination total={pages} small={matches} />}
          </MediaQuery>
        </div>
      </div>
    );
  }

  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    loadUser: PropTypes.func.isRequired,
    loadRatingAwards: PropTypes.func.isRequired,
    setPageTitle: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.ratingAwards,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (userId) => dispatch(loadUser(userId)),
  loadRatingAwards: (userId) => dispatch(loadRatingAwards(userId)),
  setPageTitle: (title) => dispatch(setPageTitle(title)),
});

export const UserRatingAwards = connect(
  mapStateToProps,
  mapDispatchToProps
)(RatingAwards);
