import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";

import { hideComment, restoreComment, updateComment } from "../../actions/";
import {
  baseUrl,
  fullDate,
  fromDate,
  pluralizeCharacters,
  highlightDiff,
  highlightBlackListWords,
} from "../../libs/";
import { SvgDown, SvgCrown, SvgHide, SvgEdit, SvgShow } from "../../svg/";
import { BanButton, Date } from "../common/";
import { ProfileLink } from "../users/";
import { CommentForm, CommentHeaderMenuFull, CommentHeaderMenuSmall } from "./";

class CommentComponent extends Component {
  state = {
    prevComment: this.props.comment,
    comment: this.props.comment,
    text: this.props.comment.text_fixed,
    showEditForm: false,
    showEditChanges: false,
    showReplyForm: false,
    showLikes: false,
  };

  componentDidMount() {
    if (this.containerRef && this.props.highlight) {
      this.containerRef.classList.add("highlighted");
      // setTimeout(() => { this.containerRef.classList.remove('highlighted'); }, 4000);
    }
  }

  componentDidUpdate() {
    if (this.textAreaRef) {
      this.textAreaRef.style.height = "20px";
      this.textAreaRef.style.height = `${this.textAreaRef.scrollHeight}px`;
      this.textAreaRef.focus();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.comment !== state.prevComment) {
      return {
        prevComment: props.comment,
        comment: props.comment,
      };
    }
    return null;
  }

  toggleEditForm = () => {
    this.setState({
      showEditForm: !this.state.showEditForm,
      text: this.state.comment.text_fixed,
    });
  };

  toggleEditChanges = () => {
    this.setState({ showEditChanges: !this.state.showEditChanges });
  };

  toggleReplyForm = () => {
    this.setState({ showReplyForm: !this.state.showReplyForm });
  };

  toggleLikes = (e) => {
    e.preventDefault();
    this.setState({ showLikes: !this.state.showLikes });
  };

  handleTextChange = (e) => {
    this.setState({ text: e.target.value });
  };

  handleUserBan = (user) => {
    if (user.banned) {
      this.hide(); // additional request returns updated values of removed_at and removed_by fields
    }
  };

  hide = () => {
    const { id } = this.state.comment;
    this.props
      .hide({ id, callback: this.props.handleHide })
      .then((e) => this.setState(e));
  };

  restore = () => {
    const { id } = this.state.comment;
    this.props.restore({ id }).then((e) => this.setState(e));
  };

  update = () => {
    const {
      comment: { id },
      text,
    } = this.state;
    const formData = { text_fixed: text };
    this.props.update({ id, formData }).then((e) => this.setState(e));
  };

  render() {
    const {
      comment,
      text,
      showEditForm,
      showEditChanges,
      showReplyForm,
      showLikes,
    } = this.state;
    const {
      handleReply,
      highlightWords,
      withIndents,
      showBanReason,
      isComplainFeed,
    } = this.props;
    const { user } = comment;
    const branchLink = `${baseUrl}/posts/${comment.post_id}?branch=${
      comment.root_id || comment.id
    }#c${comment.id}`;
    const disableSave = comment.text_fixed.trim().length === 0;
    const isPostAuthor = comment.post_user_id === comment.user.id;

    let containerClasses = showEditForm ? "comment-edit" : "comment-item";
    if (comment.hidden) {
      containerClasses += " hidden";
    }
    if (comment.bad || (isComplainFeed && comment.complain_feed)) {
      containerClasses += " bad";
    }

    const answerClass = withIndents && comment.root_id ? " answer" : "";
    const branchSizeClass =
      comment.branch_size && comment.branch_size > 5 ? "text-danger" : null;

    return (
      <div
        className={`row no-gutters mb-sm-3 mb-1 comment-item-wrapper${answerClass}`}
        ref={(c) => {
          this.containerRef = c;
        }}
      >
        <div className="col">
          <div className={containerClasses} name={`c${comment.id}`}>
            <header className="row justify-content-between">
              <div className="col-auto pr-0">
                <div className="row no-gutters">
                  <div className="col-auto align-top">
                    <ProfileLink
                      user={user}
                      className={`user-avatar opacity${
                        user.keeper ? " keeper" : ""
                      }`}
                    >
                      <Fragment>
                        <img
                          src={user.avatar}
                          width={40}
                          height={40}
                          className="rounded-circle"
                          alt=""
                        />
                        {user.vip && (
                          <span className="badge">
                            <SvgCrown width={9.4} height={9.4} />
                          </span>
                        )}
                      </Fragment>
                    </ProfileLink>
                  </div>

                  <div className="col pl-15">
                    <ProfileLink
                      user={user}
                      className={`btn btn-link p-0 opacity${
                        isPostAuthor ? " post-author" : ""
                      }${user.keeper ? " keeper-profile-link" : ""}${
                        comment.moderator ? " moderator-profile-link" : ""
                      }${user.removed_at ? " removed-user" : ""}`}
                    />
                    {user.banned_count > 0 && (
                      <Link
                        to={`${baseUrl}/users/${user.id}/bans`}
                        className="bans opacity"
                        target="_blank"
                        title="Баны пользователя"
                      >
                        {user.banned_count}
                      </Link>
                    )}

                    <div className="text-muted opacity">
                      <a
                        href="#"
                        title="Рейтинг комментария"
                        className="font-weight-bold"
                        onClick={this.toggleLikes}
                      >
                        {comment.rating > 0 && "+"}
                        {comment.rating}
                      </a>
                      <span>・</span>
                      <b title="Жалобы на комментарий">
                        {comment.comment_complains_count}
                      </b>
                      <span>・</span>
                      {comment.branch_size !== null && (
                        <Fragment>
                          <b title="Размер ветки" className={branchSizeClass}>
                            {comment.branch_size}
                          </b>
                          <span>・</span>
                        </Fragment>
                      )}
                      <Date
                        className="date text-muted"
                        date={comment.created_at}
                      />
                    </div>

                    {showLikes && (
                      <div className="row comment-likes no-gutters">
                        {comment.top_likes.map((like) => (
                          <div className="col-4 col-sm-3">
                            <span className={`emoji-icon ${like.type}`}>
                              {like.count}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {!showEditForm && (
                <div className="col-auto pl-0">
                  <MediaQuery maxWidth={449}>
                    <CommentHeaderMenuFull
                      comment={comment}
                      branchLink={branchLink}
                      reply={this.toggleReplyForm}
                    />
                  </MediaQuery>

                  <MediaQuery minWidth={450} maxWidth={767}>
                    <CommentHeaderMenuSmall
                      comment={comment}
                      branchLink={branchLink}
                      reply={this.toggleReplyForm}
                    />
                  </MediaQuery>

                  <MediaQuery minWidth={768} maxWidth={991}>
                    <CommentHeaderMenuFull
                      comment={comment}
                      branchLink={branchLink}
                      reply={this.toggleReplyForm}
                    />
                  </MediaQuery>

                  <MediaQuery minWidth={992} maxWidth={1199}>
                    <CommentHeaderMenuSmall
                      comment={comment}
                      branchLink={branchLink}
                      reply={this.toggleReplyForm}
                    />
                  </MediaQuery>

                  <MediaQuery minWidth={1200}>
                    <CommentHeaderMenuFull
                      comment={comment}
                      branchLink={branchLink}
                      reply={this.toggleReplyForm}
                    />
                  </MediaQuery>
                </div>
              )}
            </header>

            {/* text */}
            {showEditForm ? (
              <textarea
                className="form-control"
                rows="1"
                ref={(c) => {
                  this.textAreaRef = c;
                }}
                onChange={this.handleTextChange}
                value={text}
                placeholder="Текст комментария"
              />
            ) : (
              <div className="std">
                {comment.parent_id ? (
                  <div className="parent-user-link">
                    <ProfileLink
                      className="opacity user-link"
                      user={comment.parent_user}
                    />
                    {", "}
                  </div>
                ) : (
                  ""
                )}
                <span
                  dangerouslySetInnerHTML={{
                    __html: highlightBlackListWords(
                      comment.text_fixed,
                      highlightWords
                    ),
                  }}
                />
              </div>
            )}

            {/* edit buttons */}
            {showEditForm && (
              <div className="row align-items-center mt-3">
                <div className="col text-muted">
                  {pluralizeCharacters(text.length)}
                </div>
                <div className="col-auto">
                  <button
                    type="reset"
                    className="btn btn-secondary mr-2"
                    onClick={this.toggleEditForm}
                  >
                    Отмена
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={this.update}
                    disabled={disableSave}
                  >
                    Сохранить
                  </button>
                </div>
              </div>
            )}

            {(comment.moderator || comment.hidden) && (
              <div className="details text-muted">
                <div className="row">
                  {comment.moderator && (
                    <div className="col-md-3 col-6 mt-2">
                      <b>Ответил: </b>
                      <ProfileLink
                        className="opacity user-link"
                        user={comment.moderator}
                      />
                    </div>
                  )}

                  {comment.hidden && comment.removed_at && (
                    <Fragment>
                      <div className="col-md-3 col-6 mt-2">
                        <b>Скрыл:</b>
                        {comment.remover ? (
                          <ProfileLink
                            className="opacity user-link"
                            user={comment.remover}
                          />
                        ) : (
                          comment.removed_by_worker
                        )}
                      </div>
                      <div className="col-md-3 col-6 mt-2">
                        <b>Дата скрытия:</b>
                        {fullDate(comment.removed_at)}
                      </div>
                      {showBanReason && comment.ban_reason && (
                        <div className="col-md-3 col-6 mt-2">
                          <b>Бан за:</b>
                          {comment.ban_reason}
                        </div>
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
            )}

            {comment.edited && !showEditForm && (
              <div className="edited">
                <button
                  type="button"
                  className={`btn btn-link btn-block p-0${
                    showEditChanges ? " opened" : ""
                  }`}
                  onClick={this.toggleEditChanges}
                >
                  Отредактирован{" "}
                  {fromDate(comment.edited_at, comment.created_at)}
                  <SvgDown className="ml-1" width={8} heigth={8} />
                </button>
                {showEditChanges && (
                  <div
                    className="text mt-2"
                    dangerouslySetInnerHTML={{
                      __html: highlightDiff(
                        comment.original_text,
                        comment.text_fixed
                      ),
                    }}
                  />
                )}
              </div>
            )}

            {showReplyForm && (
              <CommentForm
                postId={comment.post_id}
                parentCommentId={comment.id}
                saveCallback={handleReply}
                toggleForm={this.toggleReplyForm}
              />
            )}
          </div>
        </div>

        {/* side buttons */}
        <MediaQuery minWidth={576}>
          {(matches) => (
            <div className="col-sm-auto col-12 comment-buttons ml-sm-15">
              {!comment.hidden && !showEditForm && (
                <div className="inner">
                  {comment.editable && (
                    <div>
                      <button
                        title="Редактировать"
                        type="button"
                        className="btn btn-shadow mb-sm-1 btn-comment-edit"
                        onClick={this.toggleEditForm}
                      >
                        {matches ? (
                          <SvgEdit
                            width={20}
                            height={20}
                            className="fill-primary"
                          />
                        ) : (
                          "Редактировать"
                        )}
                      </button>
                    </div>
                  )}

                  <div className="hide">
                    <button
                      title="Скрыть"
                      type="button"
                      className="btn btn-shadow mb-sm-1 btn-comment-hide"
                      onClick={this.hide}
                    >
                      {matches ? (
                        <SvgHide
                          width={20}
                          height={20}
                          className="fill-primary"
                        />
                      ) : (
                        "Скрыть"
                      )}
                    </button>
                  </div>

                  <div>
                    <BanButton
                      isIconic={matches}
                      classes="btn btn-shadow btn-dropdown btn-comment-ban"
                      user={comment.user}
                      comment={comment}
                      callback={this.handleUserBan}
                    />
                  </div>
                </div>
              )}

              {comment.hidden && (
                <div className="inner">
                  <div className="restore">
                    <button
                      type="button"
                      title="Восстановить"
                      className="btn btn-shadow mb-sm-1 btn-comment-show"
                      onClick={this.restore}
                    >
                      {matches ? (
                        <SvgShow
                          width={20}
                          height={20}
                          className="fill-primary"
                        />
                      ) : (
                        "Восстановить"
                      )}
                    </button>
                  </div>

                  <div>
                    <BanButton
                      isIconic={matches}
                      classes="btn btn-shadow btn-dropdown btn-comment-ban"
                      user={comment.user}
                      comment={comment}
                      callback={this.handleUserBan}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </MediaQuery>
      </div>
    );
  }

  static propTypes = {
    comment: PropTypes.objectOf(PropTypes.any).isRequired,
    handleReply: PropTypes.func.isRequired,
    handleHide: PropTypes.func.isRequired,
    highlightWords: PropTypes.arrayOf(PropTypes.string).isRequired,
    withIndents: PropTypes.bool,
    showBanReason: PropTypes.bool,
    hide: PropTypes.func.isRequired,
    restore: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    isComplainFeed: PropTypes.bool.isRequired,
    highlight: PropTypes.bool,
  };

  static defaultProps = {
    withIndents: false,
    showBanReason: false,
    highlight: false,
  };
}

const mapStateToProps = (state) => ({
  isComplainFeed: state.filters.comments.type === "with_complains",
});

const mapDispatchToProps = (dispatch) => ({
  hide: (data) => dispatch(hideComment(data)),
  restore: (data) => dispatch(restoreComment(data)),
  update: (data) => dispatch(updateComment(data)),
});

export const Comment = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentComponent);
