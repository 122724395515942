import { DataLayer } from "../../libs/";
import { loadCountries } from "../";

export const updateCountry = (country) => async (dispatch) => {
  await DataLayer.put({
    path: `/countries/${country.id}.json`,
    body: country,
    loader: true,
  }).catch(() => {});
  await dispatch(loadCountries(true));
};
