import { DataLayer } from "../../libs/";

export const deleteBan =
  ({ userId }) =>
  async () => {
    const newState = {};

    await DataLayer.delete({
      path: `/users/${userId}/unban.json`,
      loader: true,
    })
      .then((response) => {
        newState.banned = response.banned;
        newState.unbanModal = response.false;
      })
      .catch(() => {});

    return newState;
  };
