import { DataLayer } from "../../libs/";

export const loadPushMessages = () => async () => {
  const newState = {
    loading: false,
    loadingError: false,
  };

  await DataLayer.get({ path: "/push_messages.json" })
    .then((response) => {
      newState.pushMessages = response.push_messages;
      newState.recentPosts = response.recent_posts;
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
