import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import { createCategory, updateCategory } from "../../actions/";
import { Error } from "../common/";
import { SvgCheck, SvgCross } from "../../svg/";

class Form extends Component {
  state = {
    category: this.props.category,
    errors: null,
  };

  handleChange = (e) => {
    const category = { ...this.state.category };
    category[e.target.name] = e.target.value;
    this.setState({ category });
  };

  handleCheckboxChange = (e) => {
    const category = { ...this.state.category };
    category[e.target.name] = e.target.checked;
    this.setState({ category });
  };

  save = () => {
    const { category } = this.state;
    this[category.id ? "update" : "create"](category);
  };

  create = (category) => {
    this.props
      .create(category)
      .then((_) => this.props.close())
      .catch((error) =>
        this.setState({
          errors:
            _.get(error, "data.errors") ||
            "Произошла ошибка. Попробуйте позже.",
        })
      );
  };

  update = (category) => {
    this.props
      .update(category)
      .then((_) => this.props.close())
      .catch((error) =>
        this.setState({
          errors:
            _.get(error, "data.errors") ||
            "Произошла ошибка. Попробуйте позже.",
        })
      );
  };

  render() {
    const {
      errors,
      category: {
        id,
        name,
        hide_from_list: hideFromList,
        hide_from_site: hideFromSite,
        hide_from_settings: hideFromSettings,
        hide_in_underside: hideInUnderside,
        hide_for_vip: hideForVip,
        hide_in_dashboard: hideInDashboard,
        post_count: postCount,
        total_excluded_percent: totalExcludedPercent,
        alias,
        description,
      },
    } = this.state;
    const { close } = this.props;
    const disabled = !name || name.length === 0;

    return (
      <Fragment>
        <tr>
          <td>{id}</td>
          <td valign={"middle"}>
            <input
              type="text"
              name="name"
              placeholder="Название"
              value={name || ""}
              onChange={this.handleChange}
              className="form-control form-control-sm"
            />
            <input
              type="text"
              name="alias"
              placeholder="Слаг"
              value={alias || ""}
              onChange={this.handleChange}
              className="form-control form-control-sm"
            />
          </td>
          <td colSpan={2}>
            <textarea
              name="description"
              placeholder="Описание"
              value={description || ""}
              rows={4}
              onChange={this.handleChange}
              className="form-control form-control-sm"
            />
          </td>
          <td>
            <input
              type="checkbox"
              name="hide_from_list"
              checked={!!hideFromList}
              onChange={this.handleCheckboxChange}
            />
            {" / "}
            <input
              type="checkbox"
              name="hide_from_site"
              checked={!!hideFromSite}
              onChange={this.handleCheckboxChange}
            />
            {" / "}
            <input
              type="checkbox"
              name="hide_from_settings"
              checked={!!hideFromSettings}
              onChange={this.handleCheckboxChange}
            />
            {" / "}
            <input
              type="checkbox"
              name="hide_in_underside"
              checked={!!hideInUnderside}
              onChange={this.handleCheckboxChange}
            />
            {" / "}
            <input
              type="checkbox"
              name="hide_for_vip"
              checked={!!hideForVip}
              onChange={this.handleCheckboxChange}
            />
            {" / "}
            <input
              type="checkbox"
              name="hide_in_dashboard"
              checked={!!hideInDashboard}
              onChange={this.handleCheckboxChange}
            />
          </td>
          <td>{postCount}</td>
          <td>{totalExcludedPercent > 0 && totalExcludedPercent}</td>
          <td className="text-right text-nowrap action-buttons">
            <button
              className="btn btn-success btn-sm"
              onClick={this.save}
              disabled={disabled}
              title="Сохранить"
            >
              <SvgCheck width={14} height={12} className="fill-white" />
            </button>
            <button
              className="btn btn-primary btn-sm ml-1"
              onClick={close}
              title="Отменить"
            >
              <SvgCross width={14} height={12} className="fill-white" />
            </button>
          </td>
        </tr>
        {errors && (
          <tr>
            <td colSpan={8}>
              <Error errors={errors} />
            </td>
          </tr>
        )}
      </Fragment>
    );
  }

  static propTypes = {
    category: PropTypes.objectOf(PropTypes.any).isRequired,
    create: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createCategory(data)),
  update: (data) => dispatch(updateCategory(data)),
});

export const CategoryForm = connect(null, mapDispatchToProps)(Form);
