import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Date } from "../common";
import { ProfileLink } from "../users/";
import { SvgDevices } from "../../svg/";
import { baseUrl } from "../../libs";

export const PushMessage = ({ pushMessage }) => (
  <div className="push-item my-3">
    <header className="row align-items-center mb-25">
      <div className="col font-weight-500">
        <ProfileLink user={pushMessage.author} className="btn btn-link p-0" />
      </div>

      {pushMessage.post && (
        <div className="col-auto">
          <Link
            to={`${baseUrl}/posts/${pushMessage.post.id}`}
            className="btn btn-link opacity p-0"
          >
            #{pushMessage.post.id}
          </Link>
        </div>
      )}

      {!pushMessage.special_for_device.includes("all") && (
        <div className="col-auto">
          {pushMessage.special_for_device.map((device, index) => {
            const SvgIcon = SvgDevices[device];
            const className = `device-svg${index > 0 ? " ml-1" : ""}`;
            return (
              <SvgIcon
                key={device}
                width={16}
                height={16}
                className={className}
              />
            );
          })}
        </div>
      )}
      <div className="col-auto text-muted">
        <Date date={pushMessage.created_at} />
      </div>
    </header>

    <h6>{pushMessage.title}</h6>
    <div className="std">{pushMessage.body}</div>
  </div>
);

PushMessage.propTypes = {
  pushMessage: PropTypes.objectOf(PropTypes.any).isRequired,
};
