import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { loadEditorStats } from "../../actions/";
import { Loading, LoadingError } from "../../components/common/";
import { baseUrl } from "../../libs/";

class Stats extends Component {
  state = {
    loading: true,
    loadingError: false,
  };

  componentDidMount() {
    this.loadStats();
  }

  loadStats = () => {
    this.props.load().then((e) => this.setState(e));
  };

  render() {
    const { loading, loadingError } = this.state;
    const { userId, stats } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (loadingError) {
      return <LoadingError retry={this.loadStats} />;
    }

    if (!stats) return null;

    return (
      <div className="block-filters mb-4">
        <h3>Статистика публикаций</h3>

        <h4 className="mb-2">{moment().subtract(1, "month").format("MMMM")}</h4>

        <div className="row align-items-end mb-2">
          <div className="col">Добавлено:</div>
          <div className="col-auto h3">
            <a
              href={`${baseUrl}/publishers/${userId}/secrets/added/prev_month`}
              target="_blank"
              rel="noreferrer"
            >
              {stats.prev_month_added}
            </a>
          </div>
        </div>

        <div className="row align-items-end mb-2">
          <div className="col">Опубликовано:</div>
          <div className="col-auto h3">
            <a
              href={`${baseUrl}/publishers/${userId}/posts/published/prev_month`}
              target="_blank"
              rel="noreferrer"
            >
              {stats.prev_month_published}
            </a>
          </div>
        </div>

        <div className="row align-items-end mb-2">
          <div className="col">Удалено:</div>
          <div className="col-auto h3">
            <a
              href={`${baseUrl}/publishers/${userId}/posts/removed/prev_month`}
              target="_blank"
              rel="noreferrer"
            >
              {stats.prev_month_removed}
            </a>
          </div>
        </div>

        <div className="row align-items-end mb-2">
          <div className="col">Лайки:</div>
          <div className="col-auto h3">{stats.prev_month_likes_per_post}</div>
        </div>

        <div className="row align-items-end mb-2">
          <div className="col">Комментарии:</div>
          <div className="col-auto h3">
            {stats.prev_month_comments_per_post}
          </div>
        </div>

        <div className="row align-items-end mb-2">
          <div className="col">Закладки:</div>
          <div className="col-auto h3">
            {stats.prev_month_bookmarks_per_post}
          </div>
        </div>

        <h4 className="mb-2">{moment().format("MMMM")}</h4>

        <div className="row align-items-end mb-2">
          <div className="col">Добавлено:</div>
          <div className="col-auto h3">
            <a
              href={`${baseUrl}/publishers/${userId}/secrets/added/this_month`}
              target="_blank"
              rel="noreferrer"
            >
              {stats.this_month_added}
            </a>
          </div>
        </div>

        <div className="row align-items-end mb-2">
          <div className="col">Опубликовано:</div>
          <div className="col-auto h3">
            <a
              href={`${baseUrl}/publishers/${userId}/posts/published/this_month`}
              target="_blank"
              rel="noreferrer"
            >
              {stats.this_month_published}
            </a>
          </div>
        </div>

        <div className="row align-items-end mb-2">
          <div className="col">Удалено:</div>
          <div className="col-auto h3">
            <a
              href={`${baseUrl}/publishers/${userId}/posts/removed/this_month`}
              target="_blank"
              rel="noreferrer"
            >
              {stats.this_month_removed}
            </a>
          </div>
        </div>

        <div className="row align-items-end mb-2">
          <div className="col">Запланировано:</div>
          <div className="col-auto h3">
            <a
              href={`${baseUrl}/publishers/${userId}/secrets/scheduled/this_month`}
              target="_blank"
              rel="noreferrer"
            >
              {stats.this_month_scheduled}
            </a>
          </div>
        </div>

        <div className="row align-items-end mb-2">
          <div className="col">Лайки:</div>
          <div className="col-auto h3">{stats.this_month_likes_per_post}</div>
        </div>

        <div className="row align-items-end mb-2">
          <div className="col">Комментарии:</div>
          <div className="col-auto h3">
            {stats.this_month_comments_per_post}
          </div>
        </div>

        <div className="row align-items-end mb-2">
          <div className="col">Закладки:</div>
          <div className="col-auto h3">
            {stats.this_month_bookmarks_per_post}
          </div>
        </div>

        <h4 className="mb-2">{moment().add(1, "month").format("MMMM")}</h4>

        <div className="row align-items-end mb-2">
          <div className="col">Запланировано:</div>
          <div className="col-auto h3">
            <a
              href={`${baseUrl}/publishers/${userId}/secrets/scheduled/next_month`}
              target="_blank"
              rel="noreferrer"
            >
              {stats.next_month_scheduled}
            </a>
          </div>
        </div>

        <h4 className="mb-2">Всего</h4>
        <div className="row align-items-end mb-2">
          <div className="col">Опубликовано:</div>
          <div className="col-auto h3">
            <a
              href={`${baseUrl}/publishers/${userId}/posts/published`}
              target="_blank"
              rel="noreferrer"
            >
              {stats.all_time_published}
            </a>
          </div>
        </div>

        <div className="row align-items-end mb-2">
          <div className="col">Удалено:</div>
          <div className="col-auto h3">
            <a
              href={`${baseUrl}/publishers/${userId}/posts/removed`}
              target="_blank"
              rel="noreferrer"
            >
              {stats.all_time_removed}
            </a>
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    userId: PropTypes.number.isRequired,
    load: PropTypes.func.isRequired,
    stats: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    stats: null,
  };
}

const mapStateToProps = (state) => ({
  userId: state.user.id,
  stats: state.editorStats,
});

const mapDispatchToProps = (dispatch) => ({
  load: () => dispatch(loadEditorStats()),
});

export const EditorStats = connect(mapStateToProps, mapDispatchToProps)(Stats);
