import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { SvgSearch } from "../../../../svg/";
import { UserAddFormButton } from "../../../users/";

const Buttons = ({ hasEditorAccess, toggleSearch }) => (
  <div className="col-auto">
    <div className="row no-gutters align-items-center">
      <div className="col">
        <button type="submit" className="btn btn-link p-0">
          <SvgSearch
            width={16}
            height={16}
            className="fill-primary"
            onClick={toggleSearch}
          />
        </button>
      </div>

      {hasEditorAccess && (
        <div className="col-auto ml-sm-3 ml-2">
          <UserAddFormButton />
        </div>
      )}
    </div>
  </div>
);

Buttons.propTypes = {
  hasEditorAccess: PropTypes.bool.isRequired,
  toggleSearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  hasEditorAccess: state.user.hasEditorAccess,
});

export const ButtonsUsers = connect(mapStateToProps)(Buttons);
