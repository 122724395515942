import { DataLayer } from "../../libs/";

export const updateUser =
  ({ id, formData }) =>
  () =>
    DataLayer.put({
      path: "/users/" + id + ".json",
      body: formData,
      loader: true,
    });
