import { DataLayer } from "../../libs/";

export const loadCategories =
  ({ withPostCount = false, loader = false }) =>
  async (dispatch) => {
    const categories = localStorage.getItem("categories");

    if (categories && !withPostCount) {
      return dispatch({
        type: "SET_CATEGORIES",
        payload: JSON.parse(categories),
      });
    }

    if (withPostCount) {
      await DataLayer.get({
        path: "/categories.json?all_with_post_count=1",
        loader,
      })
        .then((response) => {
          dispatch({
            type: "SET_CATEGORIES_WITH_POST_COUNT",
            payload: response,
          });
        })
        .catch(() => {});
    }

    DataLayer.get({ path: "/categories.json" })
      .then((response) => {
        localStorage.setItem("categories", JSON.stringify(response));
        dispatch({ type: "SET_CATEGORIES", payload: response });
      })
      .catch(() => {});
  };
