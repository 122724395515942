import { DataLayer } from "../../libs/";

export const updateSecret =
  ({ formData, secret }) =>
  async () => {
    const newState = {
      errors: null,
      editConflict: null,
      formData: null,
    };

    await DataLayer.put({
      path: "/secrets/" + secret.id + ".json",
      body: formData,
      loader: true,
    })
      .then((response) => {
        newState.secret = response;
        if (formData.secret.note) {
          newState.showForm = false;
        }
      })
      .catch((error) => {
        newState.formData = formData;

        if (error.status === 409) {
          const editConflict = { ...error.data, initialDiff: {} };

          Object.keys(editConflict.savedData).forEach((field) => {
            if (secret[field] !== editConflict.savedData[field]) {
              editConflict.initialDiff[field] = [
                secret[field],
                editConflict.savedData[field],
              ];
            }
          });

          newState.editConflict = editConflict;
        } else {
          newState.errors = error.data
            ? error.data.errors || error.data
            : error.message;
        }
      });

    return newState;
  };
