import { DataLayer } from "../../libs/";
import { loadCities } from "../";

export const updateCity = (city) => async (dispatch) => {
  await DataLayer.put({
    path: `/cities/${city.id}.json`,
    body: city,
    loader: true,
  }).catch(() => {});
  const response = await dispatch(loadCities({}));
  return response.cities;
};
