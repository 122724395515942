import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadSecret, setPageTitle } from "../../actions/";
import { NotFound, PageHeader } from "../../components/common/";
import { Secret } from "../../components/secrets/";
import { SecretHistory } from "./";

class SecretComponent extends Component {
  state = {
    secretId: parseInt(this.props.match.params.id, 10),
    secret: null,
    loading: true,
    loadingError: false,
  };

  componentDidMount() {
    this.props.load(this.state.secretId).then((e) => this.setState(e));
    this.props.setPageTitle(`Секрет #${this.state.secretId}`);
  }

  componentWillUnmount() {
    this.props.setPageTitle(null);
  }

  render() {
    const { secret, secretId, loading, loadingError } = this.state;

    if (!secret) {
      return (
        <NotFound
          title="Секрет"
          id={secretId}
          loading={loading}
          loadingError={loadingError}
        />
      );
    }

    return (
      <div className="container mt-lg-5 mt-3">
        <div className="row">
          <div className="col-xl-8 mx-auto">
            <PageHeader />

            <Secret key={secret.id} secret={secret} />

            <SecretHistory secretId={secretId} postId={secret.post_id} />
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    load: PropTypes.func.isRequired,
    setPageTitle: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  load: (id) => dispatch(loadSecret(id)),
  setPageTitle: (title) => dispatch(setPageTitle(title)),
});

export const SecretShow = connect(null, mapDispatchToProps)(SecretComponent);
