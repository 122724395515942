import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";

export class ChatMessageStats extends Component {
  state = {
    showForMobile: false,
  };

  toggle = () => {
    this.setState({ showForMobile: !this.state.showForMobile });
  };

  render() {
    const { showForMobile } = this.state;
    const { stats } = this.props;
    return (
      <MediaQuery minWidth={992}>
        {(matches) => {
          if (matches) return <Content stats={stats} />;

          return (
            <div className="mb-3">
              <button
                key="button"
                type="button"
                className="btn btn-block btn-secondary"
                onClick={this.toggle}
              >
                Показать статистику
              </button>
              {showForMobile && (
                <aside>
                  <div className="aside-wrapper">
                    <Content key="stats" stats={stats} />
                    <button
                      type="button"
                      className="btn btn-block btn-primary mt-5"
                      onClick={this.toggle}
                    >
                      Закрыть
                    </button>
                  </div>
                </aside>
              )}
            </div>
          );
        }}
      </MediaQuery>
    );
  }

  static propTypes = {
    stats: PropTypes.objectOf(PropTypes.any).isRequired,
  };
}

const Content = ({ stats }) => (
  <div className="totals mb-3">
    <div className="row">
      <div className="col-lg-6">
        <div className="row">
          <div className="col">Чаты</div>
          <div className="col-auto font-weight-500">{stats.chats}</div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="row">
          <div className="col">Активированных юзеров</div>
          <div className="col-auto font-weight-500">{stats.activated}</div>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-lg-6">
        <div className="row">
          <div className="col">Созданные VIP</div>
          <div className="col-auto font-weight-500">{stats.chats_by_vips}</div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="row">
          <div className="col">Сообщений</div>
          <div className="col-auto font-weight-500">{stats.messages}</div>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-lg-6">
        <div className="row">
          <div className="col">Созданные хранителями</div>
          <div className="col-auto font-weight-500">
            {stats.chats_by_keepers}
          </div>
        </div>
      </div>
    </div>
  </div>
);

Content.propTypes = {
  stats: PropTypes.objectOf(PropTypes.any).isRequired,
};
