export const cleanRequestBody = (body) => {
  const result = JSON.parse(JSON.stringify(body));
  skipEmpty(result);

  return result;
};

// Функция очистки приходящего request.body от пустых, null и undefined значений
const skipEmpty = (body) => {
  for (const key in body) {
    // если тип number, string, boolean
    if (body[key] === null || body[key] === undefined || body[key] === "") {
      // убираем пустые значения
      delete body[key];
    }

    // если массив элементов
    else if (body[key] instanceof Array) {
      // фильтруем и убираем пустые значения
      // тут без any не обойтись т.к. значения в массиве могут быть различными
      body[key] = body[key].filter(
        (item) => item !== null && item !== undefined && item !== ""
      );

      if (!body[key]?.length) {
        delete body[key];
      }

      // приводим к нужным типам, потому что мы знаем, что рассматриваем массив
      // а typescript не понимает, что если body[key] instance of Array, то body[key] никак не может быть null
      for (const item in body[key]) {
        if (typeof body[key][item] === "object") {
          skipEmpty(body[key][item]);
        }
      }

      // если это вложенный объект - прогоняем ф-цию ещё раз
    } else if (typeof body[key] === "object") {
      skipEmpty(body[key]);
    }
  }
};
