import React, { Component } from "react";
import PropTypes from "prop-types";

const touchEvent = "ontouchstart" in window ? "touchstart" : "click";

export class ClickOutside extends Component {
  componentDidMount() {
    window.addEventListener(touchEvent, this.handleClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener(touchEvent, this.handleClick, false);
  }

  handleClick = (e) => {
    if (!this.props.isOpen) return;

    if (!this.Dropdown.contains(e.target)) {
      this.props.onBlur();
    }
  };

  render() {
    const { className, children } = this.props;

    return (
      <div
        className={className}
        ref={(c) => {
          this.Dropdown = c;
        }}
      >
        {children}
      </div>
    );
  }

  static propTypes = {
    className: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    className: "d-inline",
  };
}
