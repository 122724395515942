import React from "react";

export const EmptyList = () => (
  <div className="alert alert-primary">
    По вашему запросу ничего не найдено.
  </div>
);

export const EndOfList = () => (
  <div className="alert alert-light text-center">Конец</div>
);
