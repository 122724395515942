import React from "react";
import PropTypes from "prop-types";

import Settings from "./settings";
import { VKTabs, VKLinkForm, VKLink } from "./";
import _ from "lodash";

export class VKLinks extends Settings {
  toggleForm = () => {
    this.setState({ showAddForm: !this.state.showAddForm });
  };

  saveLink = (link) => {
    const data = { ...this.state.data };
    const links = data.links ? [...data.links] : [];
    data.links = this.updateLinksList(link, links);
    this.setState({ data, showAddForm: false }, () => this.save("vk"));
  };

  deleteLink = (id) => {
    const data = { ...this.state.data };
    _.remove(data.links, ["id", id]);
    data.timers.forEach((timer, index) => {
      if (timer.link_id === id) {
        data.timers[index].link_id = null;
      }
    });
    this.setState({ data }, () => this.save("vk"));
  };

  updateLinksList = (item, list) => {
    const indexById = _.findIndex(list, ["id", item.id]);
    const indexByUrl = _.findIndex(list, ["url", item.url]);

    if (indexById >= 0) {
      list[indexById] = item;
    } else if (indexByUrl >= 0) {
      alert("Ошибка! Такая ссылка уже существует.");
    } else {
      item.id = list.length > 0 ? list[list.length - 1].id + 1 : 1;
      list.push(item);
    }

    return list;
  };

  render() {
    const {
      showAddForm,
      data: { links },
    } = this.state;
    return (
      <div className="settings-item bg-white p-2">
        <VKTabs addButtonTitle="ссылку" addButtonCallback={this.toggleForm} />

        <table className="table table-responsive table-striped table-sm table-hover">
          <colgroup>
            <col />
            <col width="90" />
          </colgroup>
          <thead>
            <tr>
              <th>URL</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {showAddForm && (
              <VKLinkForm
                key="add"
                close={this.toggleForm}
                saveCallback={this.saveLink}
              />
            )}

            {links &&
              links.map((item) => (
                <VKLink
                  key={item.id}
                  item={item}
                  saveCallback={this.saveLink}
                  deleteCallback={this.deleteLink}
                />
              ))}
          </tbody>
        </table>
      </div>
    );
  }

  static propTypes = {
    data: PropTypes.object.isRequired,
    saveCallback: PropTypes.func.isRequired,
  };
}
