import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { baseUrl } from "../../../../libs/";
import { SvgNext, SvgPrev } from "../../../../svg/";

const Buttons = ({ prevId, nextId }) => (
  <div className="navs post-nav">
    {!!prevId && (
      <Link
        to={`${baseUrl}/posts/${prevId}`}
        className="btn btn-lg btn-secondary rounded"
      >
        <SvgPrev />
      </Link>
    )}

    {!!nextId && (
      <Link
        to={`${baseUrl}/posts/${nextId}`}
        className="btn btn-lg btn-secondary rounded"
      >
        <SvgNext />
      </Link>
    )}
  </div>
);

Buttons.propTypes = {
  prevId: PropTypes.number,
  nextId: PropTypes.number,
};

Buttons.defaultProps = {
  prevId: null,
  nextId: null,
};

const mapStateToProps = (state) => ({
  prevId: state.postLinks.prevId,
  nextId: state.postLinks.nextId,
});

export const ButtonsPost = connect(mapStateToProps)(Buttons);
