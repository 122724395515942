import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { baseUrl } from "../../libs/";
import { ProfileModal } from "./";

export class ProfileLink extends Component {
  state = {
    showProfileModal: false,
  };

  toggleProfileModal = (e) => {
    e.preventDefault();
    this.setState({ showProfileModal: !this.state.showProfileModal });
  };

  render() {
    const { showProfileModal } = this.state;
    const { user, className, children } = this.props;

    return (
      <Fragment>
        <Link
          to={`${baseUrl}/users/${user.id}`}
          onClick={this.toggleProfileModal}
          className={className}
        >
          {children || user.fullname_or_anonymus}
        </Link>
        {showProfileModal && (
          <ProfileModal user={user} toggleModal={this.toggleProfileModal} />
        )}
      </Fragment>
    );
  }

  static propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    className: PropTypes.string,
    children: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    className: null,
    children: null,
  };
}
