import { DataLayer } from "../../libs/";

export const loadStatistics = () => async (_, getState) => {
  const { type } = getState().filters.statistics;
  const newState = {
    loading: false,
    loadingError: false,
  };

  await DataLayer.get({ path: "/statistics/" + type + ".json" })
    .then((response) => {
      if (type === "posts") {
        newState.created = response.created;
        newState.bookmarks = response.bookmarks;
        newState.likes = response.likes;
      }

      if (type === "secrets") {
        newState.statusPanel = response.statusPanel;
        newState.created = response.created;
        newState.rejected = response.rejected;
        newState.published = response.published;
        newState.underside = response.underside;
        newState.underside_secrets = response.underside_secrets;
      }

      if (type === "comments") {
        newState.created = response.created;
        newState.rejected = response.rejected;
      }

      if (type === "users") {
        newState.created = response.created;
        newState.vip = response.vip;
        newState.active = response.active;
      }
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
