import { DataLayer } from "../../libs/";

export const loadReasons =
  (onlyBanReasons = false) =>
  async (dispatch) => {
    const banReasons = localStorage.getItem("banReasons");

    if (banReasons && onlyBanReasons) {
      return dispatch({
        type: "SET_BAN_REASONS",
        payload: JSON.parse(banReasons),
      });
    }

    const newState = {
      loadingError: false,
    };

    await DataLayer.get({ path: "/reasons.json" })
      .then((response) => {
        newState.reasons = response;
        localStorage.setItem("banReasons", JSON.stringify(response.for_ban));
        dispatch({ type: "SET_BAN_REASONS", payload: response.for_ban });
      })
      .catch(() => {
        newState.loadingError = true;
      });

    return newState;
  };
