import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { VKGeneralSettings, VKTimers, VKLinks } from "./";

const VKSettingsByType = ({ vkTab, data, saveCallback }) => {
  if (vkTab === "timers") {
    return <VKTimers data={data} saveCallback={saveCallback} />;
  }
  if (vkTab === "links") {
    return <VKLinks data={data} saveCallback={saveCallback} />;
  }
  return <VKGeneralSettings data={data} saveCallback={saveCallback} />;
};

VKSettingsByType.propTypes = {
  vkTab: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  saveCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  vkTab: state.filters.vk.vkTab,
});

export const VKSettings = connect(mapStateToProps)(VKSettingsByType);
