import { DataLayer } from "../../libs/";

export const loadSettingsHistory = () => async (dispatch, getState) => {
  const { page } = getState().filters.history;
  const params = [];
  const newState = {
    loading: false,
    loadingError: false,
  };

  if (page > 1) params.push(`page=${page}`);

  await DataLayer.get({
    path:
      "/settings/history.json" + (params.length ? "?" + params.join("&") : ""),
  })
    .then((response) => {
      newState.versions = response.versions;
      newState.pages = response.pages;
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
