export const scrollPage = (to) => {
  if ("scrollBehavior" in document.documentElement.style) {
    window.scrollTo({
      top: to,
      behavior: "smooth",
    });
  } else {
    window.document.documentElement.scrollTop = to;
  }
};
