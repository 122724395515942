import { DataLayer } from "../../libs/";

export const hideComment =
  ({ id, callback }) =>
  async () => {
    const newState = {};

    await DataLayer.post({
      path: `/comments/${id}/hide.json`,
      loader: true,
    })
      .then((response) => {
        if (callback) {
          callback(response);
        }
        newState.comment = response;
        newState.showReplyForm = false;
      })
      .catch(() => {});

    return newState;
  };
