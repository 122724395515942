import { DataLayer } from "../../libs/";

export const updateComment =
  ({ id, formData }) =>
  async () => {
    const newState = { showEditForm: false };
    await DataLayer.put({
      path: `/comments/${id}.json`,
      body: formData,
      loader: true,
    })
      .then((response) => {
        newState.comment = response;
        newState.text = response.text;
      })
      .catch(() => {
        newState.showEditForm = true;
      });
    return newState;
  };
