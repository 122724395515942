import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";

import { loadFilterOptionsForComments } from "../../actions/";
import { Loading, LoadingError, CategoryFilter, FilterItem } from "../common/";

class Filters extends Component {
  state = {
    filterOptions: {},
    live: localStorage.getItem("liveInComments") === "true",
    loading: true,
    filters: this.props.filters,
  };

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(state.filters, props.filters)) {
      return { filters: props.filters };
    }
    return null;
  }

  componentDidMount() {
    this.props.load().then((e) => this.setState(e));
  }

  changeLive = () => {
    const { live } = this.state;
    localStorage.setItem("liveInComments", !live);
    this.setState({ live: !live });
  };

  render() {
    const {
      filterOptions: { moderators, recentPostIds },
      live,
      loading,
    } = this.state;
    const {
      filters: { type, user, action, excludePost },
      hasAdminAccess,
    } = this.props;
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("ban");

    if (loading) {
      return <Loading />;
    }

    if (!recentPostIds) {
      return <LoadingError />;
    }

    return (
      <div className="block-sorting mb-4">
        <h3>
          Фильтрация
          {searchParams.toString().length > 0 && (
            <Fragment>
              {" ("}
              <Link
                to={window.location.pathname + (action === "ban" ? "?ban" : "")}
              >
                сбросить
              </Link>
              {")"}
            </Fragment>
          )}
        </h3>

        {type === "actions" && hasAdminAccess && (
          <FilterItem
            title="Модератор"
            name="user"
            options={moderators}
            current={user}
          />
        )}

        {type !== "actions" && <CategoryFilter className="mb-2" />}

        <FilterItem
          title="Исключить пост"
          name="exclude_post"
          options={recentPostIds}
          current={excludePost}
        />

        {["all", "bad"].includes(type) && (
          <div className="row mb-2">
            <div className="col">В реальном времени</div>
            <div className="col-auto">
              <label htmlFor="realtime" className="custom-checkbox-switcher">
                <input
                  type="checkbox"
                  id="realtime"
                  name="live"
                  checked={live}
                  onChange={this.changeLive}
                />
                <span />
              </label>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Filters.propTypes = {
  hasAdminAccess: PropTypes.bool.isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  load: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  hasAdminAccess: state.user.hasAdminAccess,
  filters: state.filters.comments,
});

const mapDispatchToProps = (dispatch) => ({
  load: () => dispatch(loadFilterOptionsForComments()),
});

export const CommentFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters);
