import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import _ from "lodash";

import { loadSocialAccounts, setPageTitle } from "../../actions/";
import { baseUrl } from "../../libs/";
import {
  Loading,
  LoadingError,
  EmptyList,
  PageHeader,
  ResponsiveSidebar,
} from "../../components/common/";
import {
  SocialAccountFilters,
  SocialAccountRow,
} from "../../components/social_accounts/";

class List extends Component {
  state = {
    accounts: [],
    loading: true,
    loadingError: false,
    end: false,
    filters: this.props.filters,
  };

  componentDidMount() {
    this.props.load().then((e) => this.setState(e));
    this.props.setPageTitle(`Соц. аккаунты`);
  }

  componentWillUnmount() {
    this.props.setPageTitle(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevState.filters, this.state.filters)) {
      this.props.load().then((e) => this.setState(e));
      // title never changes on any filter change
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.filters, state.filters)) {
      return {
        filters: props.filters,
        accounts: [],
        loading: true,
        loadingError: false,
        end: false,
      };
    }
    return null;
  }

  loadMore = () => {
    const { accounts } = this.state;

    this.setState({ loading: true });
    this.props.load({ accounts }).then((e) => this.setState(e));
  };

  render() {
    const { accounts, loading, loadingError, end } = this.state;

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <PageHeader>
          <nav>
            <NavLink
              exact
              to={`${baseUrl}/social_accounts/email`}
              activeClassName="active"
            >
              Почта
            </NavLink>
            <NavLink
              exact
              to={`${baseUrl}/social_accounts/fb`}
              activeClassName="active"
            >
              Facebook
            </NavLink>
            <NavLink
              exact
              to={`${baseUrl}/social_accounts/vk`}
              activeClassName="active"
            >
              VK
            </NavLink>
            <NavLink
              exact
              to={`${baseUrl}/social_accounts/apple`}
              activeClassName="active"
            >
              Apple
            </NavLink>
            <NavLink
              exact
              to={`${baseUrl}/social_accounts/dt`}
              activeClassName="active"
            >
              Device token
            </NavLink>
          </nav>
        </PageHeader>

        <div className="row p-static">
          <ResponsiveSidebar mobileTitle="Показать фильтры">
            <SocialAccountFilters />
          </ResponsiveSidebar>

          <div className="col-lg-8 col-12 order-lg-1">
            <table className="table">
              <thead>
                <tr>
                  <th>Аккаунт</th>
                  <th className="text-center">Дата</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {accounts.map((account) => (
                  <SocialAccountRow key={account.id} account={account} />
                ))}

                <tr>
                  <td colSpan={3}>
                    {accounts.length > 0 && !end && !loadingError && (
                      <VisibilitySensor
                        onChange={(isVisible) => isVisible && this.loadMore()}
                        children={<div className="visibility_sensor" />}
                      />
                    )}
                    {loading && <Loading />}
                    {loadingError && <LoadingError retry={this.loadMore} />}
                    {!loading && !loadingError && !accounts[0] && <EmptyList />}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    load: PropTypes.func.isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    setPageTitle: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.social_accounts,
});

const mapDispatchToProps = (dispatch) => ({
  load: (data = {}) => dispatch(loadSocialAccounts(data)),
  setPageTitle: (title) => dispatch(setPageTitle(title)),
});

export const SocialAccountIndex = connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
