import { DataLayer } from "../../libs/";

export const confirmEmail = (id) => async () => {
  const newState = {};
  await DataLayer.post({
    path: `/users/${id}/confirm_email.json`,
    loader: true,
  })
    .then((response) => {
      newState.user = response;
    })
    .catch(() => {});
  return newState;
};
