import React, { useEffect } from "react";

import { SimpleHeader, SimpleFooter } from "../components/simple_apparel/";

export const Page404 = ({ handle404 }) => {
  useEffect(() => {
    handle404(true);
    return () => handle404(false);
  });

  return (
    <div className="simple">
      <SimpleHeader />

      <div className="content">
        <div className="wrapper">
          <h3>Страница не найдена.</h3>
        </div>
      </div>

      <SimpleFooter />
    </div>
  );
};
