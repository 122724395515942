import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import { SvgMenu, SvgSearch } from "../../../svg/";
import { HeaderButton, SearchBox } from "../";
import { Nav, SearchBoxRoutes } from "./";

export class HeaderMobile extends Component {
  state = {
    showNav: false,
    showSearch: false,
  };

  toggleNav = () => {
    this.setState({ showNav: !this.state.showNav });
  };

  toggleSearch = () => {
    this.setState({ showSearch: !this.state.showSearch });
  };

  render() {
    const { showNav, showSearch } = this.state;
    const { pageTitle } = this.props;

    return (
      <header className="site-header">
        <div className="container">
          {showSearch ? (
            <SearchBox toggleSearch={this.toggleSearch} focus />
          ) : (
            <div className="row no-gutters align-items-center">
              <div className="col">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <button
                      type="button"
                      className="nav-toggler"
                      onClick={this.toggleNav}
                    >
                      <SvgMenu
                        width={16}
                        height={12}
                        className="fill-primary"
                      />
                    </button>
                  </div>

                  <div className="col active-nav">{pageTitle}</div>
                </div>
              </div>

              <div className="col-auto">
                <div className="row no-gutters align-items-center">
                  <Switch>
                    <Route exact path={SearchBoxRoutes}>
                      <div className="col">
                        <button type="submit" className="btn btn-link p-0">
                          <SvgSearch
                            width={16}
                            height={16}
                            className="fill-primary"
                            onClick={this.toggleSearch}
                          />
                        </button>
                      </div>
                    </Route>
                  </Switch>

                  <HeaderButton />
                </div>
              </div>
            </div>
          )}
        </div>

        {showNav && <Nav toggleNav={this.toggleNav} />}
      </header>
    );
  }

  static propTypes = {
    pageTitle: PropTypes.string,
  };
}
