import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import _ from "lodash";

import { loadSecrets } from "../../actions/";
import {
  Loading,
  LoadingError,
  Alert,
  Pagination,
  EmptyList,
} from "../../components/common/";
import { Secret } from "../../components/secrets/";
import { scrollPage } from "../../libs";

class List extends Component {
  state = {
    secrets: [],
    alert: null,
    loading: true,
    loadingError: false,
    pages: 0,
    filters: this.props.filters,
  };

  componentDidMount() {
    this.loadSecrets();
  }

  componentDidUpdate(__, prevState) {
    if (!_.isEqual(prevState.filters, this.state.filters)) {
      scrollPage(0);
      this.loadSecrets();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.filters, state.filters)) {
      return { filters: props.filters, secrets: [], loading: true };
    }
    return null;
  }

  loadSecrets = () => {
    this.props.load().then((e) => this.setState(e));
  };

  updateList = (action, secret) => {
    const secrets = [...this.state.secrets];
    const index = _.findIndex(secrets, ["id", secret.id]);

    if (secret.replacement) {
      _.remove(secrets, ["id", secret.replacement.id]); // remove a replacement from the list if exists

      if (action === "replace") {
        secrets.splice(index, 1, secret.replacement);
      } else if (action === "append") {
        secrets.splice(index + 1, 0, secret.replacement);
      }
    } else if (action === "replace" || action === "remove") {
      _.remove(secrets, ["id", secret.id]);
    }

    if (secrets.length > 0) {
      this.setState({ secrets });
    } else {
      this.loadSecrets();
    }
  };

  render() {
    const { secrets, alert, loading, loadingError, pages } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (loadingError) {
      return <LoadingError />;
    }

    if (secrets.length < 1) {
      return <EmptyList />;
    }

    return (
      <div>
        {alert && <Alert type={alert} />}

        <MediaQuery maxWidth={767}>
          {(matches) => <Pagination total={pages} small={matches} />}
        </MediaQuery>

        {secrets.map((secret) => (
          <Secret
            key={secret.id}
            secret={secret}
            updateList={this.updateList}
          />
        ))}

        <MediaQuery maxWidth={767}>
          {(matches) => <Pagination total={pages} small={matches} />}
        </MediaQuery>
      </div>
    );
  }

  static propTypes = {
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    load: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.secrets,
});

const mapDispatchToProps = (dispatch) => ({
  load: () => dispatch(loadSecrets()),
});

export const SecretList = connect(mapStateToProps, mapDispatchToProps)(List);
