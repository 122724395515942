import { cleanRequestBody } from "./cleanRequestBody";

export const baseUrl = "/dashboard";

export let devDomain = "";

if (!!process?.env?.REACT_APP_TO_STAGE) {
  devDomain = "https://stage.podslyshano.com";
}

if (!!process?.env?.REACT_APP_TO_LOCALHOST) {
  devDomain = "http://localhost:3000";
}

class DataLayer {
  get({ path, body, loader = false }) {
    return this.fetchJSON({ method: "GET", path, body, loader });
  }

  post({ path, body, loader = false, blob = false, headers }) {
    return this.fetchJSON({
      method: "POST",
      path,
      body,
      loader,
      blob,
      headers,
    });
  }

  put({ path, body, loader = false }) {
    return this.fetchJSON({ method: "PUT", path, body, loader });
  }

  delete({ path, body, loader = false }) {
    return this.fetchJSON({ method: "DELETE", path, body, loader });
  }

  generateUrl(method, url, payload) {
    const result = `${devDomain}${baseUrl}${url}`;

    if (method === "GET" && payload) {
      const params = new URLSearchParams(payload);
      return `${result}?${params.toString()}`;
    }

    return result;
  }

  generateParams(method, payload, customHeaders = {}) {
    const headers = {
      Accept: "*/*",
      "Content-Type": "application/json",
      ...customHeaders,
    };

    const params = {
      method: method,
      mode: "cors",
      credentials: "include",
      headers,
    };

    if (["POST", "PUT", "DELETE"].includes(method) && payload) {
      return {
        ...params,
        body: JSON.stringify(payload),
      };
    }

    return params;
  }

  async fetchJSON({ method, path, body, loader, blob, headers }) {
    let payload = body && cleanRequestBody(body);

    const url = this.generateUrl(method, path, payload);
    const params = this.generateParams(method, payload, headers);

    try {
      if (loader) {
        document.body.classList.add("loader");
      }

      const response = await fetch(url, params);

      if (blob) {
        return response;
      }

      if (!response.ok && response.status >= 400) {
        throw response;
      }

      return await response.json();
    } catch (error) {
      throw error;
    } finally {
      if (loader) {
        document.body.classList.remove("loader");
      }
    }
  }
}

export default new DataLayer();
