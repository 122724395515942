import _ from "lodash";

import { DataLayer } from "../../libs/";

export const loadSocialAccounts =
  ({ accounts = [] }) =>
  async (__, getState) => {
    const { type, searchQuery, user, bannedOnly } =
      getState().filters.social_accounts;
    const params = [];
    const newState = {
      loading: false,
      loadingError: false,
    };

    if (type) params.push(`type=${type}`);
    if (searchQuery) params.push(`uid=${searchQuery}`);
    if (user) params.push(`user_id=${user}`);
    if (bannedOnly) params.push(`banned_only=yes`);
    if (accounts.length > 0) {
      params.push(`from=${_.last(accounts).id}`);
    }

    await DataLayer.get({
      path:
        "/social_accounts.json" + (params.length ? "?" + params.join("&") : ""),
    })
      .then((response) => {
        newState.accounts = [...accounts, ...response];
        newState.end = !response.length;
      })
      .catch(() => {
        newState.loadingError = true;
      });

    return newState;
  };
