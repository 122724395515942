import React from "react";

import Settings from "./settings";

const devices = [
  { name: "ios", title: "iOS" },
  { name: "android", title: "Android" },
  { name: "huawei", title: "Huawei" },
  { name: "rustore", title: "RuStore" },
];

export class AdSettings extends Settings {
  render() {
    const { data } = this.state;
    return (
      <div className="settings-item bg-white p-2">
        <h5>Настройки рекламы</h5>

        <div className="row align-items-center mt-3">
          <div className="col">Реклама в Главной ленте</div>
          <div className="col-sm-5 col-6">
            <div className="custom-radio-box">
              {devices.map((device) =>
                this.checkbox(`enabled_in_posts.${device.name}`, device.title)
              )}
            </div>
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">Реклама в Лучших постах</div>
          <div className="col-sm-5 col-6">
            <div className="custom-radio-box">
              {devices.map((device) =>
                this.checkbox(`enabled_in_best.${device.name}`, device.title)
              )}
            </div>
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">Реклама в Случайных постах</div>
          <div className="col-sm-5 col-6">
            <div className="custom-radio-box">
              {devices.map((device) =>
                this.checkbox(
                  `enabled_in_random_posts.${device.name}`,
                  device.title
                )
              )}
            </div>
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">Реклама в Отдельном посте</div>
          <div className="col-sm-5 col-6">
            <div className="custom-radio-box">
              {devices.map((device) =>
                this.checkbox(`enabled_in_post.${device.name}`, device.title)
              )}
            </div>
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">Реклама в Комментариях</div>
          <div className="col-sm-5 col-6">
            <div className="custom-radio-box">
              {devices.map((device) =>
                this.checkbox(
                  `enabled_in_comments.${device.name}`,
                  device.title
                )
              )}
            </div>
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Показывать первую рекламу в <b>ленте</b> и <b>лучших</b> после поста
            номер
          </div>
          <div className="col-sm-5 col-6">{this.input("posts_offset")}</div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Показывать первую рекламу в <b>ленте при закрепе</b> после поста
            номер
          </div>
          <div className="col-sm-5 col-6">
            {this.input("fixed_posts_offset")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Количество постов в <b>ленте</b> и <b>лучших</b> между двумя
            рекламами
          </div>
          <div className="col-sm-5 col-6">{this.input("posts_between")}</div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Количество прочитанных постов, после которого начинается показ
            рекламы
          </div>
          <div className="col-sm-5 col-6">{this.input("show_after_post")}</div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Показывать первую рекламу в <b>случайных</b> после поста номер
          </div>
          <div className="col-sm-5 col-6">
            {this.input("random_posts_offset")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Количество постов в <b>случайных</b> между двумя рекламами
          </div>
          <div className="col-sm-5 col-6">
            {this.input("random_posts_between")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Показывать первую рекламу после <b>комментария</b> номер
          </div>
          <div className="col-sm-5 col-6">{this.input("comments_offset")}</div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Количество <b>комментариев</b> между двумя рекламами
          </div>
          <div className="col-sm-5 col-6">{this.input("comments_between")}</div>
        </div>

        <h5 className="mt-5">Формула рассчёта кол-ва постов на страницу:</h5>

        <div className="mb-5">
          <code>(offset + 1) + (between + 1) x n ≤ per_page</code>, где
          <br />
          <ul>
            <li>per_page = 25</li>
            <li>
              offset = {data.posts_offset} (или {data.fixed_posts_offset} при
              закрепах)
            </li>
            <li>between = {data.posts_between}</li>
            <li>
              n - наибольшее целое число, удовлетворяющее условиям выражения.
              Вычисляется автоматически.
            </li>
          </ul>
          Значения <b>offset</b> и <b>between</b> следует выставлять таким
          образом, чтобы значение левой части выражения было как можно ближе к
          значению правой.
        </div>

        <div className="mt-7 text-center">
          <button className="btn btn-primary" onClick={() => this.save("ad")}>
            Сохранить
          </button>
        </div>
      </div>
    );
  }
}
