import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";

import { changeSecretStatus, updateSecret } from "../../actions/";
import {
  CategoryDropdown,
  EditConflict,
  Error as ErrorContainer,
  Modal,
  StatusConflict,
  Textarea,
} from "../common/";
import { SvgArchive, SvgRemove, SvgEdit, SvgClock } from "../../svg/";
import {
  textAutoCorrection,
  baseUrl,
  fullDate,
  highlightSearched,
  pluralizeCharacters,
  TEXT_REPLACE_TO,
} from "../../libs/";

import { Uniqueness } from "../../containers";
import { ProfileLink } from "../users/";

const ARCHIVE_CATEGORY_ID = 132;
const ACTION_STATUS_MAP = {
  consider: "pending",
  schedule: "scheduled",
  publish: "published",
  reject: "rejected",
};

class SecretComponent extends Component {
  state = {
    secret: this.props.secret,
    note: this.props.secret.note,
    cursor: null,
    showForm: false,
    showPublishConfirmation: false,
    showScheduleConfirmation: false,
    errors: null,
    editConflict: null,
    statusConflict: null,
    formData: {},
  };

  handleToggleForm = () => {
    this.setState({
      showForm: !this.state.showForm,
      note: this.state.secret.note,
      editConflict: null,
    });
  };

  handleBackspace = (e) => {
    if (
      e.key !== "Backspace" ||
      e.target.selectionStart !== e.target.selectionEnd ||
      !TEXT_REPLACE_TO.includes(
        e.target.value.slice(0, e.target.selectionEnd).slice(-1)
      )
    ) {
      return;
    }
    e.preventDefault(e);

    const { text, cursor } = textAutoCorrection(e, { reverse: true });
    this.setState({ note: text, cursor });
  };

  handleNoteChange = (e) => {
    const { text, cursor } = textAutoCorrection(e);
    this.setState({ note: text, cursor });
  };

  togglePublishConfirmation = () => {
    this.setState({
      showPublishConfirmation: !this.state.showPublishConfirmation,
    });
  };

  toggleScheduleConfirmation = () => {
    const { secret, showScheduleConfirmation } = this.state;

    if (
      showScheduleConfirmation ||
      secret.was_removed_by_worker ||
      secret.user.is_active_author ||
      secret.uniqueness === null
    ) {
      this.setState({ showScheduleConfirmation: !showScheduleConfirmation });
    } else {
      this.changeStatus("schedule");
    }
  };

  changeStatus = (action) => {
    const { secret } = this.state;
    const { updateList } = this.props;
    const prevStatus = secret.status;
    const formData = {
      secret: {
        status: ACTION_STATUS_MAP[action],
        editable_fields_hash: secret.editable_fields_hash,
      },
    };

    this.props.changeStatus({ action, formData, secret }).then((newState) => {
      if (updateList && !newState.statusConflict) {
        let afterChangeAction;
        switch (prevStatus) {
          case "pending":
          case "rejected":
            afterChangeAction = ["schedule", "publish"].includes(action)
              ? "remove"
              : null;
            break;
          case "scheduled":
            afterChangeAction = action === "consider" ? "append" : "replace";
            break;
          default:
            throw new Error(`Unsupported transition: ${action} ${prevStatus}`);
        }
        updateList(afterChangeAction, newState.secret);

        // we shouldn't update the state of the current secret
        // since it will be removed from the list any way
        if (["replace", "remove"].includes(afterChangeAction)) {
          return;
        }
      }

      this.setState(newState);
    });
  };

  updateCategory = (category) => {
    this.updateSecret({ secret: { category_id: category } });
  };

  updateNote = () => {
    this.updateSecret({ secret: { note: this.state.note } });
  };

  updateApprovedByEditor = (status) => {
    this.updateSecret({ secret: { approved_by_editor: status } });
  };

  updateSecret = (formData) => {
    const secret = { ...this.state.secret };
    formData.secret.editable_fields_hash = secret.editable_fields_hash;
    this.props.update({ formData, secret }).then((e) => this.setState(e));
  };

  setUniqueness = (uniqueness) => {
    this.setState({ secret: { ...this.state.secret, uniqueness } });
  };

  handleConflict = (action) => {
    // send update request to the server
    if (action === "overwrite") {
      const formData = { ...this.state.formData };
      formData.secret.overwrite = true;
      this.updateSecret(formData);

      // update state with the saved on the server data
    } else {
      this.setState({
        secret: { ...this.props.secret, ...this.state.editConflict.savedData },
        editConflict: null,
        showForm: false,
      });
    }
  };

  handleStatusConflict = () => {
    this.setState({ statusConflict: null });
  };

  render() {
    const {
      secret,
      note,
      cursor,
      showForm,
      showPublishConfirmation,
      showScheduleConfirmation,
      errors,
      editConflict,
      statusConflict,
    } = this.state;
    const { searchQuery } = this.props;

    let statusClass = "btn-success";
    if (secret.status === "pending") statusClass = "btn-primary";
    if (secret.status === "rejected") statusClass = "btn-secondary";

    return (
      <div
        className={`row no-gutters mb-sm-3 mb-1 ${
          secret.approved_by_editor ? "mobile-pinned" : ""
        } secret-item-wrapper${secret.publisher ? " with-publisher" : ""}`}
      >
        <div className={`col ${secret.approved_by_editor ? "pinned" : ""}`}>
          <div className={`secret-item${errors ? " error" : ""}`}>
            <header className="d-flex w-100 align-items-center">
              <Link
                to={`${baseUrl}/secrets/${secret.id}`}
                className={`btn btn-sm rounded mr-2 ${statusClass}`}
                target="_blank"
              >
                #{secret.id}
              </Link>
              <CategoryDropdown
                selectedId={secret.category_id}
                onChange={this.updateCategory}
              />
              <span className="text-muted ml-auto">
                {showForm && [
                  <em key="edit_text">Редактирование</em>,
                  <span key="split" className="mx-1">
                    |
                  </span>,
                ]}
                {pluralizeCharacters(note.length)}
              </span>
            </header>

            {errors && <ErrorContainer errors={errors} />}

            {editConflict && (
              <EditConflict
                title="секрет"
                author={editConflict.author}
                initialDiff={editConflict.initialDiff}
                updateDiff={editConflict.updateDiff}
                action={this.handleConflict}
              />
            )}

            {statusConflict && (
              <StatusConflict
                title="секрет"
                author={statusConflict.author}
                close={this.handleStatusConflict}
              />
            )}

            {showForm ? (
              <div className="form">
                <Textarea
                  onKeyDown={this.handleBackspace}
                  onChange={this.handleNoteChange}
                  cursor={cursor}
                  name="note"
                  value={note}
                />

                <div className="text-right mt-3">
                  <button
                    className="btn btn-secondary mr-2"
                    onClick={this.handleToggleForm}
                  >
                    Отмена
                  </button>

                  <button className="btn btn-primary" onClick={this.updateNote}>
                    Сохранить
                  </button>
                </div>
              </div>
            ) : (
              <div
                className={`std${
                  secret.status === "rejected" ? " text-muted" : ""
                }`}
                dangerouslySetInnerHTML={{
                  __html: highlightSearched(secret.note, searchQuery),
                }}
              />
            )}

            <div className="details text-muted">
              <div className="row">
                {secret.status === "scheduled" && (
                  <>
                    <div className="col-md-3 col-6 mt-2">
                      <b>Запланирован на:</b>
                      {fullDate(secret.scheduled_to)}
                    </div>
                    <div className="col-md-3 col-6 mt-2">
                      <b>Запланирован в:</b>
                      {secret.scheduled_at
                        ? fullDate(secret.scheduled_at)
                        : "-"}
                    </div>
                    <div className="col-md-3 col-6 mt-2">
                      <b>
                        {secret.publisher ? "Запланировал" : "Запланировано"}:
                      </b>
                      {secret.publisher ? (
                        <ProfileLink user={secret.publisher} />
                      ) : (
                        "Автоматически"
                      )}
                    </div>
                    <div className="col-md-3 col-6 mt-2" />
                  </>
                )}

                {secret.status === "published" && (
                  <>
                    <div className="col-md-3 col-6 mt-2">
                      <b>Дата публикации:</b>
                      {fullDate(secret.published_at)}
                    </div>
                    <div className="col-md-3 col-6 mt-2">
                      <b>Запланирован в:</b>
                      {secret.scheduled_at
                        ? fullDate(secret.scheduled_at)
                        : "-"}
                    </div>
                    <div className="col-md-3 col-6 mt-2">
                      <b>
                        {secret.publisher ? "Опубликовал" : "Опубликовано"}:
                      </b>
                      {secret.publisher ? (
                        <ProfileLink user={secret.publisher} />
                      ) : (
                        "Автоматически"
                      )}
                    </div>
                    <div className="col-md-3 col-6 mt-2" />
                  </>
                )}

                {secret.status === "rejected" && (
                  <>
                    <div className="col-md-3 col-6 mt-2">
                      <b>Удалено:</b>
                      {secret.removed_at
                        ? fullDate(secret.removed_at)
                        : "неизвестно"}
                    </div>

                    <div className="col-md-3 col-6 mt-2">
                      <b>Удалил:</b>
                      {secret.removed_by_worker ? (
                        <b>Воркер</b>
                      ) : secret.remover ? (
                        <ProfileLink user={secret.remover} />
                      ) : (
                        "неизвестно"
                      )}
                    </div>
                    <div className="col-md-3 col-6 mt-2" />
                    <div className="col-md-3 col-6 mt-2" />
                  </>
                )}

                <div className="col-md-3 col-6 mt-2">
                  <b>Прислано:</b> {fullDate(secret.created_at)}
                </div>
                <div className="col-md-3 col-6 mt-2">
                  <b>Автор:</b>
                  <ProfileLink
                    user={secret.user}
                    className={`${
                      secret.user.is_active_author ? "active-author" : ""
                    }${secret.user.removed_at ? " removed-user" : ""}`}
                  />
                </div>
                <div className="col-md-3 col-6 mt-2">
                  <b>Источник:</b> {secret.source}
                </div>
                <div className="col-md-3 col-6 mt-2">
                  <b>Уникальность:</b>
                  {secret && (
                    <Uniqueness
                      id={secret.id}
                      data={secret.uniqueness_text_ru}
                      callback={this.setUniqueness}
                    />
                  )}
                </div>

                <div className="col-md-3 col-6 mt-2">
                  <b>Рейтинг:</b> {secret.rating}
                </div>
                <div className="col-md-3 col-6 mt-2">
                  <b>Просмотры:</b> {secret.significant_views}
                </div>
                <div className="col-md-3 col-6 mt-2">
                  <b>Вес:</b> {secret.weighted_rating}
                </div>
                <div className="col-md-3 col-6 mt-2" />
              </div>
            </div>

            <footer className="text-sm-right secret-footer-buttons text-center mt-3">
              {secret.status === "pending" && (
                <>
                  {secret.can_publish && (
                    <button
                      className="btn btn-secondary"
                      onClick={this.togglePublishConfirmation}
                    >
                      Опубликовать
                    </button>
                  )}
                  {secret.can_schedule && (
                    <button
                      className="btn btn-success"
                      onClick={this.toggleScheduleConfirmation}
                    >
                      Поставить в очередь
                    </button>
                  )}
                </>
              )}

              {secret.status === "scheduled" && (
                <>
                  {secret.can_consider && (
                    <button
                      className={`btn ${
                        secret.publisher
                          ? "btn-with-publisher"
                          : "btn btn-secondary"
                      }`}
                      onClick={() => this.changeStatus("consider")}
                    >
                      Снять с публикации
                    </button>
                  )}
                  {secret.can_publish && (
                    <button
                      className="btn btn-success"
                      onClick={this.togglePublishConfirmation}
                    >
                      Опубликовать
                    </button>
                  )}
                  {!secret.approved_by_editor ? (
                    <button
                      type="button"
                      onClick={() => this.updateApprovedByEditor(true)}
                      className="btn btn-secondary"
                    >
                      Одобрить
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => this.updateApprovedByEditor(false)}
                      className="btn approved-button"
                    >
                      Одобрено
                    </button>
                  )}
                </>
              )}

              {secret.status === "rejected" && (
                <>
                  {secret.can_consider && (
                    <button
                      className="btn btn-info"
                      onClick={() => this.changeStatus("consider")}
                    >
                      Восстановить
                    </button>
                  )}
                  {secret.can_publish && (
                    <button
                      className="btn btn-success"
                      onClick={this.togglePublishConfirmation}
                    >
                      Опубликовать
                    </button>
                  )}
                  {secret.can_schedule && (
                    <button
                      className="btn btn-success"
                      onClick={this.toggleScheduleConfirmation}
                    >
                      Поставить в очередь
                    </button>
                  )}
                </>
              )}
            </footer>
          </div>
        </div>

        {/* side buttons */}
        <MediaQuery minWidth={576}>
          {(matches) => (
            <div className="col-sm-auto col-12 ml-sm-15 secret-side-buttons">
              {secret.can_reject && (
                <>
                  <button
                    title="Удалить в Архив"
                    type="button"
                    className={`btn ${
                      matches ? "bg-white lh-0" : "danger-light"
                    } mb-1`}
                    onClick={() => this.changeStatus("archive")}
                  >
                    {matches ? (
                      <SvgArchive width={16} height={16} className="fill-red" />
                    ) : (
                      "Удалить в Архив"
                    )}
                  </button>
                  <button
                    title="Удалить"
                    type="button"
                    className={`btn ${
                      matches ? "bg-white lh-0" : "danger-light"
                    } mb-1`}
                    onClick={() => this.changeStatus("reject")}
                  >
                    {matches ? (
                      <SvgRemove width={16} height={16} className="fill-red" />
                    ) : (
                      "Удалить"
                    )}
                  </button>
                </>
              )}

              {secret.status === "rejected" &&
                secret.category_id !== ARCHIVE_CATEGORY_ID && (
                  <button
                    title="В Архив"
                    type="button"
                    className={`btn ${
                      matches ? "bg-white lh-0" : "danger-light"
                    } mb-1`}
                    onClick={() => this.updateCategory(ARCHIVE_CATEGORY_ID)}
                  >
                    {matches ? (
                      <SvgArchive width={16} height={16} className="fill-red" />
                    ) : (
                      "В Архив"
                    )}
                  </button>
                )}

              {secret.can_edit && (
                <button
                  title="Редактировать"
                  type="button"
                  className={`btn ${
                    matches ? "bg-white lh-0" : "primary-light"
                  } mb-1`}
                  onClick={this.handleToggleForm}
                >
                  {matches ? (
                    <SvgEdit width={16} height={16} className="fill-primary" />
                  ) : (
                    "Редактировать"
                  )}
                </button>
              )}

              <Link
                title="История изменений"
                to={`${baseUrl}/secrets/${secret.id}`}
                className={`btn ${
                  matches ? "bg-white lh-0" : "primary-light"
                } mb-1`}
                target="_blank"
              >
                {matches ? (
                  <SvgClock width={16} height={16} className="fill-primary" />
                ) : (
                  "История изменений"
                )}
              </Link>
            </div>
          )}
        </MediaQuery>

        {showPublishConfirmation && (
          <Modal
            key="confirm"
            title={`Публикация секрета #${secret.id}`}
            toggleModal={this.togglePublishConfirmation}
            small
          >
            <div>
              <ul>
                {secret.was_removed_by_worker && (
                  <li>
                    Данный секрет был удалён воркером {secret.removed_by_worker}
                    .
                  </li>
                )}
              </ul>
              <div>Вы действительно хотите опубликовать секрет в ленту?</div>
              <div className="mt-25 text-right">
                <button
                  type="button"
                  onClick={this.togglePublishConfirmation}
                  className="btn btn-primary mr-15"
                >
                  Отмена
                </button>
                <button
                  type="button"
                  onClick={() => this.changeStatus("publish")}
                  className="btn btn-secondary"
                >
                  Опубликовать
                </button>
              </div>
            </div>
          </Modal>
        )}

        {showScheduleConfirmation && (
          <Modal
            key="confirm"
            title={`Добавление секрета #${secret.id} в очередь`}
            toggleModal={this.toggleScheduleConfirmation}
            small
          >
            <div className="mb-1">
              <ul>
                {secret.was_removed_by_worker && (
                  <li>
                    Данный секрет был удалён воркером {secret.removed_by_worker}
                    .
                  </li>
                )}
                {secret.user.is_active_author && (
                  <li>
                    У данного автора много опубликованных секретов (
                    {secret.user.post_count}).
                  </li>
                )}
                {Boolean(secret.user.note) && (
                  <li>
                    У данного автора есть текст в Заметке:
                    <br />
                    <blockquote
                      className="mb-0 ml-1"
                      style={{ whiteSpace: "pre-wrap", fontStyle: "italic" }}
                    >
                      {secret.user.note}
                    </blockquote>
                  </li>
                )}
                {secret.uniqueness === null && (
                  <li>
                    Вы пытаетесь добавить секрет, который не проверен на
                    уникальность.
                  </li>
                )}
              </ul>
              <div>Вы действительно хотите поставить секрет в очередь?</div>
              <div className="mt-25 text-center text-sm-right">
                <button
                  type="button"
                  onClick={this.toggleScheduleConfirmation}
                  className="btn btn-primary"
                >
                  Отмена
                </button>
                <button
                  type="button"
                  onClick={() => this.changeStatus("schedule")}
                  className="btn btn-secondary ml-15"
                >
                  Поставить в очередь
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }

  static propTypes = {
    secret: PropTypes.objectOf(PropTypes.any).isRequired,
    searchQuery: PropTypes.string,
    changeStatus: PropTypes.func,
    update: PropTypes.func,
    updateList: PropTypes.func,
  };

  static defaultProps = {
    searchQuery: null,
    updateList: null,
  };
}

const mapStateToProps = (state) => ({
  searchQuery: state.filters.searchQuery,
});

const mapDispatchToProps = (dispatch) => ({
  changeStatus: (data) => dispatch(changeSecretStatus(data)),
  update: (data) => dispatch(updateSecret(data)),
});

export const Secret = connect(
  mapStateToProps,
  mapDispatchToProps
)(SecretComponent);
