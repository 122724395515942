import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { ClickOutside } from "../../../libs";
import { SvgDown } from "../../../svg";

const Filters = [
  { value: "", title: "Все" },
  { value: "include", title: "Включить" },
  { value: "exclude", title: "Исключить" },
];

export class GroupedDropdown extends Component {
  state = {
    selected: this.props.selected,
    prevSelected: this.props.selected,
    showDropdown: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.selected !== state.prevSelected) {
      return {
        selected: props.selected,
        prevSelected: props.selected,
        showDropdown: false,
      };
    }
    return null;
  }

  getTitle = () => {
    const { groups, selected } = this.props;

    if (!["include", "exclude"].includes(selected.filter)) {
      return Filters[0].title;
    }

    const prefix = selected.filter === "include" ? "Только" : "Все кроме";
    const list = groups
      .filter((group) => selected.groups.includes(group.value))
      .map((group) => group.title);

    return `${prefix} ${list.join(", ")}`;
  };

  handleFilterChange = (e) => {
    this.setState({ selected: { filter: e.target.value, groups: [] } });
  };

  handleGroupsChange = (e) => {
    const { filter, groups } = this.state.selected;
    const { name, checked } = e.target;
    const newGroups = checked
      ? groups.slice().concat([name])
      : groups.filter((item) => item !== name);
    this.setState({ selected: { filter, groups: newGroups } });
  };

  toggleDropdown = () => {
    const {
      selected: { filter, groups },
      prevSelected,
      showDropdown,
    } = this.state;

    // on close when a default filter or another filter with at least one group is selected
    if (showDropdown && (filter === Filters[0].value || groups.length)) {
      return this.props.callback({ filter, groups });
    }

    this.setState({ showDropdown: !showDropdown, selected: prevSelected });
  };

  render() {
    const { showDropdown, selected } = this.state;
    const { groups, disabled } = this.props;

    if (disabled) {
      return this.getTitle();
    }

    return (
      <ClickOutside
        className="dropdown-toggle"
        onBlur={this.toggleDropdown}
        isOpen={showDropdown}
      >
        <Fragment>
          <button
            type="button"
            className={"btn btn-link p-0" + (showDropdown ? " opened" : "")}
            onClick={this.toggleDropdown}
          >
            {this.getTitle()}
            <SvgDown width={9} height={6} className="toggler" />
          </button>
          {showDropdown && (
            <div className="dropdown p-2 right">
              {Filters.map((filter) => (
                <div key={filter.value}>
                  <label className="custom-control custom-radio">
                    <input
                      type="radio"
                      name="filter.value"
                      value={filter.value}
                      className="custom-control-input"
                      checked={selected.filter === filter.value}
                      onChange={this.handleFilterChange}
                    />
                    <span className="custom-control-indicator" />
                    <span className="custom-control-description" width="100">
                      {filter.title}
                    </span>
                  </label>

                  {filter.value !== "" &&
                    selected.filter === filter.value &&
                    groups.map((group) => (
                      <label
                        key={group.value}
                        className="custom-control custom-checkbox ml-25"
                      >
                        <input
                          type="checkbox"
                          name={group.value}
                          value={group.value}
                          className="custom-control-input"
                          checked={selected.groups.includes(group.value)}
                          onChange={this.handleGroupsChange}
                        />
                        <span className="custom-control-indicator" />
                        <span className="custom-control-description">
                          {group.title}
                        </span>
                      </label>
                    ))}
                </div>
              ))}
            </div>
          )}
        </Fragment>
      </ClickOutside>
    );
  }

  static propTypes = {
    disabled: PropTypes.bool,
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    selected: PropTypes.shape({
      filter: PropTypes.string.isRequired,
      groups: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    callback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selected: { filter: "", groups: [] },
    disabled: false,
  };
}
