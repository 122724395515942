import _ from "lodash";

import { DataLayer } from "../../../libs/";

export const loadUserComments =
  ({ comments = [], userId }) =>
  async (__, getState) => {
    const { visibility, hiddenBy, searchQuery } =
      getState().filters.user_comments;
    const newState = {
      loading: false,
      loadingError: false,
    };

    const params = [];

    if (visibility) {
      params.push(`visibility=${visibility}`);
    }
    if (hiddenBy) {
      params.push(`hidden_by=${hiddenBy}`);
    }
    if (searchQuery) {
      params.push(`text=${searchQuery}`);
    }
    if (comments.length > 0) {
      params.push(`from=${_.last(comments).id}`);
    }

    await DataLayer.get({
      path:
        "/users/" +
        userId +
        "/comments.json" +
        (params.length ? "?" + params.join("&") : ""),
    })
      .then((response) => {
        newState.comments = [...comments, ...response.comments];
        newState.end = !response.comments.length;
      })
      .catch(() => {
        newState.loadingError = true;
      });

    return newState;
  };
