import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";

import { loadUser, loadUserActions, setPageTitle } from "../../actions/";
import {
  Loading,
  LoadingError,
  History,
  NotFound,
  PageHeader,
} from "../../components/common/";
import { CommentActions, ProfileLink } from "../../components/users/";
import { baseUrl } from "../../libs/";

const actionNames = {
  comment_likes: "Лайк",
  comment_dislikes: "Дизлайк",
  comment_complains: "Жалоба",
};

class Actions extends Component {
  state = {
    userId: parseInt(this.props.match.params.userId, 10),
    user: null,
    actions: [],
    loading: true,
    loadingError: false,
    pages: 0,
    filters: this.props.filters,
  };

  componentDidMount() {
    const { userId } = this.state;

    this.props.loadUser(userId).then((newState) => {
      if (newState.user) {
        newState.loading = true;
        this.props.loadActions(userId).then((e) => this.setState(e));
      }
      this.setState(newState);
    });

    this.props.setPageTitle(`История действий пользователя`);
  }

  componentWillUnmount() {
    this.props.setPageTitle(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevState.filters, this.state.filters)) {
      this.props.loadActions(this.state.userId).then((e) => this.setState(e));
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.filters, state.filters)) {
      return { filters: props.filters, actions: [], loading: true };
    }
    return null;
  }

  render() {
    const { user, userId, actions, loading, loadingError, pages } = this.state;
    const {
      filters: { type },
    } = this.props;
    const baseLink = `${baseUrl}/users/${userId}/actions`;

    if (!user) {
      return (
        <NotFound
          title="Пользователь"
          id={userId}
          loading={loading}
          loadingError={loadingError}
        />
      );
    }

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <div className="bg-white p-2 p-sm-3">
          <PageHeader>
            <h2>
              <ProfileLink user={user} />
            </h2>
            <nav>
              {["admin", "editor", "moderator"].includes(user.role) && (
                <NavLink to={`${baseLink}/posts`}>Посты</NavLink>
              )}
              {["admin", "editor"].includes(user.role) && (
                <NavLink to={`${baseLink}/secrets`}>Секреты</NavLink>
              )}
              <NavLink to={`${baseLink}/comments`}>Комментарии</NavLink>
              <NavLink to={`${baseLink}/comment_likes`}>Лайки</NavLink>
              <NavLink to={`${baseLink}/comment_dislikes`}>Дизлайки</NavLink>
              <NavLink to={`${baseLink}/comment_complains`}>Жалобы</NavLink>
              {["admin", "editor", "moderator"].includes(user.role) && (
                <Fragment>
                  <NavLink to={`${baseLink}/users`}>Пользователи</NavLink>
                  <NavLink to={`${baseUrl}/users/${userId}/made_bans`}>
                    Баны
                  </NavLink>
                </Fragment>
              )}
            </nav>
          </PageHeader>

          {loading && <Loading />}
          {loadingError && <LoadingError />}
          {!loading &&
            !loadingError &&
            (["posts", "secrets", "comments", "users"].includes(type) ? (
              <History versions={actions} pages={pages} showObject={type} />
            ) : (
              <CommentActions
                actions={actions}
                pages={pages}
                actionName={actionNames[type]}
              />
            ))}
        </div>
      </div>
    );
  }

  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    loadUser: PropTypes.func.isRequired,
    loadActions: PropTypes.func.isRequired,
    setPageTitle: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.actions,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (userId) => dispatch(loadUser(userId)),
  loadActions: (userId) => dispatch(loadUserActions(userId)),
  setPageTitle: (title) => dispatch(setPageTitle(title)),
});

export const UserActions = connect(
  mapStateToProps,
  mapDispatchToProps
)(Actions);
