import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { cancelBan, restoreBan, updateBan } from "../../actions/";
import { BanModal } from "../common/";
import { baseUrl, fullDate } from "../../libs/";

class Item extends Component {
  state = {
    ban: this.props.ban,
    showBanModal: false,
  };

  toggleBanModal = () => {
    this.setState({ showBanModal: !this.state.showBanModal });
  };

  update = (formData) => {
    const { id } = this.state.ban;
    this.props.update({ id, formData }).then((e) => this.setState(e));
  };

  cancel = () => {
    const { id } = this.state.ban;
    this.props.cancel(id).then((e) => this.setState(e));
  };

  restore = () => {
    const { id } = this.state.ban;
    this.props.restore(id).then((e) => this.setState(e));
  };

  render() {
    const {
      ban,
      ban: { comment, reason },
      showBanModal,
    } = this.state;
    const { type, canEdit, canDelete } = this.props;
    const commentLink = comment
      ? `${baseUrl}/posts/${comment.post_id}?branch=${
          comment.root_id || comment.id
        }#c${comment.id}`
      : null;

    return (
      <tr key={`ban-${ban.id}`}>
        <td>
          <a
            href={`/dashboard/users/${
              type === "made_bans" ? ban.banned_user.id : ban.acting_user.id
            }`}
            className="btn btn-link p-0"
            target="_blank"
            rel="noreferrer"
          >
            {type === "made_bans"
              ? ban.banned_user.fullname_or_anonymus
              : ban.acting_user.fullname_or_anonymus}
          </a>
        </td>
        <td>
          {comment && (
            <a
              href={commentLink}
              title={comment.text_fixed}
              target="_blank"
              rel="noreferrer"
            >
              #{comment.id}
            </a>
          )}
        </td>
        <td>{ban.note}</td>
        <td>{reason && reason.title}</td>
        <td>{reason ? reason.description : ban.reason_text}</td>
        <td>
          {fullDate(ban.created_at)}
          <br />
          {ban.updated_at !== ban.created_at && (
            <span title="Дата обновления" style={{ color: "red" }}>
              {fullDate(ban.updated_at)}
            </span>
          )}
        </td>
        {canEdit && (
          <td>
            <button
              onClick={this.toggleBanModal}
              className="btn btn-sm btn-primary btn-block"
              disabled={ban.cancelled}
            >
              Редактировать
            </button>
            {showBanModal && (
              <BanModal
                ban={ban}
                toggleModal={this.toggleBanModal}
                save={this.update}
              />
            )}
          </td>
        )}
        {canDelete && (
          <td>
            {ban.cancelled ? (
              <button
                onClick={this.restore}
                className="btn btn-sm btn-success btn-block"
              >
                Восстановить
              </button>
            ) : (
              <button
                onClick={this.cancel}
                className="btn btn-sm danger-light btn-block"
              >
                Отменить
              </button>
            )}
          </td>
        )}
      </tr>
    );
  }

  static propTypes = {
    ban: PropTypes.objectOf(PropTypes.any).isRequired,
    type: PropTypes.string.isRequired,
    canEdit: PropTypes.bool.isRequired,
    canDelete: PropTypes.bool.isRequired,
    cancel: PropTypes.func.isRequired,
    restore: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  canEdit: state.user.hasModeratorAccess,
  canDelete: state.user.hasEditorAccess,
});

const mapDispatchToProps = (dispatch) => ({
  cancel: (id) => dispatch(cancelBan(id)),
  restore: (id) => dispatch(restoreBan(id)),
  update: (data) => dispatch(updateBan(data)),
});

export const Ban = connect(mapStateToProps, mapDispatchToProps)(Item);
