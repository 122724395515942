import { DataLayer } from "../../libs/";

export const restoreComment =
  ({ id }) =>
  async () => {
    const newState = {};
    await DataLayer.post({ path: `/comments/${id}/restore.json`, loader: true })
      .then((response) => {
        newState.comment = response;
        newState.showDropDownMenu = false;
      })
      .catch(() => {});
    return newState;
  };
