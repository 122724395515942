import React from "react";
import PropTypes from "prop-types";

import { ProfileLink } from "../../users/";

export const StatusConflict = ({ title, author, close }) => (
  <div className="status-conflict">
    <h4>
      <span className="title">{title}</span> был изменён другим пользователем!
    </h4>

    {author.fullname_or_anonymus && (
      <div className="my-2">
        Автор последних изменений:{" "}
        {author.id ? (
          <ProfileLink user={author} />
        ) : (
          author.fullname_or_anonymus
        )}
        .
      </div>
    )}

    <h6 className="mt-2 mb-1">Данные обновлены.</h6>

    <div className="text-right">
      <button className="btn btn-primary ml-1" onClick={close}>
        Ok
      </button>
    </div>
  </div>
);

StatusConflict.propTypes = {
  title: PropTypes.string.isRequired,
  author: PropTypes.objectOf(PropTypes.any).isRequired,
  close: PropTypes.func.isRequired,
};
