import { DataLayer } from "../../libs/";

export const updatePost =
  ({ id, formData, propsPost }) =>
  async () => {
    const newState = {};

    await DataLayer.put({
      path: "/posts/" + id + ".json",
      body: formData,
      loader: true,
    })
      .then((post) => {
        newState.post = post;
      })
      .catch((error) => {
        newState.formData = formData;

        if (error.status === 409) {
          const editConflict = { ...error.data, initialDiff: {} };

          Object.keys(editConflict.savedData).forEach((field) => {
            if (propsPost[field] !== editConflict.savedData[field]) {
              editConflict.initialDiff[field] = [
                propsPost[field],
                editConflict.savedData[field],
              ];
            }
          });

          newState.editConflict = editConflict;
        } else {
          newState.errors = error.data
            ? error.data.errors || error.data
            : error.message;
        }
      });

    return newState;
  };
