import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";

import {
  postToggleComments,
  postToggleFixed,
  removePost,
  restorePost,
} from "../../actions/";
import { Date, Modal, BtnDropdown } from "../common/";
import {
  baseUrl,
  copyToClipboard,
  fullDate,
  highlightSearched,
} from "../../libs/";
import {
  SvgDown,
  SvgHeart,
  SvgComment,
  SvgEye,
  SvgBookmark,
  SvgDots,
  SvgDevices,
} from "../../svg/";

import { Uniqueness } from "../../containers/secrets";
import { ProfileLink } from "../users/";

import { audienceGroupList } from "./_consts";

class Item extends Component {
  state = {
    showDetails: false,
    showRemoveForm: false,
    showRestoreConfirmation: false,
    showLikes: false,
    showViews: false,
    showBookmarks: false,
    showFixedConfirmation: false,
    removeReason: "",
  };

  handleShowDetails = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  toggleComments = (e) => {
    const id = this.props.post.id;
    const value = e.target.checked;

    this.props
      .toggleComments({ id, value })
      .then((post) => {
        this.props.updateData({ post });
      })
      .catch(() => {});
  };

  toggleFixed = (e) => {
    const value = e.target.checked;

    if (value) {
      this.toggleFixedConfirmation();
    } else {
      this.performToggleFixed(false);
    }
  };

  performToggleFixed = (value) => {
    const id = this.props.post.id;

    this.props
      .toggleFixed({ id, value })
      .then((post) => {
        this.props.updateData({ post });
        this.setState({ showFixedConfirmation: false });
      })
      .catch(() => {});
  };

  toggleFixedConfirmation = () => {
    this.setState({
      showFixedConfirmation: !this.state.showFixedConfirmation,
    });
  };

  toggleRemoveForm = () => {
    this.setState({
      showRemoveForm: !this.state.showRemoveForm,
      removeReason: "",
    });
  };

  toggleRestoreForm = () => {
    this.setState({
      showRestoreConfirmation: !this.state.showRestoreConfirmation,
    });
  };

  toggleLikes = (e) => {
    e.preventDefault();
    this.setState({
      showLikes: !this.state.showLikes,
      showViews: false,
      showBookmarks: false,
    });
  };

  toggleViews = (e) => {
    e.preventDefault();
    this.setState({
      showViews: !this.state.showViews,
      showLikes: false,
      showBookmarks: false,
    });
  };

  toggleBookmarks = (e) => {
    e.preventDefault();
    this.setState({
      showBookmarks: !this.state.showBookmarks,
      showLikes: false,
      showViews: false,
    });
  };

  changeRemoveReason = (e) => {
    this.setState({ removeReason: e.target.value });
  };

  remove = () => {
    const id = this.props.post.id;
    const reason = this.state.removeReason;

    this.props
      .remove({ id, reason })
      .then((post) => {
        this.props.updateData({ post });
        this.setState({ showRemoveForm: false, removeReason: "" });
      })
      .catch(() => {});
  };

  restore = () => {
    const id = this.props.post.id;

    this.props
      .restore({ id })
      .then((post) => {
        this.props.updateData({ post });
        this.setState({ showRestoreConfirmation: false });
      })
      .catch(() => {});
  };

  audienceTitle = () => {
    const { audience_filter: audienceFilter, audience_groups: audienceGroups } =
      this.props.post;
    if (!audienceFilter) {
      return null;
    }

    const groups = audienceGroupList
      .filter((group) => audienceGroups.includes(group.value))
      .map((group) => group.title)
      .join(", ");

    if (audienceFilter === "include") {
      return <b className="text-success align-middle">{groups}</b>;
    } else {
      return <b className="text-danger align-middle">Кроме {groups}</b>;
    }
  };

  copyNoteWithTag = (e) => {
    e.preventDefault();
    const { note, tag } = this.props.post;
    copyToClipboard(note + (tag ? "\n\n" + tag : ""));
  };

  render() {
    const {
      showDetails,
      showRemoveForm,
      showRestoreConfirmation,
      showLikes,
      showViews,
      showBookmarks,
      showFixedConfirmation,
      removeReason,
    } = this.state;
    const { post, toggleForm, searchQuery, hasEditorAccess } = this.props;
    const secret = post.secret;
    const isRemoved = post.status === "removed";
    const SvgIos = SvgDevices["ios"];
    const SvgAndroid = SvgDevices["android"];
    const SvgWindows = SvgDevices["windows"];
    const SvgHuawei = SvgDevices["huawei"];
    const SvgRustore = SvgDevices["rustore"];

    return (
      <div className="post-item mb-sm-3 mb-1">
        <header className="row justify-content-between">
          <div className="col-auto">
            <Link
              to={`${baseUrl}/posts/${post.id}`}
              className={`btn btn-link p-0${isRemoved ? " text-muted" : ""}`}
            >
              #{post.id}
            </Link>

            <span className="separator-dot">·</span>

            <Link
              to={`${baseUrl}/posts?category_id=${post.category_id}`}
              className={`btn btn-link p-0${isRemoved ? " text-muted" : ""}`}
            >
              {post.category_name}
            </Link>

            {!post.special_for_device.includes("all") && (
              <>
                <span className="separator-dot">·</span>

                {post.special_for_device.includes("none") ? (
                  <b className="text-danger align-middle">Скрыт</b>
                ) : (
                  post.special_for_device.map((device, index) => {
                    const SvgIcon = SvgDevices[device];
                    const className = `device-svg${index > 0 ? " ml-1" : ""}`;
                    return (
                      <SvgIcon
                        key={device}
                        width={16}
                        height={16}
                        className={className}
                      />
                    );
                  })
                )}
              </>
            )}

            {post.audience_filter && (
              <>
                <span className="separator-dot">·</span>
                {this.audienceTitle()}
              </>
            )}

            {post.status !== "removed" && (
              <>
                <span className="separator-dot">·</span>
                {post.status === "scheduled" ? (
                  <Date
                    className="date text-muted"
                    date={post.scheduled_to}
                    fromNow={false}
                  />
                ) : (
                  <Date className="date text-muted" date={post.created_at} />
                )}
              </>
            )}
          </div>

          {hasEditorAccess && isRemoved && (
            <div className="col-auto">
              <button
                type="button"
                className="btn btn-link p-0 text-success"
                onClick={this.toggleRestoreForm}
              >
                Восстановить
              </button>
            </div>
          )}

          {!isRemoved && (
            <div className="col-auto">
              <BtnDropdown
                btnClass="btn btn-link p-0"
                btnText={
                  <SvgDots width={18} height={4} className="fill-gray" />
                }
              >
                <ul className="dropdown right">
                  <li>
                    <button
                      type="button"
                      className="btn btn-link p-0"
                      onClick={toggleForm}
                    >
                      Редактировать
                    </button>
                  </li>
                  <li>
                    <Link
                      to={`${baseUrl}/posts/${post.id}/history`}
                      className="btn btn-link p-0"
                      onClick={this.copyNoteWithTag}
                    >
                      Копировать
                    </Link>
                  </li>

                  {hasEditorAccess && (
                    <>
                      <li>
                        <Link
                          to={`${baseUrl}/posts/${post.id}/history`}
                          className="btn btn-link p-0"
                        >
                          История
                        </Link>
                      </li>
                      <li>
                        <button
                          type="button"
                          className="btn btn-link p-0"
                          onClick={this.toggleRemoveForm}
                        >
                          Удалить
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </BtnDropdown>
            </div>
          )}
        </header>

        <div
          className={`std${isRemoved ? " text-muted" : ""}`}
          dangerouslySetInnerHTML={{
            __html: highlightSearched(post.note, searchQuery),
          }}
        />

        {post.image_uri && (
          <div>
            <img
              src={post.image_uri}
              className="rounded mt-2 img-fluid"
              alt=""
              style={{ maxWidth: "70%" }}
            />
          </div>
        )}

        <button
          type="button"
          className={`btn btn-link mt-sm-2 mt-1 p-0 btn-sm${
            showDetails ? " opened" : ""
          }`}
          onClick={this.handleShowDetails}
        >
          {showDetails ? "Скрыть " : "Показать "}информацию
          <SvgDown width={9} height={6} className="toggler" />
        </button>

        {showDetails && (
          <div className="details text-muted">
            <div className="row">
              <div className="col-md-3 col-6 mt-2">
                <b>Прислано:</b>
                {fullDate(secret ? secret.created_at : post.created_at)}
              </div>

              {hasEditorAccess && (
                <div className="col-md-3 col-6 mt-2">
                  <b>Автор:</b>
                  <ProfileLink
                    user={post.user}
                    className={secret?.user.removed_at ? " removed-user" : null}
                  />
                </div>
              )}

              <div className="col-md-3 col-6 mt-2">
                <b>Источник:</b>
                {secret ? secret.source : "Админка"}
              </div>

              {hasEditorAccess && (
                <>
                  {!secret && (
                    <div className="col-md-3 col-6 mt-2">
                      <b>Опубликовал: </b>
                      <ProfileLink user={post.user} />
                    </div>
                  )}

                  {secret && secret.publisher && (
                    <div className="col-md-3 col-6 mt-2">
                      <b>Опубликовал: </b>
                      <ProfileLink user={secret.publisher} />
                    </div>
                  )}

                  {secret && !secret.publisher && (
                    <div className="col-md-3 col-6 mt-2">
                      <b>Опубликовано </b>
                      автоматически
                    </div>
                  )}

                  <div className="col-md-3 col-6 mt-2">
                    <b>Пост ВКонтакте:</b>
                    {post.vk_post_url ? (
                      <a
                        href={post.vk_post_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        открыть
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>

                  <div className="col-md-3 col-6 mt-2">
                    <b>Уникальность:</b>
                    {secret ? (
                      <Uniqueness
                        id={secret.id}
                        data={secret.uniqueness_text_ru}
                      />
                    ) : (
                      "-"
                    )}
                  </div>

                  {post.removed_at && (
                    <>
                      <div className="col-md-3 col-6 mt-2">
                        <b>Удалено:</b>
                        {fullDate(post.removed_at)}
                      </div>

                      <div className="col-md-3 col-6 mt-2">
                        <b>Удалил:</b>
                        <ProfileLink user={post.remover} />
                      </div>

                      <div className="col-md-3 col-6 mt-2">
                        <b>Причина:</b>
                        {post.remove_reason}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        <footer className="row text-md-left text-center">
          <div className="col-md-6 counters">
            <div className="row no-gutters justify-content-between">
              <div className="col-auto item">
                <a href="#" className="align-middle" onClick={this.toggleLikes}>
                  <SvgHeart width={16} height={16} className="fill-primary" />
                  <span>{post.likes_count}</span>
                </a>
              </div>

              <div className="col-auto item">
                <Link
                  to={`${baseUrl}/posts/${post.id}#comments`}
                  className="align-middle"
                >
                  <SvgComment width={16} height={16} className="fill-primary" />
                  <span>{post.comments_count}</span>
                </Link>
              </div>

              <div className="col-auto item">
                <a href="#" className="align-middle" onClick={this.toggleViews}>
                  <SvgEye width={16} height={16} className="fill-primary" />
                  <span>{post.views}</span>
                </a>
              </div>

              <div className="col-auto item">
                <a
                  href="#"
                  className="align-middle"
                  onClick={this.toggleBookmarks}
                >
                  <SvgBookmark
                    width={16}
                    height={16}
                    className="fill-primary"
                  />
                  <span>{post.bookmark_count}</span>
                </a>
              </div>
            </div>

            <MediaQuery maxWidth={767}>
              {showLikes && (
                <div className="row text-left no-gutters mt-1">
                  <div className="col-3 col-sm pl-1 pl-sm-0 item">Лайки:</div>
                  {post.top_likes.map((like) => (
                    <div className="col-3 col-sm pl-1 pl-sm-0 item">
                      <span className={`emoji-icon ${like.type}`}>
                        {like.count}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </MediaQuery>

            {showViews && (
              <div className="row no-gutters mt-1">
                <div className="col text-sm-left item">Просмотры:</div>
                <div className="col item ml-1">
                  <SvgIos width={16} height={16} className="fill-primary" />
                  <span>{post.ios_views}</span>
                </div>
                <div className="col item ml-1">
                  <SvgAndroid width={16} height={16} className="fill-primary" />
                  <span>{post.android_views}</span>
                </div>
                <div className="col item ml-1">
                  <SvgHuawei width={16} height={16} className="fill-primary" />
                  <span>{post.huawei_views}</span>
                </div>
                <div className="col item ml-1">
                  <SvgRustore width={16} height={16} className="fill-primary" />
                  <span>{post.rustore_views}</span>
                </div>
              </div>
            )}

            {showBookmarks && (
              <div className="row no-gutters mt-1">
                <div className="col text-sm-left item">Закладки:</div>
                <div className="col item ml-1">
                  <SvgIos width={16} height={16} className="fill-primary" />
                  <span>{post.bookmarks_in_ios}</span>
                </div>
                <div className="col item ml-1">
                  <SvgAndroid width={16} height={16} className="fill-primary" />
                  <span>{post.bookmarks_in_android}</span>
                </div>
                <div className="col item ml-1">
                  <SvgHuawei width={16} height={16} className="fill-primary" />
                  <span>{post.bookmarks_in_huawei}</span>
                </div>
                <div className="col item ml-1">
                  <SvgRustore width={16} height={16} className="fill-primary" />
                  <span>{post.bookmarks_in_rustore}</span>
                </div>
              </div>
            )}
          </div>

          {post.status !== "removed" && (
            <div className="col">
              <div className="row no-gutters justify-content-end">
                <div className="col-md-auto col-6">
                  <label className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={post.enable_comments}
                      disabled={!hasEditorAccess}
                      onChange={this.toggleComments}
                    />
                    <span className="custom-control-indicator" />
                    <span className="custom-control-description text-muted">
                      Комментарии
                    </span>
                  </label>
                </div>

                <div className="col-md-auto col-6 ml-md-2">
                  <label className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={post.fixed}
                      disabled={!hasEditorAccess && !post.fixed}
                      onChange={this.toggleFixed}
                    />
                    <span className="custom-control-indicator" />
                    <span className="custom-control-description text-muted">
                      Закрепить
                    </span>
                  </label>
                </div>
              </div>
            </div>
          )}

          {showFixedConfirmation && (
            <Modal
              key="confirm"
              title={`Закрепление поста #${this.props.post.id}`}
              toggleModal={this.toggleFixedConfirmation}
              small
            >
              <div className="mb-1">
                <div>Вы действительно хотите закрепить данный пост?</div>
                <div className="mt-25 text-center text-sm-right">
                  <button
                    type="button"
                    onClick={this.toggleFixedConfirmation}
                    className="btn btn-primary"
                  >
                    Отмена
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      this.performToggleFixed(true);
                    }}
                    className="btn btn-secondary ml-15"
                  >
                    Закрепить
                  </button>
                </div>
              </div>
            </Modal>
          )}

          <MediaQuery minWidth={768}>
            {showLikes && (
              <div className="col-12 counters">
                <div className="row no-gutters mt-1">
                  <div className="col-auto item mr-2">Лайки:</div>
                  {post.top_likes.map((like) => (
                    <div className="col-auto item">
                      <span className={`emoji-icon ${like.type}`}>
                        {like.count}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </MediaQuery>
        </footer>

        {showRestoreConfirmation && (
          <Modal
            key="confirm"
            title={`Восстановление поста #${post.id}`}
            toggleModal={this.toggleRestoreForm}
            small
          >
            <div>
              <div className="mb-1">
                Вы действительно хотите восстановить пост в ленту?
              </div>
              <div className="mt-2 text-right">
                <button
                  type="button"
                  onClick={this.toggleRestoreForm}
                  className="btn btn-primary mr-1"
                >
                  Отмена
                </button>
                <button
                  type="button"
                  onClick={this.restore}
                  className="btn btn-secondary"
                >
                  Восстановить
                </button>
              </div>
            </div>
          </Modal>
        )}

        {showRemoveForm && (
          <Modal
            title={`Удаление поста #${post.id}`}
            toggleModal={this.toggleRemoveForm}
            small
          >
            <div>
              <div className="mb-1">Причина удаления</div>
              <textarea
                className="form-control"
                rows={5}
                onChange={this.changeRemoveReason}
                value={removeReason}
              />
              <div className="mt-2 text-right">
                <button
                  type="button"
                  onClick={this.toggleRemoveForm}
                  className="btn btn-primary mr-1"
                >
                  Отмена
                </button>
                <button
                  type="button"
                  onClick={this.remove}
                  className="btn btn-secondary"
                >
                  Удалить
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }

  static propTypes = {
    post: PropTypes.objectOf(PropTypes.any).isRequired,
    searchQuery: PropTypes.string,
    hasEditorAccess: PropTypes.bool.isRequired,

    updateData: PropTypes.func.isRequired,
    toggleForm: PropTypes.func.isRequired,
    toggleComments: PropTypes.func.isRequired,
    toggleFixed: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    restore: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  searchQuery: state.filters.searchQuery,
  hasEditorAccess: state.user.hasEditorAccess,
});

const mapDispatchToProps = (dispatch) => ({
  toggleComments: (data) => dispatch(postToggleComments(data)),
  toggleFixed: (data) => dispatch(postToggleFixed(data)),
  remove: (data) => dispatch(removePost(data)),
  restore: (data) => dispatch(restorePost(data)),
});

export const Post = connect(mapStateToProps, mapDispatchToProps)(Item);
