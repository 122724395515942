import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { ClickOutside } from "../../../libs";
import { SvgDown } from "../../../svg";
import { GroupedDropdown } from "./grouped-dropdown";

const Filters = [
  { value: "", title: "Кто угодно" },
  { value: "include", title: "Выбрать" },
];

export class GroupedDropdownUsersDeleted extends GroupedDropdown {
  getTitle = () => {
    const { groups, selected } = this.props;

    if (selected.filter === "") {
      return Filters[0].title;
    }

    if (selected.filter === "include") {
      return Filters[1].title;
    }
  };

  handleFilterChange = (e) => {
    const newFilter = e.target.value;
    let newGroups = [];

    if (newFilter === "include") {
      newGroups = [
        "underside",
        ...this.props.groups.map((group) => group.value),
      ];
    }

    this.setState({
      selected: {
        filter: newFilter,
        groups: newGroups,
      },
    });
  };

  toggleDropdown = () => {
    const {
      selected: { filter, groups },
      prevSelected,
      showDropdown,
    } = this.state;

    if (
      showDropdown &&
      this.state.selected.filter === "include" &&
      this.state.selected.groups.length !== this.props.groups.length + 1
    ) {
      return this.props.callback({ filter, groups });
    }

    if (
      this.state.selected.filter === "include" &&
      this.state.selected.groups.length === 0
    ) {
      let newGroups = [
        "underside",
        ...this.props.groups.map((group) => group.value),
      ];

      this.setState({
        showDropdown: !showDropdown,
        selected: {
          filter: "include",
          groups: newGroups,
        },
      });

      return;
    }

    // on close when a default filter or another filter with at least one group is selected
    if (
      showDropdown &&
      ([Filters[0].value].includes(filter) || groups.length)
    ) {
      if (groups.length === this.state.selected.groups.length) {
        return this.props.callback({ filter, groups: [] });
      }

      return this.props.callback({ filter, groups });
    }

    this.setState({ showDropdown: !showDropdown, selected: prevSelected });
  };

  render() {
    const { showDropdown, selected } = this.state;
    const { groups, disabled } = this.props;

    const modifiedGroups = [
      { value: "underside", title: "Изнанка" },
      ...groups,
    ];

    if (disabled) {
      return this.getTitle();
    }

    return (
      <ClickOutside
        className="dropdown-toggle"
        onBlur={this.toggleDropdown}
        isOpen={showDropdown}
      >
        <Fragment>
          <button
            type="button"
            className={"btn btn-link p-0" + (showDropdown ? " opened" : "")}
            onClick={this.toggleDropdown}
          >
            {this.getTitle()}
            <SvgDown width={9} height={6} className="toggler" />
          </button>
          {showDropdown && (
            <div className="dropdown p-2 right">
              {Filters.map((filter) => (
                <div key={filter.value}>
                  <label className="custom-control custom-radio">
                    <input
                      type="radio"
                      name="filter.value"
                      value={filter.value}
                      className="custom-control-input"
                      checked={selected.filter === filter.value}
                      onChange={this.handleFilterChange}
                    />
                    <span className="custom-control-indicator" />
                    <span className="custom-control-description" width="100">
                      {filter.title}
                    </span>
                  </label>

                  {filter.value === "include" &&
                    selected.filter === filter.value &&
                    modifiedGroups.map((group) => (
                      <label
                        key={group.value}
                        className="custom-control custom-checkbox ml-25"
                      >
                        <input
                          type="checkbox"
                          name={group.value}
                          value={group.value}
                          className="custom-control-input"
                          checked={selected.groups.includes(group.value)}
                          onChange={this.handleGroupsChange}
                        />
                        <span className="custom-control-indicator" />
                        <span className="custom-control-description">
                          {group.title}
                        </span>
                      </label>
                    ))}
                </div>
              ))}
            </div>
          )}
        </Fragment>
      </ClickOutside>
    );
  }
}
