import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadStatistics } from "../../actions/";
import { Loading, LoadingError } from "../../components/common/";
import {
  CreatedUsers,
  VipUsers,
  ActiveUsers,
} from "../../components/statistics/";

class Users extends Component {
  state = {
    created: null,
    vip: null,
    active: null,
    loading: true,
    loadingError: false,
  };

  componentDidMount() {
    this.props.load().then((e) => this.setState(e));
  }

  render() {
    const { created, vip, active, loading, loadingError } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (loadingError) {
      return <LoadingError />;
    }

    return (
      <div className="statistics-container">
        <CreatedUsers data={created} className="created-users mt-1" />
        <VipUsers data={vip} className="vip-users mt-4" />
        <ActiveUsers data={active} className="active-users mt-4" />
      </div>
    );
  }

  static propTypes = {
    load: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  load: () => dispatch(loadStatistics()),
});

export const StatisticsUsers = connect(null, mapDispatchToProps)(Users);
