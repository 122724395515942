import { push } from "connected-react-router";

export const submitSearch =
  ({ query }) =>
  (dispatch, getState) => {
    const { search } = getState().router.location;
    const params = new URLSearchParams(search);

    if (query.length > 0) {
      params.set("text", query);
    } else {
      params.delete("text");
    }

    dispatch(push(`?${params.toString()}`));
  };
