import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { BtnDropdown } from "./";
import { SvgDown } from "../../svg";

class Dropdown extends Component {
  state = {
    selectedId: this.props.selectedId || 0,
    prevSelectedId: this.props.selectedId || 0,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.selectedId !== state.prevSelectedId) {
      return { selectedId: props.selectedId, prevSelectedId: props.selectedId };
    }
    return null;
  }

  handleSelection = (id) => {
    this.setState({ selectedId: id });
    this.props.onChange(id);
  };

  getCategoryName = (id) => {
    if (!this.props.categories) return "Категория";
    const category = this.props.categories.find((el) => el.id === id);
    return category ? category.name : "Категория";
  };

  render() {
    const { selectedId } = this.state;
    const { categories } = this.props;

    return (
      <BtnDropdown
        btnClass="btn btn-link p-0"
        btnText={
          <span>
            {this.getCategoryName(selectedId)}
            <SvgDown width={9} height={6} className="toggler" />
          </span>
        }
      >
        <ul className="dropdown">
          <li
            key={`cat_37_duplicate`}
            className={37 === selectedId ? "active" : null}
          >
            <button
              className="btn btn-link p-0"
              onClick={() => this.handleSelection(37)}
            >
              Разное
            </button>
          </li>
          <li
            key={`cat_21_duplicate`}
            className={21 === selectedId ? "active" : null}
          >
            <button
              className="btn btn-link p-0"
              onClick={() => this.handleSelection(21)}
            >
              От редакции
            </button>
          </li>
          {categories
            .filter((category) => !category.hide_from_list)
            .map((category) => (
              <li
                key={`cat_${category.id}`}
                className={category.id === selectedId ? "active" : null}
              >
                <button
                  className="btn btn-link p-0"
                  onClick={() => this.handleSelection(category.id)}
                >
                  {category.name}
                </button>
              </li>
            ))}
        </ul>
      </BtnDropdown>
    );
  }

  static propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedId: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  categories: state.categories,
});

export const CategoryDropdown = connect(mapStateToProps)(Dropdown);
