import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";

import { submitSearch } from "../../actions/";
import { SvgSearch, SvgCross } from "../../svg/";

class Search extends Component {
  state = {
    text: this.props.query,
  };

  componentDidMount() {
    if (this.props.focus) {
      this.inputRef.focus();
    }
  }

  handleInput = (e) => {
    this.setState({ text: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.submit({ query: this.state.text });
  };

  render() {
    const { toggleSearch } = this.props;

    return (
      <form
        action=""
        method="get"
        className="search-box"
        onSubmit={this.onSubmit}
      >
        <input
          type="text"
          name="text"
          ref={(c) => {
            this.inputRef = c;
          }}
          value={this.state.text}
          onChange={this.handleInput}
          className="form-control"
          placeholder="Поиск"
        />

        <button type="submit" className="submit">
          <SvgSearch width={12} height={12} className="fill-gray" />
        </button>

        <MediaQuery maxWidth={379}>
          <button
            type="button"
            className="btn btn-link p-0"
            onClick={toggleSearch}
          >
            <SvgCross width={16} height={16} className="fill-gray" />
          </button>
        </MediaQuery>

        <MediaQuery minWidth={380} maxWidth={991}>
          <button
            type="button"
            className="btn btn-link p-0"
            onClick={toggleSearch}
          >
            Отменить
          </button>
        </MediaQuery>
      </form>
    );
  }

  static propTypes = {
    query: PropTypes.string,
    toggleSearch: PropTypes.func,
    submit: PropTypes.func.isRequired,
    focus: PropTypes.bool,
  };

  static defaultProps = {
    query: "",
    toggleSearch: null,
    focus: false,
  };
}

const mapStateToProps = (state) => ({
  query: state.filters.searchQuery,
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({
  submit: (data) => dispatch(submitSearch(data)),
});

export const SearchBox = connect(mapStateToProps, mapDispatchToProps)(Search);
