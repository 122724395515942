import moment from "moment";
import "moment/locale/ru";

export const formattedDate = (date, format) =>
  moment(date)
    .utcOffset(180)
    .format(format || "DD.MM.YYYY");

export const fullDate = (date) =>
  moment(date).utcOffset(180).format("DD.MM.YYYY HH:mm:ss");

export const fromNow = (date, withoutSuffix) =>
  moment(date).utcOffset(180).fromNow(withoutSuffix);

export const fromDate = (date, fromDate, withoutSuffix) =>
  moment(date).from(fromDate, withoutSuffix);
