import { DataLayer } from "../../libs/";

export const loadCities =
  ({ cities = [], page = 0 }) =>
  async (dispatch, getState) => {
    const filters = getState().filters.cities;
    const params = [];
    const newState = {
      loading: false,
      loadingError: false,
    };

    if (filters.country > 0) params.push(`country=${filters.country}`);
    if (filters.searchQuery) params.push(`text=${filters.searchQuery}`);
    if (page > 0) params.push(`page=${page + 1}`);

    await DataLayer.get({
      path: `/cities.json` + (params.length ? "?" + params.join("&") : ""),
    })
      .then((response) => {
        newState.cities = [...cities, ...response];
        newState.page = page + 1;
        newState.end = response.length === 0;
      })
      .catch(() => {
        newState.loadingError = true;
      });

    return newState;
  };
