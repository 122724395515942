import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { createBan, deleteBan } from "../../../actions/";
import { fromNow } from "../../../libs/";
import { SvgBan } from "../../../svg/";
import { BanModal, BtnDropdown, Modal } from "../";

class Button extends Component {
  state = {
    prevBanned: this.props.user.banned,
    banned: this.props.user.banned,
    bannedUntil: this.props.user.banned_until,
    showBanModal: false,
    unbanModal: false,
    banError: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.user.banned !== state.prevBanned) {
      return {
        prevBanned: props.user.banned,
        banned: props.user.banned,
        bannedUntil: props.user.banned_until,
      };
    }
    return null;
  }

  toggleBanModal = () => {
    this.setState({ showBanModal: !this.state.showBanModal });
  };

  toggleUnbanModal = (e) => {
    e.preventDefault();
    this.setState({ unbanModal: !this.state.unbanModal });
  };

  quickBanUser = (e, banReasonId) => {
    e.preventDefault();
    const formData = { reason_id: banReasonId };
    if (this.props.comment) {
      formData.comment_id = this.props.comment.id;
    }
    this.banUser(formData);
  };

  banUser = (formData) => {
    const {
      user: { id: userId },
      callback,
    } = this.props;
    this.props
      .createBan({ userId, formData, callback })
      .then((e) => this.setState(e));
  };

  unBanUser = () => {
    const { id: userId } = this.props.user;
    this.props.deleteBan({ userId }).then((e) => this.setState(e));
  };

  resetBanError = () => {
    this.setState({ banError: null });
  };

  render() {
    const { banned, bannedUntil, showBanModal, unbanModal, banError } =
      this.state;
    const { showUnBanButton, isIconic, classes, banReasons } = this.props;
    const ban = { banned_user: this.props.user, comment: this.props.comment };

    if (banned && !showUnBanButton) {
      return null;
    }

    if (banned && showUnBanButton) {
      return (
        <Fragment>
          <span className="text-muted">
            {bannedUntil
              ? `Бан на ${fromNow(bannedUntil, true)}`
              : "Забанен навсегда"}
          </span>
          <span className="mx-1">·</span>
          <a href="?unban" className={classes} onClick={this.toggleUnbanModal}>
            Разбанить
          </a>

          {unbanModal && (
            <Modal
              title="Подтверждение"
              toggleModal={this.toggleUnbanModal}
              small
            >
              <div>
                <div>
                  {bannedUntil
                    ? `Пользователь будет разбанен ${fromNow(bannedUntil)}`
                    : "Забанен навсегда."}
                </div>
                <div>
                  Вы действительно хотите разбранить пользователя сейчас?
                </div>
                <div className="mt-25 text-right">
                  <button
                    type="button"
                    onClick={this.toggleUnbanModal}
                    className="btn btn-primary mr-15"
                  >
                    Нет
                  </button>
                  <button
                    type="button"
                    onClick={this.unBanUser}
                    className="btn btn-secondary"
                  >
                    Да
                  </button>
                </div>
              </div>
            </Modal>
          )}
        </Fragment>
      );
    }

    return [
      <BtnDropdown
        key="btn"
        btnClass={classes || "btn btn-danger danger-light"}
        btnText={
          isIconic ? (
            <SvgBan className="fill-red" width={20} height={20} />
          ) : (
            <span>Забанить...</span>
          )
        }
      >
        <ul className="dropdown">
          {banReasons.slice(0, 4).map((banReason) => (
            <li key={banReason.id}>
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={(e) => this.quickBanUser(e, banReason.id)}
              >
                {banReason.title}
              </button>
            </li>
          ))}
          <li>
            <button
              type="button"
              className="btn btn-link p-0"
              onClick={this.toggleBanModal}
            >
              Еще...
            </button>
          </li>
        </ul>
      </BtnDropdown>,

      showBanModal && (
        <BanModal
          key="modal"
          ban={ban}
          toggleModal={this.toggleBanModal}
          save={this.banUser}
        />
      ),
      banError && (
        <Modal
          key="error"
          title="Ошибка"
          small
          toggleModal={this.resetBanError}
        >
          <Fragment>
            {banError}
            <button
              type="button"
              onClick={this.resetBanError}
              className="btn btn-primary mt-1 float-right"
            >
              Закрыть
            </button>
          </Fragment>
        </Modal>
      ),
    ];
  }

  static propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    comment: PropTypes.objectOf(PropTypes.any),
    chat_message: PropTypes.objectOf(PropTypes.any),
    showUnBanButton: PropTypes.bool,
    callback: PropTypes.func,
    isIconic: PropTypes.bool,
    classes: PropTypes.string,
    banReasons: PropTypes.arrayOf(PropTypes.any).isRequired,
    createBan: PropTypes.func.isRequired,
    deleteBan: PropTypes.func.isRequired,
  };

  static defaultProps = {
    comment: null,
    chat_message: null,
    showUnBanButton: false,
    callback: null,
    isIconic: false,
    classes: null,
  };
}

const mapStateToProps = (state) => ({
  banReasons: state.banReasons,
});

const mapDispatchToProps = (dispatch) => ({
  createBan: (data) => dispatch(createBan(data)),
  deleteBan: (data) => dispatch(deleteBan(data)),
});

export const BanButton = connect(mapStateToProps, mapDispatchToProps)(Button);
