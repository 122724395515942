import _ from "lodash";

import { DataLayer } from "../../libs/";

export const loadPosts =
  ({ posts = [], scheduled = [] }) =>
  async (__, getState) => {
    const { type, category, enableComments, period, searchQuery } =
      getState().filters.posts;
    const params = [];
    const newState = {
      loading: false,
      loadingError: false,
    };

    if (category) {
      params.push(`category_id=${category}`);
    }
    if (enableComments) {
      params.push(`enable_comments=${enableComments}`);
    }
    if (type === "best" && period) {
      params.push(`period=${period}`);
    }
    if (searchQuery) {
      params.push(`text=${encodeURIComponent(searchQuery)}`);
    }
    if (posts.length > 0) {
      params.push(`from=${_.last(posts).id}`);
    }

    await DataLayer.get({
      path:
        "/posts/" +
        type +
        ".json" +
        (params.length ? "?" + params.join("&") : ""),
    })
      .then((response) => {
        newState.posts = [...posts, ...response.posts];
        newState.end = !response.posts.length;
        newState.alert = response.alert;

        if (response.scheduled) {
          newState.scheduled = [...scheduled, ...response.scheduled];
        }

        if (response.fixed) {
          newState.posts = [...response.fixed, ...newState.posts];
        }
      })
      .catch(() => {
        newState.loadingError = true;
      });

    return newState;
  };
