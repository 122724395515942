import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";

import { resolveChatMessageComplain } from "../../actions/";
import { BanButton, Date } from "../common/";
import { ProfileLink } from "../users/";

class Item extends Component {
  state = {
    complain: this.props.complain,
  };

  resolve = () => {
    this.props
      .resolve({ id: this.state.complain.id })
      .then((e) => this.setState(e));
  };

  render() {
    const { complain } = this.state;

    return (
      <div
        className={`complain-item mb-3${complain.resolved ? " resolved" : ""}`}
      >
        <header className="row justify-content-between">
          <div className={`col-auto${complain.resolved ? " resolved" : ""}`}>
            <span className="text-primary font-weight-500">#{complain.id}</span>
            <span className="separator-dot">·</span>
            <ProfileLink user={complain.user} className="btn btn-link p-0" />
            <span className="separator-dot">·</span>
            <Date className="date text-muted" date={complain.created_at} />
          </div>
        </header>

        <div className={`wrap${complain.resolved ? " resolved" : ""}`}>
          <header>
            <span className="text-muted">
              На сообщение{" "}
              <span className="text-danger font-weight-500">
                #{complain.chat_message.id}
              </span>{" "}
              от{" "}
            </span>
            <ProfileLink
              user={complain.chat_message.user}
              className="text-danger font-weight-500"
            />
            <MediaQuery maxWidth={510}>
              {(matches) =>
                matches ? (
                  <div className="w-100" />
                ) : (
                  <span key="separator_dot" className="separator-dot">
                    ·
                  </span>
                )
              }
            </MediaQuery>
            <Date
              className="date text-muted"
              date={complain.chat_message.created_at}
            />
          </header>

          <div className="std">{complain.chat_message.text}</div>
        </div>

        <footer className="row text-md-left text-center">
          <div className="col-auto ml-auto">
            <BanButton
              key={`btnEdit_${complain.id}`}
              classes="btn btn-dropdown btn-complain-ban"
              user={complain.chat_message.user}
              chat_message={complain.chat_message}
              callback={this.handleUserBan}
            />
            {!complain.resolved && (
              <button
                type="submit"
                className="btn btn-secondary ml-1"
                onClick={this.resolve}
              >
                Обработать
              </button>
            )}
          </div>
        </footer>
      </div>
    );
  }

  static propTypes = {
    complain: PropTypes.objectOf(PropTypes.any).isRequired,
    resolve: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  resolve: (data) => dispatch(resolveChatMessageComplain(data)),
});

export const Complain = connect(null, mapDispatchToProps)(Item);
