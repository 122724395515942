import React from "react";
import PropTypes from "prop-types";

import { Loading, LoadingError } from "../../components/common/";

export const NotFound = ({ title, id, loading, loadingError }) => {
  if (loading) {
    return <Loading />;
  }

  if (loadingError) {
    return <LoadingError />;
  }

  return (
    <div className="p-3">
      <div className="alert alert-danger text-center">
        {title} c id: {id} не найден в базе!
      </div>
    </div>
  );
};

NotFound.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  loading: PropTypes.bool.isRequired,
  loadingError: PropTypes.bool.isRequired,
};
