import React, { Component } from "react";
import PropTypes from "prop-types";

import { fullDate, fromNow } from "../../libs/datetime";

export class Date extends Component {
  state = {
    showFromNow: this.props.fromNow,
  };

  toggleFormat = () => {
    this.setState({ showFromNow: !this.state.showFromNow });
  };

  render() {
    const { date, className } = this.props;
    const { showFromNow } = this.state;

    return (
      <span className={className} onClick={this.toggleFormat}>
        {showFromNow ? fromNow(date, true) : fullDate(date)}
      </span>
    );
  }

  static propTypes = {
    date: PropTypes.string.isRequired,
    className: PropTypes.string,
    fromNow: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    fromNow: true,
  };
}
