import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import { baseUrl } from "../../../libs";
import { PostAddFormButton } from "../../posts";
import { UserAddFormButton } from "../../users";
import { ButtonsPost } from "../header";

const HeaderButtonComponent = ({ hasEditorAccess }) => {
  return (
    <Switch>
      <Route
        exact
        path={[`${baseUrl}/posts`, `${baseUrl}/posts/:type(best|removed)`]}
      >
        {hasEditorAccess && (
          <div className="col-auto ml-2">
            <PostAddFormButton />
          </div>
        )}
      </Route>

      <Route
        exact
        path={[
          `${baseUrl}/posts/:id(\\d+)`,
          `${baseUrl}/posts/:id(\\d+)/comments/:type(new|best|worst)`,
        ]}
      >
        <div className="col-auto ml-2">
          <ButtonsPost />
        </div>
      </Route>

      <Route
        exact
        path={[
          `${baseUrl}/users`,
          `${baseUrl}/users/:type(admins|editors|moderators|keepers|vip|banned)`,
        ]}
      >
        {hasEditorAccess && (
          <div className="col-auto ml-2">
            <UserAddFormButton />
          </div>
        )}
      </Route>
    </Switch>
  );
};

HeaderButtonComponent.propTypes = {
  hasEditorAccess: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired, // implicitly required in Switch
};

const mapStateToProps = (state) => ({
  hasEditorAccess: state.user.hasEditorAccess,
  path: state.router.location.pathname,
});

export const HeaderButton = connect(mapStateToProps)(HeaderButtonComponent);
