import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";

import {
  updateUser,
  unlinkSocialAccount,
  fetchCountrySuggestions,
  fetchCitySuggestions,
} from "../../actions/";
import { formattedDate } from "../../libs/";
import { SvgDevices, SvgEdit } from "../../svg/";
import { Error, Modal } from "../common/";
import { Roles } from "./roles";
import { InputWithSuggestions } from "./";

const adShowStates = {
  "": "В ожидании",
  true: "Включена",
  false: "Отключена",
};
const showCommentsStates = {
  true: "Доступны",
  false: "Скрыты",
};
const showLikesStates = {
  true: "Доступны",
  false: "Скрыты",
};
const enableChatStates = {
  true: "Разрешены",
  false: "Запрещены",
};

class FormComponent extends Component {
  constructor(props) {
    super(props);

    const user = this.props.user;

    this.state = {
      user,
      prevUser: user,
      bioDisabled: true,
      cityId: user.city ? user.city.id : null,
      cityName: user.city ? user.city.name : "",
      countryId: user.city ? user.city.country_id : null,
      countryName: user.city ? user.city.country_name : "",
      showUnlinkConfirmation: false,
      unlinkProvider: null,
      errors: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.user !== state.prevUser) {
      const { user } = props;
      return {
        user,
        prevUser: user,
        cityId: user.city ? user.city.id : null,
        cityName: user.city ? user.city.name : "",
        countryId: user.city ? user.city.country_id : null,
        countryName: user.city ? user.city.country_name : "",
      };
    }
    return null;
  }

  handleInputChange = (e) => {
    const user = {};
    user[e.target.name] = e.target.value;
    this.setState({ user: { ...this.state.user, ...user } });
  };

  setCountry = (countryId, countryName) => {
    this.setState({ countryId, countryName, cityId: null, cityName: "" });
  };

  setCity = (cityId, cityName) => {
    const user = { ...this.state.user, city_id: cityId, location: cityName };
    this.setState({ cityId, cityName, user });
  };

  resetCity = () => {
    const user = { ...this.state.user, city_id: null, location: "" };
    this.setState({
      cityId: null,
      cityName: "",
      countryId: null,
      countryName: "",
      user,
    });
  };

  openUnlinkAccountConfirmation = (provider) => {
    this.setState({ showUnlinkConfirmation: true, unlinkProvider: provider });
  };

  closeUnlinkAccountConfirmation = () => {
    this.setState({ showUnlinkConfirmation: false, unlinkProvider: null });
  };

  enableBioInput = () => {
    this.setState({ bioDisabled: false });
  };

  unlinkAccount = () => {
    const {
      user: { id },
      unlinkProvider,
    } = this.state;
    const formData = { provider: unlinkProvider };

    this.props
      .unlinkAccount({ id, formData })
      .then((response) => {
        this.setState({
          errors: null,
          showUnlinkConfirmation: false,
          unlinkProvider: null,
        });
        this.props.callback(response);
      })
      .catch((error) => {
        const errors = error.data
          ? error.data.errors || error.data
          : error.message;
        this.setState({
          errors,
          showUnlinkConfirmation: false,
          unlinkProvider: null,
        });
      });
  };

  saveUser = (e) => {
    e.preventDefault();

    const {
      id,
      fullname,
      bio,
      email,
      role,
      rating,
      city_id,
      location,
      show_comments,
      show_likes,
      enable_chat,
      ad_show,
    } = this.state.user;
    const formData = {
      user: {
        fullname,
        bio,
        email,
        role,
        rating,
        city_id,
        location,
        show_comments,
        show_likes,
        enable_chat,
        ad_show,
      },
    };
    this.props
      .update({ id, formData })
      .then((response) => {
        this.setState({ errors: null });
        this.props.callback(response);
      })
      .catch((error) => {
        const errors = error.data
          ? error.data.errors || error.data
          : error.message;
        this.setState({ errors });
      });
  };

  render() {
    const {
      user,
      bioDisabled,
      countryId,
      countryName,
      cityId,
      cityName,
      showUnlinkConfirmation,
      errors,
    } = this.state;
    const {
      hasAdminAccess,
      hasEditorAccess,
      hasModeratorAccess,
      loadCountrySuggestions,
      loadCitySuggestions,
    } = this.props;
    const DeviceIcon = SvgDevices[user.device_type];

    return (
      <div>
        <h3 className="mt-md-4 mb-md-25 mt-2 mb-15">Информация</h3>

        {errors && <Error errors={errors} />}

        <div className="form-group row align-items-center">
          <div className="col-sm-6 col-12 mb-sm-0 mb-1">Роль</div>
          <div className="col">
            <select
              className="form-control custom-select"
              name="role"
              value={user.role}
              onChange={this.handleInputChange}
              disabled={!hasAdminAccess}
            >
              {Object.keys(Roles).map((roleName) => (
                <option key={roleName} value={roleName}>
                  {Roles[roleName]}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row align-items-center">
          <div className="col-sm-6 col-12 mb-sm-0 mb-1">Ник</div>
          <div className="col">
            <input
              type="text"
              className="form-control"
              name="fullname"
              value={user.fullname || ""}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className="form-group row align-items-center">
          <div className="col-sm-6 col-12 mb-sm-0 mb-1">О себе</div>
          <div className="col">
            <input
              type="text"
              className="form-control"
              name="bio"
              value={user.bio || ""}
              onChange={this.handleInputChange}
              disabled={bioDisabled}
              maxLength={80}
            />
          </div>
          {hasModeratorAccess && bioDisabled && (
            <button
              title="Редактировать"
              type="button"
              className="btn lh-0 pl-0"
              onClick={this.enableBioInput}
            >
              <SvgEdit width={16} height={16} className="fill-primary" />
            </button>
          )}
        </div>
        <div className="form-group row align-items-center">
          <div className="col-sm-6 col-12 mb-sm-0 mb-1">Рейтинг</div>
          <div className="col">
            <input
              type="number"
              className="form-control"
              name="rating"
              value={user.rating}
              onChange={this.handleInputChange}
              disabled={!hasEditorAccess}
            />
          </div>
        </div>
        {hasAdminAccess && (
          <Fragment>
            <div className="form-group row align-items-center">
              <div className="col-sm-6 col-12 mb-sm-0 mb-1">Почта</div>
              <div
                className="col"
                title={
                  user.email && !user.verified_email ? "Не подтверждён" : null
                }
              >
                <input
                  type="email"
                  className={`form-control${
                    user.email && !user.verified_email ? " border-warning" : ""
                  }`}
                  name="email"
                  value={user.email || ""}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row align-items-center">
              <div className="col-sm-6 col-12 mb-sm-0 mb-1">Страна</div>
              <div className="col">
                <InputWithSuggestions
                  title={countryName}
                  value={countryId}
                  placeholder="Не выбрана"
                  load={(search) => loadCountrySuggestions({ search })}
                  callback={this.setCountry}
                />
              </div>
            </div>
            {countryId && (
              <div className="form-group row align-items-center">
                <div className="col-sm-6 col-12 mb-sm-0 mb-1">Город</div>
                <div className="col">
                  <InputWithSuggestions
                    title={cityName}
                    value={cityId}
                    placeholder="Не выбран"
                    load={(search) =>
                      loadCitySuggestions({ search, countryId })
                    }
                    callback={this.setCity}
                  />
                </div>
              </div>
            )}
          </Fragment>
        )}

        <div className="form-group row align-items-center">
          <div className="col-sm-6 col-12 mb-sm-0 mb-1">Location</div>
          <div className="col">
            <input
              type="text"
              className="form-control"
              name="location"
              value={user.location || ""}
              onChange={this.handleInputChange}
              disabled
            />
            {user.location && hasAdminAccess && (
              <button
                type="button"
                className="btn btn-danger reset-city-btn"
                onClick={this.resetCity}
              >
                &times;
              </button>
            )}
          </div>
        </div>

        {hasAdminAccess && (
          <Fragment>
            <div className="form-group row align-items-center">
              <div className="col-sm-6 col-12 mb-sm-0 mb-1">Комментарии</div>
              <div className="col">
                <select
                  className="form-control custom-select"
                  name="show_comments"
                  value={user.show_comments}
                  onChange={this.handleInputChange}
                >
                  {Object.keys(showCommentsStates).map((state) => (
                    <option key={`state_${state}`} value={`${state}`}>
                      {showCommentsStates[state]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row align-items-center">
              <div className="col-sm-6 col-12 mb-sm-0 mb-1">Эмоции</div>
              <div className="col">
                <select
                  className="form-control custom-select"
                  name="show_likes"
                  value={user.show_likes}
                  onChange={this.handleInputChange}
                >
                  {Object.keys(showLikesStates).map((state) => (
                    <option key={`state_${state}`} value={`${state}`}>
                      {showLikesStates[state]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row align-items-center">
              <div className="col-sm-6 col-12 mb-sm-0 mb-1">Сообщения</div>
              <div className="col">
                <select
                  className="form-control custom-select"
                  name="enable_chat"
                  value={user.enable_chat}
                  onChange={this.handleInputChange}
                >
                  {Object.keys(enableChatStates).map((state) => (
                    <option key={`state_${state}`} value={`${state}`}>
                      {enableChatStates[state]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row align-items-center">
              <div className="col-sm-6 col-12 mb-sm-0 mb-1">Реклама</div>
              <div className="col">
                <select
                  className="form-control custom-select"
                  name="ad_show"
                  value={user.ad_show}
                  onChange={this.handleInputChange}
                >
                  {Object.keys(adShowStates).map((state) => (
                    <option key={`state_${state}`} value={`${state}`}>
                      {adShowStates[state]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row align-items-center">
              <div className="col">Подписка</div>
              {user.last_payment ? (
                <div
                  className={`col text-right text-${
                    moment(user.last_payment.expire_at).utcOffset(180) >
                    moment()
                      ? "success"
                      : "danger"
                  }`}
                >
                  <span>{user.last_payment.type} </span>
                  <span className="text-nowrap">
                    {formattedDate(user.last_payment.payed_at)}-
                    {formattedDate(user.last_payment.expire_at)}
                  </span>
                </div>
              ) : (
                <div className="col text-right">нет</div>
              )}
            </div>
            <div className="form-group row align-items-center">
              <div className="col">Социальные сети</div>
              <div className="col-auto text-right">
                {user.apple_user_id && (
                  <div className="d-block btn-group mb-1">
                    <button className="btn btn-apple">{user.apple_name}</button>
                    <button
                      type="button"
                      className="btn btn-danger px-1"
                      onClick={() =>
                        this.openUnlinkAccountConfirmation("apple")
                      }
                    >
                      &times;
                    </button>
                  </div>
                )}
                {user.fb_user_id && (
                  <div className="d-block btn-group mb-1">
                    <button className="btn btn-fb">{user.fb_name}</button>
                    <button
                      type="button"
                      className="btn btn-danger px-1"
                      onClick={() => this.openUnlinkAccountConfirmation("fb")}
                    >
                      &times;
                    </button>
                  </div>
                )}
                {user.vk_user_id && (
                  <div className="d-block btn-group">
                    <a
                      href={user.vk_link}
                      className="btn btn-vk"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {user.vk_name}
                    </a>
                    <button
                      type="button"
                      className="btn btn-danger px-1"
                      onClick={() => this.openUnlinkAccountConfirmation("vk")}
                    >
                      &times;
                    </button>
                  </div>
                )}
                {!user.fb_link && !user.vk_link && "нет"}
              </div>
            </div>
          </Fragment>
        )}
        <div className="form-group row align-items-center">
          <div className="col">Устройство</div>
          <div className="col-auto">
            <DeviceIcon width={18} height={18} className="ml-1" />
          </div>
        </div>
        <div className="form-group row align-items-center">
          <div className="col">Дата регистрации</div>
          <div className="col-auto">{formattedDate(user.created_at)}</div>
        </div>
        <div className="form-group row align-items-center">
          <div className="col">Последний вход</div>
          <div className="col-auto">
            {user.last_seen && moment(user.last_seen).format("DD.MM.YYYY")}
          </div>
        </div>

        <div className="mt-6 text-center">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.saveUser}
          >
            Сохранить
          </button>
        </div>

        {showUnlinkConfirmation && (
          <Modal
            title="Подтверждение"
            toggleModal={this.closeUnlinkAccountConfirmation}
          >
            <div>
              <div>Вы действительно хотите открепить профиль?</div>
              <div className="mt-25 text-right">
                <button
                  type="button"
                  onClick={this.closeUnlinkAccountConfirmation}
                  className="btn btn-primary mr-15"
                >
                  Нет
                </button>
                <button
                  type="button"
                  onClick={this.unlinkAccount}
                  className="btn btn-secondary"
                >
                  Да
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }

  static propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    callback: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    unlinkAccount: PropTypes.func.isRequired,
    hasAdminAccess: PropTypes.bool.isRequired,
    hasEditorAccess: PropTypes.bool.isRequired,
    hasModeratorAccess: PropTypes.bool.isRequired,
    loadCountrySuggestions: PropTypes.func.isRequired,
    loadCitySuggestions: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  hasAdminAccess: state.user.hasAdminAccess,
  hasEditorAccess: state.user.hasEditorAccess,
  hasModeratorAccess: state.user.hasModeratorAccess,
});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateUser(data)),
  unlinkAccount: (data) => dispatch(unlinkSocialAccount(data)),
  loadCountrySuggestions: (data) => dispatch(fetchCountrySuggestions(data)),
  loadCitySuggestions: (data) => dispatch(fetchCitySuggestions(data)),
});

export const Form = connect(mapStateToProps, mapDispatchToProps)(FormComponent);
