import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { logIn, setCurrentUser } from "../actions/";
import { SimpleHeader, SimpleFooter } from "../components/simple_apparel/";

class Form extends Component {
  state = {
    email: "",
    password: "",
    error: null,
  };

  handleInput = (e) => {
    this.setState({ [e.target.type]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { email, password } = this.state;
    this.props
      .logIn({ email, password })
      .then((response) => {
        this.props.setUserData(response.user);
      })
      .catch((error) => {
        const errors = error.data
          ? error.data.errors || error.data
          : error.message;
        this.setState({ password: "", error: errors });
      });
  };

  render() {
    const { error, email, password } = this.state;

    return (
      <div className="simple">
        <SimpleHeader />

        <div className="content sm">
          <div className="wrapper">
            <h3 className="mb-2">Войдите в свой аккаунт</h3>

            {error === "not_authorized" && (
              <div className="alert alert-danger">
                Неверный email или пароль
              </div>
            )}

            {error === "no_access" && (
              <div className="alert alert-warning">
                У вас нет доступа к этой странице.
              </div>
            )}

            <form
              className="new_user_session"
              action=""
              onSubmit={this.handleSubmit}
            >
              <input
                type="email"
                className="form-control mb-1"
                value={email}
                onChange={this.handleInput}
                placeholder="Ваш e-mail"
                autoComplete="username"
              />
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={this.handleInput}
                placeholder="Ваш пароль"
                autoComplete="current-password"
              />

              <div className="mt-1 text-right">
                <button type="submit" className="btn btn-primary">
                  Войти
                </button>
              </div>
            </form>
          </div>
        </div>

        <SimpleFooter />
      </div>
    );
  }

  static propTypes = {
    logIn: PropTypes.func.isRequired,
    setUserData: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  logIn: (data) => dispatch(logIn(data)),
  setUserData: (data) => dispatch(setCurrentUser(data)),
});

export const Login = connect(null, mapDispatchToProps)(Form);
