import { DataLayer } from "../../libs/";

export const loadBans = (id, type) => async (_, getState) => {
  const { page } = getState().filters;
  const params = [];
  const newState = {
    loading: false,
    loadingError: false,
  };

  if (type === "made_bans") params.push(`made_bans=1`);
  if (page > 1) params.push(`page=${page}`);

  await DataLayer.get({
    path: `/users/${id}/bans.json${
      params.length ? "?" + params.join("&") : ""
    }`,
  })
    .then((response) => {
      newState.bans = response;
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
