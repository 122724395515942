import React, { Component } from "react";
import PropTypes from "prop-types";

export class VKTimePicker extends Component {
  constructor(props) {
    super(props);

    const [hours, minutes] = props.time.split(":");

    this.state = {
      hours: hours || "08",
      minutes: minutes || "00",
    };
  }

  handleHoursChange = (event) => {
    this.setState({ hours: event.target.value }, this.updateTime);
  };

  handleMinutesChange = (event) => {
    this.setState({ minutes: event.target.value }, this.updateTime);
  };

  updateTime = () => {
    const { hours, minutes } = this.state;
    const time = `${hours}:${minutes}`;
    this.props.callback(time);
  };

  render() {
    const { hours, minutes } = this.state;

    const hourOptions = Array.from({ length: 24 }, (_, i) =>
      String(i).padStart(2, "0")
    );
    const minuteOptions = Array.from({ length: 60 }, (_, i) =>
      String(i).padStart(2, "0")
    );

    return (
      <div className="time-picker">
        <div className="d-flex align-items-center">
          <select
            value={hours}
            onChange={this.handleHoursChange}
            className="form-select"
          >
            {hourOptions.map((hour) => (
              <option key={hour} value={hour}>
                {hour}
              </option>
            ))}
          </select>
          :
          <select
            value={minutes}
            onChange={this.handleMinutesChange}
            className="form-select"
          >
            {minuteOptions.map((minute) => (
              <option key={minute} value={minute}>
                {minute}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  static propTypes = {
    time: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
  };
}
