import React, { Component } from "react";
import PropTypes from "prop-types";

import { ReasonForm } from ".";
import { SvgEdit } from "../../svg";

export class Reason extends Component {
  state = { showForm: false };

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  render() {
    const { showForm } = this.state;
    const { reason, saveCallback } = this.props;

    if (showForm) {
      return (
        <ReasonForm
          reason={reason}
          close={this.toggleForm}
          saveCallback={saveCallback}
        />
      );
    }

    return <ReasonRow reason={reason} onEditClick={this.toggleForm} />;
  }

  static propTypes = {
    reason: PropTypes.objectOf(PropTypes.any).isRequired,
    saveCallback: PropTypes.func.isRequired,
  };
}

const ReasonRow = ({ reason, onEditClick }) => (
  <tr key={`ban-reason-${reason.id}-form`}>
    <td>{reason.position}</td>
    <td>{reason.title}</td>
    <td>{reason.description}</td>
    <td>{reason.use_count}</td>
    <td className="text-right action-buttons">
      <button
        onClick={onEditClick}
        className="btn btn-sm btn-primary"
        title="Редактировать"
      >
        <SvgEdit width={14} height={12} className="fill-white" />
      </button>
    </td>
  </tr>
);

ReasonRow.propTypes = {
  reason: PropTypes.objectOf(PropTypes.any).isRequired,
  onEditClick: PropTypes.func.isRequired,
};
