import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { updateCountry } from "../../actions/";
import { baseUrl } from "../../libs/";
import { SvgEdit, SvgHide, SvgShow } from "../../svg/";
import { CountryForm } from "./";

class _Country extends Component {
  state = { showForm: false };

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  update = (country) => {
    this.toggleForm();
    this.props.update(country);
  };

  toggleHidden = () => {
    const country = { ...this.props.country };
    country.hidden = !country.hidden;
    this.props.update(country);
  };

  render() {
    const { showForm } = this.state;
    const { country } = this.props;

    if (showForm) {
      return (
        <CountryForm
          country={country}
          close={this.toggleForm}
          saveCallback={this.update}
        />
      );
    }

    return (
      <CountryRow
        country={country}
        onEditClick={this.toggleForm}
        onToggleClick={this.toggleHidden}
      />
    );
  }

  static propTypes = {
    country: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateCountry(data)),
});

export const Country = connect(null, mapDispatchToProps)(_Country);

const CountryRow = ({ country, onEditClick, onToggleClick }) => (
  <tr key={`country-${country.id}-form`}>
    <td className="align-middle text-center">
      {country.position > 0 && country.position}
    </td>
    <td className="align-middle">{country.name}</td>
    <td className="text-center">
      <Link
        to={`${baseUrl}/settings/cities?country=${country.id}`}
        className={`btn btn-link btn-sm`}
        target="_blank"
      >
        {country.cities_count}
      </Link>
    </td>
    <td className="text-right action-buttons">
      {country.hidden ? (
        <button
          onClick={onToggleClick}
          className="btn btn-sm btn-success"
          title="Восстановить"
        >
          <SvgShow width={14} height={12} className="fill-white" />
        </button>
      ) : (
        <button
          onClick={onToggleClick}
          className="btn btn-sm btn-danger"
          title="Скрыть"
        >
          <SvgHide width={14} height={12} className="fill-white" />
        </button>
      )}
      <button
        onClick={onEditClick}
        className="btn btn-sm btn-primary ml-1"
        title="Редактировать"
      >
        <SvgEdit width={14} height={12} className="fill-white" />
      </button>
    </td>
  </tr>
);

CountryRow.propTypes = {
  country: PropTypes.objectOf(PropTypes.any).isRequired,
  onEditClick: PropTypes.func.isRequired,
  onToggleClick: PropTypes.func.isRequired,
};
