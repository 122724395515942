import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Reason, ReasonForm } from "./";

const Tabs = [
  { name: "for_ban", title: "Банов" },
  { name: "for_chat_message_complain", title: "Жалоб на сообщения" },
  { name: "for_comment_complain", title: "Жалоб на комментарии" },
  { name: "for_post_complain", title: "Жалоб на посты" },
];

const buttonTitles = {
  for_ban: "бана",
  for_chat_message_complain: "жалобы на сообщения",
  for_comment_complain: "жалобы на комментарии",
  for_post_complain: "жалобы на посты",
};

class ReasonsByType extends Component {
  state = {
    showAddForm: false,
  };

  toggleForm = () => {
    this.setState({ showAddForm: !this.state.showAddForm });
  };

  render() {
    const { showAddForm } = this.state;
    const {
      data: reasons,
      filters: { reasonType },
      saveCallback,
    } = this.props;

    return (
      <div className="settings-item bg-white p-2">
        <div className="row mb-1">
          <div className="col secondary-nav border-0">
            <nav className="mt-15">
              {Tabs.map((tab) => (
                <Link
                  to={`#${tab.name}`}
                  key={tab.name}
                  className={reasonType === tab.name ? "active" : null}
                >
                  {tab.title}
                </Link>
              ))}
            </nav>
          </div>
          <div className="col-auto">
            <button className="btn btn-primary" onClick={this.toggleForm}>
              Добавить причину {buttonTitles[reasonType]}
            </button>
          </div>
        </div>

        <table className="table table-responsive table-striped table-sm table-hover">
          <colgroup>
            <col width="2" />
            <col width="150" />
            <col />
            <col width="110" />
            <col width="90" />
          </colgroup>
          <thead>
            <tr>
              <th>Позиция</th>
              <th>Название</th>
              <th>
                Описание <i>(показывается пользователю)</i>
              </th>
              <th>Кол-во {reasonType === "for_ban" ? "банов" : "жалоб"}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {showAddForm && (
              <ReasonForm
                key="add"
                reason={{}}
                close={this.toggleForm}
                saveCallback={saveCallback}
                type={reasonType}
              />
            )}
            {reasons[reasonType] &&
              reasons[reasonType].map((reason) => (
                <Reason
                  key={reason.id}
                  reason={reason}
                  saveCallback={saveCallback}
                />
              ))}
          </tbody>
        </table>
      </div>
    );
  }

  static propTypes = {
    data: PropTypes.objectOf(PropTypes.array).isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    saveCallback: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.reasons,
});

export const Reasons = connect(mapStateToProps)(ReasonsByType);
