import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setSortingParams } from "../../../actions";

const getValueFromProps = (props) => {
  const { sortBy, sortOrder } = props;
  return sortBy && sortOrder ? `${sortBy}_${sortOrder}` : "id_desc";
};

class Sorting extends Component {
  state = {
    value: getValueFromProps(this.props),
  };

  static getDerivedStateFromProps(props, state) {
    const value = getValueFromProps(props);
    if (value !== state.value) {
      return { value };
    }
    return null;
  }

  setOrder = (e) => {
    const [field, order] = e.target.value.split("_");
    this.props.setSorting({ field, order });
  };

  render() {
    const { value } = this.state;

    return (
      <div className="sorting-select row">
        <div className="col-auto col-sm-6 my-auto">Сортировка</div>
        <div className="col col-sm-6">
          <select
            name="field"
            value={value}
            className="form-control"
            onChange={this.setOrder}
          >
            <option value="id_asc">по ID &#9650;</option>
            <option value="id_desc">по ID &#9660;</option>
            <option value="posts_asc">по кол-ву постов &#9650;</option>
            <option value="posts_desc">по кол-ву постов &#9660;</option>
            <option value="rating_asc">по рейтингу &#9650;</option>
            <option value="rating_desc">по рейтингу &#9660;</option>
            <option value="comments_asc">по кол-ву комментариев &#9650;</option>
            <option value="comments_desc">
              по кол-ву комментариев &#9660;
            </option>
            <option value="bans_asc">по кол-ву банов &#9650;</option>
            <option value="bans_desc">по кол-ву банов &#9660;</option>
          </select>
        </div>
      </div>
    );
  }
}

Sorting.propTypes = {
  setSorting: PropTypes.func.isRequired,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
};

Sorting.defaultProps = {
  sortBy: null,
  sortOrder: null,
};

const mapStateToProps = (state) => ({
  sortBy: state.filters.sortBy,
  sortOrder: state.filters.sortOrder,
});

const mapDispatchToProps = (dispatch) => ({
  setSorting: (data) => dispatch(setSortingParams(data)),
});

export const SortingSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Sorting);
