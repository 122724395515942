import { DataLayer } from "../../libs";

export const exportPostsToCsv = async (body) => {
  return DataLayer.post({
    path: `/posts/export.json`,
    body,
    headers: {
      "content-type": "application/json;charset=UTF-8",
    },
    blob: true,
  });
};
