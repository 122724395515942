import { DataLayer } from "../../libs/";

export const loadCountries =
  (force = false) =>
  async (dispatch) => {
    const countries = localStorage.getItem("countries");

    if (countries && !force) {
      return dispatch({
        type: "SET_COUNTRIES",
        payload: JSON.parse(countries),
      });
    }

    return DataLayer.get({ path: "/countries.json" })
      .then((response) => {
        localStorage.setItem("countries", JSON.stringify(response));
        dispatch({ type: "SET_COUNTRIES", payload: response });
      })
      .catch(() => {});
  };
