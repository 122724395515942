import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { loadSecretStats } from "../../actions/";
import { Loading, LoadingError } from "../../components/common/";
import { baseUrl } from "../../libs/";

class Stats extends Component {
  state = {
    loading: true,
    loadingError: false,
    stats: null,
  };

  componentDidMount() {
    this.loadStats();
  }

  loadStats = () => {
    this.props.load().then((e) => this.setState(e));
  };

  render() {
    const { loading, loadingError, stats } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (loadingError) {
      return <LoadingError retry={this.loadStats} />;
    }

    return (
      <div className="block-filters mb-4">
        <h3>Статистика</h3>

        <div className="row mb-2">
          <div className="col">
            В очереди <small>(до {stats.cp_last_scheduled})</small>
          </div>
          <div className="col-auto font-weight-bold">
            {stats.cp_scheduled} (
            <span title="Редакторские">{stats.cp_scheduled_by_user}</span>/
            <span title="Автоматические">{stats.cp_scheduled_by_worker}</span>)
          </div>
        </div>

        {stats.cp_pending && (
          <div className="row mb-2">
            <div className="col">На модерации</div>
            <div className="col-auto font-weight-bold">{stats.cp_pending}</div>
          </div>
        )}

        {stats.cp_published && (
          <div className="row mb-2">
            <div className="col">Опубликованные</div>
            <div className="col-auto font-weight-bold">
              {stats.cp_published}
            </div>
          </div>
        )}

        <div className="row mb-2">
          <div className="col">Удалено антибаяном</div>
          <div className="col-auto font-weight-bold">
            <Link to={`${baseUrl}/secrets/rejected_by_worker`}>
              {stats.removed_by_worker}
            </Link>
          </div>
        </div>

        {stats.cp_rejected && (
          <div className="row mb-2">
            <div className="col">Удаленные</div>
            <div className="col-auto font-weight-bold">{stats.cp_rejected}</div>
          </div>
        )}

        {stats.underside_size > -1 && (
          <div className="row align-items-end">
            <div className="col">В изнанке</div>
            <div className="col-auto font-weight-bold">
              <span title="В API">{stats.underside_size} </span>
              <span title="В главной ленте">
                ({stats.underside_short_size})
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }

  static propTypes = {
    load: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  load: () => dispatch(loadSecretStats()),
});

export const SecretStats = connect(null, mapDispatchToProps)(Stats);
