import { DataLayer } from "../../libs/";

export const loadUser = (id) => async () => {
  const newState = {
    loading: false,
    loadingError: false,
  };

  await DataLayer.get({ path: `/users/${id}.json` })
    .then((response) => {
      newState.user = response;
    })
    .catch((error) => {
      if (error.status !== 404) {
        newState.loadingError = true;
      }
    });

  return newState;
};
