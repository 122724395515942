import { DataLayer } from "../../libs/";

export const loadUsers =
  (types = null) =>
  async (dispatch, getState) => {
    const { type, searchQuery, page, sortBy, sortOrder } =
      getState().filters.users;
    const params = [];
    const newState = {
      loading: false,
      loadingError: false,
    };

    if (type) params.push(`type=${type}`);
    if (types) params.push(`types=${types}`);
    if (searchQuery) params.push(`text=${searchQuery}`);
    if (sortBy) params.push(`sort_by=${sortBy}`);
    if (sortOrder) params.push(`sort_order=${sortOrder}`);
    if (page > 1) params.push(`page=${page}`);

    await DataLayer.get({
      path: "/users.json" + (params.length ? "?" + params.join("&") : ""),
    })
      .then((response) => {
        newState.users = response.users;
        newState.pages = response.pages;
      })
      .catch(() => {
        newState.loadingError = true;
      });

    return newState;
  };
