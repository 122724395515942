import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { checkUniqueness } from "../../actions/";
import { Modal } from "../../components/common/";

class UniquenessComponent extends Component {
  state = {
    data: this.props.data || {},
    processing: false,
    error: null,
    showModal: false,
    modalTitle: null,
  };

  toggleModal = () => {
    const { error, showModal, data } = this.state;
    const title = error
      ? "Проверка завершилась с ошибкой"
      : `Уникальность: ${data.unique}%`;
    this.setState({ showModal: !showModal, modalTitle: title });
  };

  check = () => {
    const { callback } = this.props;
    this.setState({ processing: true });
    this.props.check(this.props.id).then(({ data, error, processing }) => {
      if (data && callback) {
        callback(data.unique);
      }
      this.setState({ data, error, processing });
    });
  };

  render() {
    const { data, processing, showModal, error, modalTitle } = this.state;
    let value = data.unique !== undefined ? `${data.unique}%` : "";
    let className =
      data.unique && data.unique > 70 ? "text-success" : "text-danger";
    if (error) {
      value = "Ошибка";
      className = "text-danger";
    }
    className = `btn btn-link p-0 mr-1 ${className}`;

    return (
      <Fragment>
        {processing ? (
          <div className="processing">
            Проверка...
            <div className="spinner">
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        ) : (
          <Fragment>
            {(data.unique !== undefined || error) && (
              <button
                type="button"
                className={className}
                onClick={this.toggleModal}
              >
                {value}
              </button>
            )}
            <button
              type="button"
              className="btn btn-link p-0"
              onClick={this.check}
            >
              Проверить
            </button>
          </Fragment>
        )}

        {showModal && (
          <Modal
            key="modal"
            title={modalTitle}
            small={!!error}
            toggleModal={this.toggleModal}
          >
            {error ? (
              <div className="alert alert-danger">{error}</div>
            ) : (
              <UniquenessInfo data={data} />
            )}
          </Modal>
        )}
      </Fragment>
    );
  }

  static propTypes = {
    id: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any),
    callback: PropTypes.func,
    check: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  hasEditorAccess: state.user.hasEditorAccess,
});

const mapDispatchToProps = (dispatch) => ({
  check: (id) => dispatch(checkUniqueness(id)),
});

export const Uniqueness = connect(
  mapStateToProps,
  mapDispatchToProps
)(UniquenessComponent);

const UniquenessInfo = ({ data }) => {
  if (!data.urls || data.urls.length === 0) {
    return null;
  }
  return (
    <table key="urls" width="100%" border={0} cellPadding={3} cellSpacing={0}>
      <tbody>
        <tr>
          <th>URL страницы, где найдено совпадение</th>
          <th className="text-right">Сколько совпало</th>
        </tr>
        {data.urls.map((match) => (
          <tr key={match.url}>
            <td>
              <a href={match.url} target="_blank" rel="noreferrer">
                {match.url}
              </a>
            </td>
            <td className="text-right">{match.plagiat}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

UniquenessInfo.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};
