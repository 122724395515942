import _ from "lodash";

import { DataLayer } from "../../../libs/";

export const loadPostComments =
  ({ comments = [], postId, loader = false }) =>
  async (__, getState) => {
    const { branch, type, hideHidden } = getState().filters.post_comments;
    const newState = {
      loading: false,
      loadingError: false,
    };

    const params = [`type=${type}`];

    if (branch) {
      params.push(`branch=${branch}`);
    }

    if (hideHidden) {
      params.push(`hide_hidden=yes`);
    }

    if (comments.length > 0) {
      params.push(`from=${_.last(comments).id}`);
    }

    await DataLayer.get({
      path: `/posts/${postId}/comments.json?${params.join("&")}`,
      loader: loader,
    })
      .then((response) => {
        newState.comments = [...comments, ...response.comments];
        newState.end = !response.comments.length;
      })
      .catch(() => {
        newState.loadingError = true;
      });

    return newState;
  };
