import React, { Component } from "react";
import PropTypes from "prop-types";

import { SvgCross } from "../../../svg";

export class Modal extends Component {
  componentDidMount() {
    const scrollWidth = window.innerWidth - document.body.clientWidth;
    document.body.setAttribute(
      "style",
      `overflow: hidden; padding-right: ${scrollWidth}px`
    );
  }

  componentWillUnmount() {
    document.body.removeAttribute("style");
  }

  render() {
    const { children, title, toggleModal, small, xs, className } = this.props;

    return (
      <div className={`modal-wrapper ${className}`}>
        <div className="spacer" />
        <div className={`modal${small ? " sm" : ""}${xs ? " xs" : ""}`}>
          {title.length ? (
            <header className="row justify-content-between align-items-center">
              <h3 className="col">{title}</h3>
              <div className="col-auto">
                <button
                  type="button"
                  className="btn-reset close"
                  onClick={toggleModal}
                >
                  <SvgCross width={14} height={14} className="fill-gray" />
                </button>
              </div>
            </header>
          ) : (
            <button
              type="button"
              className="btn-reset close absolute"
              onClick={toggleModal}
            >
              <SvgCross width={14} height={14} className="fill-gray" />
            </button>
          )}
          {children}
        </div>
        <div className="spacer" />
        <button className="bg" onClick={toggleModal} />
      </div>
    );
  }

  static propTypes = {
    children: PropTypes.objectOf(PropTypes.any).isRequired,
    toggleModal: PropTypes.func.isRequired,
    title: PropTypes.string,
    small: PropTypes.bool,
    xs: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    title: "",
    small: false,
    xs: false,
    className: null,
  };
}
