import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { BtnDropdown } from "./";
import { setQueryParam } from "../../actions";
import { SvgDown } from "../../svg";

class Filter extends Component {
  state = {
    selected: this.props.current,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.current !== state.selected) {
      return { selected: props.current };
    }
    return null;
  }

  getTitle = (value) => {
    const item = this.props.options.find((el) => el.value === value);
    return item ? item.title : null;
  };

  changeFilter = (name, value) => {
    this.props.setQueryParam({ name, value, defaultValue: "" });
  };

  render() {
    const { selected } = this.state;
    const { title, name, options } = this.props;
    const btnText = (
      <span>
        {this.getTitle(selected)}
        <SvgDown width={9} height={6} className="toggler" />
      </span>
    );

    return (
      <div className="row justify-content-between mb-2">
        <div className="col">{title}</div>
        <div className="col-auto">
          <BtnDropdown btnClass="btn btn-link p-0" btnText={btnText}>
            <ul className="dropdown">
              {options.map((option) => (
                <li key={option.value}>
                  <button
                    type="button"
                    className="btn btn-link p-0"
                    onClick={() => this.changeFilter(name, option.value)}
                  >
                    {option.title}
                  </button>
                </li>
              ))}
            </ul>
          </BtnDropdown>
        </div>
      </div>
    );
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object),
    current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setQueryParam: PropTypes.func.isRequired,
  };

  static defaultProps = {
    current: "",
    options: [],
  };
}

const mapDispatchToProps = (dispatch) => ({
  setQueryParam: (data) => dispatch(setQueryParam(data)),
});

export const FilterItem = connect(null, mapDispatchToProps)(Filter);
