import React, { Component } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import moment from "moment";

import { DateCustomInput } from "./";

const dateRanges = {
  month: "месяц",
  quarter: "3 месяца",
  year: "год",
  allTime: "всё время",
};
const dateGroups = {
  day: "по дням",
  week: "по неделям",
  month: "по месяцам",
};
const defaultRange = "month";
const defaultGroup = "day";

export class DateFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      range: defaultRange,
      group: defaultGroup,
      prevShowGroups: this.props.showGroups,
      ...DateFilters.calculateDatesForRange(defaultRange, props.data.dates),
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showGroups !== state.prevShowGroups) {
      let newState = { showGroups: props.showGroups };
      if (state.range === "allTime" && state.group === "day") {
        newState = {
          ...newState,
          range: defaultRange,
          ...DateFilters.calculateDatesForRange(defaultRange, props.data.dates),
        };
      }
      return newState;
    }
    return null;
  }

  static calculateDatesForRange = (range, dates) => {
    const minDate = new Date(dates[0]);
    const maxDate = new Date(dates.slice(-1)[0]);
    const endDate = new Date(maxDate);
    const startDate = DateFilters.advanceDate(endDate, range, minDate);
    return { minDate, maxDate, startDate, endDate };
  };

  static advanceDate = (date, range, minDate) => {
    let newDate = new Date(date);

    if (range === "month") {
      newDate = new Date(new Date(date).setMonth(date.getMonth() - 1));
    }
    if (range === "quarter") {
      newDate = new Date(new Date(date).setMonth(date.getMonth() - 3));
    }
    if (range === "year") {
      newDate = new Date(new Date(date).setYear(date.getFullYear() - 1));
    }
    if (range === "allTime" || newDate < minDate) {
      newDate = new Date(minDate);
    }

    return newDate;
  };

  changeDate = (field, date) => {
    const newState = { range: null };
    newState[field] = date;
    this.setState(newState);
  };

  changeDateRange = (range) => {
    const { minDate, maxDate } = this.state;
    this.setState({
      startDate: DateFilters.advanceDate(maxDate, range, minDate),
      endDate: maxDate,
      range,
    });
  };

  changeDateGroup = (group) => {
    this.setState({ group });
  };

  filteredData = () => {
    const filteredByRange = this.filterByRange(this.props.data);
    return this.filterByGroup(filteredByRange);
  };

  filterByRange = ({ dates, series }) => {
    const { startDate, endDate } = this.state;

    const startIndex = dates.indexOf(moment(startDate).format("YYYY-MM-DD"));
    const endIndex = dates.indexOf(moment(endDate).format("YYYY-MM-DD")) + 1;

    const filteredDates = dates.slice(startIndex, endIndex);
    const filteredSeries = series.map((obj) => {
      const row = { ...obj };
      row.data = row.data.slice(startIndex, endIndex);
      return row;
    });

    return { dates: filteredDates, series: filteredSeries };
  };

  filterByGroup = ({ dates, series }) => {
    const { group } = this.state;

    if (group === "day") {
      return { dates, series };
    }

    const groupedDates = [];
    const groupedSeries = [];
    series.forEach((item) => {
      groupedSeries.push({ ...item, data: [] });
    });

    let prevDate, groupedIndex;
    dates.forEach((date, dateIndex) => {
      const currentDate = this.groupDate(date, group);

      if (currentDate !== prevDate) {
        prevDate = currentDate;
        groupedIndex = groupedDates.length;

        groupedDates.push(currentDate);
        series.forEach((item, seriesIndex) => {
          groupedSeries[seriesIndex].data.push(item.data[dateIndex]);
        });
      } else {
        series.forEach((item, seriesIndex) => {
          groupedSeries[seriesIndex].data[groupedIndex] += item.data[dateIndex];
        });
      }
    });

    return { dates: groupedDates, series: groupedSeries };
  };

  groupDate = (date, group) => {
    const inputDate = moment(date);
    let groupDate = inputDate;

    if (group === "week") {
      groupDate = inputDate.day() === 0 ? inputDate : inputDate.day(7);
    }

    if (group === "month") {
      groupDate = inputDate.date() === 1 ? inputDate : inputDate.date(1);
    }

    return groupDate.format("YYYY-MM-DD");
  };

  render() {
    const { range, group, minDate, maxDate, startDate, endDate } = this.state;
    const { showGroups, children } = this.props;

    return (
      <div className="date-filters">
        <h6 className="current-range">
          <DatePicker
            minDate={minDate}
            maxDate={endDate}
            selected={startDate}
            onChange={(date) => this.changeDate("startDate", date)}
            className="form-control-sm custom-select"
            calendarClassName="test"
            customInput={<DateCustomInput />}
          />
          <div className="delimiter"> — </div>
          <DatePicker
            minDate={startDate}
            maxDate={maxDate}
            selected={endDate}
            onChange={(date) => this.changeDate("endDate", date)}
            className="form-control-sm custom-select"
            customInput={<DateCustomInput />}
          />
        </h6>

        <h6 className="highlighted-date hide" />

        <div className="row justify-content-between">
          <div className="col-auto date-periods">
            {Object.keys(dateRanges).map((i) => {
              const className = `btn-link${range === i ? " active" : ""}`;
              if (i === "allTime" && group === "day" && showGroups) {
                return null;
              }
              return (
                <button
                  type="button"
                  key={i}
                  className={className}
                  onClick={() => this.changeDateRange(i)}
                >
                  {dateRanges[i]}
                </button>
              );
            })}
          </div>
          {showGroups && (
            <div className="col-auto date-groups">
              {Object.keys(dateGroups).map((i) => {
                const className = `btn-link${group === i ? " active" : ""}`;
                if (i === "day" && range === "allTime") {
                  return null;
                }
                return (
                  <button
                    type="button"
                    key={i}
                    className={className}
                    onClick={() => this.changeDateGroup(i)}
                  >
                    {dateGroups[i]}
                  </button>
                );
              })}
            </div>
          )}
        </div>
        {typeof this.props.children === "function"
          ? this.props.children({
              data: this.filteredData(),
              onlyMonths: group === "month",
            })
          : React.cloneElement(children, {
              data: this.filteredData(),
              onlyMonths: group === "month",
            })}
      </div>
    );
  }

  static propTypes = {
    data: PropTypes.shape({
      dates: PropTypes.arrayOf(PropTypes.string),
      series: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    showGroups: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  };

  static defaultProps = {
    className: null,
  };
}
