import { DataLayer } from "../../libs/";
import { loadReasons } from "../";

export const updateReason =
  ({ reason }) =>
  async (dispatch) => {
    await DataLayer.put({
      path: `/reasons/${reason.id}.json`,
      body: reason,
      loader: true,
    }).catch(() => {});
    const response = await dispatch(loadReasons());
    return response.reasons;
  };
