import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadStatistics } from "../../actions/";
import { Loading, LoadingError } from "../../components/common/";
import {
  SecretsStatusPanel,
  CreatedSecrets,
  RejectedSecrets,
  PublishedSecrets,
  UndersideVisits,
  UndersideSecrets,
} from "../../components/statistics/";

class Secrets extends Component {
  state = {
    statusPanel: null,
    created: null,
    rejected: null,
    published: null,
    underside: null,
    underside_secrets: null,
    loading: true,
    loadingError: false,
  };

  componentDidMount() {
    this.props.load().then((e) => this.setState(e));
  }

  render() {
    const {
      statusPanel,
      created,
      rejected,
      published,
      underside,
      underside_secrets,
      loading,
      loadingError,
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (loadingError) {
      return <LoadingError />;
    }

    return (
      <div className="statistics-container">
        <SecretsStatusPanel data={statusPanel} />
        <CreatedSecrets data={created} className="created-secrets mt-1" />
        <RejectedSecrets data={rejected} className="rejected-secrets mt-4" />
        <PublishedSecrets data={published} className="published-secrets mt-4" />
        <UndersideVisits data={underside} className="underside-views mt-4" />
        <UndersideSecrets
          data={underside_secrets}
          className="underside-secrets mt-4"
        />
      </div>
    );
  }

  static propTypes = {
    load: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  load: () => dispatch(loadStatistics()),
});

export const StatisticsSecrets = connect(null, mapDispatchToProps)(Secrets);
