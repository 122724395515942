import { DataLayer } from "../../libs/";
import { loadReasons } from "../";

export const createReason =
  ({ reason }) =>
  async (dispatch) => {
    await DataLayer.post({
      path: "/reasons.json",
      body: reason,
      loader: true,
    }).catch(() => {});
    const response = await dispatch(loadReasons());
    return response.reasons;
  };
