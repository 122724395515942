import React from "react";
import { Link } from "react-router-dom";

import { logoBig } from "../../images/";

export const SimpleHeader = () => (
  <header className="header">
    <div className="wrapper">
      <Link to="/dashboard" className="logo">
        <img src={logoBig} alt="" />
      </Link>
    </div>
  </header>
);
