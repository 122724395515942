import React, { Fragment } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";

import { baseUrl, fullDate } from "../../libs";
import { Pagination } from "../../components/common/";
import { ProfileLink } from "./profile-link";

export const CommentActions = ({ actions, pages, actionName }) => {
  if (actions.length < 1) {
    return <div className="alert alert-warning">Записей не найдено.</div>;
  }

  return (
    <div className="history-container">
      <MediaQuery maxWidth={767}>
        {(matches) => <Pagination total={pages} small={matches} />}
      </MediaQuery>

      <table className="table table-responsive table-bordered">
        <thead>
          <tr>
            <th>Действие</th>
            <th>Описание</th>
            <th>Дата</th>
          </tr>
        </thead>
        <tbody>
          {actions.map((action) => {
            const { comment } = action;
            return (
              <tr key={action.id}>
                <td>{actionName}</td>
                <td>
                  {comment ? (
                    <Fragment>
                      <span>
                        Для <ProfileLink user={comment.user} /> за комментарий{" "}
                      </span>
                      <a
                        href={`${baseUrl}/posts/${comment.post_id}?branch=${
                          comment.root_id || comment.id
                        }#c${comment.id}`}
                        title={comment.text_fixed}
                        target="_blank"
                        rel="noreferrer"
                      >
                        #{comment.id}
                      </a>
                      <span> в посте #{comment.post_id}</span>
                    </Fragment>
                  ) : (
                    "Комментарий"
                  )}
                </td>
                <td>{fullDate(action.created_at)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <MediaQuery maxWidth={767}>
        {(matches) => <Pagination total={pages} small={matches} />}
      </MediaQuery>
    </div>
  );
};

CommentActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  pages: PropTypes.number.isRequired,
  actionName: PropTypes.string.isRequired,
};
