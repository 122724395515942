import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import _ from "lodash";

import { loadPosts } from "../../actions/";
import {
  PageHeader,
  Loading,
  LoadingError,
  EmptyList,
  EndOfList,
  Alert,
  ResponsiveSidebar,
} from "../../components/common/";
import {
  PostsListScheduled,
  PostListItem,
  PostFilters,
} from "../../components/posts/";
import { SecretStats } from "../secrets";

class List extends Component {
  state = {
    alert: null,
    loading: true,
    loadingError: false,
    end: false,
    scheduled: [],
    posts: [],
    filters: this.props.filters,
  };

  componentDidMount() {
    this.props.load().then((e) => this.setState(e));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevState.filters, this.state.filters)) {
      this.props.load().then((e) => this.setState(e));
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.filters, state.filters)) {
      return {
        filters: props.filters,
        posts: [],
        scheduled: [],
        loading: true,
        end: false,
      };
    }
    return null;
  }

  loadMore = () => {
    const { posts, scheduled } = this.state;

    this.setState({ loading: true });
    this.props.load({ posts, scheduled }).then((e) => this.setState(e));
  };

  render() {
    const { posts, scheduled, alert, end, loading, loadingError } = this.state;
    const { hasEditorAccess } = this.props;

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <PageHeader />

        <div className="row p-static">
          <ResponsiveSidebar
            mobileTitle={`Показать фильтры ${
              hasEditorAccess ? "и статистику" : null
            }`}
          >
            <PostFilters />
            {hasEditorAccess && <SecretStats />}
          </ResponsiveSidebar>

          <div className="col-lg-8 col-12 order-lg-1">
            {alert && <Alert type={alert} />}

            {scheduled.length > 0 && <PostsListScheduled posts={scheduled} />}

            {posts.map((post) => (
              <PostListItem key={`post_${post.id}`} post={post} />
            ))}

            {posts.length > 0 && !end && !loadingError && (
              <VisibilitySensor
                onChange={(isVisible) => isVisible && this.loadMore()}
                children={<div className="visibility_sensor" />}
              />
            )}

            {loading && <Loading />}

            {loadingError && <LoadingError retry={this.loadMore} />}

            {!loading && !loadingError && !posts[0] && <EmptyList />}

            {posts.length > 0 && end && <EndOfList />}
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    load: PropTypes.func.isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    hasEditorAccess: PropTypes.bool.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.posts,
  hasEditorAccess: state.user.hasEditorAccess,
});

const mapDispatchToProps = (dispatch) => ({
  load: (data = {}) => dispatch(loadPosts(data)),
});

export const PostsList = connect(mapStateToProps, mapDispatchToProps)(List);
