import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink, Route, Switch } from "react-router-dom";
import MediaQuery from "react-responsive";

import { siteName } from "../../libs/";
import { HeaderButton, SearchBox, SearchBoxRoutes } from "./";

const PageHeaderConst = ({
  currentUser,
  pageTitle,
  activeNav,
  counter,
  children,
}) => {
  return (
    <div className="page-header mb-lg-3 mb-2">
      <MediaQuery minWidth={992}>
        <div className="row">
          <div className="col">
            <h1>{pageTitle || (activeNav ? activeNav.title : siteName)}</h1>
          </div>

          <div className="col-auto">
            <div className="row no-gutters">
              <Switch>
                <Route exact path={SearchBoxRoutes}>
                  <div className="col">
                    <SearchBox />
                  </div>
                </Route>
              </Switch>

              <HeaderButton />
            </div>
          </div>
        </div>
      </MediaQuery>

      {!children && activeNav && activeNav.children && (
        <nav>
          {activeNav.children.map((child) => {
            if (!currentUser[child.accessMethod]) {
              return null;
            }
            return (
              <NavLink
                key={child.url}
                exact
                to={child.url}
                activeClassName="active"
              >
                {child.title}
                {counter && <span>{counter[child.url]}</span>}
              </NavLink>
            );
          })}
        </nav>
      )}

      {children}
    </div>
  );
};

PageHeaderConst.propTypes = {
  currentUser: PropTypes.object.isRequired,
  pageTitle: PropTypes.string,
  activeNav: PropTypes.object,
  counter: PropTypes.object,
  path: PropTypes.string.isRequired, // implicitly required in NavLink to highlight an active link
};

PageHeaderConst.defaultProps = {
  pageTitle: null,
};

const mapStateToProps = (state) => ({
  currentUser: state.user,
  pageTitle: state.pageTitle,
  activeNav: state.activeNav,
  path: state.router.location.pathname,
});

export const PageHeader = connect(mapStateToProps)(PageHeaderConst);
