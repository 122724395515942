import { DataLayer } from "../../libs/";

export const updateVkScheduledSecret = (vkSecret) => async () => {
  const newState = {};

  await DataLayer.put({
    path: "/secrets/vk_scheduled/" + vkSecret.id + ".json",
    body: vkSecret,
    loader: true,
  })
    .then((response) => {
      newState.vkSecret = response;
      newState.showForm = false;
    })
    .catch((error) => {
      newState.vkSecret = vkSecret;
      newState.vkSecret.errors = error.data
        ? error.data.errors || error.data
        : error.message;
    });

  return newState;
};
