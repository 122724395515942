import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";

import { SvgCross } from "../../../../svg/";
import { Logo, NavItem, NavLinks, LogoutLink } from "../";

const NavContainer = ({ toggleNav, currentUser, path }) => {
  return (
    <Switch>
      <Route>
        <nav>
          <MediaQuery maxWidth={991}>
            <div className="row align-items-center mb-2">
              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-link p-0 close"
                  onClick={toggleNav}
                >
                  <SvgCross width={14} height={14} className="fill-gray" />
                </button>
              </div>
              <div className="col-auto ml-auto">
                <Logo />
              </div>
            </div>
          </MediaQuery>

          <ul>
            {NavLinks.map((item) => {
              if (!currentUser[item.accessMethod]) {
                return null;
              }
              return (
                <NavItem
                  key={item.url}
                  currentUser={currentUser}
                  data={item}
                  toggleNav={toggleNav}
                  path={path}
                />
              );
            })}

            <MediaQuery maxWidth={991}>
              <li className="mt-5">
                <LogoutLink />
              </li>
            </MediaQuery>
          </ul>
        </nav>
      </Route>
    </Switch>
  );
};

NavContainer.propTypes = {
  toggleNav: PropTypes.func,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  path: PropTypes.string.isRequired,
};

NavContainer.defaultProps = {
  toggleNav: null,
};

const mapStateToProps = (state) => ({
  currentUser: state.user,
  path: state.router.location.pathname,
});

export const Nav = connect(mapStateToProps)(NavContainer);
