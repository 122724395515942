import _ from "lodash";

import { loadCountries } from "../";

export const fetchCountrySuggestions =
  ({ search }) =>
  async (dispatch, getState) => {
    await dispatch(loadCountries());

    const suggestions = _.filter(getState().countries, ({ name }) => {
      return name.toLowerCase().startsWith(search.toLowerCase());
    }).slice(0, 7);

    return suggestions;
  };
