import React from "react";
import PropTypes from "prop-types";

import { fullDate } from "../../libs/";
import { ProfileLink } from "../users/";

export const SocialAccountLogs = ({ logs }) => (
  <table className="table">
    <thead>
      <tr>
        <th colSpan={2}>Использовался:</th>
      </tr>
    </thead>
    <tbody>
      {logs.map((log) => (
        <tr key={log.id} className={log.user.banned ? "table-danger" : null}>
          <td>
            <ProfileLink user={log.user} />
          </td>
          <td>
            {fullDate(log.created_at)}
            {log.updated_at !== log.created_at &&
              ` (${fullDate(log.updated_at)})`}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

SocialAccountLogs.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.object).isRequired,
};
