import { DataLayer } from "../../../libs/";

export const loadPublisherSecrets = (publisherId) => async (_, getState) => {
  const { type, category, period, sortBy, page } =
    getState().filters.publishers;
  const params = [`publisher_id=${publisherId}`];
  const newState = {
    loading: false,
    loadingError: false,
  };

  if (category) {
    params.push(`category_id=${category}`);
  }
  if (period) {
    params.push(`period=${period}`);
  }
  if (page > 1) {
    params.push(`page=${page}`);
  }
  params.push(`sort_by=${sortBy ? sortBy : "scheduled_to"}`);

  await DataLayer.get({
    path:
      "/secrets/" +
      type +
      ".json" +
      (params.length ? "?" + params.join("&") : ""),
  })
    .then((response) => {
      newState.secrets = response.secrets;
      newState.pages = response.pages;
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
