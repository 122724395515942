import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { baseUrl } from "../../libs/";
import { SvgCheck, SvgCross } from "../../svg/";

export class CountryForm extends Component {
  state = {
    country: this.props.country,
  };

  handleChange = (e) => {
    const country = { ...this.state.country };
    country[e.target.name] = e.target.value;
    this.setState({ country });
  };

  save = () => {
    this.props.saveCallback(this.state.country);
  };

  render() {
    const {
      country: { id, position, name, cities_count: citiesCount },
    } = this.state;
    const { close } = this.props;
    const disabled = !name || name.length === 0;

    return (
      <Fragment>
        <tr>
          <td className="text-center">
            <input
              type="number"
              name="position"
              placeholder="Позиция"
              value={position !== undefined ? position : 0}
              onChange={this.handleChange}
              className="form-control form-control-sm text-center"
            />
          </td>
          <td>
            <input
              type="text"
              name="name"
              placeholder="Название"
              value={name || ""}
              onChange={this.handleChange}
              className="form-control form-control-sm"
            />
          </td>
          <td className="text-center">
            <Link
              to={`${baseUrl}/settings/cities?country=${id}`}
              className={`btn btn-link btn-sm`}
              target="_blank"
            >
              {citiesCount}
            </Link>
          </td>
          <td className="text-right text-nowrap action-buttons">
            <button
              className="btn btn-success btn-sm"
              onClick={this.save}
              disabled={disabled}
              title="Сохранить"
            >
              <SvgCheck width={14} height={12} className="fill-white" />
            </button>
            <button
              className="btn btn-primary btn-sm ml-1"
              onClick={close}
              title="Отменить"
            >
              <SvgCross width={14} height={12} className="fill-white" />
            </button>
          </td>
        </tr>
      </Fragment>
    );
  }

  static propTypes = {
    country: PropTypes.objectOf(PropTypes.any).isRequired,
    saveCallback: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
  };
}
