import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { ClickOutside } from "../../../libs";
import { SvgDown } from "../../../svg";

export class BtnDropdown extends Component {
  state = {
    showDropdown: false,
    prevBtnText: this.props.btnText,
  };

  toggleDropdown = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  // close dropdown if something were selected
  // useful for selecting categories dropdown
  static getDerivedStateFromProps(props, state) {
    if (props.btnText !== state.prevBtnText) {
      return { prevBtnText: props.btnText, showDropdown: false };
    }
    return null;
  }

  render() {
    const { btnClass, btnText, hasIcon, children } = this.props;
    const { showDropdown } = this.state;

    return (
      <ClickOutside
        className="dropdown-toggle"
        onBlur={this.toggleDropdown}
        isOpen={showDropdown}
      >
        <Fragment>
          <button
            type="button"
            className={btnClass + (showDropdown ? " opened" : "")}
            onClick={this.toggleDropdown}
          >
            {btnText}
            {hasIcon && <SvgDown width={9} height={6} className="toggler" />}
          </button>
          {showDropdown && children}
        </Fragment>
      </ClickOutside>
    );
  }

  static propTypes = {
    btnClass: PropTypes.string,
    hasIcon: PropTypes.bool,
    btnText: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.any),
      PropTypes.string,
    ]).isRequired,
    children: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    btnClass: "btn btn-primary",
    hasIcon: false,
  };
}
