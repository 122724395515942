import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { VKTimeForm } from ".";
import { SvgCross, SvgEdit } from "../../svg";
import { Modal } from "../common";

export class VKTimeItem extends Component {
  state = { showForm: false };

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  render() {
    const { showForm } = this.state;
    const { id, item, links, saveCallback, deleteCallback } = this.props;
    const link = _.find(links, ["id", item.link_id]) || { url: "нет" };

    if (showForm) {
      return (
        <VKTimeForm
          item={item}
          links={links}
          close={this.toggleForm}
          saveCallback={saveCallback}
        />
      );
    }

    return (
      <VKTimeRow
        id={id}
        item={item}
        link={link}
        onEditClick={this.toggleForm}
        deleteCallback={deleteCallback}
      />
    );
  }

  static propTypes = {
    id: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    links: PropTypes.arrayOf(PropTypes.object).isRequired,
    saveCallback: PropTypes.func.isRequired,
    deleteCallback: PropTypes.func.isRequired,
  };
}

class VKTimeRow extends Component {
  state = {
    showConfirm: false,
  };

  toggleConfirm = () => {
    this.setState({ showConfirm: !this.state.showConfirm });
  };

  render() {
    const { showConfirm } = this.state;
    const { id, item, link, onEditClick, deleteCallback } = this.props;

    return (
      <tr key={`vk-time-${item.id}-row`}>
        <td>
          {id}

          {showConfirm && (
            <Modal
              key="confirm"
              title={`Удаление таймера '${item.time}'`}
              toggleModal={this.toggleConfirm}
              small
            >
              <div>
                <div className="mb-1">
                  Вы действительно хотите удалить данный таймер?
                </div>
                <div className="mt-2 text-right">
                  <button
                    type="button"
                    onClick={this.toggleConfirm}
                    className="btn btn-primary mr-1"
                  >
                    Отмена
                  </button>
                  <button
                    type="button"
                    onClick={() => deleteCallback(item.id)}
                    className="btn btn-secondary"
                  >
                    Удалить
                  </button>
                </div>
              </div>
            </Modal>
          )}
        </td>
        <td>{item.time}</td>
        <td title={link.url}>{link.url.slice(0, 60)}</td>
        <td className="text-right action-buttons">
          <button
            className="btn btn-sm btn-primary"
            onClick={onEditClick}
            title="Редактировать"
          >
            <SvgEdit width={14} height={12} className="fill-white" />
          </button>
          <button
            className="btn btn-sm btn-danger ml-1"
            onClick={this.toggleConfirm}
            title="Удалить"
          >
            <SvgCross width={14} height={12} className="fill-white" />
          </button>
        </td>
      </tr>
    );
  }

  static propTypes = {
    id: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    link: PropTypes.object.isRequired,
    onEditClick: PropTypes.func.isRequired,
    deleteCallback: PropTypes.func.isRequired,
  };
}
