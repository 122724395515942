import { DataLayer } from "../../libs/";

export const restoreBan = (id) => async () => {
  const newState = {};
  await DataLayer.put({
    path: `/bans/${id}/restore.json`,
    loader: true,
  })
    .then((response) => {
      newState.ban = response;
    })
    .catch(() => {});
  return newState;
};
