import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { baseUrl } from "../../libs/";

export const Alert = ({ type }) => {
  if (type === "upcoming_secret_was_rescheduled") {
    return (
      <div className="alert alert-danger">
        Запланированный секрет был удален!
        <br />
        Проверьте <Link to={`${baseUrl}/secrets/scheduled`}>секрет</Link>,
        вставший на его место.
      </div>
    );
  }
  return null;
};

Alert.propTypes = {
  type: PropTypes.string.isRequired,
};
