import { DataLayer } from "../../libs/";

import { setCurrentUser } from "../";

export const loadCurrentUser = () => async (dispatch) => {
  const currentUser = localStorage.getItem("currentUser");
  let user = null;

  if (
    currentUser &&
    `${currentUser}` !== "undefined" &&
    `${currentUser}` !== "null"
  ) {
    try {
      user = JSON.parse(currentUser);
    } catch (e) {
      throw new Error("cannot parse localStorage data");
    }
  } else {
    try {
      const data = await DataLayer.get({ path: ".json" });

      user = data.user;
    } catch (e) {
      throw e;
    }
  }

  await dispatch(setCurrentUser(user));
};
