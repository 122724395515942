import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import { SvgCross } from "../../svg";

export class ResponsiveSidebar extends Component {
  state = {
    showForMobile: false,
  };

  toggle = () => {
    const { showForMobile } = this.state;

    if (showForMobile) {
      document.body.classList.remove("lock-position");
    } else {
      document.body.classList.add("lock-position");
    }

    this.setState({ showForMobile: !showForMobile });
  };

  render() {
    const { mobileTitle, children } = this.props;
    const { showForMobile } = this.state;

    return (
      <MediaQuery minWidth={992}>
        {(matches) => {
          if (matches) {
            return (
              <aside className="col-lg-4 col-12 order-lg-2">
                <div className="aside-wrapper">{children}</div>
              </aside>
            );
          }

          return (
            <aside className="col-lg-4 col-12 order-lg-2 p-static mb-3">
              <button
                type="button"
                className="btn btn-block btn-secondary"
                onClick={this.toggle}
              >
                {mobileTitle}
              </button>
              {showForMobile && (
                <div className="aside-wrapper">
                  <div className="aside-mobile-close">
                    <button
                      type="button"
                      className="btn btn-link p-0 close"
                      onClick={this.toggle}
                    >
                      <SvgCross width={14} height={14} className="fill-gray" />
                    </button>
                  </div>

                  {children}

                  <button
                    type="button"
                    className="btn btn-block btn-primary mt-5"
                    onClick={this.toggle}
                  >
                    Закрыть
                  </button>
                </div>
              )}
            </aside>
          );
        }}
      </MediaQuery>
    );
  }

  static propTypes = {
    mobileTitle: PropTypes.string.isRequired,
  };
}
