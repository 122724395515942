import React from "react";
import PropTypes from "prop-types";

export const Error = ({ errors }) => {
  if (typeof errors === "string") {
    return <div className="alert alert-danger">{errors}</div>;
  }

  return (
    <div className="alert alert-danger">
      {Object.keys(errors).map((key) => (
        <div key={key}>
          {key}: {errors[key]}
        </div>
      ))}
    </div>
  );
};

Error.propTypes = {
  errors: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.string,
  ]).isRequired,
};
