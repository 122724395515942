import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";

import { ProfileLink } from "../users/";
import { baseUrl, fullDate } from "../../libs/";
import { SvgCrown } from "../../svg/";
import { Date } from "../common/";

export const UserBan = ({
  id,
  createdAt,
  banReason,
  comment,
  actingUser,
  bannedUser,
}) => {
  let branchLink = null;
  if (comment) {
    branchLink = `${baseUrl}/posts/${comment.post_id}?branch=${
      comment.root_id || comment.id
    }#c${comment.id}`;
  }

  return (
    <div className="row no-gutters mb-sm-3 mb-1 comment-item-wrapper">
      <div className="col">
        <div className="comment-item" name={`b${id}`}>
          <header className="row justify-content-between">
            <div className="col-auto">
              <div className="row no-gutters align-items-center">
                <div className="col-auto">
                  <ProfileLink
                    user={bannedUser}
                    className={`user-avatar opacity${
                      bannedUser.keeper ? " keeper" : ""
                    }`}
                  >
                    <Fragment>
                      <img
                        src={bannedUser.avatar}
                        width={40}
                        height={40}
                        className="rounded-circle"
                        alt=""
                      />
                      {bannedUser.vip && (
                        <span className="badge">
                          <SvgCrown width={9.4} height={9.4} />
                        </span>
                      )}
                    </Fragment>
                  </ProfileLink>
                </div>

                <div className="col pl-15">
                  <ProfileLink
                    user={bannedUser}
                    className="btn btn-link p-0 opacity"
                  />
                  {bannedUser.banned_count > 0 && (
                    <span className="bans opacity">
                      {bannedUser.banned_count}
                    </span>
                  )}
                  {comment && (
                    <div className="text-muted opacity">
                      <b>
                        {comment.rating > 0 && "+"}
                        {comment.rating}
                      </b>
                      <span> ・ </span>
                      <Date
                        className="date text-muted"
                        date={comment.created_at}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {comment && (
              <div className="col-auto">
                <MediaQuery minWidth={992}>
                  {(matches) => {
                    if (matches) {
                      return (
                        <div>
                          <Link
                            to={branchLink}
                            className="btn btn-link opacity p-0"
                            target="_blank"
                          >
                            #{comment.post_id}
                          </Link>
                          <Link
                            to={`?category_id=${comment.category_id}`}
                            className="btn btn-link opacity p-0"
                            target="_blank"
                          >
                            {comment.category_name}
                          </Link>
                        </div>
                      );
                    }

                    return (
                      <div className="row align-items-center no-gutters">
                        <div className="col text-right">
                          <Link
                            to={branchLink}
                            className="btn btn-link opacity p-0"
                            target="_blank"
                          >
                            #{comment.post_id}
                          </Link>
                          <br />
                          <Link
                            to={`?category_id=${comment.category_id}`}
                            className="btn btn-link opacity p-0"
                            target="_blank"
                          >
                            {comment.category_name}
                          </Link>
                        </div>
                      </div>
                    );
                  }}
                </MediaQuery>
              </div>
            )}
          </header>

          {comment && (
            <div className={`std${comment.hidden ? " text-muted" : ""}`}>
              {comment.parent_id ? (
                <div className="parent-user-link">
                  <ProfileLink user={comment.parent_user} />
                  {", "}
                </div>
              ) : (
                ""
              )}
              <span>{comment.text_fixed}</span>
            </div>
          )}

          <div className="details text-muted">
            <div className="row">
              <div className="col-md-4 col-6 mt-2">
                <b>Забанил:</b>
                <ProfileLink user={actingUser} />
              </div>
              <div className="col-md-5 col-6 mt-2">
                <b>Дата бана:</b>
                {fullDate(createdAt)}
              </div>
              {banReason && (
                <div className="col-md-3 col-6 mt-2">
                  <b>Причина бана:</b>
                  {banReason}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UserBan.propTypes = {
  id: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  banReason: PropTypes.string.isRequired,
  actingUser: PropTypes.objectOf(PropTypes.any).isRequired,
  bannedUser: PropTypes.objectOf(PropTypes.any).isRequired,
  comment: PropTypes.objectOf(PropTypes.any),
};

UserBan.defaultTypes = {
  comment: null,
};
