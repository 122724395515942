import { DataLayer } from "../../libs/";

export const removePost =
  ({ id, reason }) =>
  () => {
    return DataLayer.put({
      path: `/posts/${id}/remove.json`,
      body: {
        remove_reason: reason,
      },
      loader: true,
    });
  };
