import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadStatistics } from "../../actions/";
import { Loading, LoadingError } from "../../components/common/";
import {
  CreatedPosts,
  PostBookmarks,
  PostLikes,
} from "../../components/statistics/";

class Posts extends Component {
  state = {
    created: null,
    bookmarks: null,
    likes: null,
    loading: true,
    loadingError: false,
  };

  componentDidMount() {
    this.props.load().then((e) => this.setState(e));
  }

  render() {
    const { created, bookmarks, likes, loading, loadingError } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (loadingError) {
      return <LoadingError />;
    }

    return (
      <div className="statistics-container">
        <CreatedPosts data={created} className="created-posts mt-1" />
        <PostBookmarks data={bookmarks} className="created-bookmarks mt-4" />
        <PostLikes data={likes} className="created-post-likes mt-4" />
      </div>
    );
  }

  static propTypes = {
    load: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  load: () => dispatch(loadStatistics()),
});

export const StatisticsPosts = connect(null, mapDispatchToProps)(Posts);
