import { DataLayer } from "../../libs/";

export const loadEditorStats = () => async (dispatch) => {
  const newState = {
    loading: false,
    loadingError: false,
  };

  await DataLayer.get({ path: "/secrets/editor_stats.json" })
    .then((stats) => {
      dispatch({ type: "SET_EDITOR_STATS", payload: stats });
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
