import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { SortingLinks } from "../../components/common";
import { SvgDevices } from "../../svg/";
import { baseUrl } from "../../libs/";

const List = ({ users, hasAdminAccess, showEditorStats }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th className="text-left">Имя</th>

          {hasAdminAccess && showEditorStats && (
            <Fragment>
              <th>Добавлено/опубликовано в прошлом месяце</th>
              <th>Добавлено/опубликовано в текущем месяце</th>
              <th>Запланировано на следующий месяц</th>
              <th>Всего опубликовано / удалено</th>
            </Fragment>
          )}

          {hasAdminAccess && !showEditorStats && (
            <Fragment>
              <th className="text-left">Привязки</th>
              <th className="text-nowrap">
                Посты <SortingLinks field="posts" />
              </th>
              <th className="text-nowrap">
                Секреты <SortingLinks field="secrets" />
              </th>
              <th className="text-nowrap">
                Рейтинг <SortingLinks field="rating" />
              </th>
              <th className="text-nowrap">
                Комментарии <SortingLinks field="comments" />
              </th>
            </Fragment>
          )}

          {(!hasAdminAccess || !showEditorStats) && (
            <th className="text-nowrap">
              Баны <SortingLinks field="bans" />
            </th>
          )}
        </tr>
      </thead>

      <tbody>
        {users.map((user) => {
          const DeviceIcon = SvgDevices[user.device_type];
          const accountsCount =
            (user.email ? 1 : 0) +
            (user.fb_user_id ? 1 : 0) +
            (user.vk_user_id ? 1 : 0);
          const rowClass = user.banned
            ? "table-danger"
            : user.keeper
            ? "table-success"
            : null;

          return (
            <tr key={user.id} className={rowClass}>
              <td width="220" className="text-left">
                <DeviceIcon width={16} height={16} className="fill-gray mr-1" />
                <a
                  href={`${baseUrl}/users/${user.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {user.fullname_or_anonymus}
                </a>
              </td>

              {hasAdminAccess && showEditorStats && user.editor_stats && (
                <Fragment>
                  <td>
                    <a
                      href={`${baseUrl}/publishers/${user.id}/secrets/added/prev_month`}
                      target="_blank"
                      title="Количество добавленных секретов в прошлом месяце"
                      rel="noreferrer"
                    >
                      {user.editor_stats.prev_month_added}
                    </a>
                    {" / "}
                    <a
                      href={`${baseUrl}/publishers/${user.id}/posts/published/prev_month`}
                      target="_blank"
                      title="Количество опубликованных секретов в прошлом месяце"
                      rel="noreferrer"
                    >
                      {user.editor_stats.prev_month_published}
                    </a>
                  </td>
                  <td>
                    <a
                      href={`${baseUrl}/publishers/${user.id}/secrets/added/this_month`}
                      target="_blank"
                      title="Количество добавленных секретов в текущем месяце"
                      rel="noreferrer"
                    >
                      {user.editor_stats.this_month_added}
                    </a>
                    {" / "}
                    <a
                      href={`${baseUrl}/publishers/${user.id}/posts/published/this_month`}
                      target="_blank"
                      title="Количество опубликованных секретов в текущем месяце"
                      rel="noreferrer"
                    >
                      {user.editor_stats.this_month_published}
                    </a>
                    {" ("}
                    <a
                      href={`${baseUrl}/publishers/${user.id}/secrets/scheduled/this_month`}
                      target="_blank"
                      title="Количество секретов, запланированных на публикацию в текущем месяце"
                      rel="noreferrer"
                    >
                      {user.editor_stats.this_month_scheduled}
                    </a>
                    )
                  </td>
                  <td>
                    <a
                      href={`${baseUrl}/publishers/${user.id}/secrets/scheduled/next_month`}
                      target="_blank"
                      title="Количество секретов, запланированных на публикацию в следующем месяце"
                      rel="noreferrer"
                    >
                      {user.editor_stats.next_month_scheduled}
                    </a>
                  </td>
                  <td width="170">
                    <a
                      href={`${baseUrl}/publishers/${user.id}/posts/published`}
                      target="_blank"
                      title="Общее количество опубликованных секретов"
                      rel="noreferrer"
                    >
                      {user.editor_stats.all_time_published}
                    </a>
                    {" ("}
                    <a
                      href={`${baseUrl}/publishers/${user.id}/posts/removed`}
                      target="_blank"
                      title="Общее количество удаленных секретов"
                      rel="noreferrer"
                    >
                      {user.editor_stats.all_time_removed}
                    </a>
                    )
                  </td>
                </Fragment>
              )}

              {hasAdminAccess && !showEditorStats && (
                <Fragment>
                  <td className="text-left text-nowrap">
                    {user.email && accountsCount > 1 && (
                      <button
                        className="btn btn-account email mr-1 icon"
                        title={user.email}
                      />
                    )}

                    {user.email && accountsCount <= 1 && (
                      <button className="btn btn-account email mr-1">
                        {user.email}
                      </button>
                    )}

                    {user.apple_user_id && accountsCount > 1 && (
                      <button
                        className="btn btn-account apple mr-1 icon"
                        title={user.apple_name}
                      />
                    )}

                    {user.apple_user_id && accountsCount <= 1 && (
                      <button className="btn btn-account apple mr-1">
                        {user.apple_name}
                      </button>
                    )}

                    {user.fb_user_id && accountsCount > 1 && (
                      <button
                        className="btn btn-account fb mr-1 icon"
                        title={user.fb_name}
                      />
                    )}

                    {user.fb_user_id && accountsCount <= 1 && (
                      <button className="btn btn-account fb mr-1">
                        {user.fb_name}
                      </button>
                    )}

                    {user.vk_user_id && accountsCount > 1 && (
                      <a
                        href={user.vk_link}
                        className="btn btn-account vk icon"
                        target="_blank"
                        title={user.vk_name}
                        rel="noreferrer"
                      />
                    )}

                    {user.vk_user_id && accountsCount <= 1 && (
                      <a
                        href={user.vk_link}
                        className="btn btn-account vk"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {user.vk_name}
                      </a>
                    )}
                  </td>
                  <td>
                    <a
                      target="blank"
                      href={`${baseUrl}/users/${user.id}/posts`}
                    >
                      {user.post_count}
                    </a>
                  </td>
                  <td>
                    <a
                      target="blank"
                      href={`${baseUrl}/users/${user.id}/secrets`}
                    >
                      {user.secret_count}
                    </a>
                  </td>
                  <td>
                    <a
                      target="blank"
                      href={`${baseUrl}/users/${user.id}/rating_awards`}
                    >
                      {user.rating}
                    </a>
                  </td>
                  <td>
                    <a
                      target="blank"
                      href={`${baseUrl}/users/${user.id}/comments`}
                    >
                      {user.commented_count}
                    </a>
                  </td>
                </Fragment>
              )}

              {(!hasAdminAccess || !showEditorStats) && (
                <td>
                  <a target="blank" href={`${baseUrl}/users/${user.id}/bans`}>
                    {user.banned_count}
                  </a>
                  {user.banned_count !== user.bans_count && (
                    <span style={{ color: "#B17532" }}>
                      {" "}
                      ({user.bans_count})
                    </span>
                  )}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

List.propTypes = {
  users: PropTypes.arrayOf(PropTypes.any).isRequired,
  showEditorStats: PropTypes.bool.isRequired,
  hasAdminAccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  hasAdminAccess: state.user.hasAdminAccess,
});

export const UserList = connect(mapStateToProps)(List);
