import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { sendBySimulator } from "../../actions/";
import { Error } from "../common/";

const types = [
  { name: "approve", title: "Публикация секрета" },
  { name: "like", title: "Лайк поста" },
  { name: "comment", title: "Комментарий к посту" },
  { name: "reply", title: "Ответ на комментарий" },
  { name: "comment_like", title: "Лайк комментария" },
  { name: "best_posts", title: "Лучший пост недели" },
  { name: "system_message", title: "Сообщение от администрации" },
  { name: "chat_message", title: "Личное сообщение" },
  { name: "unban", title: "Снятие бана" },
  { name: "next_rank", title: "Новый ранг" },
];

const fields = {
  approve: ["postId"],
  like: ["postId", "userId"],
  comment: ["commentId"],
  reply: ["commentId"],
  comment_like: ["commentId", "userId"],
  best_posts: ["postId"],
  system_message: ["title", "text", "postId"],
  chat_message: ["chatId", "userId"],
  unban: [],
  next_rank: [],
};

class Form extends Component {
  state = {
    targetUserId: "",
    type: "approve",
    title: "",
    text: "",
    userId: "",
    postId: "",
    commentId: "",
    chatId: "",
    secret: "",
    errors: null,
    sent: null,
  };

  handleInputChange = (e) => {
    const newState = {};
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  };

  handleTypeSelection = (e) => {
    this.setState({
      type: e.target.value,
      title: "",
      text: "",
      userId: "",
      postId: "",
      commentId: "",
      chatId: "",
    });
  };

  assignYourself = (e) => {
    e.preventDefault();
    this.setState({
      targetUserId: JSON.parse(localStorage.getItem("currentUser")).id,
    });
  };

  sendPushMessage = (e) => {
    e.preventDefault();

    const {
      type,
      targetUserId,
      title,
      text,
      userId,
      postId,
      commentId,
      chatId,
      secret,
    } = this.state;
    const formData = {
      target_user_id: targetUserId,
      type,
      title,
      text,
      user_id: userId,
      post_id: postId,
      comment_id: commentId,
      chat_id: chatId,
      secret,
    };

    this.props
      .send({ formData })
      .then((response) => {
        if (!response.secret_valid) {
          this.setState({
            errors: { "Секретное слово": "неверно" },
            sent: null,
          });
        } else {
          this.setState({ errors: false, sent: response.sent });
        }
      })
      .catch((error) => {
        const errors = error.data
          ? error.data.errors || error.data
          : error.message;
        this.setState({ errors, sent: null });
      });
  };

  render() {
    const {
      sent,
      targetUserId,
      type,
      title,
      text,
      userId,
      postId,
      commentId,
      chatId,
      secret,
      errors,
    } = this.state;
    const disabled =
      !targetUserId ||
      (type === "system_message" && text.trim().length === 0) ||
      !secret ||
      (fields[type].includes("postId") &&
        type !== "system_message" &&
        !postId) ||
      (fields[type].includes("commentId") && !commentId) ||
      (fields[type].includes("chatId") && !chatId);

    return (
      <div className="bg-white p-2">
        <h3>Симулятор</h3>

        <div className="mt-3">
          <div className="row">
            <div className="col font-weight-500">
              Id пользователя (адресат) *
            </div>
            <div className="col-auto text-muted">
              <Link to="#" onClick={this.assignYourself}>
                Вставить мой Id
              </Link>
            </div>
          </div>
          <input
            type="number"
            name="targetUserId"
            className="form-control mt-1"
            min={1}
            value={targetUserId}
            onChange={this.handleInputChange}
          />
        </div>

        <div className="mt-3">
          <div className="font-weight-500">Тип пуша</div>
          <select
            name="type"
            className="form-control custom-form"
            value={type}
            onChange={this.handleTypeSelection}
          >
            {types.map((t) => (
              <option key={t.name} value={t.name}>
                {t.title}
              </option>
            ))}
          </select>
        </div>

        {fields[type].includes("title") && (
          <div className="mt-3">
            <div className="row">
              <div className="col font-weight-500">Заголовок</div>
              <div className="col-auto text-muted">50</div>
            </div>
            <input
              type="text"
              name="title"
              className="form-control mt-1"
              maxLength={50}
              value={title}
              onChange={this.handleInputChange}
            />
          </div>
        )}

        {fields[type].includes("text") && (
          <div className="mt-3">
            <div className="row">
              <div className="col font-weight-500">Текст *</div>
              <div className="col-auto text-muted">200</div>
            </div>
            <input
              type="text"
              name="text"
              className="form-control mt-1"
              maxLength={200}
              value={text}
              onChange={this.handleInputChange}
            />
          </div>
        )}

        {fields[type].includes("postId") && (
          <div className="mt-3">
            <div className="row">
              <div className="col font-weight-500">
                Id поста{type !== "system_message" ? " *" : ""}
              </div>
            </div>
            <input
              type="number"
              name="postId"
              className="form-control mt-1"
              min={1}
              value={postId}
              onChange={this.handleInputChange}
            />
          </div>
        )}

        {fields[type].includes("commentId") && (
          <div className="mt-3">
            <div className="row">
              <div className="col font-weight-500">Id комментария *</div>
            </div>
            <input
              type="number"
              name="commentId"
              className="form-control mt-1"
              min={1}
              value={commentId}
              onChange={this.handleInputChange}
            />
          </div>
        )}

        {fields[type].includes("userId") && (
          <div className="mt-3">
            <div className="row">
              <div className="col font-weight-500">Id пользователя *</div>
            </div>
            <input
              type="number"
              name="userId"
              className="form-control mt-1"
              min={1}
              value={userId}
              onChange={this.handleInputChange}
            />
          </div>
        )}

        {fields[type].includes("chatId") && (
          <div className="mt-3">
            <div className="row">
              <div className="col font-weight-500">Id чата *</div>
            </div>
            <input
              type="number"
              name="chatId"
              className="form-control mt-1"
              min={1}
              value={chatId}
              onChange={this.handleInputChange}
            />
          </div>
        )}

        <div className="mt-3">
          <div className="font-weight-500">Секретное слово *</div>
          <input
            type="text"
            className="form-control mt-1"
            name="secret"
            onChange={this.handleInputChange}
          />
        </div>

        <div className="mt-3 text-center">
          {errors && <Error errors={errors} />}
          {sent !== null && (
            <div className={`alert alert-${sent ? "success" : "warning"}`}>
              Пуш {sent ? "" : "не "}отправлен ({new Date().toLocaleString()})
            </div>
          )}
          <button
            className="btn btn-success"
            onClick={this.sendPushMessage}
            disabled={disabled}
          >
            Отправить {sent ? "ещё один пуш" : ""}
          </button>
        </div>
      </div>
    );
  }

  static propTypes = {
    send: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  send: (data) => dispatch(sendBySimulator(data)),
});

export const SimulatorForm = connect(null, mapDispatchToProps)(Form);
