import { DataLayer } from "../../libs/";

export const loadBadFeedWords = () => async (dispatch) => {
  const badFeedWords = localStorage.getItem("badFeedWords");

  if (badFeedWords) {
    return dispatch({
      type: "SET_BAD_FEED_WORDS",
      payload: badFeedWords,
    });
  }

  return DataLayer.get({ path: "/settings/bad_feed_words.json" })
    .then((response) => {
      localStorage.setItem("badFeedWords", response.bad_feed_words);
      dispatch({
        type: "SET_BAD_FEED_WORDS",
        payload: response.bad_feed_words,
      });
    })
    .catch(() => {});
};
