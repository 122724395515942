import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import MediaQuery from "react-responsive";
import _ from "lodash";

import { loadUser, loadUserSecrets, setPageTitle } from "../../actions/";
import {
  Loading,
  LoadingError,
  EmptyList,
  NotFound,
  Alert,
  Pagination,
  PageHeader,
  ResponsiveSidebar,
} from "../../components/common/";
import { SecretFilters, Secret } from "../../components/secrets/";
import { ProfileLink } from "../../components/users";
import { baseUrl, scrollPage } from "../../libs";

const secretTypes = [
  { uri: "", title: "Все" },
  { uri: "/pending", title: "На модерации" },
  { uri: "/scheduled", title: "Запланированные" },
  { uri: "/published", title: "Опубликованные" },
  { uri: "/rejected", title: "Удалённые" },
];

class Secrets extends Component {
  state = {
    userId: parseInt(this.props.match.params.userId, 10),
    user: null,
    secrets: [],
    alert: null,
    loading: true,
    loadingError: false,
    pages: 0,
    filters: this.props.filters,
  };

  componentDidMount() {
    this.props.loadUser(this.state.userId).then((e) => {
      this.setState({ ...e, loading: true }, () => this.loadSecrets());
    });
    this.props.setPageTitle(`Секреты пользователя`);
  }

  componentWillUnmount() {
    this.props.setPageTitle(null);
  }

  componentDidUpdate(__, prevState) {
    if (!_.isEqual(prevState.filters, this.state.filters)) {
      scrollPage(0);
      this.loadSecrets();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.filters, state.filters)) {
      return { filters: props.filters, secrets: [], loading: true };
    }
    return null;
  }

  loadSecrets = () => {
    const { userId } = this.state;
    this.props.loadSecrets({ userId }).then((e) => this.setState(e));
  };

  render() {
    const { userId, user, secrets, alert, loading, loadingError, pages } =
      this.state;

    if (!user) {
      return (
        <NotFound
          title="Пользователь"
          id={userId}
          loading={loading}
          loadingError={loadingError}
        />
      );
    }

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <PageHeader>
          <h2>
            <ProfileLink user={user} />
          </h2>
          <nav>
            {secretTypes.map(({ uri, title }) => (
              <NavLink
                key={uri}
                exact
                to={`${baseUrl}/users/${userId}/secrets${uri}`}
                activeClassName="active"
              >
                {title}
              </NavLink>
            ))}
          </nav>
        </PageHeader>

        <div className="row p-static">
          <ResponsiveSidebar mobileTitle="Показать фильтры">
            <SecretFilters />
          </ResponsiveSidebar>

          <div className="col-lg-8 col-12 order-lg-1">
            {alert && <Alert type={alert} />}

            <MediaQuery maxWidth={767}>
              {(matches) => <Pagination total={pages} small={matches} />}
            </MediaQuery>

            {loading && <Loading className="mb-3" />}
            {!loading && loadingError && <LoadingError />}
            {!loading && !loadingError && secrets.length < 1 && <EmptyList />}
            {secrets.map((secret) => (
              <Secret key={secret.id} secret={secret} />
            ))}

            <MediaQuery maxWidth={767}>
              {(matches) => <Pagination total={pages} small={matches} />}
            </MediaQuery>
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    loadUser: PropTypes.func.isRequired,
    loadSecrets: PropTypes.func.isRequired,
    setPageTitle: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.secrets,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (userId) => dispatch(loadUser(userId)),
  loadSecrets: (data) => dispatch(loadUserSecrets(data)),
  setPageTitle: (title) => dispatch(setPageTitle(title)),
});

export const UserSecrets = connect(
  mapStateToProps,
  mapDispatchToProps
)(Secrets);
