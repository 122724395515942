import React from "react";
import { Switch, Route } from "react-router-dom";

import { PageHeader } from "../../components/common/";
import { baseUrl } from "../../libs/";
import {
  StatisticsSecrets,
  StatisticsPosts,
  StatisticsComments,
  StatisticsUsers,
} from "./";

export const StatisticsIndex = () => (
  <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
    <PageHeader />

    <div className="p-static">
      <Switch>
        <Route
          exact
          path={`${baseUrl}/statistics/secrets`}
          component={StatisticsSecrets}
        />
        <Route
          exact
          path={`${baseUrl}/statistics/posts`}
          component={StatisticsPosts}
        />
        <Route
          exact
          path={`${baseUrl}/statistics/comments`}
          component={StatisticsComments}
        />
        <Route
          exact
          path={`${baseUrl}/statistics/users`}
          component={StatisticsUsers}
        />
      </Switch>
    </div>
  </div>
);
