import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import _ from "lodash";

import { loadPostComments, toggleQueryParam } from "../../actions/";
import { baseUrl, scrollPage } from "../../libs/";
import {
  Loading,
  LoadingError,
  EmptyList,
  EndOfList,
  ButtonsPost,
} from "../../components/common/";

import { CommentList, CommentForm } from "../../components/comments";

const commentTypes = [
  { uri: "", title: "Все" },
  { uri: "/comments/new", title: "Новые" },
  { uri: "/comments/best", title: "Лучшие" },
  { uri: "/comments/worst", title: "Худшие" },
];

class CommentsWrapper extends Component {
  state = {
    comments: [],
    end: false,
    loading: true,
    loadingError: false,
    scrollTo: /#(comments)|(c\d+)/.test(window.location.hash)
      ? window.location.hash.replace("#", "")
      : null,
    scrollIsDisabled: false,
    filters: this.props.filters,
  };

  componentDidMount() {
    const { scrollTo } = this.state;

    const to = document
      .querySelector(`[name="${scrollTo}"]`)
      ?.getBoundingClientRect().top;

    if (to) {
      scrollPage(to + (window.innerWidth > 991 ? 90 : 30));
    }

    this.props.load({ postId: this.props.postId }).then((newState) => {
      if (!scrollTo || scrollTo === "comments") {
        newState.scrollIsDisabled = true;
      }

      this.setState(newState);
    });
  }

  componentDidUpdate(__, prevState) {
    const { scrollTo, scrollIsDisabled, loading } = this.state;

    if (scrollTo && !scrollIsDisabled && !loading) {
      const to = document
        .querySelector(`[name="${scrollTo}"]`)
        ?.getBoundingClientRect().top;

      if (to) {
        scrollPage(to + (window.innerWidth > 991 ? 90 : 30));
      }
    }

    if (!_.isEqual(prevState.filters, this.state.filters)) {
      this.props
        .load({ postId: this.props.postId })
        .then((e) => this.setState(e));
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.filters, state.filters)) {
      return {
        filters: props.filters,
        comments: [],
        loading: true,
        end: false,
      };
    }
    return null;
  }

  loadMore = () => {
    const { postId } = this.props;
    const { comments, loading, scrollTo, scrollIsDisabled } = this.state;

    if (!loading) {
      this.setState({ loading: true });
    }

    this.props.load({ comments, postId }).then((newState) => {
      if (
        !scrollIsDisabled &&
        _.find(comments, ["id", parseInt(scrollTo.replace("c", ""), 10)])
      ) {
        newState.scrollIsDisabled = true;
      }
      this.setState(newState);
    });
  };

  tabClick = (e) => {
    if (e.target.classList.contains("active")) {
      e.preventDefault();
      this.props
        .load({ comments: [], postId: this.props.postId, loader: true })
        .then((e) => this.setState(e));
    }
  };

  handleNewComment = (newComment) => {
    this.setState({ comments: [newComment, ...this.state.comments] });
  };

  render() {
    const { comments, end, loading, loadingError, scrollTo, scrollIsDisabled } =
      this.state;

    const {
      postId,
      toggleQueryParam,
      filters: { type, hideHidden },
    } = this.props;

    const withIndents = ["all", "new"].includes(type);

    const commentToHighlight = scrollTo && !scrollIsDisabled ? scrollTo : null;

    return (
      <div className="post-comments my-4" name="comments">
        <div className="row mb-25 align-items-center">
          <h3 className="col-md m-0">Комментарии</h3>
          <div className="col-md-auto">
            <span
              className="btn btn-link p-0"
              onClick={() =>
                toggleQueryParam({
                  name: "hide_hidden",
                  show: !hideHidden,
                  value: "yes",
                })
              }
            >
              {`${hideHidden ? "Показать" : "Не показывать"}`} скрытые
            </span>
          </div>
        </div>

        <nav>
          {commentTypes.map(({ uri, title }) => (
            <NavLink
              key={uri}
              exact
              to={`${baseUrl}/posts/${postId}${uri}`}
              activeClassName="active"
              onClick={this.tabClick}
            >
              {title}
            </NavLink>
          ))}
        </nav>

        <hr className="mt-2 mb-4" />

        <CommentForm postId={postId} saveCallback={this.handleNewComment} />

        <CommentList
          comments={comments}
          withIndents={withIndents}
          highlightComment={commentToHighlight}
        />

        {comments.length > 0 && !end && !loadingError && (
          <VisibilitySensor
            onChange={(isVisible) => isVisible && this.loadMore()}
            children={<div className="visibility_sensor" />}
          />
        )}

        {loading && <Loading />}

        {loadingError && <LoadingError retry={this.loadMore} />}

        {!loading && !loadingError && !comments[0] && (
          <>
            <div className="mb-2 d-flex">
              <div className="ml-auto">
                <ButtonsPost />
              </div>
            </div>

            <EmptyList />
          </>
        )}

        {comments.length > 0 && end && (
          <>
            <div className="my-2 d-flex">
              <div className="ml-auto">
                <ButtonsPost />
              </div>
            </div>

            <EndOfList />
          </>
        )}
      </div>
    );
  }

  static propTypes = {
    load: PropTypes.func.isRequired,
    toggleQueryParam: PropTypes.func.isRequired,
    postId: PropTypes.number.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    filters: PropTypes.objectOf(PropTypes.any),
  };
}

const CommentsWithRouter = withRouter(CommentsWrapper);

const mapStateToProps = (state) => ({
  filters: state.filters.post_comments,
});

const mapDispatchToProps = (dispatch) => ({
  load: (data) => dispatch(loadPostComments(data)),
  toggleQueryParam: (data) => dispatch(toggleQueryParam(data)),
});

export const PostComments = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentsWithRouter);
