import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Category, CategoryForm, PostExportModal } from "./";
import { Loading } from "../common/loading";

class Categories extends Component {
  state = {
    showAddForm: false,
    showExportModal: false,
  };

  toggleAddForm = () => {
    this.setState({ showAddForm: !this.state.showAddForm });
  };

  toggleExportModal = () => {
    this.setState({ showExportModal: !this.state.showExportModal });
  };

  render() {
    const { showAddForm, showExportModal } = this.state;
    const { data, hasAdminAccess } = this.props;
    return (
      <div className="settings-item bg-white p-2">
        <div className="row align-items-top mb-4">
          <h5 className="col">Категории</h5>
          <div className="col text-right">
            <button
              className="btn btn-primary mb-1"
              onClick={this.toggleAddForm}
            >
              Добавить категорию
            </button>
            {hasAdminAccess && (
              <button
                className="btn btn-secondary mb-1 ml-1"
                onClick={this.toggleExportModal}
              >
                Экспорт постов
              </button>
            )}
          </div>
        </div>

        {showExportModal && <PostExportModal close={this.toggleExportModal} />}

        <table className="table table-responsive table-striped table-sm table-hover">
          <colgroup>
            <col width="1" />
            <col />
            <col width="150" />
            <col width="200" />
            <col width="250" />
            <col width="60" />
            <col width="60" />
            <col width="90" />
          </colgroup>
          <thead>
            <tr>
              <th>ID</th>
              <th>Название</th>
              <th>Слаг</th>
              <th>Описание</th>
              <th
                title={
                  "Настройки скрытия (В приложениях / На сайте / В настройках пользователя / В изнанке / Для VIP / В админке)"
                }
              >
                Настройки скрытия
                <br />
                (Прил / Сайт / Пол / Изн / VIP / Адм)
              </th>
              <th>Кол-во секретов</th>
              <th>Скрыто в ленте</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {showAddForm && (
              <CategoryForm category={{}} close={this.toggleAddForm} />
            )}

            {data.length === 0 && (
              <tr>
                <td colSpan={10}>
                  <Loading />
                </td>
              </tr>
            )}

            {data.map((category) => (
              <Category key={category.id} category={category} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    hasAdminAccess: PropTypes.bool.isRequired,
  };
}

const mapStateToProps = (state) => ({
  hasAdminAccess: state.user.hasAdminAccess,
});

export const CategorySettings = connect(mapStateToProps)(Categories);
