import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";

import { loadUser, loadUserHistory, setPageTitle } from "../../actions/";
import {
  Loading,
  LoadingError,
  History,
  NotFound,
  PageHeader,
} from "../../components/common/";
import { baseUrl } from "../../libs/";
import { ProfileLink } from "../../components/users";

class HistoryComponent extends Component {
  state = {
    userId: parseInt(this.props.match.params.userId, 10),
    user: null,
    versions: [],
    loading: true,
    loadingError: false,
    pages: 0,
    filters: this.props.filters,
  };

  componentDidMount() {
    const { userId } = this.state;

    this.props.loadUser(userId).then((newState) => {
      if (newState.user) {
        newState.loading = true;
        this.props.loadHistory(userId).then((e) => this.setState(e));
      }
      this.setState(newState);
    });

    this.props.setPageTitle(`История изменений пользователя`);
  }

  componentWillUnmount() {
    this.props.setPageTitle(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevState.filters, this.state.filters)) {
      this.props.loadHistory(this.state.userId).then((e) => this.setState(e));
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.filters, state.filters)) {
      return { filters: props.filters, versions: [], loading: true };
    }
    return null;
  }

  render() {
    const { user, userId, versions, loading, loadingError, pages } = this.state;
    const baseLink = `${baseUrl}/users/${userId}/history`;

    if (!user) {
      return (
        <NotFound
          title="Пользователь"
          id={userId}
          loading={loading}
          loadingError={loadingError}
        />
      );
    }

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <div className="bg-white p-2 p-sm-3">
          <PageHeader>
            <h2>
              <ProfileLink user={user} />
            </h2>
            <nav>
              <NavLink to={baseLink} exact>
                Все
              </NavLink>
              <NavLink to={`${baseLink}/profile`}>Профиль</NavLink>
              <NavLink to={`${baseLink}/status`}>Статус</NavLink>
              <NavLink to={`${baseLink}/auth`}>Авторизация</NavLink>
              <NavLink to={`${baseLink}/device`}>Устройство</NavLink>
            </nav>
          </PageHeader>

          {loading && <Loading />}
          {loadingError && <LoadingError />}
          {!loading && !loadingError && (
            <History versions={versions} pages={pages} />
          )}
        </div>
      </div>
    );
  }

  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    loadUser: PropTypes.func.isRequired,
    loadHistory: PropTypes.func.isRequired,
    setPageTitle: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.history,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (userId) => dispatch(loadUser(userId)),
  loadHistory: (userId) => dispatch(loadUserHistory(userId)),
  setPageTitle: (title) => dispatch(setPageTitle(title)),
});

export const UserHistory = connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryComponent);
