import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

export class InputWithSuggestions extends Component {
  state = {
    formValue: this.props.value,
    formTitle: this.props.title,
    title: this.props.title,
    suggestions: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.formValue || props.title !== state.formTitle) {
      return {
        formValue: props.value,
        formTitle: props.title,
        title: props.title,
        suggestions: [],
      };
    }
    return null;
  }

  handleSelect = (value, title) => {
    this.setState({ title, suggestions: [] }, () =>
      this.props.callback(value, title)
    );
  };

  handleChange = (e) => {
    this.setState({ title: e.target.value }, () => {
      const { title } = this.state;
      if (title.length > 0) {
        this.props
          .load(title)
          .then((suggestions) => this.setState({ suggestions }));
      } else {
        this.setState({ suggestions: [] });
      }
    });
  };

  handleBlur = () => {
    this.setState({ title: this.state.formTitle, suggestions: [] });
  };

  handleKeyDown = (e) => {
    const activeElem = document.querySelector(
      "ul.suggestions-dropdown li.active"
    );
    if (
      !["ArrowUp", "ArrowDown", "Tab", "Enter"].includes(e.key) ||
      !activeElem
    ) {
      return;
    }

    e.preventDefault();

    if (e.key === "Enter") {
      activeElem.click();
    } else if (e.key === "ArrowUp" || (e.key === "Tab" && e.shiftKey)) {
      if (activeElem.previousElementSibling) {
        activeElem.classList.remove("active");
        activeElem.previousElementSibling.classList.add("active");
      }
    } else if (e.key === "ArrowDown" || (e.key === "Tab" && !e.shiftKey)) {
      if (activeElem.nextElementSibling) {
        activeElem.classList.remove("active");
        activeElem.nextElementSibling.classList.add("active");
      }
    }
  };

  render() {
    const { title, suggestions } = this.state;
    const { placeholder } = this.props;

    return (
      <Fragment>
        <input
          type="text"
          className="form-control"
          value={title}
          placeholder={placeholder}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
        />

        {suggestions.length > 0 && (
          <ul
            className="suggestions-dropdown"
            onMouseDown={(e) => e.preventDefault()}
          >
            {suggestions.map((item, i) => (
              <li
                key={item.id}
                className={i === 0 ? "active" : null}
                onClick={() => this.handleSelect(item.id, item.name)}
              >
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </Fragment>
    );
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.number,
    placeholder: PropTypes.string,
    load: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
  };
}
