import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { SvgDevices } from "../../svg/";
import { baseUrl } from "../../libs/";

export const MobileList = ({ users, hasAdminAccess, showEditorStats }) => {
  return (
    <div className="list">
      {users.map((user) => {
        const DeviceIcon = SvgDevices[user.device_type];
        const accountsCount =
          (user.email ? 1 : 0) +
          (user.fb_user_id ? 1 : 0) +
          (user.vk_user_id ? 1 : 0);
        return (
          <div className="item" key={user.id}>
            <div className="row mb-2">
              <div className="col font-weight-500 text-muted">Имя</div>
              <div className="col-auto">
                <a
                  href={`${baseUrl}/users/${user.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {user.fullname_or_anonymus}
                </a>
                <DeviceIcon width={16} height={16} className="fill-gray ml-1" />
              </div>
            </div>

            {hasAdminAccess && showEditorStats && user.editor_stats && (
              <Fragment>
                <div className="row mb-2">
                  <div className="col font-weight-500 text-muted">
                    Добавлено/опубликовано в прошлом месяце
                  </div>
                  <div className="col-auto">
                    <a
                      href={`${baseUrl}/publishers/${user.id}/secrets/added/prev_month`}
                      target="_blank"
                      title="Количество добавленных секретов в прошлом месяце"
                      rel="noreferrer"
                    >
                      {user.editor_stats.prev_month_added}
                    </a>
                    {" / "}
                    <a
                      href={`${baseUrl}/publishers/${user.id}/posts/published/prev_month`}
                      target="_blank"
                      title="Количество опубликованных секретов в прошлом месяце"
                      rel="noreferrer"
                    >
                      {user.editor_stats.prev_month_published}
                    </a>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col font-weight-500 text-muted">
                    Добавлено/опубликовано в текущем месяце
                  </div>
                  <div className="col-auto">
                    <a
                      href={`${baseUrl}/publishers/${user.id}/secrets/added/this_month`}
                      target="_blank"
                      title="Количество добавленных секретов в текущем месяце"
                      rel="noreferrer"
                    >
                      {user.editor_stats.this_month_added}
                    </a>
                    {" / "}
                    <a
                      href={`${baseUrl}/publishers/${user.id}/posts/published/this_month`}
                      target="_blank"
                      title="Количество опубликованных секретов в текущем месяце"
                      rel="noreferrer"
                    >
                      {user.editor_stats.this_month_published}
                    </a>
                    {" ("}
                    <a
                      href={`${baseUrl}/publishers/${user.id}/secrets/scheduled/this_month`}
                      target="_blank"
                      title="Количество секретов, запланированных на публикацию в текущем месяце"
                      rel="noreferrer"
                    >
                      {user.editor_stats.this_month_scheduled}
                    </a>
                    )
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col font-weight-500 text-muted">
                    Запланировано на следующий месяц
                  </div>
                  <div className="col-auto">
                    <a
                      href={`${baseUrl}/publishers/${user.id}/secrets/scheduled/next_month`}
                      target="_blank"
                      title="Количество секретов, запланированных на публикацию в следующем месяце"
                      rel="noreferrer"
                    >
                      {user.editor_stats.next_month_scheduled}
                    </a>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col font-weight-500 text-muted">
                    Всего опубликовано / удалено
                  </div>
                  <div className="col-auto">
                    <a
                      href={`${baseUrl}/publishers/${user.id}/posts/published`}
                      target="_blank"
                      title="Общее количество опубликованных секретов"
                      rel="noreferrer"
                    >
                      {user.editor_stats.all_time_published}
                    </a>
                    {" ("}
                    <a
                      href={`${baseUrl}/publishers/${user.id}/posts/removed`}
                      target="_blank"
                      title="Общее количество удаленных секретов"
                      rel="noreferrer"
                    >
                      {user.editor_stats.all_time_removed}
                    </a>
                    )
                  </div>
                </div>
              </Fragment>
            )}

            {hasAdminAccess && !showEditorStats && (
              <Fragment>
                {hasAdminAccess && (
                  <div className="row mb-2">
                    <div className="col font-weight-500 text-muted">
                      Привязки
                    </div>
                    <div className="col-auto">
                      {user.email && accountsCount > 1 && (
                        <button
                          className="btn btn-account email icon"
                          title={user.email}
                        />
                      )}

                      {user.email && accountsCount <= 1 && (
                        <button className="btn btn-account email">
                          {user.email}
                        </button>
                      )}

                      {user.apple_user_id && accountsCount > 1 && (
                        <button
                          className="btn btn-account apple ml-1 icon"
                          title={user.apple_name}
                        />
                      )}

                      {user.apple_user_id && accountsCount <= 1 && (
                        <button className="btn btn-account apple ml-1">
                          {user.apple_name}
                        </button>
                      )}

                      {user.fb_user_id && accountsCount > 1 && (
                        <button
                          className="btn btn-account fb ml-1 icon"
                          title={user.fb_name}
                        />
                      )}

                      {user.fb_user_id && accountsCount <= 1 && (
                        <button className="btn btn-account fb ml-1">
                          {user.fb_name}
                        </button>
                      )}

                      {user.vk_user_id && accountsCount > 1 && (
                        <a
                          href={user.vk_link}
                          className="btn btn-account vk ml-1 icon"
                          target="_blank"
                          title={user.vk_name}
                          rel="noreferrer"
                        />
                      )}

                      {user.vk_user_id && accountsCount <= 1 && (
                        <a
                          href={user.vk_link}
                          className="btn btn-account vk ml-1"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {user.vk_name}
                        </a>
                      )}
                    </div>
                  </div>
                )}
                <div className="row mb-2">
                  <div className="col font-weight-500 text-muted">Посты</div>
                  <div className="col-auto">
                    <a
                      target="blank"
                      href={`${baseUrl}/users/${user.id}/posts`}
                    >
                      {user.post_count}
                    </a>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col font-weight-500 text-muted">Секреты</div>
                  <div className="col-auto">
                    <a
                      target="blank"
                      href={`${baseUrl}/users/${user.id}/secrets`}
                    >
                      {user.secret_count}
                    </a>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col font-weight-500 text-muted">Рейтинг</div>
                  <div className="col-auto">
                    <a
                      target="blank"
                      href={`${baseUrl}/users/${user.id}/rating_awards`}
                    >
                      {user.rating}
                    </a>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col font-weight-500 text-muted">
                    Комментарии
                  </div>
                  <div className="col-auto">
                    <a
                      target="blank"
                      href={`${baseUrl}/users/${user.id}/comments`}
                    >
                      {user.commented_count}
                    </a>
                  </div>
                </div>
              </Fragment>
            )}

            {(!hasAdminAccess || !showEditorStats) && (
              <div className="row">
                <div className="col font-weight-500 text-muted">Баны</div>
                <div className="col-auto">
                  <a target="blank" href={`${baseUrl}/users/${user.id}/bans`}>
                    {user.banned_count}
                  </a>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

MobileList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.any).isRequired,
  showEditorStats: PropTypes.bool.isRequired,
  hasAdminAccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  hasAdminAccess: state.user.hasAdminAccess,
});

export const UserListMobile = connect(mapStateToProps)(MobileList);
