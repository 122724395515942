export const digObject = (obj, keys) => {
  if (obj === undefined) {
    return undefined;
  } else if (typeof keys === "string") {
    // convert dot notation to array
    return digObject(obj, keys.split("."));
  } else if (keys.length === 1) {
    // return final result
    return obj[keys[0]];
  }

  // dig deeper
  return digObject(obj[keys[0]], keys.slice(1));
};

export const updateObject = (obj, keys, value) => {
  if (typeof keys === "string") {
    // convert dot notation to array
    return updateObject(obj, keys.split("."), value);
  } else if (keys.length === 1) {
    // assign new value and return an updated part of the object
    obj[keys[0]] = value;
    return obj;
  }

  // dig deeper and return the updated object
  if (obj[keys[0]] === undefined) {
    obj[keys[0]] = {};
  }
  obj[keys[0]] = updateObject(obj[keys[0]], keys.slice(1), value);

  return obj;
};
