import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { createPushMessage } from "../../actions/";
import { Error } from "../common/";

const devices = [
  { name: "all", title: "Все" },
  { name: "ios", title: "iOS" },
  { name: "android", title: "Android" },
  { name: "huawei", title: "Huawei" },
  { name: "rustore", title: "RuStore" },
];

const timerTime = 10; // seconds
const timerStep = 1; // seconds

class Form extends Component {
  state = {
    title: "",
    body: "",
    postId: "",
    secret: "",
    specialForDevice: ["all"],
    errors: null,
    timer: null,
    time: null,
  };

  componentWillUnmount() {
    clearTimeout(this.state.timer);
  }

  handleInputChange = (e) => {
    const newState = {};
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  };

  handlePostSelection = (e) => {
    this.setState({ postId: e.target.value });
  };

  handleDevicesSelection = (e) => {
    const { name, checked } = e.target;
    let newValue = this.state.specialForDevice;
    const maxDevices = devices.length - 1;

    if (checked && name === "all") {
      newValue = [name];
    } else if (!checked && name === "all") {
      // do nothing
    } else if (checked) {
      newValue = newValue.filter((el) => el !== "all" && el !== "none");
      newValue.push(name);
      if (newValue.length === maxDevices) newValue = ["all"];
    } else {
      newValue = newValue.filter((el) => el !== name);
      if (newValue.length === 0) newValue = ["all"];
    }

    this.setState({ specialForDevice: newValue });
  };

  startTimer = () => {
    this.setState({
      timer: setTimeout(this.runTimer, timerStep * 1000),
      time: timerTime,
    });
  };

  stopTimer = () => {
    clearTimeout(this.state.timer);
    this.setState({ timer: null });
  };

  runTimer = () => {
    const { time } = this.state;

    if (time > 0) {
      this.setState({
        timer: setTimeout(this.runTimer, timerStep * 1000),
        time: time - timerStep,
      });
    } else {
      this.createPushMessage();
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.state.timer) {
      this.startTimer();
    } else {
      this.stopTimer();
    }
  };

  createPushMessage = () => {
    const { title, body, postId, specialForDevice, secret } = this.state;
    const formData = {
      push_message: {
        title,
        body,
        post_id: postId,
        special_for_device: specialForDevice.join(","),
      },
      secret,
    };

    this.props.create({ formData }).then((errors) => {
      if (errors) {
        this.setState({ errors });
      } else {
        this.setState({
          errors: null,
          timer: null,
          title: "",
          body: "",
          postId: "",
          secret: "",
          specialForDevice: ["all"],
        });
        this.props.onCreate();
      }
    });
  };

  render() {
    const {
      title,
      body,
      postId,
      secret,
      specialForDevice,
      errors,
      timer,
      time,
    } = this.state;
    const { recentPosts } = this.props;
    const disabled = body.trim().length === 0 || secret.length === 0;

    return (
      <div className="push-message-form bg-white p-2">
        <h3>Новое сообщение</h3>

        <div className="mt-3">
          <div className="row">
            <div className="col font-weight-500">Заголовок</div>
            <div className="col-auto text-muted">50</div>
          </div>
          <input
            type="text"
            className="form-control mt-1"
            maxLength={50}
            name="title"
            value={title}
            onChange={this.handleInputChange}
          />
        </div>

        <div className="mt-3">
          <div className="row">
            <div className="col font-weight-500">Текст *</div>
            <div className="col-auto text-muted">200</div>
          </div>
          <input
            type="text"
            className="form-control mt-1"
            maxLength={200}
            name="body"
            value={body}
            onChange={this.handleInputChange}
          />
        </div>

        <div className="mt-3">
          <div className="font-weight-500">Прикрепить пост</div>
          <select
            className="form-control custom-form"
            onChange={this.handlePostSelection}
            value={postId}
          >
            <option value={null} />
            {recentPosts.map((post) => (
              <option key={post.id} value={post.id}>
                #{post.id} {post.note?.slice(0, 50) || ""}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-3">
          <div className="font-weight-500">Секретное слово *</div>
          <input
            type="text"
            className="form-control mt-1"
            name="secret"
            value={secret}
            onChange={this.handleInputChange}
          />
        </div>

        <div className="mt-3">
          <div className="font-weight-500">Платформы</div>
          <div className="custom-radio-box mt-1">
            {devices.map((device) => (
              <label key={device.name} htmlFor={`device_${device.name}`}>
                <input
                  type="checkbox"
                  id={`device_${device.name}`}
                  name={device.name}
                  checked={specialForDevice.includes(device.name)}
                  onChange={this.handleDevicesSelection}
                />
                <span>{device.title}</span>
              </label>
            ))}
          </div>
        </div>
        <div className="mt-3 text-center">
          {errors && <Error errors={errors} />}
          <button
            className={`btn btn-${timer ? "danger" : "success"}`}
            onClick={this.handleSubmit}
            disabled={disabled}
          >
            {timer ? `Отправится через ${time} сек` : "Отправить"}
          </button>
        </div>
      </div>
    );
  }

  static propTypes = {
    create: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    recentPosts: PropTypes.arrayOf(PropTypes.any).isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createPushMessage(data)),
});

export const PushMessageForm = connect(null, mapDispatchToProps)(Form);
