import { baseUrl } from "../../../../libs";

export const SearchBoxRoutes = [
  `${baseUrl}/posts`,
  `${baseUrl}/posts/:type(best|removed)`,
  `${baseUrl}/secrets`,
  `${baseUrl}/secrets/:type(pending|scheduled|published|rejected|rejected_by_worker)`,
  `${baseUrl}/settings/cities`,
  `${baseUrl}/social_accounts`,
  `${baseUrl}/users`,
  `${baseUrl}/users/:type(admins|editors|moderators|keepers|vip|banned)`,
  `${baseUrl}/users/:id/comments`,
  `${baseUrl}/users/:id/secrets`,
  `${baseUrl}/users/:id/secrets/:type(pending|scheduled|published|rejected)`,
];
