import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { createComment } from "../../actions/";
import { pluralizeCharacters } from "../../libs/";

class Form extends Component {
  state = {
    text: "",
  };

  componentDidMount() {
    this.textAreaRef.style.height = "20px";
    this.textAreaRef.style.height = `${this.textAreaRef.scrollHeight}px`;
  }

  componentDidUpdate() {
    this.textAreaRef.style.height = "20px";
    this.textAreaRef.style.height = `${this.textAreaRef.scrollHeight}px`;
  }

  handleTextChange = (e) => {
    this.setState({ text: e.target.value });
  };

  cancel = () => {
    if (this.props.parentCommentId) {
      this.props.toggleForm();
    } else {
      this.setState({ text: "" });
    }
  };

  send = () => {
    const { postId, parentCommentId, toggleForm, saveCallback } = this.props;
    const formData = {
      text_fixed: this.state.text,
      post_id: postId,
      parent_id: parentCommentId,
    };
    this.props
      .create({ formData })
      .then((response) => {
        if (parentCommentId) {
          toggleForm();
        } else {
          this.setState({ text: "" });
        }
        saveCallback(response);
      })
      .catch(() => {});
  };

  render() {
    const { text } = this.state;
    const { parentCommentId } = this.props;
    const sendTitle = parentCommentId ? "Ответить" : "Комментировать";
    const placeholder = parentCommentId
      ? "Написать ответ..."
      : "Написать комментарий...";
    const containerClassName = parentCommentId ? "reply" : "comment-add mb-3";

    return (
      <div className={containerClassName}>
        <textarea
          className="form-control"
          rows="3"
          ref={(c) => {
            this.textAreaRef = c;
          }}
          onChange={this.handleTextChange}
          value={text}
          placeholder={placeholder}
        />
        {text.length > 0 && (
          <div className="row align-items-center mt-3">
            <div className="col text-muted">
              {pluralizeCharacters(text.length)}
            </div>
            <div className="col-auto">
              <button className="btn btn-secondary mr-2" onClick={this.cancel}>
                Отмена
              </button>
              <button className="btn btn-success" onClick={this.send}>
                {sendTitle}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  static propTypes = {
    postId: PropTypes.number.isRequired,
    saveCallback: PropTypes.func.isRequired,
    parentCommentId: PropTypes.number,
    toggleForm: PropTypes.func,
    create: PropTypes.func.isRequired,
  };

  static defaultProps = {
    parentCommentId: null,
    toggleForm: null,
  };
}

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createComment(data)),
});

export const CommentForm = connect(null, mapDispatchToProps)(Form);
