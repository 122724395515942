import { DataLayer } from "../../libs/";

export const loadChatMessageComplains = () => async (dispatch, getState) => {
  const { type, page } = getState().filters.chat_message_complains;
  const params = [];
  const newState = {
    loading: false,
    loadingError: false,
  };

  if (page > 1) {
    params.push(`page=${page}`);
  }

  await DataLayer.get({
    path:
      "/chat_message_complains" +
      (type && "/" + type) +
      ".json" +
      (params.length ? "?" + params.join("&") : ""),
  })
    .then((response) => {
      newState.complains = response.complains;
      newState.pages = response.pages;
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
