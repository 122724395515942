import { push } from "connected-react-router";

export const setSortingParams =
  ({ field, order }) =>
  (dispatch, getState) => {
    const { search } = getState().router.location;
    const params = new URLSearchParams(search);

    if (params.get("sort_by") === field && params.get("sort_order") === order) {
      params.delete("sort_by");
      params.delete("sort_order");
    } else {
      params.set("sort_by", field);
      params.set("sort_order", order);
    }

    dispatch(push(`?${params.toString()}`));
  };
