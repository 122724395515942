export const TEXT_REPLACE_FROM = ["--", "<<", ">>", "..."];
export const TEXT_REPLACE_TO = ["—", "«", "»", "…"];

const updateText = (text, options = {}) => {
  if (options.reverse && TEXT_REPLACE_TO.includes(text.slice(-1))) {
    return (
      text.slice(0, -1) +
      TEXT_REPLACE_FROM[TEXT_REPLACE_TO.indexOf(text.slice(-1))]
    );
  }

  if (options.reverse) {
    return text.slice(0, -1);
  }

  if (text.slice(-2) === TEXT_REPLACE_FROM[0]) {
    return text.slice(0, -2) + TEXT_REPLACE_TO[0];
  } else if (text.slice(-2) === TEXT_REPLACE_FROM[1]) {
    return text.slice(0, -2) + TEXT_REPLACE_TO[1];
  } else if (text.slice(-2) === TEXT_REPLACE_FROM[2]) {
    return text.slice(0, -2) + TEXT_REPLACE_TO[2];
  } else if (text.slice(-3) === TEXT_REPLACE_FROM[3]) {
    return text.slice(0, -3) + TEXT_REPLACE_TO[3];
  }

  return text;
};

export const textAutoCorrection = (e, options = {}) => {
  let text, cursor;
  if (e.target.selectionEnd === e.target.value.length) {
    text = updateText(e.target.value, options);
    cursor = text.length;
  } else {
    const replaced = updateText(
      e.target.value.slice(0, e.target.selectionEnd),
      options
    );

    text =
      replaced +
      e.target.value.slice(e.target.selectionEnd, e.target.value.length);

    cursor = replaced.length;
  }

  return { text, cursor };
};
