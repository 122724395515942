import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { FilterItem } from "../common/";
import { Link } from "react-router-dom";

const visibilityList = [
  { title: "Все", value: "" },
  { title: "Только видимые", value: "only_visible" },
  { title: "Только скрытые", value: "only_hidden" },
];

const hiddenByList = [
  { title: "Неважно кем", value: "" },
  { title: "Модераторами", value: "moderator" },
  { title: "Пользователем", value: "user" },
];

const Filters = ({ filters }) => (
  <div className="block-sorting mb-4">
    <h3>
      Фильтрация
      {window.location.search && (
        <Fragment>
          {" ("}
          <Link to={window.location.pathname}>сбросить</Link>
          {")"}
        </Fragment>
      )}
    </h3>

    <FilterItem
      title="Видимость"
      name="visibility"
      options={visibilityList}
      current={filters.visibility}
    />

    {filters.visibility === "only_hidden" && (
      <FilterItem
        title="Скрыты"
        name="hidden_by"
        options={hiddenByList}
        current={filters.hiddenBy}
      />
    )}
  </div>
);

Filters.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.filters.user_comments,
});

export const CommentFilters = connect(mapStateToProps)(Filters);
