import moment from "moment";

const buildFormStateFromProps = (props) => {
  const { post } = props;

  return {
    noteTab: "note",
    newImage: null,
    newImageName: null,
    removeImage: false,
    errors: null,
    editConflict: null,
    formData: {},
    noteCursor: null,
    note2Cursor: null,
    note3Cursor: null,

    fullForm: post === null,
    id: post && post.id,
    status: post && post.status,
    scheduledTo:
      post && post.scheduled_to
        ? moment(post.scheduled_to).utcOffset(180).toString()
        : null,
    category: (post && post.category_id) || 37,
    note: (post && post.note) || "",
    note2: (post && post.note2) || "",
    note3: (post && post.note3) || "",
    image: post && post.image_uri,
    specialForDevice: (post && post.special_for_device) || ["all"],
    isAd: post ? post.is_ad : false,
    enableComments: post ? post.enable_comments : true,
    fixed: post ? post.fixed : false,
    illustration: post ? post.illustration : false,
    hideCommentButton: post ? post.hide_comment_button : false,
    hideLikeButton: post ? post.hide_like_button : false,
    hideShareButton: post ? post.hide_share_button : false,
    hideBookmarksButton: post ? post.hide_bookmarks_button : false,
    hideCategory: post ? post.hide_category : false,
    hotDiscussion: post ? post.hot_discussion : false,
    editableFieldsHash: post && post.editable_fields_hash,
    audienceFilter: post && post.audience_filter ? post.audience_filter : "",
    audienceGroups: post ? post.audience_groups : [],
  };
};

const prepareFormStateForSubmit = (state) => {
  const formData = {
    post: {
      id: state.id,
      category_id: state.category,
      note: state.note,
      note2: state.note2,
      note3: state.note3,
      special_for_device: state.specialForDevice.join(","),
      is_ad: state.isAd,
      enable_comments: state.enableComments,
      fixed: state.fixed,
      illustration: state.illustration,
      hide_comment_button: state.hideCommentButton,
      hide_like_button: state.hideLikeButton,
      hide_share_button: state.hideShareButton,
      hide_bookmarks_button: state.hideBookmarksButton,
      hide_category: state.hideCategory,
      hot_discussion: state.hotDiscussion,
      editable_fields_hash: state.editableFieldsHash,
    },
  };

  if (state.scheduledTo && state.scheduledTo > moment()) {
    formData.post.schedule = true;
    formData.post.scheduled_to = moment(state.scheduledTo).format(
      "DD.MM.YYYY HH:mm"
    );
  }

  if (state.removeImage) {
    formData.post.remove_image = true;
  }

  const { audienceFilter, audienceGroups } = state;
  formData.post.audience_filter =
    audienceFilter.length > 0 && audienceGroups.length > 0
      ? audienceFilter
      : null;
  formData.post.audience_groups = audienceGroups.join(",");

  if (state.newImage) {
    formData.post.image = state.newImage;
  }

  return formData;
};

export { buildFormStateFromProps, prepareFormStateForSubmit };
