import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";

import { loadPost, setPageTitle } from "../../actions/";
import { NotFound, PageHeader } from "../../components/common/";
import { baseUrl } from "../../libs/";
import { PostListItem } from "../../components/posts/";
import { PostHistory, PostComments } from "./";

export class Post extends Component {
  state = {
    post: null,
    postId: parseInt(this.props.match.params.id, 10),
    prevId: null,
    nextId: null,
    loading: true,
    loadingError: false,
  };

  componentDidMount() {
    this.props.load(this.state.postId).then((e) => this.setState(e));
    this.props.setPageTitle(`Пост #${this.state.postId}`);
  }

  componentWillUnmount() {
    this.props.setPageTitle(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.postId !== prevState.postId) {
      this.props.load(this.state.postId).then((e) => this.setState(e));
      this.props.setPageTitle(`Пост #${this.state.postId}`);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const idFromProps = parseInt(props.match.params.id, 10);
    if (idFromProps !== state.postId) {
      return { postId: idFromProps, loading: true, post: null };
    }
    return null;
  }

  render() {
    const { post, postId, loading, loadingError } = this.state;
    const { hasEditorAccess } = this.props;
    const content = this.props.match.params.content || "comments";

    return (
      <div className="container mt-lg-5 mt-3">
        <div className="row">
          <div className="col-xl-8 mx-auto">
            <PageHeader>
              {post && hasEditorAccess && (
                <nav className="mt-0 mt-lg-25">
                  <NavLink
                    exact
                    to={`${baseUrl}/posts/${post.id}`}
                    activeClassName="active"
                  >
                    Пост
                  </NavLink>

                  <NavLink
                    exact
                    to={`${baseUrl}/posts/${post.id}/history`}
                    activeClassName="active"
                  >
                    История изменений
                  </NavLink>

                  {!!(post.secret && post.secret.id) && (
                    <Link to={`${baseUrl}/secrets/${post.secret.id}`}>
                      Секрет
                    </Link>
                  )}
                </nav>
              )}
            </PageHeader>

            {!post && (
              <NotFound
                title="Пост"
                id={postId}
                loading={loading}
                loadingError={loadingError}
              />
            )}

            {post && content === "comments" && (
              <>
                <PostListItem post={post} />
                <PostComments postId={post.id} />
              </>
            )}

            {post && content === "history" && <PostHistory postId={post.id} />}
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    load: PropTypes.func.isRequired,
    setPageTitle: PropTypes.func.isRequired,
    hasEditorAccess: PropTypes.bool.isRequired,
  };
}

const mapStateToProps = (state) => ({
  hasEditorAccess: state.user.hasEditorAccess,
});

const mapDispatchToProps = (dispatch) => ({
  load: (id) => dispatch(loadPost(id)),
  setPageTitle: (title) => dispatch(setPageTitle(title)),
});

export const PostShow = connect(mapStateToProps, mapDispatchToProps)(Post);
