import { DataLayer } from "../../libs/";

export const updateBan =
  ({ id, formData }) =>
  async () => {
    const newState = {};
    await DataLayer.put({
      path: `/bans/${id}.json`,
      body: formData,
      loader: true,
    })
      .then((response) => {
        newState.showBanModal = false;
        newState.ban = response;
      })
      .catch(() => {});
    return newState;
  };
