import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadSettingsHistory, setPageTitle } from "../../actions/";
import {
  Loading,
  LoadingError,
  History,
  PageHeader,
} from "../../components/common/";

class HistoryComponent extends Component {
  state = {
    versions: [],
    loading: true,
    loadingError: false,
    pages: 0,
  };

  componentDidMount() {
    this.props.loadHistory().then((e) => this.setState(e));
    this.props.setPageTitle(`История изменений настроек`);
  }

  componentWillUnmount() {
    this.props.setPageTitle(null);
  }

  render() {
    const { versions, loading, loadingError, pages } = this.state;

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <div className="bg-white p-2 p-sm-3">
          <PageHeader />

          {loading && <Loading />}
          {loadingError && <LoadingError />}
          {!loading && !loadingError && (
            <History versions={versions} pages={pages} />
          )}
        </div>
      </div>
    );
  }

  static propTypes = {
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    loadHistory: PropTypes.func.isRequired,
    setPageTitle: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.history,
});

const mapDispatchToProps = (dispatch) => ({
  loadHistory: (userId) => dispatch(loadSettingsHistory(userId)),
  setPageTitle: (title) => dispatch(setPageTitle(title)),
});

export const SettingsHistory = connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryComponent);
