import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { CategoryFilter, FilterItem } from "../../components/common/";
import { Link } from "react-router-dom";

const sortingList = [
  { title: "по дате публикации", value: "scheduled_to" },
  { title: "по дате добавления", value: "scheduled_at" },
  { title: "по дате создания", value: "created_at" },
];

const Filters = ({ filters }) => {
  return (
    <div className="block-sorting mb-4">
      <h3>
        Фильтрация
        {window.location.search && (
          <Fragment>
            {" ("}
            <Link to={window.location.pathname}>сбросить</Link>
            {")"}
          </Fragment>
        )}
      </h3>

      <CategoryFilter />

      {filters.type === "scheduled" && (
        <FilterItem
          title="Сортировка"
          name="sort_by"
          options={sortingList}
          current={filters.sortBy}
        />
      )}
    </div>
  );
};

Filters.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.filters.publishers,
});

export const PublisherFilters = connect(mapStateToProps)(Filters);
