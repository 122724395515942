import { DataLayer } from "../../libs/";

export const loadPost = (id) => async (dispatch) => {
  const newState = {
    loading: false,
    loadingError: false,
  };

  await DataLayer.get({ path: `/posts/${id}.json` })
    .then((response) => {
      newState.post = response.post;
      newState.prevId = response.prevId;
      newState.nextId = response.nextId;
    })
    .catch((error) => {
      if (error.status !== 404) {
        newState.loadingError = true;
      }
    });

  dispatch({
    type: "SET_POST_LINKS",
    payload: { prevId: newState.prevId, nextId: newState.nextId },
  });

  return newState;
};
