import { DataLayer } from "../../libs/";
import { loadCities } from "../";

export const createCity = (city) => async (dispatch) => {
  await DataLayer.post({
    path: "/cities.json",
    body: city,
    loader: true,
  }).catch(() => {});
  const response = await dispatch(loadCities({}));
  return response.cities;
};
