import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { fullDate, highlightDiff } from "../../libs";

const textFields = [
  "note",
  "note2",
  "note3",
  "text_fixed",
  "comment_block_words",
  "bad_feed_words",
  "complain_feed_words",
];

const fetchChangedAttributes = (from, to) => {
  const diff = { from: {}, to: {} };

  if (from === null) {
    return { from: {}, to: to };
  }

  if (to === null) {
    return { from: from, to: {} };
  }

  Object.keys(from).forEach((field) => {
    if (!_.isEqual(from[field], to[field])) {
      if (from[field] && from[field].constructor.name === "Object") {
        const { from: fromVal, to: toVal } = fetchChangedAttributes(
          from[field],
          to[field]
        );
        diff.from[field] = fromVal;
        diff.to[field] = toVal;
      } else {
        diff.from[field] = from[field];
        diff.to[field] = to[field];
      }
    }
  });

  return diff;
};

export const HistoryItem = ({ version, showObject }) => {
  const info = {
    createdAt: version.created_at,
    author: version.author.fullname_or_anonymus,
  };
  const fields = Object.keys(version.changes);
  const rows = fields.length;

  return fields.map((field, index) => {
    const transition = version.changes[field];

    if (field === "uniqueness_text_ru") {
      if (typeof transition[0] === "string") {
        transition[0] =
          transition[0] === "null" ? null : JSON.parse(transition[0]);
      }
      if (typeof transition[1] === "string") {
        transition[1] =
          transition[0] === "null" ? null : JSON.parse(transition[1]);
      }
    } else if (transition[0] && transition[0].constructor.name === "Object") {
      const { from, to } = fetchChangedAttributes(transition[0], transition[1]);
      transition[0] = (
        <pre style={{ whiteSpace: "pre-wrap" }}>
          {JSON.stringify(from, null, 2)}
        </pre>
      );
      transition[1] = (
        <pre style={{ whiteSpace: "pre-wrap" }}>
          {JSON.stringify(to, null, 2)}
        </pre>
      );
    }

    if (
      transition[0] !== null &&
      transition[0].constructor.name === "Boolean"
    ) {
      transition[0] = `${transition[0]}`;
    }
    if (
      transition[1] !== null &&
      transition[1].constructor.name === "Boolean"
    ) {
      transition[1] = `${transition[1]}`;
    }

    return (
      <tr key={field}>
        {index < 1
          ? [
              <td key={`info_${index}`} rowSpan={rows}>
                {fullDate(info.createdAt)}
                {info.author && (
                  <div className="font-weight-bold">{info.author}</div>
                )}
              </td>,

              showObject ? (
                <td key={`object_${index}`} rowSpan={rows}>
                  {version.item ? (
                    <div>
                      {version.item.fullname_or_anonymus ? (
                        <a
                          href={`/dashboard/users/${version.item.id}`}
                          className="btn btn-link p-0"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {version.item.fullname_or_anonymus}
                        </a>
                      ) : (
                        <b>#{version.item.id}</b>
                      )}
                      <br />
                      {version.item.text}
                    </div>
                  ) : (
                    "Удалён"
                  )}
                </td>
              ) : null,
            ]
          : null}
        <td>{field}</td>

        {textFields.includes(field) ? (
          <td
            colSpan={2}
            style={{ wordBreak: "break-all", minWidth: "70px" }}
            dangerouslySetInnerHTML={{
              __html: highlightDiff(transition[0], transition[1]),
            }}
          />
        ) : null}

        {["uniqueness_text_ru"].includes(field)
          ? [
              <td key="before">
                {transition[0] && (
                  <span>
                    date: {transition[0].date_check}
                    <br />
                    unique: {transition[0].unique}
                  </span>
                )}
              </td>,
              <td key="after">
                {transition[1] && (
                  <span>
                    date: {transition[1].date_check}
                    <br />
                    unique: {transition[1].unique}
                  </span>
                )}
              </td>,
            ]
          : null}

        {!textFields.includes(field) && !["uniqueness_text_ru"].includes(field)
          ? [
              <td
                key="before"
                style={{ wordBreak: "break-all", minWidth: "70px" }}
              >
                {transition[0]}
              </td>,
              <td key="after" style={{ wordBreak: "break-all" }}>
                {transition[1]}
              </td>,
            ]
          : null}
      </tr>
    );
  });
};

HistoryItem.propTypes = {
  version: PropTypes.objectOf(PropTypes.any).isRequired,
  showObject: PropTypes.string.isRequired,
};
