import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import MediaQuery from "react-responsive";

export class NavItem extends Component {
  state = {
    showChildren: false,
  };

  toggleChildren = (e) => {
    e.preventDefault();
    this.setState({ showChildren: !this.state.showChildren });
  };

  render() {
    const { showChildren } = this.state;
    const {
      data: { url, title, parentUrl, children },
      toggleNav,
      currentUser,
      path,
    } = this.props;

    return (
      <li>
        <MediaQuery maxWidth={991}>
          {(matches) => {
            const clickAction = children ? this.toggleChildren : toggleNav;
            const className = path.includes(parentUrl || url) ? "active" : null;

            return (
              <Link
                to={url}
                className={className}
                onClick={matches ? clickAction : null}
              >
                {title}
              </Link>
            );
          }}
        </MediaQuery>

        <MediaQuery minWidth={992}>
          {(matches) =>
            ((children && showChildren) || matches) && (
              <ul className="dropdown">
                {children.map((child) => {
                  if (!currentUser[child.accessMethod]) {
                    return null;
                  }
                  return (
                    <li key={child.url}>
                      <NavLink
                        exact
                        to={child.url}
                        activeClassName="active"
                        onClick={toggleNav}
                      >
                        {child.title}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            )
          }
        </MediaQuery>
      </li>
    );
  }

  static propTypes = {
    toggleNav: PropTypes.func,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
    path: PropTypes.string.isRequired,
  };

  static defaultProps = {
    toggleNav: null,
  };
}
