import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";

import { logOut } from "../../../../actions/";
import { SvgLogOut } from "../../../../svg/";

const Link = ({ actionLogOut }) => (
  <MediaQuery maxWidth={991}>
    {(matches) => (
      <button
        onClick={actionLogOut}
        className={"btn p-0" + (matches ? " text-muted" : "")}
      >
        <SvgLogOut
          width={18}
          height={16}
          className={(matches ? "mr-1 " : "") + "fill-gray"}
        />
        {matches && <span>Выйти</span>}
      </button>
    )}
  </MediaQuery>
);

Link.propTypes = {
  actionLogOut: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actionLogOut: () => dispatch(logOut()),
});

export const LogoutLink = connect(null, mapDispatchToProps)(Link);
