import React from "react";
import PropTypes from "prop-types";

export const Loading = ({ className }) => (
  <div
    className={`loading-box${className ? ` ${className}` : ""}`}
    data-text="Загружаем..."
  >
    <div className="spinner">
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

Loading.propTypes = {
  className: PropTypes.string,
};

Loading.defaultProps = {
  className: null,
};
