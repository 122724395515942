import React from "react";
import PropTypes from "prop-types";

import { baseUrl, fullDate } from "../../libs/";
import { ProfileLink } from "./";

export const RatingAward = ({
  ratingAward,
  ratingAward: { author, comment },
}) => (
  <tr key={`ratingAward-${ratingAward.id}`}>
    <td>{ratingAward.type}</td>
    <td>{ratingAward.amount}</td>
    <td>
      {author && (
        <div>
          <span>
            От <ProfileLink user={author} />
          </span>
          {comment && (
            <span>
              {" за комментарий "}
              <a
                href={`${baseUrl}/posts/${comment.post_id}?branch=${
                  comment.root_id || comment.id
                }#c${comment.id}`}
                title={comment.text_fixed}
                target="_blank"
                rel="noreferrer"
              >
                #{comment.id}
              </a>
              {" в посте "}
              <a
                href={`${baseUrl}/posts/${comment.post_id}`}
                target="_blank"
                rel="noreferrer"
              >
                #{comment.post_id}
              </a>
            </span>
          )}
        </div>
      )}
      {ratingAward.note}
    </td>
    <td>{fullDate(ratingAward.created_at)}</td>
  </tr>
);

RatingAward.propTypes = {
  ratingAward: PropTypes.objectOf(PropTypes.any).isRequired,
};
