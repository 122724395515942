import { DataLayer } from "../../libs/";
import { loadEditorStats } from "../";

export const changeSecretStatus =
  ({ action, formData, secret }) =>
  async (dispatch) => {
    const newState = {
      showPublishConfirmation: false,
      showScheduleConfirmation: false,
      errors: null,
      statusConflict: null,
    };

    await DataLayer.put({
      path: `/secrets/${secret.id}/${action}.json`,
      body: formData,
      loader: true,
    })
      .then((response) => {
        dispatch(loadEditorStats());
        newState.secret = response;
      })
      .catch((error) => {
        if (error.status === 409) {
          newState.statusConflict = { author: error.data.author };
          newState.secret = error.data.savedObject;
        } else {
          newState.errors = error.data
            ? error.data.errors || error.data
            : error.message;
        }
      });

    return newState;
  };
