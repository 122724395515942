import React, { Fragment } from "react";
import PropTypes from "prop-types";

export const SwitchView = ({ current, callback }) => (
  <Fragment>
    <button
      type="button"
      className={"btn btn-link p-0" + (current === "graph" ? " active" : "")}
      name="graph"
      onClick={callback}
    >
      График
    </button>
    {" / "}
    <button
      type="button"
      className={"btn btn-link p-0" + (current === "pieChart" ? " active" : "")}
      name="pieChart"
      onClick={callback}
    >
      Диаграмма
    </button>
  </Fragment>
);

SwitchView.propTypes = {
  current: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};
