import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadUser, loadBans, setPageTitle } from "../../actions/";
import {
  BanButton,
  Loading,
  EmptyList,
  NotFound,
  PageHeader,
} from "../../components/common/";
import { Ban, ProfileLink } from "../../components/users/";

class Bans extends Component {
  state = {
    userId: parseInt(this.props.match.params.userId, 10),
    type: this.props.match.params.type,
    user: null,
    bans: [],
    loading: true,
    loadingError: false,
  };

  componentDidMount() {
    const { userId, type } = this.state;

    this.props.loadUser(userId).then((newState) => {
      if (newState.user) {
        newState.loading = true;
        this.props.loadBans(userId, type).then((e) => this.setState(e));
      }
      this.setState(newState);
    });

    this.props.setPageTitle(
      `Баны ${this.state.type === "made_bans" ? "от " : ""}пользователя`
    );
  }

  componentWillUnmount() {
    this.props.setPageTitle(null);
  }

  render() {
    const { userId, type, user, bans, loading, loadingError } = this.state;
    const { showActions } = this.props;

    if (!user) {
      return (
        <NotFound
          title="Пользователь"
          id={userId}
          loading={loading}
          loadingError={loadingError}
        />
      );
    }

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <div className="bg-white p-2 p-sm-3">
          <PageHeader>
            <h2>
              <ProfileLink user={user} />
              {type !== "made_bans" && user && (
                <h5 className="ml-2 d-inline-block">
                  <BanButton user={user} showUnBanButton />
                </h5>
              )}
            </h2>
          </PageHeader>

          {loading && <Loading />}
          {!loading && !bans.length && <EmptyList />}

          {bans.length > 0 && (
            <table className="table table-responsive table-striped table-sm table-hover">
              <colgroup>
                <col width="150" />
                <col />
                <col />
                <col width="100" />
                <col />
                <col width="140" />
                {showActions && <col width="110" />}
                {showActions && <col width="110" />}
              </colgroup>
              <thead>
                <tr>
                  <th>{type === "made_bans" ? "Забанен" : "Забанил"}</th>
                  <th>Комментарий</th>
                  <th>Заметка</th>
                  <th>Причина</th>
                  <th>Описание для пользователя</th>
                  <th>Дата</th>
                  {showActions && <th />}
                  {showActions && <th />}
                </tr>
              </thead>
              <tbody>
                {bans.map((ban) => (
                  <Ban key={ban.id} ban={ban} type={type} />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }

  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    loadUser: PropTypes.func.isRequired,
    loadBans: PropTypes.func.isRequired,
    showActions: PropTypes.bool.isRequired,
    setPageTitle: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  showActions: state.user.hasAdminAccess,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (userId) => dispatch(loadUser(userId)),
  loadBans: (userId, type) => dispatch(loadBans(userId, type)),
  setPageTitle: (title) => dispatch(setPageTitle(title)),
});

export const UserBans = connect(mapStateToProps, mapDispatchToProps)(Bans);
