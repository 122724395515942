import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadUser, updateUser, setPageTitle } from "../../actions/";
import { BanButton, Loading, LoadingError } from "../../components/common/";
import { baseUrl, fullDate } from "../../libs/";
import { SvgCrown } from "../../svg/";

import {
  ActionsDropdown,
  Form,
  NoteForm,
  Roles,
} from "../../components/users/";
import { Modal } from "../../components/common/";
import MediaQuery from "react-responsive";

class User extends Component {
  state = {
    userId: parseInt(this.props.match.params.id, 10),
    user: {},
    loading: true,
    loadingError: false,
    showResetAvatarConfirmation: false,
  };

  componentDidMount() {
    this.props.load(this.state.userId).then((e) => this.setState(e));
    this.props.setPageTitle(`Пользователь #${this.state.userId}`);
  }

  componentWillUnmount() {
    this.props.setPageTitle(null);
  }

  handleFileSelection = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const FR = new FileReader();
      FR.addEventListener("load", (img) => this.saveAvatar(img.target.result));
      FR.readAsDataURL(file);
    }
  };

  saveAvatar = (img) => {
    const data = { id: this.state.userId, formData: { user: { avatar: img } } };
    this.props
      .update(data)
      .then((response) => this.setState({ user: response }))
      .catch(() => {});
  };

  resetAvatar = (e) => {
    e.preventDefault();
    if (this.state.showResetAvatarConfirmation) {
      const data = {
        id: this.state.userId,
        formData: { user: { reset_avatar: 1 } },
      };
      this.props
        .update(data)
        .then((response) =>
          this.setState({ user: response, showResetAvatarConfirmation: false })
        )
        .catch(() => {});
    } else {
      this.setState({ showResetAvatarConfirmation: true });
    }
  };

  closeResetAvatarConfirmation = () => {
    this.setState({ showResetAvatarConfirmation: false });
  };

  update = (user) => {
    this.setState({ user });
  };

  render() {
    const { user, userId, loading, loadingError, showResetAvatarConfirmation } =
      this.state;
    const { hasEditorAccess } = this.props;
    const { keeperStats, editorStats } = user;
    const removedUserClass = user.removed_at ? " removed-user" : null;

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        {loading && <Loading />}
        {loadingError && <LoadingError />}
        {!loading && !loadingError && !user.id && (
          <div className="alert alert-danger text-center">
            Пользовать #{userId} не найден в базе!
          </div>
        )}
        {user.id && (
          <div className="profile p-2 p-sm-3 px-xl-0">
            <div className="row">
              <div className="col-xl-8 mx-auto">
                <div className="row no-gutters">
                  <div className="col">
                    <h1 className={`mb-md-15 mb-1${removedUserClass}`}>
                      {user.fullname_or_anonymus}
                    </h1>
                    <div className="text-muted">
                      {Roles[user.role]}
                      <span className="mx-1">·</span>
                      {"рейтинг: "}
                      <a
                        href={`${baseUrl}/users/${user.id}/rating_awards`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {user.rating}
                      </a>
                    </div>
                    <div className="mt-md-25 mt-15">
                      <BanButton user={user} showUnBanButton />
                      <ActionsDropdown user={user} callback={this.update} />
                    </div>
                  </div>
                  <div className="col-auto ml-2">
                    <div
                      className={`user-avatar${user.keeper ? " keeper" : ""}`}
                    >
                      <a href={user.avatar} target="_blank" rel="noreferrer">
                        <img
                          src={user.avatar}
                          alt=""
                          width={140}
                          height={140}
                          className="rounded-circle"
                        />
                        {user.vip && (
                          <div className="badge">
                            <SvgCrown width={16} height={16} />
                          </div>
                        )}
                      </a>
                    </div>
                    <div className="mt-1 text-center ">
                      <div className="input-file">
                        <input
                          type="file"
                          onChange={this.handleFileSelection}
                        />
                        <span className="placeholder-simple">Изменить</span>
                      </div>
                      {user.avatar !== user.default_avatar && (
                        <Fragment>
                          <MediaQuery minWidth={576}>
                            {(matches) => (matches ? " / " : <br />)}
                          </MediaQuery>
                          <a href="#" onClick={this.resetAvatar}>
                            Удалить
                          </a>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>

                <NoteForm user={user} callback={this.update} />

                <h3 className="mt-md-6 mb-md-25 mt-4 mb-15">
                  Общая статистика
                </h3>
                <div className="row">
                  <div className="col-sm-3 col-6 mb-2">
                    {hasEditorAccess ? (
                      <div className="h2 text-primary">
                        <a
                          href={`${baseUrl}/users/${user.id}/posts`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {user.post_count}
                        </a>
                        {user.removed_posts_count !== user.post_count && (
                          <Fragment>
                            {" ("}
                            <a
                              href={`${baseUrl}/users/${user.id}/posts/removed`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {user.removed_posts_count}
                            </a>
                            {")"}
                          </Fragment>
                        )}
                        {" / "}
                        <a
                          href={`${baseUrl}/users/${user.id}/secrets`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {user.secret_count}
                        </a>
                      </div>
                    ) : (
                      <div className="h2 text-muted">{user.post_count}</div>
                    )}

                    <div className="text-muted mt-1">Посты</div>
                  </div>
                  <div className="col-sm-3 col-6 mb-2">
                    <div className="h2 text-primary">
                      <a
                        href={`${baseUrl}/users/${user.id}/comments`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {user.commented_count}
                      </a>
                    </div>
                    <div className="text-muted mt-1">Комментарии</div>
                  </div>
                  <div className="col-sm-3 col-6 mb-2">
                    <div className="h2 text-primary">
                      <a
                        href={`${baseUrl}/users/${user.id}/bans`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {user.banned_count}
                      </a>
                      {user.banned_count !== user.bans_count && (
                        <span> ({user.bans_count})</span>
                      )}
                    </div>
                    <div className="text-muted mt-1">Баны</div>
                  </div>
                  <div className="col-sm-3 col-6 mb-2">
                    <div className="h2 text-muted">{user.liked_count}</div>
                    <div className="text-muted mt-1">Лайки</div>
                  </div>
                </div>

                {keeperStats && (
                  <Fragment>
                    <h3 className="mt-md-6 mb-md-25 mt-4 mb-15">
                      Статистика хранителя
                    </h3>
                    <div className="row">
                      <div className="col-sm-3 col-6 mb-2">
                        <div className="h1 text-primary">
                          {keeperStats.recommended}
                        </div>
                        <div className="text-muted mt-1">Рекомендовано</div>
                      </div>
                      <div className="col-sm-3 col-6 mb-2">
                        <div className="h1 text-primary">
                          {keeperStats.published}
                        </div>
                        <div className="text-muted mt-1">Опубликовано</div>
                      </div>
                      <div className="col-sm-3 col-6 mb-2">
                        <div className="h1 text-primary">
                          {keeperStats.last_action
                            ? fullDate(keeperStats.last_action)
                            : "-"}
                        </div>
                        <div className="text-muted mt-1">
                          Последнее действие
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}

                {editorStats && (
                  <Fragment>
                    <h3 className="mt-md-6 mb-md-25 mt-4 mb-15">
                      Статистика редактора
                    </h3>
                    <div className="row">
                      <div className="col-sm-3 col-6 mb-2">
                        <div className="h1 text-primary">
                          <a
                            href={`${baseUrl}/publishers/${user.id}/secrets/added/prev_month`}
                            target="_blank"
                            title="Количество добавленных секретов в прошлом месяце"
                            rel="noreferrer"
                          >
                            {editorStats.prev_month_added}
                          </a>
                          {" / "}
                          <a
                            href={`${baseUrl}/publishers/${user.id}/posts/published/prev_month`}
                            target="_blank"
                            title="Количество опубликованных секретов в прошлом месяце"
                            rel="noreferrer"
                          >
                            {editorStats.prev_month_published}
                          </a>
                        </div>
                        <div className="text-muted mt-1">
                          Добавлено/опубликовано в прошлом месяце
                        </div>
                      </div>
                      <div className="col-sm-3 col-6 mb-2">
                        <div className="h1 text-primary">
                          <a
                            href={`${baseUrl}/publishers/${user.id}/secrets/added/this_month`}
                            target="_blank"
                            title="Количество добавленных секретов в текущем месяце"
                            rel="noreferrer"
                          >
                            {editorStats.this_month_added}
                          </a>
                          {" / "}
                          <a
                            href={`${baseUrl}/publishers/${user.id}/posts/published/this_month`}
                            target="_blank"
                            title="Количество опубликованных секретов в текущем месяце"
                            rel="noreferrer"
                          >
                            {editorStats.this_month_published}
                          </a>
                          {" ("}
                          <a
                            href={`${baseUrl}/publishers/${user.id}/secrets/scheduled/this_month`}
                            target="_blank"
                            title="Количество секретов, запланированных на публикацию в текущем месяце"
                            rel="noreferrer"
                          >
                            {editorStats.this_month_scheduled}
                          </a>
                          )
                        </div>
                        <div className="text-muted mt-1">
                          Добавлено/опубликовано в текущем месяце
                        </div>
                      </div>
                      <div className="col-sm-3 col-6 mb-2">
                        <div className="h1 text-primary">
                          <a
                            href={`${baseUrl}/publishers/${user.id}/secrets/scheduled/next_month`}
                            target="_blank"
                            title="Количество секретов, запланированных на публикацию в следующем месяце"
                            rel="noreferrer"
                          >
                            {editorStats.next_month_scheduled}
                          </a>
                        </div>
                        <div className="text-muted mt-1">
                          Запланировано на следующий месяц
                        </div>
                      </div>
                      <div className="col-sm-3 col-6 mb-2">
                        <div className="h1 text-muted">
                          <a
                            href={`${baseUrl}/publishers/${user.id}/posts/published`}
                            target="_blank"
                            title="Общее количество опубликованных секретов"
                            rel="noreferrer"
                          >
                            {editorStats.all_time_published}
                          </a>
                          (
                          <a
                            href={`${baseUrl}/publishers/${user.id}/posts/removed`}
                            target="_blank"
                            title="Общее количество удаленных секретов"
                            rel="noreferrer"
                          >
                            {editorStats.all_time_removed}
                          </a>
                          )
                        </div>
                        <div className="text-muted mt-1">
                          Всего опубликовано/удалено
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}

                <Form user={user} callback={this.update} />

                {showResetAvatarConfirmation && (
                  <Modal
                    title="Подтверждение"
                    toggleModal={this.closeResetAvatarConfirmation}
                  >
                    <div>
                      <div>
                        Вы действительно хотите сбросить аватар пользователя на
                        дефолтный?
                      </div>
                      <div className="mt-25 text-right">
                        <button
                          type="button"
                          onClick={this.closeResetAvatarConfirmation}
                          className="btn btn-primary mr-15"
                        >
                          Нет
                        </button>
                        <button
                          type="button"
                          onClick={this.resetAvatar}
                          className="btn btn-secondary"
                        >
                          Да
                        </button>
                      </div>
                    </div>
                  </Modal>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    load: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    hasEditorAccess: PropTypes.bool.isRequired,
    setPageTitle: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  hasEditorAccess: state.user.hasEditorAccess,
});

const mapDispatchToProps = (dispatch) => ({
  load: (id) => dispatch(loadUser(id)),
  update: (data) => dispatch(updateUser(data)),
  setPageTitle: (title) => dispatch(setPageTitle(title)),
});

export const UserShow = connect(mapStateToProps, mapDispatchToProps)(User);
