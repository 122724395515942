import { DataLayer } from "../../libs/";

export const loadRatingAwards = (id) => async (_, getState) => {
  const { type, page } = getState().filters.ratingAwards;
  const params = [];
  const newState = {
    loading: false,
    loadingError: false,
  };

  if (type) params.push(`type=${type}`);
  if (page > 1) params.push(`page=${page}`);

  await DataLayer.get({
    path:
      "/users/" +
      id +
      "/rating_awards.json" +
      (params.length ? "?" + params.join("&") : ""),
  })
    .then((response) => {
      newState.ratingAwards = response.rating_awards;
      newState.pages = response.pages;
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
