import _ from "lodash";

import { DataLayer } from "../../libs/";

export const loadComments =
  ({ comments = [], bans = [] }) =>
  async (__, getState) => {
    const { type, category, user, action, excludePost, searchQuery } =
      getState().filters.comments;
    const params = [];
    const newState = {
      loading: false,
      loadingError: false,
    };

    if (category) {
      params.push(`category_id=${category}`);
    }
    if (user) {
      params.push(`user=${user}`);
    }
    if (action) {
      params.push(`user_action=${action}`);
    }
    if (excludePost) {
      params.push(`exclude_post=${excludePost}`);
    }
    if (searchQuery) {
      params.push(`text=${searchQuery}`);
    }
    if (comments.length > 0) {
      params.push(`from=${_.last(comments).id}`);
    }
    if (bans.length > 0) {
      params.push(`from=${_.last(bans).id}`);
    }

    await DataLayer.get({
      path:
        "/comments/" +
        type +
        ".json" +
        (params.length ? "?" + params.join("&") : ""),
    })
      .then((json) => {
        if (action !== "ban" && json.comments.length > 0) {
          newState.comments = [...comments, ...json.comments];
        } else if (action === "ban" && json.bans.length > 0) {
          newState.bans = [...bans, ...json.bans];
        } else {
          newState.end = true;
        }
      })
      .catch(() => {
        newState.loadingError = true;
      });

    return newState;
  };
