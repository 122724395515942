import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import { createCity, updateCity } from "../../actions/";
import { SvgEdit, SvgHide, SvgShow } from "../../svg/";
import { CityForm } from "./";

class _City extends Component {
  state = { showForm: false };

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  save = (city) => {
    this[city.id ? "update" : "create"](city);
  };

  create = (city) => {
    this.props.create(city).then((cities) => {
      this.toggleForm();
      this.props.saveCallback(cities);
    });
  };

  update = (city) => {
    this.props.update(city).then((cities) => {
      this.toggleForm();
      this.props.saveCallback(cities);
    });
  };

  toggleHidden = () => {
    const city = { ...this.props.city };
    city.hidden = !city.hidden;

    this.props.update(city).then((cities) => {
      this.props.saveCallback(cities);
    });
  };

  render() {
    const { showForm } = this.state;
    const { city, countries } = this.props;
    const countryName = _.find(countries, ["id", city.country_id]).name;

    if (showForm) {
      return (
        <CityForm
          city={city}
          close={this.toggleForm}
          countries={countries}
          saveCallback={this.save}
        />
      );
    }

    return (
      <CityRow
        city={city}
        countryName={countryName}
        onEditClick={this.toggleForm}
        onToggleClick={this.toggleHidden}
      />
    );
  }

  static propTypes = {
    city: PropTypes.objectOf(PropTypes.any).isRequired,
    countries: PropTypes.arrayOf(PropTypes.object).isRequired,
    saveCallback: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createCity(data)),
  update: (data) => dispatch(updateCity(data)),
});

export const City = connect(null, mapDispatchToProps)(_City);

const CityRow = ({ city, countryName, onEditClick, onToggleClick }) => (
  <tr key={city.id}>
    <td className="align-middle text-center">
      {city.position > 0 && city.position}
    </td>
    <td className="align-middle">{city.name}</td>
    <td className="align-middle">{city.region}</td>
    <td className="align-middle">{countryName}</td>
    <td className="align-middle text-center">
      {city.users_count > 0 && city.users_count}
    </td>
    <td className="text-right action-buttons">
      {city.hidden ? (
        <button
          onClick={onToggleClick}
          className="btn btn-sm btn-success"
          title="Восстановить"
        >
          <SvgShow width={14} height={12} className="fill-white" />
        </button>
      ) : (
        <button
          onClick={onToggleClick}
          className="btn btn-sm btn-danger"
          title="Скрыть"
        >
          <SvgHide width={14} height={12} className="fill-white" />
        </button>
      )}
      <button
        onClick={onEditClick}
        className="btn btn-sm btn-primary ml-1"
        title="Редактировать"
      >
        <SvgEdit width={14} height={12} className="fill-white" />
      </button>
    </td>
  </tr>
);

CityRow.propTypes = {
  city: PropTypes.object.isRequired,
  countryName: PropTypes.string.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onToggleClick: PropTypes.func.isRequired,
};
