import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { toggleQueryParam } from "../../actions/";

const Filters = ({ filters: { bannedOnly }, toggleQueryParam }) => {
  return (
    <div className="block-sorting mb-4">
      <h3>Фильтрация</h3>

      <div className="row justify-content-between mb-2">
        <div className="col">Только забаненные</div>
        <div className="col-auto">
          <label className="custom-checkbox-switcher">
            <input
              type="checkbox"
              name="banned_only"
              checked={bannedOnly}
              onChange={(e) =>
                toggleQueryParam({
                  name: "banned_only",
                  show: e.target.checked,
                  value: "yes",
                })
              }
            />
            <span className="brown" />
          </label>
        </div>
      </div>
    </div>
  );
};

Filters.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleQueryParam: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.filters.social_accounts,
});

const mapDispatchToProps = (dispatch) => ({
  toggleQueryParam: (data) => dispatch(toggleQueryParam(data)),
});

export const SocialAccountFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters);
