import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { toggleSocialAccountBan } from "../../actions/";
import { fullDate } from "../../libs/";
import { SocialAccountLogs } from "./";

class Row extends Component {
  state = {
    account: this.props.account,
    showLogs: this.props.searchQuery,
  };

  toggleBan = () => {
    const {
      account: { id, banned },
    } = this.state;
    this.props
      .toggleBan({ id, banned: !banned })
      .then((account) => {
        this.setState({ account });
      })
      .catch(() => {});
  };

  toggleLogs = (e) => {
    e.preventDefault();
    this.setState({ showLogs: !this.state.showLogs });
  };

  render() {
    const { account, showLogs } = this.state;
    const type = account.type_human !== "email" ? `${account.type_human}:` : "";
    let uid = account.uid;
    if (account.type_human === "dt" && uid.length > 15) {
      uid = uid.slice(0, 8) + "..." + uid.slice(-8, uid.length);
    }

    return (
      <>
        <tr>
          <td>
            <a href="" onClick={this.toggleLogs}>
              {type}
              {uid}
            </a>
            <p>{account.account_name}</p>
          </td>
          <td className="text-center">{fullDate(account.created_at)}</td>
          <td>
            {account.type_human !== "dt" ? (
              <button
                onClick={this.toggleBan}
                className={`btn btn-sm ${
                  account.banned ? "btn-success" : "btn-secondary"
                } btn-block`}
              >
                {account.banned ? "Разбанить" : "Забанить"}
              </button>
            ) : (
              <a
                href={`?text=${account.uid}`}
                className="btn_btn-link"
                target="_blank"
                rel="noreferrer"
              >
                Искать по токену
              </a>
            )}
          </td>
        </tr>

        {!!showLogs && (
          <tr>
            <td colSpan={3}>
              <SocialAccountLogs logs={account.social_account_logs} />
            </td>
          </tr>
        )}
      </>
    );
  }

  static propTypes = {
    account: PropTypes.objectOf(PropTypes.any).isRequired,
    searchQuery: PropTypes.string,
  };
}

const mapStateToProps = (state) => ({
  searchQuery: state.filters.searchQuery,
});

const mapDispatchToProps = (dispatch) => ({
  toggleBan: (data) => dispatch(toggleSocialAccountBan(data)),
});

export const SocialAccountRow = connect(
  mapStateToProps,
  mapDispatchToProps
)(Row);
