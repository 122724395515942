import React from "react";

import Settings from "./settings";

export class CommentSettings extends Settings {
  save = (type) => {
    const data = { ...this.state.data };
    const settings = {
      comment_block_words: data.comment_block_words,
      bad_feed_words: data.bad_feed_words,
      complain_feed_words: data.complain_feed_words,
    };
    delete data.comment_block_words;
    delete data.bad_feed_words;
    delete data.complain_feed_words;

    settings[type] = data;
    this.props.saveCallback(settings);
  };

  render() {
    return (
      <div className="settings-item bg-white p-2">
        <div className="row align-items-center mb-4">
          <h5 className="col">Комментарии</h5>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Фильтр, блокирующий создание комментариев каждая строка - это
            отдельный фильтр (слова или фразы через запятую)
          </div>
          <div className="col-sm-7 col-12">
            {this.textarea("comment_block_words")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Фильтр слов для <b>&quot;Ленты Bad&quot;</b> (слова без пробелов
            через запятую)
          </div>
          <div className="col-sm-7 col-12">
            {this.textarea("bad_feed_words")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Фильтр слов для <b>&quot;Ленты жалоб&quot;</b> (слова без пробелов
            через запятую)
          </div>
          <div className="col-sm-7 col-12">
            {this.textarea("complain_feed_words")}
          </div>
        </div>

        <div className="row align-items-center mt-3">
          <div className="col">
            Рейтинг, при котором комментарий появляется в{" "}
            <b>&quot;Ленте топ комментариев&quot;</b>
          </div>
          <div className="col-sm-5 col-6">
            {this.input("top_comment_feed_rating", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Рейтинг (+), при котором комментарий появляется в{" "}
            <b>&quot;Ленте жалоб&quot;</b>
          </div>
          <div className="col-sm-5 col-6">
            {this.input("complain_feed_rating", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Рейтинг (-), при котором комментарий появляется в{" "}
            <b>&quot;Ленте жалоб&quot;</b>
          </div>
          <div className="col-sm-5 col-6">
            {this.input("complain_feed_negative_rating", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Кол-во жалоб, при котором комментарий появляется в{" "}
            <b>&quot;Ленте жалоб&quot;</b>
          </div>
          <div className="col-sm-5 col-6">
            {this.input("complain_feed_complains", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Кол-во жалоб, при котором комментарий появляется в{" "}
            <b>&quot;Ленте удаленных комментариев&quot;</b>
          </div>
          <div className="col-sm-5 col-6">
            {this.input("deleted_comment_feed_complains", "integer")}
          </div>
        </div>

        <h5 className="mt-5">
          Условия для попадания комментария в различные ленты:
        </h5>

        <div>
          <ul>
            <li>
              <b>Топ комментариев</b>
              <ul>
                <li>
                  рейтинг &gt;= Рейтинга, при котором комментарий появляется в
                  &quot;Ленте топ комментариев&quot;
                </li>
              </ul>
            </li>
            <li>
              <b>Bad</b> (хотя бы 1 условие)
              <ul>
                <li>
                  текст содержит слова из Фильтра слов для &quot;Ленты Bad&quot;
                </li>
                <li>
                  кол-во жалоб &gt;= Кол-ву жалоб, при котором комментарий
                  появляется в &quot;Ленте жалоб&quot;
                </li>
              </ul>
            </li>
            <li>
              <b>Лента жалоб</b> (хотя бы 1 условие)
              <ul>
                <li>
                  текст содержит слова из Фильтра слов для &quot;Ленты
                  жалоб&quot;
                </li>
                <li>
                  кол-во жалоб &gt;= Кол-ву жалоб, при котором комментарий
                  появляется в &quot;Ленте жалоб&quot;
                </li>
                <li>
                  рейтинг &gt;= положительному рейтингу, при котором комментарий
                  появляется в &quot;Ленте жалоб&quot;
                </li>
                <li>
                  рейтинг &lt;= отрицательному рейтингу, при котором комментарий
                  появляется в &quot;Ленте жалоб&quot;
                </li>
              </ul>
            </li>
            <li>
              <b>Удаленные комментарии</b>
              <ul>
                <li>
                  кол-во жалоб &gt;= Кол-во жалоб, при котором комментарий
                  появляется в &quot;Ленте удаленных комментариев&quot;
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="mt-7 text-center">
          <button
            className="btn btn-primary"
            onClick={() => this.save("comments")}
          >
            Сохранить
          </button>
        </div>
      </div>
    );
  }
}
