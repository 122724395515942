import { DataLayer } from "../../libs/";

export const publishVkScheduledSecret = (vkSecret) => async () => {
  const newState = {};

  await DataLayer.post({
    path: "/secrets/vk_scheduled/" + vkSecret.id + "/publish.json",
    loader: true,
  })
    .then((response) => {
      newState.vkSecret = response;
    })
    .catch((error) => {
      newState.showPublishConfirmation = false;
      newState.errors = error.data
        ? error.data.errors || error.data
        : error.message;
    });

  return newState;
};
