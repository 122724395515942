import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { DateFilters, Graph, PieChart, SwitchView } from "../_common";

const sourceLabels = {
  all_visits: "Всего просмотров",
  level1_visits: "Просмотры (ранг 1)",
  significant_visits: "Просмотры (ранг 2+)",
  level1_users: "Пользователи (ранг 1)",
  significant_users: "Пользователи (ранг 2+)",
  votes: "Оценки",
  good_votes: "Положительные оценки",
  bad_votes: "Отрицательные оценки",
};

export class UndersideVisits extends Component {
  state = {
    tab: "graph",
  };

  switchTab = (e) => {
    this.setState({ tab: e.target.name });
  };

  render() {
    const { tab } = this.state;
    const { data, className } = this.props;

    return (
      <div className={className}>
        <div className="row justify-content-between align-items-baseline">
          <div className="col-auto">
            <h3 className="mb-1">Просмотры изнанки</h3>
          </div>
          <div className="col-auto">
            <SwitchView current={tab} callback={this.switchTab} />
          </div>
        </div>

        <DateFilters data={data} showGroups={tab === "graph"}>
          {tab === "graph" ? (
            <Graph id="underside-graph" labels={sourceLabels} />
          ) : (
            ({ data }) => [
              <PieChart
                key="visits"
                id="underside-visits-pie-chart"
                data={{
                  ...data,
                  series: _.filter(data.series, ({ name }) =>
                    ["level1_visits", "significant_visits"].includes(name)
                  ),
                }}
                labels={sourceLabels}
                title="Просмотры"
              />,
              <PieChart
                key="votes"
                id="underside-votes-pie-chart"
                data={{
                  ...data,
                  series: _.filter(data.series, ({ name }) =>
                    ["good_votes", "bad_votes"].includes(name)
                  ),
                }}
                labels={sourceLabels}
                title="Оценки"
              />,
              <PieChart
                key="users"
                id="underside-users-pie-chart"
                data={{
                  ...data,
                  series: _.filter(data.series, ({ name }) =>
                    ["level1_users", "significant_users"].includes(name)
                  ),
                }}
                labels={sourceLabels}
                title="Пользователи"
              />,
            ]
          )}
        </DateFilters>
      </div>
    );
  }

  static propTypes = {
    data: PropTypes.shape({
      dates: PropTypes.arrayOf(PropTypes.string),
      series: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };
}
