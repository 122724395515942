import { ReactComponent as SvgAndroid } from "./icon-android.svg";
import { ReactComponent as SvgIos } from "./icon-ios.svg";
import { ReactComponent as SvgWindows } from "./icon-windows.svg";
import { ReactComponent as SvgHuawei } from "./icon-huawei.svg";
import { ReactComponent as SvgRustore } from "./icon-rustore.svg";
import { ReactComponent as SvgSite } from "./icon-site.svg";

export { ReactComponent as SvgArchive } from "./icon-archive.svg";
export { ReactComponent as SvgBookmark } from "./icon-bookmark.svg";
export { ReactComponent as SvgCheck } from "./icon-check.svg";
export { ReactComponent as SvgComment } from "./icon-comment.svg";
export { ReactComponent as SvgCross } from "./icon-cross.svg";
export { ReactComponent as SvgCrown } from "./icon-crown.svg";
export { ReactComponent as SvgDots } from "./icon-dots.svg";
export { ReactComponent as SvgDown } from "./icon-down.svg";
export { ReactComponent as SvgEye } from "./icon-eye.svg";
export { ReactComponent as SvgHeart } from "./icon-heart.svg";
export { ReactComponent as SvgLogOut } from "./icon-log-out.svg";
export { ReactComponent as SvgMenu } from "./icon-menu.svg";
export { ReactComponent as SvgNext } from "./icon-next.svg";
export { ReactComponent as SvgPlus } from "./icon-plus.svg";
export { ReactComponent as SvgPrev } from "./icon-prev.svg";
export { ReactComponent as SvgSearch } from "./icon-search.svg";
export { ReactComponent as SvgBan } from "./icon-ban.svg";
export { ReactComponent as SvgHide } from "./icon-hide.svg";
export { ReactComponent as SvgEdit } from "./icon-edit.svg";
export { ReactComponent as SvgShow } from "./icon-show.svg";
export { ReactComponent as SvgClock } from "./icon-clock.svg";
export { ReactComponent as SvgRemove } from "./icon-remove.svg";

export const SvgDevices = {
  ios: SvgIos,
  android: SvgAndroid,
  windows: SvgWindows,
  huawei: SvgHuawei,
  rustore: SvgRustore,
  site: SvgSite,
  null: SvgIos,
};
