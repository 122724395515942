import { DataLayer } from "../../libs/";

export const createPushMessage =
  ({ formData }) =>
  async () => {
    let errors = null;

    await DataLayer.post({
      path: "/push_messages.json",
      body: formData,
      loader: true,
    })
      .then((response) => {
        if (!response.secret_valid) {
          errors = { "Секретное слово": "неверно" };
        }
      })
      .catch((error) => {
        errors = error.data ? error.data.errors || error.data : error.message;
      });

    return errors;
  };
