import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import _ from "lodash";
import { schemeCategory10 } from "d3-scale";
import { Chart, Pies, Layer, Transform } from "rumble-charts";

export class PieChart extends Component {
  componentDidMount() {
    this.setMouseEventsOnTableRows();
  }

  componentDidUpdate() {
    this.setMouseEventsOnTableRows();
  }

  setMouseEventsOnTableRows = () => {
    const { id } = this.props;

    [...document.querySelectorAll(`#${id}-table tbody tr`)].forEach((tr, i) => {
      tr.addEventListener("mouseenter", (e) => {
        this.changeAllOpacity("fade");
        e.target.style.opacity = 1;
        document.querySelector(
          `.${id}-pies-series-${i} path`
        ).style.opacity = 1;
      });

      tr.addEventListener("mouseleave", () => {
        this.changeAllOpacity("highlight");
      });
    });
  };

  pieAttributes = ({ seriesIndex }) => {
    const { id } = this.props;

    return {
      onMouseEnter: (e) => {
        this.changeAllOpacity("fade");
        e.target.style.opacity = 1;
        document.querySelector(
          `#${id}-table tbody tr:nth-child(${seriesIndex + 1})`
        ).style.opacity = 1;
      },
      onMouseLeave: () => this.changeAllOpacity("highlight"),
    };
  };

  changeAllOpacity = (action) => {
    const { id } = this.props;
    const opacity = action === "fade" ? 0.5 : 1;
    [...document.querySelectorAll(`.${id}-pies path`)].forEach((path) => {
      path.style.opacity = opacity;
    });
    [...document.querySelectorAll(`#${id}-table tbody tr`)].forEach((tr) => {
      tr.style.opacity = opacity;
    });
  };

  render() {
    const { id, data, labels, title } = this.props;
    const series = _.orderBy(
      data.series.map(({ name, data }) => {
        return { name, data: [data.reduce((sum, i) => sum + i, 0)] };
      }),
      (o) => o.data[0],
      "desc"
    );
    const total = series.reduce((acc, i) => acc + i.data[0], 0);

    return (
      <div className="row">
        <div className="col-md-6 col-sm-12">
          {title && <h5 className="mt-3 text-center">{title}</h5>}
          <table id={`${id}-table`} className="table table-sm mt-3">
            <thead>
              <tr>
                <th>Название</th>
                <th className="pl-1">Количество</th>
              </tr>
            </thead>
            <tbody>
              {series.map(({ name, data }, i) => (
                <tr key={name}>
                  <td>
                    <div
                      className="pie-chart-stat"
                      style={{ backgroundColor: schemeCategory10[i] }}
                    />
                    {labels[name] || name}
                  </td>
                  <td>
                    {data[0]}
                    {total > 0 &&
                      ` (${Math.round((data[0] * 10000) / total) / 100}%)`}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr key="total">
                <td>Всего</td>
                <td>{total}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="col-md-6 col-sm-12 text-center text-md-right">
          <MediaQuery maxWidth={991}>
            {(matches) => {
              const width = matches ? 300 : 400;
              const height = matches ? 300 : 400;
              return (
                <Chart
                  id={id}
                  width={width}
                  height={height}
                  series={series}
                  minY={0}
                >
                  <Layer width={width - 60} heigth={height - 60}>
                    <Transform method="stack">
                      <Pies
                        className={`${id}-pies`}
                        colors="category10"
                        combined
                        innerRadius="30%"
                        padAngle={0.025}
                        pieAttributes={this.pieAttributes}
                      />
                    </Transform>
                  </Layer>
                </Chart>
              );
            }}
          </MediaQuery>
        </div>
      </div>
    );
  }

  static propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.shape({
      dates: PropTypes.arrayOf(PropTypes.string),
      series: PropTypes.arrayOf(PropTypes.object),
    }),
    labels: PropTypes.objectOf(PropTypes.string),
    title: PropTypes.string,
  };

  static defaultProps = {
    data: {
      dates: [],
      series: [],
    },
    labels: {},
  };
}
