import React from "react";
import { Link } from "react-router-dom";

import { logo } from "../../../images/";
import { baseUrl } from "../../../libs/";

export const Logo = () => (
  <Link to={baseUrl} className="logo">
    <img src={logo} alt="" width={30} height={30} />
  </Link>
);
