const reducer = (state = [], action) => {
  switch (action.type) {
    case "SET_BAD_FEED_WORDS":
      return action.payload;

    case "LOG_OUT":
      return "";

    default:
      return state;
  }
};

export default reducer;
