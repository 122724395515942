import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { BtnDropdown } from "./";
import { setQueryParam } from "../../actions";

const MAPPING = {
  simple: { title: "Категория", paramName: "category_id" },
  include: { title: "Включить категории", paramName: "include_categories" },
  exclude: { title: "Исключить категории", paramName: "exclude_categories" },
};

const TITLE_ANY = "Любая";

const CategoryDropdown = ({
  categories,
  setQueryParam,
  className = "mb-2",
  selected,
  mode = "simple",
}) => {
  const getName = () => {
    if (!categories) return TITLE_ANY;
    const category = categories.find(
      (el) => el.id === parseInt(selected[mode], 10)
    );
    return category ? category.name : TITLE_ANY;
  };

  const changeCategory = (e, value) => {
    e.preventDefault();
    setQueryParam({ name: MAPPING[mode].paramName, value, defaultValue: "" });
  };

  return (
    <div className={`row ${className}`}>
      <div className="col">{MAPPING[mode].title}</div>

      <div className="col-auto">
        <BtnDropdown btnClass="btn btn-link p-0" hasIcon btnText={getName()}>
          <ul className="dropdown">
            <li>
              <a href="" onClick={(e) => changeCategory(e, "")}>
                {TITLE_ANY}
              </a>
            </li>

            {categories.map((category) => (
              <li key={category.id}>
                <a href="" onClick={(e) => changeCategory(e, category.id)}>
                  {category.name}
                </a>
              </li>
            ))}
          </ul>
        </BtnDropdown>
      </div>
    </div>
  );
};

CategoryDropdown.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  setQueryParam: PropTypes.func.isRequired,
  className: PropTypes.string,
  selected: PropTypes.shape({
    simple: PropTypes.number,
    include: PropTypes.string,
    exclude: PropTypes.string,
  }),
  mode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  categories: state.categories,
  selected: {
    simple: state.filters.category,
    include: state.filters.includeCategories,
    exclude: state.filters.excludeCategories,
  },
});

const mapDispatchToProps = (dispatch) => ({
  setQueryParam: (data) => dispatch(setQueryParam(data)),
});

export const CategoryFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryDropdown);
