import { diff_match_patch as DiffMatchPatch } from "diff_match_patch";

export const highlightSearched = (text, search) => {
  if (!text || text.length === 0) return "";
  if (!search || search.length === 0) return text;

  if (search.match(/^"(.*)"$/)) {
    search = search.replace(/^"(.*)"$/, "$1");
    const regexp = new RegExp(`([^\\Bа-яА-Я])(${search})([^\\Bа-яА-Я])`, "ig");
    return text.replace(
      regexp,
      (_, m1, m2, m3) => `${m1}<span class="searched-text">${m2}</span>${m3}`
    );
  } else {
    const regexp = new RegExp(search, "ig");
    return text.replace(
      regexp,
      (match) => `<span class="searched-text">${match}</span>`
    );
  }
};

export const highlightBlackListWords = (text, words) => {
  if (!text || text.length === 0) return "";
  if (!words || words.length === 0) return text;
  const regexp = new RegExp(words.map((el) => `(${el})`).join("|"), "ig");
  return text.replace(
    regexp,
    (match) => `<span class="bad-words">${match}</span>`
  );
};

export const highlightDiff = (from, to) => {
  const dmp = new DiffMatchPatch();
  const diff = dmp.diff_main(from || "", to || "");
  dmp.diff_cleanupSemantic(diff);
  return dmp
    .diff_prettyHtml(diff)
    .replace(new RegExp("background:#E6FFE6", "g"), "background:#7dfb7d")
    .replace(new RegExp("background:#FFE6E6", "g"), "background:#fffb70");
};
