export const pluralize = (n, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    n % 100 > 4 && n % 100 < 20 ? 2 : cases[n % 10 < 5 ? n % 10 : 5]
  ];
  // usage example: {pluralize(count, ['1шт', '4шт', '5шт'])}
};

export const pluralizeCharacters = (n) =>
  `${n} ${pluralize(n, ["символ", "символа", "символов"])}`;
