import { DataLayer } from "../../libs/";

export const sendBySimulator =
  ({ formData }) =>
  () =>
    DataLayer.post({
      path: "/push_messages/simulator.json",
      body: formData,
      loader: true,
    });
