import React from "react";
import PropTypes from "prop-types";

export const LoadingError = ({ retry }) => (
  <div className="alert alert-danger text-center">
    <p>Произошла ошибка загрузки данных.</p>
    <button
      className="btn btn-danger btn-sm"
      onClick={(e) => {
        e.preventDefault();
        return retry ? retry() : window.location.reload();
      }}
    >
      Попробовать ещё раз
    </button>
  </div>
);

LoadingError.propTypes = {
  retry: PropTypes.func,
};

LoadingError.defaultProps = {
  retry: null,
};
