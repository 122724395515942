import { DataLayer } from "../../libs/";

const MAX_TRIES = 2;

const doRequest = async (id) => {
  const result = {
    data: {},
    error: null,
  };

  await DataLayer.put({ path: "/secrets/" + id + "/check_uniqueness.json" })
    .then((response) => {
      result.data = response.uniqueness;
    })
    .catch((error) => {
      result.error = error.data
        ? error.data.errors || error.data
        : error.message;
    });

  return result;
};

export const checkUniqueness = (id) => async () => {
  const newState = {
    processing: false,
    error: null,
  };

  let tries = 0;
  while (tries < MAX_TRIES) {
    tries += 1;
    await doRequest(id).then((result) => {
      newState.data = result.data;
      newState.error = result.error;
    });

    if (!newState.error || !newState.error.includes("Code: 181,")) {
      break;
    }
  }

  return newState;
};
