import { DataLayer } from "../../libs/";

export const removeAccount = (id) => async () => {
  const newState = {};

  try {
    newState.user = await DataLayer.put({
      path: `/users/${id}.json`,
      method: "delete",
      loader: true,
    });
  } catch (err) {
    console.log(err);
  }

  return newState;
};
