import React, { Component } from "react";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";
import { connect } from "react-redux";

import { loadUser, loadUserPosts, setPageTitle } from "../../actions/";
import {
  Loading,
  LoadingError,
  EmptyList,
  EndOfList,
  NotFound,
  PageHeader,
  ResponsiveSidebar,
} from "../../components/common/";
import { PostFilters, PostListItem } from "../../components/posts/";
import { ProfileLink } from "../../components/users";

class Posts extends Component {
  state = {
    userId: parseInt(this.props.match.params.userId, 10),
    user: null,
    posts: [],
    end: false,
    loading: true,
    loadingError: false,
  };

  componentDidMount() {
    this.props.loadUser(this.state.userId).then((e) => this.setState(e));
    this.props.setPageTitle(
      `${this.props.filters.removed ? "Удалённые посты" : "Посты"} пользователя`
    );
  }

  componentWillUnmount() {
    this.props.setPageTitle(null);
  }

  loadPosts = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }

    const { userId, posts } = this.state;
    this.props.loadPosts({ userId, posts }).then((e) => this.setState(e));
  };

  render() {
    const { userId, user, posts, end, loading, loadingError } = this.state;

    if (!user) {
      return (
        <NotFound
          title="Пользователь"
          id={userId}
          loading={loading}
          loadingError={loadingError}
        />
      );
    }

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <PageHeader>
          <h2>
            <ProfileLink user={user} />
          </h2>
        </PageHeader>

        <div className="row p-static">
          <ResponsiveSidebar mobileTitle="Показать фильтры">
            <PostFilters />
          </ResponsiveSidebar>

          <div className="col-lg-8 col-12 order-lg-1">
            {posts.map((post) => (
              <PostListItem key={`post_${post.id}`} post={post} />
            ))}

            {!end && !loadingError && (
              <VisibilitySensor
                onChange={(isVisible) => isVisible && this.loadPosts()}
                children={<div className="visibility_sensor" />}
              />
            )}
            {loading && <Loading />}
            {loadingError && <LoadingError retry={this.loadPosts} />}
            {!loading && !loadingError && !posts[0] && <EmptyList />}
            {end && <EndOfList />}
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    loadUser: PropTypes.func.isRequired,
    loadPosts: PropTypes.func.isRequired,
    setPageTitle: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.posts,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (userId) => dispatch(loadUser(userId)),
  loadPosts: (data) => dispatch(loadUserPosts(data)),
  setPageTitle: (title) => dispatch(setPageTitle(title)),
});

export const UserPosts = connect(mapStateToProps, mapDispatchToProps)(Posts);
