import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";
import _ from "lodash";

import { loadUsers, loadUsersStats } from "../../actions/";
import {
  PageHeader,
  Loading,
  LoadingError,
  Pagination,
  EmptyList,
  SortingSelect,
} from "../../components/common/";
import { UserList, UserListMobile, UserStats } from "../../components/users/";

class List extends Component {
  state = {
    users: [],
    usersRequestId: null,
    stats: {},
    loading: true,
    loadingError: false,
    pages: 0,
    filters: this.props.filters,
  };

  componentDidMount() {
    this.props.load().then((newState) => {
      if (this.state.usersRequestId === null) {
        this.setState(newState);
      }
    });
    if (this.props.hasAdminAccess) {
      this.props.loadStats().then((e) => this.setState(e));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !_.isEqual(prevState.filters, this.state.filters) &&
      prevState.usersRequestId !== this.state.usersRequestId
    ) {
      const { usersRequestId } = this.state;
      this.props.load().then((newState) => {
        if (this.state.usersRequestId === usersRequestId) {
          this.setState(newState);
        }
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.filters, state.filters)) {
      return {
        filters: props.filters,
        loading: true,
        loadingError: false,
        users: [],
        usersRequestId: Date.now(),
      };
    }
    return null;
  }

  render() {
    const { users, stats, loading, loadingError, pages } = this.state;
    const showEditorStats = ["admins", "editors"].includes(
      this.props.filters.type
    );

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <PageHeader counter={stats.menu_counters} />

        <div className="users">
          {stats.users && <UserStats stats={stats.users} />}

          <MediaQuery maxWidth={767}>
            {(matches) => <Pagination total={pages} small={matches} />}
          </MediaQuery>

          {loading && <Loading className="mb-3" />}
          {loadingError && <LoadingError />}
          {!loading && !loadingError && !users.length && <EmptyList />}

          {users.length > 0 && (
            <Fragment>
              <MediaQuery maxWidth={991}>
                <div className="data mt-3">
                  <SortingSelect />
                </div>
              </MediaQuery>

              <div className="data mt-3">
                <MediaQuery minWidth={992}>
                  {(matches) => {
                    if (matches) {
                      return (
                        <UserList
                          users={users}
                          showEditorStats={showEditorStats}
                        />
                      );
                    }
                    return (
                      <UserListMobile
                        users={users}
                        showEditorStats={showEditorStats}
                      />
                    );
                  }}
                </MediaQuery>
              </div>
            </Fragment>
          )}

          <MediaQuery maxWidth={767}>
            {(matches) => <Pagination total={pages} small={matches} />}
          </MediaQuery>
        </div>
      </div>
    );
  }

  static propTypes = {
    load: PropTypes.func.isRequired,
    loadStats: PropTypes.func.isRequired,
    hasAdminAccess: PropTypes.bool.isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
  };
}

const mapStateToProps = (state) => ({
  hasAdminAccess: state.user.hasAdminAccess,
  filters: state.filters.users,
});

const mapDispatchToProps = (dispatch) => ({
  load: () => dispatch(loadUsers()),
  loadStats: () => dispatch(loadUsersStats()),
});

export const UserIndex = connect(mapStateToProps, mapDispatchToProps)(List);
