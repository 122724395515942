import React from "react";

import { Logo, Nav, LogoutLink } from "./";

export const HeaderDesktop = () => (
  <header className="site-header">
    <div className="container">
      <div className="row align-items-center justify-content-between">
        <div className="col-auto">
          <Logo />
        </div>

        <div className="col-auto">
          <Nav />
        </div>

        <div className="col-auto">
          <LogoutLink />
        </div>
      </div>
    </div>
  </header>
);
