import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { createUser } from "../../actions/";
import { Error } from "../common/";
import { Roles } from "../users/";

class Form extends Component {
  state = {
    added: null,
    errors: null,
    role: "regular",
    fullname: "",
    email: "",
    password: "",
    passwordConfirmation: "",
  };

  handleInputChange = (e) => {
    const newState = {};
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  };

  createUser = () => {
    const { role, fullname, email, password, passwordConfirmation } =
      this.state;

    if (password !== passwordConfirmation) {
      const errorList = {};
      errorList["Пароли"] = ["не совпадают"];
      this.setState({ errors: errorList });
    } else {
      const formData = {
        user: { role, fullname, email, password },
      };

      this.props
        .create({ formData })
        .then(() => this.setState({ added: true }))
        .catch((error) => {
          const errors = error.data
            ? error.data.errors || error.data
            : error.message;
          this.setState({ errors });
        });
    }
  };

  render() {
    const {
      errors,
      role,
      fullname,
      email,
      password,
      passwordConfirmation,
      added,
    } = this.state;
    const disabled =
      email.length === 0 ||
      password.length === 0 ||
      passwordConfirmation.length === 0;

    if (added) {
      return (
        <div className="text-center">
          <h4 className="alert alert-light">Пользовать создан!</h4>
          <button
            className="btn btn-primary mt-1"
            onClick={() => window.location.reload()}
          >
            Закрыть
          </button>
        </div>
      );
    }

    return (
      <div>
        {errors && <Error errors={errors} />}

        <div className="form-group row align-items-center">
          <div className="col-sm-6 col-12 mb-sm-0 mb-1">Роль</div>
          <div className="col">
            <select
              className="form-control custom-select"
              value={role}
              onChange={this.handleInputChange}
            >
              {Object.keys(Roles).map((roleName) => (
                <option key={roleName} value={roleName}>
                  {Roles[roleName]}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="form-group row align-items-center">
          <div className="col-sm-6 col-12 mb-sm-0 mb-1">Ник</div>
          <div className="col">
            <input
              type="text"
              className="form-control"
              name="fullname"
              value={fullname}
              onChange={this.handleInputChange}
            />
          </div>
        </div>

        <div className="form-group row align-items-center">
          <div className="col-sm-6 col-12 mb-sm-0 mb-1">Почта *</div>
          <div className="col">
            <input
              type="email"
              className="form-control"
              name="email"
              value={email}
              onChange={this.handleInputChange}
            />
          </div>
        </div>

        <div className="form-group row align-items-center">
          <div className="col-sm-6 col-12 mb-sm-0 mb-1">Пароль *</div>
          <div className="col">
            <input
              type="password"
              className="form-control"
              name="password"
              onChange={this.handleInputChange}
            />
          </div>
        </div>

        <div className="form-group row align-items-center">
          <div className="col-sm-6 col-12 mb-sm-0 mb-1">
            Подтверждение пароля *
          </div>
          <div className="col">
            <input
              type="password"
              className="form-control"
              name="passwordConfirmation"
              onChange={this.handleInputChange}
            />
          </div>
        </div>

        <div className="mt-3 text-center">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.createUser}
            disabled={disabled}
          >
            Создать
          </button>
        </div>
      </div>
    );
  }

  static propTypes = {
    create: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createUser(data)),
});

export const AddForm = connect(null, mapDispatchToProps)(Form);
