import React, { Component } from "react";
import PropTypes from "prop-types";

export class CommentHide extends Component {
  state = {
    count: "",
  };

  handleAmountChange = (e) => {
    this.setState({ count: e.target.value });
  };

  render() {
    const { count } = this.state;
    const { hideLast } = this.props;
    const disabled = count < 1 || count > 50; // do not forget to change MAX_DELETE_BATCH_SIZE in backend
    return (
      <div className="block-sorting">
        <h5>Скрыть последние видимые комментарии</h5>

        <div className="form-group mt-2">
          <label htmlFor="hideCommentsAmount">Количество:</label>
          <div className="row no-gutters">
            <div className="col">
              <input
                id="hideCommentsAmount"
                type="number"
                className="form-control"
                min="1"
                max="50"
                value={count}
                onChange={this.handleAmountChange}
              />
            </div>
            <div className="col-auto ml-1">
              <button
                className="btn btn-danger"
                onClick={() => hideLast(count)}
                disabled={disabled}
              >
                Скрыть
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    hideLast: PropTypes.func.isRequired,
  };
}
