import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import moment from "moment";
import _ from "lodash";
import { Chart, Dots, Lines, Ticks, Layer, Handlers } from "rumble-charts";
import { TooltipsForSvg } from "./";

export class Graph extends Component {
  state = {
    visibleSeries: [...this.props.data.series],
    visibleSeriesNames: this.props.data.series.map((obj) => obj.name),
    prevSeries: this.props.data.series,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data.series !== state.prevSeries) {
      const visibleSeries = props.data.series.filter(({ name }) =>
        state.visibleSeriesNames.includes(name)
      );
      return { prevSeries: props.data.series, visibleSeries };
    }
    return null;
  }

  componentDidMount() {
    this.setTooltipPositions();
  }

  componentDidUpdate() {
    this.setTooltipPositions();
  }

  toggle = (seriesName) => {
    let visibleSeriesNames = [...this.state.visibleSeriesNames];
    if (visibleSeriesNames.includes(seriesName)) {
      if (visibleSeriesNames.length > 1) {
        _.pull(visibleSeriesNames, seriesName);
      }
    } else {
      visibleSeriesNames.push(seriesName);
    }

    const visibleSeries = this.props.data.series.filter(({ name }) =>
      visibleSeriesNames.includes(name)
    );

    this.setState({ visibleSeries, visibleSeriesNames });
  };

  showOnly = (seriesName) => {
    const { series } = this.props.data;
    const currentVisibleSeriesNames = this.state.visibleSeriesNames;
    let visibleSeries;
    let visibleSeriesNames;

    if (
      currentVisibleSeriesNames.length === 1 &&
      currentVisibleSeriesNames.includes(seriesName)
    ) {
      visibleSeries = [...series];
      visibleSeriesNames = series.map((obj) => obj.name);
    } else {
      visibleSeriesNames = [seriesName];
      visibleSeries = series.filter(({ name }) =>
        visibleSeriesNames.includes(name)
      );
    }

    this.setState({ visibleSeries, visibleSeriesNames });
  };

  setTooltipPositions = () => {
    const { id } = this.props;
    [...document.querySelectorAll(`#${id}-tooltips .dot-tooltip`)].forEach(
      (tooltip) => {
        // eslint-disable-next-line no-unused-vars
        const [_, seriesIndex, pointIndex] =
          tooltip.id.match(/tooltip-(\d+)-(\d+)/);
        const bcr = document
          .querySelector(
            `circle.${id}-dots-circle-${seriesIndex}-${pointIndex}`
          )
          .getBoundingClientRect();
        const x = bcr.left + window.scrollX + bcr.width / 2;
        const y = bcr.top + window.scrollY + bcr.height / 2;
        tooltip.style.top = `${y - 27}px`;
        tooltip.style.left = `${x}px`;
      }
    );
  };

  hideHovered = () => {
    const { id } = this.props;
    [...document.querySelectorAll(`circle.${id}-dots-circle.show`)].map((el) =>
      el.classList.remove("show")
    );
    [...document.querySelectorAll(`.dot-tooltip`)].map((el) =>
      el.classList.remove("show")
    );
    [...document.querySelectorAll(`.${id}-notes`)].map((el) =>
      el.classList.remove("show")
    );
  };

  handleMouseMove = ({ x }) => {
    const { id, data, onlyMonths } = this.props;
    const { visibleSeries, visibleSeriesNames } = this.state;
    const maxIndex = visibleSeries[0].data.length - 1;

    let pointIndex = Math.round(x);
    if (pointIndex < 0) {
      pointIndex = 0;
    }
    if (pointIndex > maxIndex) {
      pointIndex = maxIndex;
    }

    this.hideHovered();

    const currentDate = moment(data.dates[pointIndex]).format(
      onlyMonths ? "MMMM YYYY" : "D MMMM YYYY"
    );
    const dateFilters = document.querySelector(`#${id}`).parentNode;
    dateFilters.querySelector("h6.current-range").classList.add("hide");
    dateFilters.querySelector("h6.highlighted-date").classList.remove("hide");
    dateFilters.querySelector("h6.highlighted-date").innerHTML = currentDate;

    [...document.querySelectorAll(`.${id}-notes`)].forEach((note) => {
      if (visibleSeriesNames.includes(note.dataset.name)) {
        note.innerHTML = _.find(visibleSeries, [
          "name",
          note.dataset.name,
        ]).data[pointIndex];
        note.classList.add("show");
      }
    });

    visibleSeries.forEach(({ name }, i) => {
      if (visibleSeriesNames.includes(name)) {
        document
          .querySelector(`circle.${id}-dots-circle-${i}-${pointIndex}`)
          .classList.add("show");
        document
          .querySelector(`#${id}-tooltip-${i}-${pointIndex}`)
          .classList.add("show");
      }
    });
  };

  handleMouseLeave = () => {
    const { id } = this.props;
    this.hideHovered();

    const dateFilters = document.querySelector(`#${id}`).parentNode;
    dateFilters.querySelector("h6.current-range").classList.remove("hide");
    dateFilters.querySelector("h6.highlighted-date").classList.add("hide");
  };

  xTickLabelFormat = (pointIndex) => {
    const { data, onlyMonths } = this.props;
    const date = moment(data.dates[pointIndex]);
    const formatWithDays =
      date.year() === moment().year() ? `D MMMM` : `D MMM YY`;
    return date.format(onlyMonths ? "MMMM'YY" : formatWithDays);
  };

  tickDistance = (maxY, small) => {
    if (maxY === undefined) {
      return null;
    }

    //base step between nearby two ticks
    let step = Math.pow(10, maxY.toString().length - 1);

    //modify steps either: 1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000...
    if (maxY / step < 2) {
      step = step / 5;
    } else if (maxY / step < (small ? 4 : 5)) {
      step = step / 2;
    }

    return step;
  };

  render() {
    const { visibleSeries, visibleSeriesNames } = this.state;
    const {
      id,
      data: { dates, series },
      labels,
    } = this.props;
    const showDots = dates.length < 100;

    return (
      <MediaQuery maxWidth={991}>
        {(matches) => {
          const width = matches ? 690 : 1060;
          const height = matches ? 300 : 460;
          return (
            <Fragment>
              <Chart
                id={id}
                viewBox={`0 0 ${width} ${height}`}
                series={visibleSeries}
                minY={0}
              >
                <Layer
                  width={width - 60}
                  height={height - 60}
                  position="middle right"
                >
                  <Handlers
                    onMouseMove={this.handleMouseMove}
                    onMouseLeave={this.handleMouseLeave}
                    optimized={false}
                  >
                    <Ticks
                      className={`${id}-x-ticks`}
                      axis="x"
                      ticks={matches ? 7 : 10}
                      lineLength="100%"
                      lineOffset="-100%"
                      lineStyle={{ stroke: "lightgrey" }}
                      labelStyle={{ textAnchor: "middle" }}
                      labelFormat={this.xTickLabelFormat}
                      labelAttributes={{ y: 20 }}
                    />
                    <Ticks
                      className={`${id}-y-ticks`}
                      axis="y"
                      ticks={({ maxY }) => {
                        return {
                          maxTicks: matches ? 7 : 10,
                          distance: this.tickDistance(maxY, matches),
                        };
                      }}
                      lineLength="100%"
                      lineStyle={{ stroke: "lightgray" }}
                      labelStyle={{
                        textAnchor: "end",
                        dominantBaseline: "middle",
                        fill: "lightgray",
                      }}
                      labelAttributes={{ x: -5 }}
                    />
                    <Lines
                      className={`${id}-lines`}
                      interpolation="linear"
                      lineStyle={{ strokeWidth: "0.2%" }}
                    />
                    <Dots
                      className={`graph-dots ${
                        showDots ? "show-by-default " : ""
                      }${id}-dots`}
                      dotStyle={({ series }) => {
                        return { color: series.color, r: "0.4%" };
                      }}
                    />
                    <TooltipsForSvg
                      id={id}
                      series={visibleSeries}
                      labels={labels}
                    />
                  </Handlers>
                </Layer>
              </Chart>
              <div className="row ml-3">
                {series.map(({ color, name }) => (
                  <div
                    className="col-12 col-sm-6 col-lg-4"
                    key={name}
                    style={{ color }}
                  >
                    <label
                      className="custom-control custom-checkbox"
                      onDoubleClick={() => this.showOnly(name)}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={visibleSeriesNames.includes(name)}
                        onChange={() => this.toggle(name)}
                      />
                      <span className="custom-control-indicator" />
                      <span className="custom-control-description no-selection">
                        {labels[name] || name}
                      </span>
                    </label>
                    <span
                      className={`graph-notes ${id}-notes ml-1`}
                      data-name={name}
                      style={{
                        border: `1px solid ${color}`,
                        backgroundColor: color,
                      }}
                    />
                  </div>
                ))}
              </div>
              <div id={`${id}-tooltips`} />
            </Fragment>
          );
        }}
      </MediaQuery>
    );
  }

  static propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.shape({
      dates: PropTypes.arrayOf(PropTypes.string),
      series: PropTypes.arrayOf(PropTypes.object),
    }),
    onlyMonths: PropTypes.bool,
    labels: PropTypes.objectOf(PropTypes.string),
  };

  static defaultProps = {
    data: {
      dates: [],
      series: [],
    },
    onlyMonths: false,
    labels: {},
  };
}
