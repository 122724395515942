import { DataLayer } from "../../libs/";

export const loadChatMessageStats = () => async () => {
  const newState = {
    stats: null,
  };

  await DataLayer.get({ path: "/chat_message_complains/stats.json" })
    .then((response) => {
      newState.stats = response;
    })
    .catch(() => {});

  return newState;
};
