import React from "react";

export const SimpleFooter = () => (
  <footer className="footer">
    <div className="wrapper">
      <div className="row w-100 no-gutters align-items-center justify-content-between">
        <div className="col-sm-auto col-12 mb-sm-0 mb-1 text-center">
          &copy; «Подслушано» {new Date().getFullYear()}
        </div>
        <div className="col-auto d-sm-block d-none">·</div>
        <div className="col-auto">
          <a href="https://ideer.ru/polzovatelskoe-soglashenie">
            Пользовательское соглашение
          </a>
        </div>
        <div className="col-auto d-sm-block d-none">·</div>
        <div className="col-auto">
          <a href="https://ideer.ru/privacy_policy">
            Политика конфиденциальности
          </a>
        </div>
      </div>
    </div>
  </footer>
);
