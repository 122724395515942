import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import { loadPostHistory } from "../../actions/";
import { Loading, LoadingError, History } from "../../components/common/";

class HistoryWrapper extends Component {
  state = {
    versions: [],
    loading: true,
    loadingError: false,
    pages: 0,
    filters: this.props.filters,
  };

  componentDidMount() {
    this.props.loadHistory(this.props.postId).then((e) => this.setState(e));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevState.filters, this.state.filters)) {
      this.props.loadHistory(this.props.postId).then((e) => this.setState(e));
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.filters, state.filters)) {
      return { filters: props.filters, versions: [], loading: true };
    }
    return null;
  }

  render() {
    const { versions, loading, loadingError, pages } = this.state;

    return (
      <div className="mt-lg-5 mt-3">
        <h3 className="mb-2">История изменений</h3>

        {loading && <Loading />}

        {loadingError && <LoadingError />}

        {!loading && !loadingError && (
          <History versions={versions} pages={pages} />
        )}
      </div>
    );
  }

  static propTypes = {
    postId: PropTypes.number.isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    loadHistory: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.history,
});

const mapDispatchToProps = (dispatch) => ({
  loadHistory: (postId) => dispatch(loadPostHistory(postId)),
});

export const PostHistory = connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryWrapper);
