const otherSettings = [
  { name: "isAd", title: "Рекламный пост" },
  { name: "enableComments", title: "Комментарии" },
  { name: "fixed", title: "Закреплён" },
  { name: "illustration", title: "Иллюстрация" },
  { name: "hideCommentButton", title: "Скрыть кнопку комментариев" },
  { name: "hideLikeButton", title: "Скрыть кнопку лайков" },
  { name: "hideShareButton", title: "Скрыть кнопку поделиться" },
  { name: "hideBookmarksButton", title: "Скрыть кнопку закладок" },
  { name: "hideCategory", title: "Скрыть категорию" },
  { name: "hotDiscussion", title: 'Иконка "огня"' },
];

const noteTabs = [
  { name: "note", title: "Текст" },
  { name: "note2", title: "Markdown" },
  { name: "note3", title: "Html" },
];

const devices = [
  { name: "all", title: "Все" },
  { name: "ios", title: "iOS" },
  { name: "android", title: "Android" },
  { name: "huawei", title: "Huawei" },
  { name: "rustore", title: "RuStore" },
  { name: "site", title: "Сайт" },
  { name: "none", title: "Никто" },
];

const audienceGroupList = [
  { value: "vip", title: "VIP" },
  { value: "keeper", title: "Хранители" },
  { value: "admin", title: "Админы" },
];

const notePlaceholders = {
  note: "Текст поста",
  note2: "Текст поста с markdown разметкой (только ссылки)",
  note3: "Текст поста с html разметкой",
};

export {
  otherSettings,
  noteTabs,
  devices,
  audienceGroupList,
  notePlaceholders,
};
