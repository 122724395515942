import { DataLayer } from "../../libs/";

export const fetchCitySuggestions =
  ({ search, countryId }) =>
  async () => {
    let suggestions = [];

    await DataLayer.get({
      path: `/cities.json?country=${countryId}&text=${search}&limit=5`,
      loader: true,
    })
      .then((response) => {
        suggestions = response;
      })
      .catch(() => {});

    return suggestions;
  };
