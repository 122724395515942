import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { createReason, updateReason } from "../../actions";
import { SvgCheck, SvgCross } from "../../svg";

class Form extends Component {
  state = {
    reason: this.props.reason,
  };

  handleChange = (e) => {
    const reason = { ...this.state.reason };
    reason[e.target.name] = e.target.value;
    this.setState({ reason });
  };

  save = () => {
    const { reason } = this.state;
    this[reason.id ? "update" : "create"](reason);
  };

  create = (reason) => {
    reason.type = this.props.type;
    this.props.create({ reason }).then((reasons) => {
      this.props.close();
      this.props.saveCallback(reasons);
    });
  };

  update = (reason) => {
    this.props.update({ reason }).then((reasons) => {
      this.props.close();
      this.props.saveCallback(reasons);
    });
  };

  render() {
    const {
      id,
      position,
      title,
      description,
      use_count: useCount,
    } = this.state.reason;
    const { close } = this.props;
    const disabled =
      position === undefined ||
      position === "" ||
      !title ||
      title.length === 0 ||
      !description ||
      description.length === 0;

    return (
      <tr key={`ban-reason-${id}-form`}>
        <td>
          <input
            type="number"
            name="position"
            placeholder="Позиция"
            value={position !== undefined ? position : ""}
            onChange={this.handleChange}
            className="form-control form-control-sm"
          />
        </td>
        <td>
          <input
            type="text"
            name="title"
            placeholder="Название"
            value={title || ""}
            onChange={this.handleChange}
            className="form-control form-control-sm"
          />
        </td>
        <td>
          <input
            type="text"
            name="description"
            placeholder="Описание"
            value={description || ""}
            onChange={this.handleChange}
            className="form-control form-control-sm"
          />
        </td>
        <td>{useCount}</td>
        <td className="text-right text-nowrap action-buttons">
          <button
            className="btn btn-success btn-sm"
            onClick={this.save}
            disabled={disabled}
            title="Сохранить"
          >
            <SvgCheck width={14} height={12} className="fill-white" />
          </button>
          <button
            className="btn btn-primary btn-sm ml-1"
            onClick={close}
            title="Отменить"
          >
            <SvgCross width={14} height={12} className="fill-white" />
          </button>
        </td>
      </tr>
    );
  }

  static propTypes = {
    create: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    reason: PropTypes.objectOf(PropTypes.any).isRequired,
    close: PropTypes.func.isRequired,
    saveCallback: PropTypes.func.isRequired,
    type: PropTypes.string,
  };

  static defaultProps = {
    type: "for_ban",
  };
}

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createReason(data)),
  update: (data) => dispatch(updateReason(data)),
});

export const ReasonForm = connect(null, mapDispatchToProps)(Form);
