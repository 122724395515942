import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import DatePicker from "react-datepicker";
import moment from "moment";

import { createPost, updatePost } from "../../actions/";
import {
  CategoryDropdown,
  EditConflict,
  Error,
  GroupedDropdown,
  Textarea,
} from "../common/";
import { textAutoCorrection, pluralizeCharacters } from "../../libs/";
import { SvgDown, SvgCross, SvgCheck } from "../../svg/";

import {
  otherSettings,
  noteTabs,
  devices,
  audienceGroupList,
  notePlaceholders,
} from "./_consts";
import { buildFormStateFromProps, prepareFormStateForSubmit } from "./_utils";
import { BtnDropdownNonClosing } from "../common/helpers/btn-dropdown-non-closing";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = buildFormStateFromProps(props);
  }

  getSpecialForDeviceTitle = () => {
    const selection = devices.filter((device) =>
      this.state.specialForDevice.includes(device.name)
    );
    return selection.map((device) => device.title).join(", ");
  };

  handleCategoryChange = (id) => {
    this.setState({ category: id });
  };

  handleBackspace = (e) => {
    if (
      e.key !== "Backspace" ||
      e.target.selectionStart !== e.target.selectionEnd
    ) {
      return;
    }
    e.preventDefault(e);

    const { text, cursor } = textAutoCorrection(e, { reverse: true });

    const newState = {};
    newState[e.target.name] = text;
    newState[e.target.name + "Cursor"] = cursor;
    this.setState(newState);
  };

  handleTextChange = (e) => {
    const { text, cursor } = textAutoCorrection(e);

    const newState = {};
    newState[e.target.name] = text;
    newState[e.target.name + "Cursor"] = cursor;
    this.setState(newState);
  };

  handleFileSelection = (e) => {
    if (e.target.files && e.target.files[0]) {
      const FR = new FileReader();
      const fileName = e.target.value.split(/(\\|\/)/g).pop();

      FR.addEventListener("load", (img) => {
        this.setState({
          newImage: img.target.result,
          newImageName: fileName,
          removeImage: false,
        });
      });

      const file = e.target.files[0];
      FR.readAsDataURL(file);
    }
  };

  handleDeleteImage = () => {
    this.setState({ removeImage: true });
  };

  handleShowFullForm = () => {
    this.setState({ fullForm: !this.state.fullForm });
  };

  handleDevicesSelection = (e) => {
    const { name, checked } = e.target;
    let newValue = this.state.specialForDevice;
    const maxDevices = devices.length - 2;

    if (checked && (name === "all" || name === "none")) {
      newValue = [name];
    } else if (!checked && name === "all") {
      newValue = ["none"];
    } else if (!checked && name === "none") {
      newValue = ["all"];
    } else if (checked) {
      newValue = newValue.filter((el) => el !== "all" && el !== "none");
      newValue.push(name);
      if (newValue.length === maxDevices) newValue = ["all"];
    } else {
      newValue = newValue.filter((el) => el !== name);
      if (newValue.length === 0) newValue = ["none"];
    }

    this.setState({ specialForDevice: newValue });
  };

  handleNoteTabSelection = (tab) => {
    this.setState({ noteTab: tab });
  };

  handleClickOnCheckbox = (e) => {
    const newState = {};
    newState[e.target.name] = e.target.checked;
    this.setState(newState);
  };

  handleScheduledToSelection = (date) => {
    this.setState({ scheduledTo: date });
  };

  handleAudienceChange = ({ filter, groups }) => {
    this.setState({ audienceFilter: filter, audienceGroups: groups });
  };

  submitForm = (e) => {
    e.preventDefault();
    e.target.setAttribute("disabled", "disabled");

    const state = this.state;
    const formData = prepareFormStateForSubmit(state);

    if (state.id) {
      this.updatePost(formData);
    } else {
      this.props
        .create({ formData })
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          const errors = error.data
            ? error.data.errors || error.data
            : error.message;
          this.setState({ errors });
        });
    }
  };

  updatePost = (formData) => {
    const { id } = this.state;
    const { updateData, post: propsPost } = this.props;

    this.props.update({ id, formData, propsPost }).then((newState) => {
      if (newState.post) {
        updateData({ post: newState.post });

        // errors or editConflict
      } else {
        this.setState(newState);
      }
    });
  };

  handleConflict = (action) => {
    // send update request to the server
    if (action === "overwrite") {
      const formData = { ...this.state.formData };
      formData.post.overwrite = true;
      this.updatePost(formData);

      // update state (of the parent component) with the saved on the server data
    } else {
      this.props.updateData({
        post: { ...this.props.post, ...this.state.editConflict.savedData },
      });
    }
  };

  render() {
    const { hasAdminAccess, hasEditorAccess, closeForm } = this.props;
    const {
      fullForm,
      noteTab,
      newImageName,
      removeImage,
      errors,
      editConflict,
      id,
      status,
      scheduledTo,
      category,
      image,
      specialForDevice,
      note,
      audienceFilter,
      audienceGroups,
    } = this.state;

    const createTitle =
      scheduledTo && scheduledTo > new Date()
        ? "Запланировать"
        : "Опубликовать";

    const submitButtonTitle = id ? "Сохранить" : createTitle;

    return (
      <div
        className={`post-item mb-3 mt-3 form${
          errors || editConflict ? " error" : ""
        }`}
      >
        {errors && <Error errors={errors} />}

        {editConflict && (
          <EditConflict
            title="пост"
            {...editConflict}
            action={this.handleConflict}
          />
        )}

        <header className="row align-items-center justify-content-between">
          <div className="col-auto">
            <CategoryDropdown
              selectedId={category}
              onChange={this.handleCategoryChange}
            />
          </div>
          <div className="col-auto text-muted">
            {id && [
              <em key="edit_text">Редактирование</em>,
              <span key="split" className="mx-1">
                |
              </span>,
            ]}
            <span>{pluralizeCharacters(this.state[noteTab].length)}</span>
          </div>
        </header>

        <div className="std">
          {noteTabs.map((tab) => (
            <button
              type="button"
              key={`${tab.name}Tab`}
              onClick={() => this.handleNoteTabSelection(tab.name)}
              className={`btn btn-link p-0 mr-3 mb-15${
                noteTab === tab.name ? " active" : ""
              }`}
            >
              {tab.title}
            </button>
          ))}

          <Textarea
            onKeyDown={this.handleBackspace}
            onChange={this.handleTextChange}
            cursor={this.state[noteTab + "Cursor"]}
            name={noteTab}
            value={this.state[noteTab]}
            placeholder={notePlaceholders[noteTab]}
          />
        </div>

        {image && !removeImage && !newImageName ? (
          <div className="image-holder mt-2">
            <img src={image} className="rounded img-fluid" alt="" />
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleDeleteImage}
            >
              <SvgCross width={8} height={8} className="fill-white" />
            </button>
          </div>
        ) : (
          <div className="image-load mt-2">
            <div className="input-file">
              <input type="file" onChange={this.handleFileSelection} />
              <span className="placeholder">
                {newImageName || "Прикрепить картинку"}
              </span>
            </div>
          </div>
        )}

        {hasEditorAccess && (
          <>
            {(!id || status === "scheduled") && (
              <div className="mt-2">
                <h6 className="mb-1">Время публикации</h6>

                <DatePicker
                  selected={
                    scheduledTo
                      ? new Date(moment(scheduledTo).format("YYYY-MM-DDTHH:mm"))
                      : null
                  }
                  minDate={moment().utcOffset(180).toDate()}
                  onChange={this.handleScheduledToSelection}
                  onFocus={(e) => {
                    if (!scheduledTo) {
                      const newDate = moment().add(30, "minutes").toDate();
                      this.handleScheduledToSelection(newDate);
                    }
                  }}
                  timeInputLabel="Время:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  className="form-control custom-select"
                  value={
                    scheduledTo
                      ? moment(scheduledTo).format("DD.MM.YYYY HH:mm")
                      : "Сейчас"
                  }
                />
              </div>
            )}

            {(fullForm || !id) && (
              <div className="full-form">
                <hr className="my-3" />

                <MediaQuery maxWidth={991}>
                  <div className="row no-gutters mb-3 align-items-center">
                    <h6 className="col-auto m-0">Кто видит</h6>
                    <div className="col-auto ml-auto">
                      <BtnDropdownNonClosing
                        btnClass="btn btn-link p-0"
                        hasIcon
                        btnText={this.getSpecialForDeviceTitle()}
                      >
                        <div className="dropdown p-2 right">
                          {devices.map((device) => (
                            <label
                              key={device.name}
                              className="custom-control custom-checkbox"
                            >
                              <input
                                type="checkbox"
                                name={device.name}
                                checked={specialForDevice.includes(device.name)}
                                className="custom-control-input"
                                onChange={this.handleDevicesSelection}
                              />
                              <span className="custom-control-indicator" />
                              <span className="custom-control-description">
                                {device.title}
                              </span>
                            </label>
                          ))}
                        </div>
                      </BtnDropdownNonClosing>
                    </div>
                  </div>
                </MediaQuery>

                <MediaQuery minWidth={992}>
                  <div className="mb-3">
                    <h6 className="mb-1">Кто видит</h6>

                    <div className="custom-radio-box">
                      {devices.map((device) => (
                        <label
                          key={device.name}
                          htmlFor={`device_${device.name}`}
                        >
                          <input
                            type="checkbox"
                            id={`device_${device.name}`}
                            name={device.name}
                            checked={specialForDevice.includes(device.name)}
                            onChange={this.handleDevicesSelection}
                          />
                          <span>{device.title}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                </MediaQuery>

                <div className="row no-gutters mb-3 align-items-center">
                  <h6 className="col-auto m-0">Аудитория</h6>
                  <div className="col-auto ml-auto">
                    <GroupedDropdown
                      groups={audienceGroupList}
                      selected={{
                        filter: audienceFilter,
                        groups: audienceGroups,
                      }}
                      callback={this.handleAudienceChange}
                    />
                  </div>
                </div>

                <h6 className="mb-2">Настройки</h6>

                <div className="settings">
                  {otherSettings.map((field) => {
                    if (field.name === "hotDiscussion" && !hasAdminAccess) {
                      return null;
                    }

                    return (
                      <label
                        className="custom-control custom-checkbox"
                        key={field.name}
                      >
                        <input
                          type="checkbox"
                          name={field.name}
                          className="custom-control-input"
                          checked={this.state[field.name]}
                          onChange={this.handleClickOnCheckbox}
                        />
                        <span className="custom-control-indicator" />
                        <span className="custom-control-description">
                          {field.title}
                        </span>
                      </label>
                    );
                  })}
                </div>
              </div>
            )}

            {!!id && (
              <button
                type="button"
                className={`btn btn-link p-0 mt-2${fullForm ? " opened" : ""}`}
                onClick={this.handleShowFullForm}
              >
                {fullForm ? "Меньше настроек" : "Больше настроек"}
                <SvgDown width={9} height={6} className="toggler" />
              </button>
            )}
          </>
        )}

        {!hasEditorAccess && (
          <div className="settings mt-2">
            {otherSettings
              .filter(({ name }) => name === "illustration")
              .map((field) => (
                <label
                  className="custom-control custom-checkbox"
                  key={field.name}
                >
                  <input
                    type="checkbox"
                    name={field.name}
                    className="custom-control-input"
                    checked={this.state[field.name]}
                    onChange={this.handleClickOnCheckbox}
                  />
                  <span className="custom-control-indicator" />
                  <span className="custom-control-description">
                    {field.title}
                  </span>
                </label>
              ))}
          </div>
        )}

        <div className="text-right mt-3">
          <button
            type="button"
            className="btn btn-secondary mr-15"
            onClick={closeForm}
          >
            <MediaQuery minWidth={380}>
              <SvgCross width={9} height={9} className="mr-1 fill-primary" />
            </MediaQuery>
            Отмена
          </button>

          <button
            type="submit"
            className={`btn btn-${id ? "primary" : "success"}`}
            disabled={
              note.trim().length === 0 &&
              (removeImage || (!image && !newImageName))
            }
            onClick={this.submitForm}
          >
            <MediaQuery minWidth={380}>
              <SvgCheck width={14} height={9} className="mr-1 fill-white" />
            </MediaQuery>
            {submitButtonTitle}
          </button>
        </div>
      </div>
    );
  }

  static propTypes = {
    create: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    closeForm: PropTypes.func.isRequired,
    updateData: PropTypes.func,
    post: PropTypes.objectOf(PropTypes.any),
    hasAdminAccess: PropTypes.bool.isRequired,
    hasEditorAccess: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    post: null,
    actions: {},
  };
}

const mapStateToProps = (state) => ({
  hasAdminAccess: state.user.hasAdminAccess,
  hasEditorAccess: state.user.hasEditorAccess,
});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createPost(data)),
  update: (data) => dispatch(updatePost(data)),
});

export const PostForm = connect(mapStateToProps, mapDispatchToProps)(Form);
