import React, { Component } from "react";
import PropTypes from "prop-types";

import { DateFilters, Graph } from "../_common";

const sourceLabels = {
  bookmarks: "Закладки постов",
};

export class PostBookmarks extends Component {
  render() {
    const { data, className } = this.props;

    return (
      <div className={className}>
        <div className="row justify-content-between align-items-baseline1 mb-2">
          <div className="col-auto">
            <h3>Закладки постов</h3>
          </div>
        </div>

        <DateFilters data={data} showGroups>
          <Graph id="post-bookmarks-graph" labels={sourceLabels} />
        </DateFilters>
      </div>
    );
  }

  static propTypes = {
    data: PropTypes.shape({
      dates: PropTypes.arrayOf(PropTypes.string),
      series: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };
}
