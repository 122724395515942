import React from "react";
import PropTypes from "prop-types";

import { ProfileLink } from "../../users/";
import { Diff } from "./diff";

export const EditConflict = ({
  title,
  author,
  initialDiff,
  updateDiff,
  action,
}) => (
  <div className="edit-conflict">
    <h4>Данные в редактируемом {title}е устарели!</h4>

    {author.fullname_or_anonymus && (
      <div className="my-2">
        Автор последних изменений:{" "}
        {author.id ? (
          <ProfileLink user={author} />
        ) : (
          author.fullname_or_anonymus
        )}
        .
      </div>
    )}

    <h6 className="mt-2 mb-1">Конфликт версий (ваша => на сервере):</h6>
    <Diff data={initialDiff} />

    <h6 className="mb-1">
      Ваши фактические изменения:{" "}
      {Object.keys(updateDiff).length < 1
        ? "идентичны сохранённой версии."
        : null}
    </h6>
    <Diff data={updateDiff} />

    <div className="text-right">
      <button className="btn btn-success" onClick={() => action("overwrite")}>
        Сохранить изменения
      </button>
      <button className="btn btn-primary ml-1" onClick={() => action("cancel")}>
        Отменить
      </button>
    </div>
  </div>
);

EditConflict.propTypes = {
  title: PropTypes.string.isRequired,
  author: PropTypes.objectOf(PropTypes.any).isRequired,
  initialDiff: PropTypes.objectOf(PropTypes.array).isRequired,
  updateDiff: PropTypes.objectOf(PropTypes.array).isRequired,
  action: PropTypes.func.isRequired,
};
