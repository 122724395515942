import { DataLayer } from "../../libs/";

export const deleteVkScheduledSecret = (vkSecret) => async () => {
  await DataLayer.delete({
    path: "/secrets/vk_scheduled/" + vkSecret.id + ".json",
    loader: true,
  }).catch((error) => {
    console.log(error.data ? error.data.errors || error.data : error.message);
  });

  return vkSecret;
};
