import { DataLayer } from "../../libs/";

export const resolveChatMessageComplain =
  ({ id }) =>
  async () => {
    const newState = {};
    await DataLayer.put({
      path: "/chat_message_complains/" + id + "/resolve.json",
      loader: true,
    })
      .then((response) => {
        newState.complain = response.complain;
      })
      .catch(() => {});
    return newState;
  };
