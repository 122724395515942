import React, { Component } from "react";
import PropTypes from "prop-types";

import { SvgCheck, SvgCross } from "../../svg";

export class VKLinkForm extends Component {
  state = {
    item: this.props.item,
  };

  handleChange = (e) => {
    const item = { ...this.state.item };
    item[e.target.name] = e.target.value;
    this.setState({ item });
  };

  save = () => {
    this.props.close();
    this.props.saveCallback(this.state.item);
  };

  render() {
    const { item } = this.state;
    const { close } = this.props;

    return (
      <tr key={`vk-link-${item.id}-form`}>
        <td>
          <input
            type="text"
            name="url"
            value={item.url !== undefined ? item.url : ""}
            onChange={this.handleChange}
            className="form-control form-control-sm"
          />
        </td>
        <td className="text-right text-nowrap action-buttons">
          <button
            className="btn btn-success btn-sm"
            onClick={this.save}
            title="Сохранить"
          >
            <SvgCheck width={14} height={12} className="fill-white" />
          </button>
          <button
            className="btn btn-primary btn-sm ml-1"
            onClick={close}
            title="Отменить"
          >
            <SvgCross width={14} height={12} className="fill-white" />
          </button>
        </td>
      </tr>
    );
  }

  static propTypes = {
    item: PropTypes.object,
    close: PropTypes.func.isRequired,
    saveCallback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    item: {},
  };
}
