import { DataLayer } from "../../libs/";

export const unlinkSocialAccount =
  ({ id, formData }) =>
  () =>
    DataLayer.delete({
      path: `/users/${id}/unlink_social_account.json`,
      body: formData,
      loader: true,
    });
