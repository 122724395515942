import { DataLayer } from "../../libs/";

export const loadSecretStats = () => async () => {
  const newState = {
    loading: false,
    loadingError: false,
  };

  await DataLayer.get({ path: "/secrets/stats.json" })
    .then((stats) => {
      newState.stats = stats;
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
