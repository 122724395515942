import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { setQueryParam } from "../../actions/";
import { SvgPrev, SvgNext } from "../../svg/";
import { scrollPage } from "../../libs";

class Pages extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.page !== prevProps.page) {
      scrollPage(0);
    }
  }

  changePage = (e, value) => {
    e.preventDefault();
    this.props.setQueryParam({ name: "page", value, defaultValue: 1 });
  };

  render() {
    const { page: currentPage, total, small } = this.props;

    if (total < 2) return null;

    let sides = currentPage < 9995 ? 5 : 4;
    if (small) sides = currentPage < 9997 ? 3 : 2;

    let from = currentPage <= sides ? 1 : currentPage - sides + 1;
    let to = currentPage + sides >= total ? total : currentPage + sides;
    if (from === 1 && to !== total) to = 2 * sides < total ? 2 * sides : total;
    if (to === total && from !== 1)
      from = total > 2 * sides ? total - 2 * sides + 1 : 1;

    const items = [];

    for (let num = from; num <= to; num++) {
      items.push(
        <li
          key={num}
          className={`page-item${num === currentPage ? " active" : ""}`}
        >
          <a
            href=""
            className="page-link"
            onClick={(e) => this.changePage(e, num)}
          >
            {num}
          </a>
        </li>
      );
    }

    return (
      <nav className="mb-3">
        <ul className="pagination">
          <li className="page-item mr-auto first">
            <a
              href=""
              className="page-link"
              onClick={(e) => this.changePage(e, 1)}
            >
              <SvgPrev width={9} height={9} className="fill-primary" />
            </a>
          </li>
          <li className="inner">
            <ul className="list-unstyled">{items}</ul>
          </li>
          <li className="page-item ml-auto last">
            <a
              href=""
              className="page-link"
              onClick={(e) => this.changePage(e, total)}
            >
              <SvgNext width={9} height={9} className="fill-primary" />
            </a>
          </li>
        </ul>
      </nav>
    );
  }

  static propTypes = {
    setQueryParam: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    small: PropTypes.bool,
  };

  static defaultProps = {
    small: false,
  };
}

const mapStateToProps = (state) => ({
  page: state.filters.page,
});

const mapDispatchToProps = (dispatch) => ({
  setQueryParam: (data) => dispatch(setQueryParam(data)),
});

export const Pagination = connect(mapStateToProps, mapDispatchToProps)(Pages);
