import React from "react";
import PropTypes from "prop-types";

export const DateCustomInput = React.forwardRef(({ onClick, value }, ref) => (
  <button className="btn-link" onClick={onClick} ref={ref}>
    {value}
  </button>
));

DateCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
};
