import { DataLayer } from "../../libs/";
import { loadCategories } from "../";

export const updateCategory = (category) => async (dispatch) => {
  await DataLayer.put({
    path: `/categories/${category.id}.json`,
    body: category,
  }).catch(() => {});
  await dispatch(loadCategories({ withPostCount: true, loader: true }));
};
