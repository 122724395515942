import { DataLayer } from "../../libs/";

export const loadSecrets = () => async (dispatch, getState) => {
  const filters = getState().filters.secrets;
  const params = [];
  const newState = {
    loading: false,
    loadingError: false,
  };

  if (filters.category > 0) params.push(`category_id=${filters.category}`);
  if (filters.includeCategories)
    params.push(`include_categories=${filters.includeCategories}`);
  if (filters.excludeCategories)
    params.push(`exclude_categories=${filters.excludeCategories}`);
  if (filters.searchQuery) params.push(`text=${filters.searchQuery}`);
  if (filters.onlyChosen) params.push("only_chosen=yes");
  if (filters.scheduledByMe) params.push("scheduled_by_me=yes");
  if (filters.source) params.push(`source=${filters.source}`);
  if (filters.secretLength)
    params.push(`secret_length=${filters.secretLength}`);
  if (filters.recommendations)
    params.push(`recommendations=${filters.recommendations}`);
  if (filters.rating) params.push(`rating=${filters.rating}`);
  if (filters.weightedRating) params.push(`weight=${filters.weightedRating}`);
  if (filters.views) params.push(`views=${filters.views}`);
  if (filters.uniqueness) params.push(`uniqueness=${filters.uniqueness}`);
  if (filters.dateFrom) params.push(`date_from=${filters.dateFrom}`);
  if (filters.dateTo) params.push(`date_to=${filters.dateTo}`);
  if (filters.removedDateFrom)
    params.push(`removed_date_from=${filters.removedDateFrom}`);
  if (filters.removedDateTo)
    params.push(`removed_date_to=${filters.removedDateTo}`);
  if (filters.sortBy) params.push(`sort_by=${filters.sortBy}`);
  if (filters.authorFilter)
    params.push(`author_filter=${filters.authorFilter}`);
  if (filters.authorGroups)
    params.push(`author_groups=${filters.authorGroups.join(",")}`);
  if (filters.page > 1) params.push(`page=${filters.page}`);
  if (filters.usersDeletedFilter)
    params.push(`users_deleted_filter=${filters.usersDeletedFilter}`);
  if (filters.usersDeletedGroups)
    params.push(`users_deleted_groups=${filters.usersDeletedGroups.join(",")}`);
  if (filters.page > 1) params.push(`page=${filters.page}`);

  await DataLayer.get({
    path:
      "/secrets" +
      (filters.type && "/" + filters.type) +
      ".json" +
      (params.length ? "?" + params.join("&") : ""),
  })
    .then((response) => {
      newState.secrets = response.secrets;
      newState.alert = response.alert;
      newState.pages = response.pages;
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
