import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

export class TooltipsForSvg extends Component {
  container = document.createElement("div");

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.tooltips().appendChild(this.container);
  }

  componentWillUnmount() {
    this.tooltips().removeChild(this.container);
  }

  tooltips = () => document.querySelector(`#${this.props.id}-tooltips`);

  toggleName = (e) => e.currentTarget.lastChild.classList.toggle("show");

  render() {
    const { id, series, labels } = this.props;

    return ReactDOM.createPortal(
      <div>
        {series.map(({ name, color, data }, seriesIndex) =>
          data.map((point, pointIndex) => (
            <div
              key={`${seriesIndex}-${pointIndex}`}
              className="dot-tooltip"
              id={`${id}-tooltip-${seriesIndex}-${pointIndex}`}
              style={{ border: `1px solid ${color}`, backgroundColor: color }}
              onMouseEnter={this.toggleName}
              onMouseLeave={this.toggleName}
              onMouseMove={(e) => e.stopPropagation()}
            >
              <span className="value">{point.y}</span>
              <span className="name"> {labels[name] || name}</span>
            </div>
          ))
        )}
      </div>,
      this.container
    );
  }

  static propTypes = {
    id: PropTypes.string.isRequired,
    series: PropTypes.arrayOf(PropTypes.object).isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
  };
}
