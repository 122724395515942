import "./styles/styles.scss";

import React from "react";
import ReactDom from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import configureStore, { history } from "./reducers";
import { Loading } from "./components/common";
import Root from "./root";

const store = configureStore();

const render = () => {
  ReactDom.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <React.Suspense fallback={<Loading />}>
          <Root />
        </React.Suspense>
      </ConnectedRouter>
    </Provider>,
    document.getElementById("app")
  );
};

render(Root);
