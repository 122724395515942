import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { BtnDropdown } from "../../common";
import { SvgDots } from "../../../svg";

export const CommentHeaderMenuSmall = ({ comment, branchLink, reply }) => (
  <div className="row align-items-center no-gutters">
    <div className="col text-right">
      <Link
        to={branchLink}
        className="btn btn-link opacity p-0"
        target="_blank"
      >
        #{comment.post_id}
      </Link>
      <br />
      <Link
        to={`?category_id=${comment.category_id}`}
        className="btn btn-link opacity p-0"
        target="_blank"
      >
        {comment.category_name}
      </Link>
    </div>

    {!comment.hidden && (
      <div className="col-auto pl-1">
        <BtnDropdown
          btnClass="btn btn-link p-0"
          btnText={<SvgDots width={18} height={4} className="fill-gray" />}
        >
          <ul className="dropdown right">
            <li>
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={reply}
              >
                Ответить
              </button>
            </li>
          </ul>
        </BtnDropdown>
      </div>
    )}
  </div>
);

CommentHeaderMenuSmall.propTypes = {
  comment: PropTypes.object.isRequired,
  branchLink: PropTypes.string.isRequired,
  reply: PropTypes.func.isRequired,
};
