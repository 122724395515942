import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { connect } from "react-redux";

const Tabs = [
  { name: "general", title: "Основные" },
  { name: "timers", title: "Расписание" },
  { name: "links", title: "Ссылки" },
];

const VKTabsContent = ({ vkTab, addButtonTitle, addButtonCallback }) => (
  <div className="row mb-1">
    <div className="col secondary-nav border-0">
      <nav className="mt-0">
        {Tabs.map((tab) => (
          <Link
            to={`#${tab.name}`}
            key={tab.name}
            className={vkTab === tab.name ? "active" : null}
          >
            {tab.title}
          </Link>
        ))}
      </nav>
    </div>
    {addButtonTitle && (
      <div className="col-auto">
        <button className="btn btn-primary" onClick={addButtonCallback}>
          Добавить {addButtonTitle}
        </button>
      </div>
    )}
  </div>
);

VKTabsContent.propTypes = {
  vkTab: PropTypes.string.isRequired,
  addButtonTitle: PropTypes.string,
  addButtonCallback: PropTypes.func,
};

const mapStateToProps = (state) => ({
  vkTab: state.filters.vk.vkTab,
});

export const VKTabs = connect(mapStateToProps)(VKTabsContent);
