import { DataLayer } from "../../libs";

export const logOut = () => async (dispatch) => {
  try {
    await DataLayer.get({ path: "/logout.json" });
  } catch (error) {
    console.error(error);
  } finally {
    localStorage.removeItem("badFeedWords");
    localStorage.removeItem("banReasons");
    localStorage.removeItem("categories");
    localStorage.removeItem("categoriesWithPostCount");
    localStorage.removeItem("countries");
    localStorage.removeItem("currentUser");

    dispatch({ type: "LOG_OUT" });
  }
};
