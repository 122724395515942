import React, { Component } from "react";
import PropTypes from "prop-types";

import { Post, PostForm } from "./";

export class PostListItem extends Component {
  state = {
    post: this.props.post,
    showForm: false,
    formData: null,
  };

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  updateData = ({ post }) => {
    this.setState({ post, showForm: false });
  };

  render() {
    const { showForm, post } = this.state;

    if (showForm) {
      return (
        <PostForm
          post={post}
          updateData={this.updateData}
          closeForm={this.toggleForm}
        />
      );
    }

    return (
      <Post
        post={post}
        toggleForm={this.toggleForm}
        updateData={this.updateData}
      />
    );
  }

  static propTypes = {
    post: PropTypes.objectOf(PropTypes.any).isRequired,
  };
}
