import React, { useEffect, useCallback } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

import { loadCurrentUser } from "./actions/";
import { baseUrl } from "./libs/";
import { Header, Loading, LoadingError } from "./components/common/";

import {
  PostsList,
  PostShow,
  SecretIndex,
  SecretShow,
  VkScheduledSecrets,
  CommentIndex,
  PushMessageIndex,
  ChatMessageComplainIndex,
  StatisticsIndex,
  UserIndex,
  UserShow,
  SocialAccountIndex,
  PublisherPosts,
  PublisherSecrets,
  UserPosts,
  UserComments,
  UserSecrets,
  UserBans,
  UserRatingAwards,
  UserHistory,
  UserActions,
  SettingsShow,
  SettingsHistory,
  Login,
  Page404,
} from "./containers/";

registerLocale("ru", ru);
setDefaultLocale("ru");

const Root = ({ currentUser, init }) => {
  const [status, setStatus] = React.useState("loading");

  const getData = useCallback(async () => {
    try {
      await init();
      setStatus("idle");
    } catch (error) {
      setStatus("error");
    }
  }, [init]);

  const handle404 = (flag) => {
    setStatus(flag ? "404" : "idle");
  };

  useEffect(() => {
    getData();
  }, [getData]);

  if (status === "loading") {
    return (
      <div className="page-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  if (status === "error") {
    return (
      <div className="page-100 d-flex align-items-center justify-content-center">
        <LoadingError />
      </div>
    );
  }

  if (!currentUser.id) {
    return <Login />;
  }

  return (
    <>
      {status !== "404" && <Header />}

      <Switch>
        <Route
          path={[
            `${baseUrl}/posts/:id(\\d+)/:content(comments)/:commentType(new|best|worst)`,
            `${baseUrl}/posts/:id(\\d+)/:content(history)`,
            `${baseUrl}/posts/:id(\\d+)`,
          ]}
          component={PostShow}
        />

        <Route
          path={[`${baseUrl}/posts`, `${baseUrl}/posts/:type(best|removed)`]}
          component={PostsList}
        />

        <Route
          path={`${baseUrl}/secrets/:type(vk_scheduled)`}
          component={VkScheduledSecrets}
        />

        <Route path={`${baseUrl}/secrets/:id(\\d+)`} component={SecretShow} />

        <Route
          path={[
            `${baseUrl}/secrets/:type(pending|scheduled|published|rejected|rejected_by_worker)`,
            `${baseUrl}/secrets`,
          ]}
          component={SecretIndex}
        />

        <Route
          path={[
            `${baseUrl}/comments/:type(bad|top|with_complains|deleted|actions)`,
            `${baseUrl}/comments`,
          ]}
          component={CommentIndex}
        />

        <Route
          path={[
            `${baseUrl}/chat_message_complains/:type(new|resolved)`,
            `${baseUrl}/chat_message_complains`,
          ]}
          component={ChatMessageComplainIndex}
        />

        <Route
          path={`${baseUrl}/statistics/:type(secrets|posts|comments|users)`}
          component={StatisticsIndex}
        />

        <Route
          path={[
            `${baseUrl}/users/:userId(\\d+)/posts/removed`,
            `${baseUrl}/users/:userId(\\d+)/posts`,
          ]}
          component={UserPosts}
        />

        <Route
          path={`${baseUrl}/users/:userId(\\d+)/comments`}
          component={UserComments}
        />

        <Route
          path={[
            `${baseUrl}/users/:userId(\\d+)/secrets/:type(pending|scheduled|published|rejected)`,
            `${baseUrl}/users/:userId(\\d+)/secrets`,
          ]}
          component={UserSecrets}
        />

        <Route
          path={`${baseUrl}/users/:userId(\\d+)/:type(bans|made_bans)`}
          component={UserBans}
        />

        <Route
          path={[
            `${baseUrl}/users/:userId(\\d+)/rating_awards/:type`,
            `${baseUrl}/users/:userId(\\d+)/rating_awards`,
          ]}
          component={UserRatingAwards}
        />

        <Route
          path={[
            `${baseUrl}/users/:userId(\\d+)/history/:type`,
            `${baseUrl}/users/:userId(\\d+)/history`,
          ]}
          component={UserHistory}
        />

        <Route
          path={[
            `${baseUrl}/users/:userId(\\d+)/actions/:type(posts|secrets|comments|comment_likes|comment_dislikes|comment_complains|users)`,
            `${baseUrl}/users/:userId(\\d+)/actions`,
          ]}
          component={UserActions}
        />

        <Route path={`${baseUrl}/users/:id(\\d+)`} component={UserShow} />

        <Route
          path={[
            `${baseUrl}/users/:type(admins|editors|moderators|keepers|vip|banned|removed)`,
            `${baseUrl}/users`,
          ]}
          component={UserIndex}
        />

        <Route
          path={[
            `${baseUrl}/social_accounts/:type(email|fb|vk|apple|dt)`,
            `${baseUrl}/social_accounts`,
          ]}
          component={SocialAccountIndex}
        />

        <Route
          path={[
            `${baseUrl}/publishers/:publisherId(\\d+)/posts/:type(published|removed)/:period(prev_month|this_month)`,
            `${baseUrl}/publishers/:publisherId(\\d+)/posts/:type(published|removed)`,
          ]}
          component={PublisherPosts}
        />

        <Route
          path={[
            `${baseUrl}/publishers/:publisherId(\\d+)/secrets/:type(scheduled)/:period(this_month|next_month)`,
            `${baseUrl}/publishers/:publisherId(\\d+)/secrets/:type(scheduled)`,
          ]}
          component={PublisherSecrets}
        />

        <Route
          path={[
            `${baseUrl}/publishers/:publisherId(\\d+)/secrets/:type(added)/:period(prev_month|this_month)`,
            `${baseUrl}/publishers/:publisherId(\\d+)/secrets/:type(added)`,
          ]}
          component={PublisherSecrets}
        />

        <Route
          path={[
            `${baseUrl}/push_messages/:simulator(simulator)`,
            `${baseUrl}/push_messages`,
          ]}
          component={PushMessageIndex}
        />

        <Route
          path={`${baseUrl}/settings/(general|comments|categories|countries|cities|reasons|ad|underside|vk|system)`}
          component={SettingsShow}
        />

        <Route
          path={`${baseUrl}/settings/history`}
          component={SettingsHistory}
        />

        {!!currentUser.defaultAction && (
          <Route exact path={["/", "/dashboard"]}>
            <Redirect to={`${baseUrl}/${currentUser.defaultAction}`} />
          </Route>
        )}

        <Route>
          <Page404 handle404={handle404} />
        </Route>
      </Switch>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  init: () => dispatch(loadCurrentUser()),
});

const RootContainer = connect(mapStateToProps, mapDispatchToProps)(Root);

export default RootContainer;
