import { DataLayer } from "../../libs/";

export const loadUserHistory = (id) => async (_, getState) => {
  const { type, page } = getState().filters.history;
  const params = [];
  const newState = {
    loading: false,
    loadingError: false,
  };

  if (type) params.push(`type=${type}`);
  if (page > 1) params.push(`page=${page}`);

  await DataLayer.get({
    path:
      "/users/" +
      id +
      "/history.json" +
      (params.length ? "?" + params.join("&") : ""),
  })
    .then((response) => {
      newState.versions = response.versions;
      newState.pages = response.pages;
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
