import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { baseUrl } from "../../../libs";

export const SecretsStatusPanel = ({ data }) => (
  <div className="status-panel row no-gutters justify-content-between mb-3">
    <div className="col-lg-auto col-sm-4">
      <h2 className="text-primary">
        <Link to={`${baseUrl}/secrets/pending`}>{data.pending}</Link>
      </h2>
      <div>На модерации</div>
    </div>
    <div className="col-lg-auto col-sm-8">
      <h2 className="text-primary scheduled">
        <Link to={`${baseUrl}/secrets/scheduled`}>{data.scheduled}</Link>{" "}
        <small>(до {data.lastScheduled})</small>
      </h2>
      <div>Запланировано</div>
    </div>
    <div className="col-lg-auto col-sm-4">
      <h2 className="text-primary">
        <Link to={`${baseUrl}/secrets/published`}>{data.published}</Link>
      </h2>
      <div>Опубликовано</div>
    </div>
    <div className="col-lg-auto col-sm-4">
      <h2 className="text-primary">
        <Link to={`${baseUrl}/secrets/rejected`}>{data.rejected}</Link>
      </h2>
      <div>Удалено</div>
    </div>
    <div className="col-lg-auto col-sm-4">
      <h2 className="text-primary">
        <Link to={`${baseUrl}/secrets/rejected_by_worker`}>
          {data.removedByWorker}
        </Link>
      </h2>
      <div>Удалено антибаяном</div>
    </div>
  </div>
);

SecretsStatusPanel.propTypes = {
  data: PropTypes.object.isRequired,
};
