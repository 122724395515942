import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadPushMessages } from "../../actions/";
import { PageHeader, Loading, LoadingError } from "../../components/common/";
import {
  PushMessageForm,
  SimulatorForm,
  PushMessage,
} from "../../components/push_messages/";

class PushMessages extends Component {
  state = {
    pushMessages: [],
    recentPosts: [],
    loading: true,
    loadingError: false,
    simulator: this.props.match.params.simulator,
  };

  componentDidMount() {
    this.props.load().then((e) => this.setState(e));
  }

  static getDerivedStateFromProps(props, state) {
    const simulator = props.match.params.simulator;
    if (simulator !== state.simulator) {
      return { simulator };
    }
    return null;
  }

  reload = () => {
    this.setState({ loading: true });
    this.props.load().then((e) => this.setState(e));
  };

  render() {
    const { simulator, pushMessages, recentPosts, loading, loadingError } =
      this.state;

    return (
      <Fragment>
        <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
          <div className="row">
            <div className="col-xl-8 mx-auto">
              <PageHeader />

              {simulator ? (
                <SimulatorForm />
              ) : (
                <PushMessageForm
                  recentPosts={recentPosts}
                  onCreate={this.reload}
                />
              )}
            </div>
          </div>

          {!simulator && (
            <div className="row mt-lg-5 mt-3">
              <div className="col-xl-8 mx-auto">
                <h3>Последние сообщения</h3>
                {loading && <Loading />}
                {loadingError && <LoadingError />}
                {pushMessages.map((item) => (
                  <PushMessage key={item.id} pushMessage={item} />
                ))}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }

  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    load: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  load: (data = {}) => dispatch(loadPushMessages(data)),
});

export const PushMessageIndex = connect(null, mapDispatchToProps)(PushMessages);
