import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { updateUser } from "../../actions/";
import { Error } from "../common/";

class FormComponent extends Component {
  constructor(props) {
    super(props);

    const user = this.props.user;

    this.state = { user, prevUser: user, errors: null };
  }

  componentDidMount() {
    if (this.textAreaRef) {
      this.textAreaRef.style.height = "20px";
      this.textAreaRef.style.height = `${this.textAreaRef.scrollHeight + 2}px`;
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.user.note !== state.prevUser.note) {
      const { user } = props;
      return { user, prevUser: user };
    }
    return null;
  }

  componentDidUpdate() {
    this.textAreaRef.style.height = "20px";
    this.textAreaRef.style.height = `${this.textAreaRef.scrollHeight + 2}px`;
  }

  handleInputChange = (e) => {
    const user = {};
    user[e.target.name] = e.target.value;
    this.setState({ user: { ...this.state.user, ...user } });
  };

  saveUser = (e) => {
    e.preventDefault();

    const { id, note } = this.state.user;
    const formData = { user: { note } };
    this.props
      .update({ id, formData })
      .then((response) => {
        this.setState({ errors: null });
        this.props.callback(response);
      })
      .catch((error) => {
        const errors = error.data
          ? error.data.errors || error.data
          : error.message;
        this.setState({ errors });
      });
  };

  render() {
    const { user, errors } = this.state;

    const showSaveButton = user.note !== this.props.user.note;

    return (
      <div>
        <h3 className="mt-md-4 mb-md-25 mt-2 mb-15">Заметка</h3>

        {errors && <Error errors={errors} />}

        <div className="form-group row align-items-center">
          <div className="col-lg-9 col-12">
            <textarea
              rows={2}
              ref={(c) => {
                this.textAreaRef = c;
              }}
              className="form-control"
              name="note"
              value={user.note || ""}
              placeholder="Введите текст заметки... Её видим только мы, но не пользователь."
              onChange={this.handleInputChange}
            />
          </div>

          {showSaveButton && (
            <div className="col-lg-3 col-12 mt-lg-0 mt-1 text-right">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={this.saveUser}
              >
                Сохранить
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  static propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    callback: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateUser(data)),
});

export const NoteForm = connect(null, mapDispatchToProps)(FormComponent);
