import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { updateUser } from "../../actions/";
import { baseUrl } from "../../libs/";
import { SvgEdit } from "../../svg";

class Form extends Component {
  state = {
    user: this.props.user,
    savedUser: this.props.user,
    bioDisabled: true,
    showActions: false,
  };

  componentDidMount() {
    if (this.noteTextAreaRef) {
      this.noteTextAreaRef.style.height = "20px";
      this.noteTextAreaRef.style.height = `${
        this.noteTextAreaRef.scrollHeight + 2
      }px`;
    }
    if (this.bioTextAreaRef) {
      this.bioTextAreaRef.style.height = "20px";
      this.bioTextAreaRef.style.height = `${
        this.bioTextAreaRef.scrollHeight + 2
      }px`;
    }
  }

  componentDidUpdate() {
    this.noteTextAreaRef.style.height = "20px";
    this.noteTextAreaRef.style.height = `${
      this.noteTextAreaRef.scrollHeight + 2
    }px`;
    this.bioTextAreaRef.style.height = "20px";
    this.bioTextAreaRef.style.height = `${
      this.bioTextAreaRef.scrollHeight + 2
    }px`;
  }

  enableBioInput = () => {
    this.setState({ bioDisabled: false });
  };

  handleChange = (e) => {
    const { user, savedUser } = this.state;
    const updatedUser = { ...user };
    updatedUser[e.target.name] = e.target.value;
    const updated =
      updatedUser.fullname !== (savedUser.fullname || "") ||
      updatedUser.note !== (savedUser.note || "") ||
      updatedUser.bio !== (savedUser.bio || "") ||
      updatedUser.rating.toString() !== savedUser.rating.toString();

    this.setState({ user: updatedUser, showActions: updated });
  };

  handleReset = () => {
    this.setState({ user: this.state.savedUser, showActions: false });
  };

  handleSave = (e) => {
    e.preventDefault();

    const { id, fullname, note, bio, rating } = this.state.user;
    this.props
      .update({ id, formData: { user: { fullname, note, bio, rating } } })
      .then((response) => {
        this.setState({
          user: response,
          savedUser: response,
          showActions: false,
        });
      })
      .catch(() => {});
  };

  render() {
    const {
      user: { id, fullname, note, bio, rating },
      bioDisabled,
      showActions,
    } = this.state;
    const { hasEditorAccess, hasModeratorAccess } = this.props;

    return (
      <Fragment>
        <h6 className="mt-2 mb-1 font-weight-500">Ник</h6>
        <input
          type="text"
          className="form-control"
          name="fullname"
          value={fullname || ""}
          onChange={this.handleChange}
        />

        <h6 className="mt-2 mb-1 font-weight-500">Заметка</h6>
        <textarea
          rows="1"
          ref={(c) => {
            this.noteTextAreaRef = c;
          }}
          className="form-control"
          name="note"
          value={note || ""}
          onChange={this.handleChange}
        />

        <h6 className="mt-2 mb-1 font-weight-500">О себе</h6>
        <div className="row mt-2 mb-1">
          <div className="col">
            <textarea
              rows="1"
              ref={(c) => {
                this.bioTextAreaRef = c;
              }}
              className="form-control"
              name="bio"
              value={bio || ""}
              onChange={this.handleChange}
              disabled={bioDisabled}
              maxLength={80}
            />
            {/*<input*/}
            {/*  type="text"*/}
            {/*  className="form-control"*/}
            {/*  name="bio"*/}
            {/*  value={bio || ''}*/}
            {/*  onChange={this.handleChange}*/}
            {/*  disabled={bioDisabled}*/}
            {/*  maxLength={80}*/}
            {/*/>*/}
          </div>
          {hasModeratorAccess && bioDisabled && (
            <button
              title="Редактировать"
              type="button"
              className="btn lh-0 pl-0"
              onClick={this.enableBioInput}
            >
              <SvgEdit width={16} height={16} className="fill-primary" />
            </button>
          )}
        </div>

        <div className="row mt-2 mb-1">
          <div className="col">
            <h6 className="font-weight-500 lh-15">Рейтинг</h6>
          </div>
          <div className="col-auto">
            <a
              href={`${baseUrl}/users/${id}/rating_awards`}
              target="_blank"
              rel="noreferrer"
            >
              История начисления
            </a>
          </div>
        </div>

        <input
          type="number"
          className="form-control"
          name="rating"
          value={rating}
          onChange={this.handleChange}
          disabled={!hasEditorAccess}
        />

        {showActions && (
          <div className="mt-25 text-right">
            <button
              type="button"
              onClick={this.handleReset}
              className="btn btn-secondary mr-15"
            >
              Отмена
            </button>
            <button
              type="button"
              onClick={this.handleSave}
              className="btn btn-primary"
            >
              Сохранить
            </button>
          </div>
        )}
      </Fragment>
    );
  }

  static propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    update: PropTypes.func.isRequired,
    hasEditorAccess: PropTypes.bool.isRequired,
    hasModeratorAccess: PropTypes.bool.isRequired,
  };
}

const mapStateToProps = (state) => ({
  hasEditorAccess: state.user.hasEditorAccess,
  hasModeratorAccess: state.user.hasModeratorAccess,
});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateUser(data)),
});

export const ProfileModalForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
