import React from "react";
import { connect } from "react-redux";

import { PageHeader, ResponsiveSidebar } from "../../components/common/";
import { SecretFilters } from "../../components/secrets/";
import { EditorStats, SecretList, SecretStats } from "./";

const SecretsIndexComponent = ({ hasEditorAccess }) => (
  <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
    <PageHeader />

    <div className="row p-static">
      <ResponsiveSidebar mobileTitle="Показать фильтры и статистику">
        <SecretFilters />

        {hasEditorAccess && [
          <SecretStats key="stats-secret" />,
          <EditorStats key="stats-editor" />,
        ]}
      </ResponsiveSidebar>

      <div className="col-lg-8 col-12 order-lg-1">
        <SecretList />
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  hasEditorAccess: state.user.hasEditorAccess,
});

export const SecretIndex = connect(mapStateToProps)(SecretsIndexComponent);
