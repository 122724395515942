import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";

import activeNav from "./active-nav";
import badFeedWords from "./bad-feed-words";
import banReasons from "./ban-reasons";
import categories from "./categories";
import categoriesWithPostCount from "./categories-with-post-count";
import countries from "./countries";
import editorStats from "./editor-stats";
import filters from "./filters";
import pageTitle from "./page-title";
import postLinks from "./post-links";
import user from "./user";
import vkSettings from "./vk-settings";

export const history = createBrowserHistory();

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    activeNav,
    badFeedWords,
    banReasons,
    categories,
    categoriesWithPostCount,
    countries,
    editorStats,
    filters,
    pageTitle,
    postLinks,
    user,
    vkSettings,
  });

export default function configureStore(preloadedState = {}) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    compose(applyMiddleware(routerMiddleware(history), thunk))
  );

  return store;
}
