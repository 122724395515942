import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ActionCableProvider, ActionCable } from "react-actioncable-provider";
import { NavLink } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import _ from "lodash";

import { loadComments } from "../../actions/";
import { baseUrl } from "../../libs/";
import {
  PageHeader,
  Loading,
  LoadingError,
  EmptyList,
  EndOfList,
  ResponsiveSidebar,
} from "../../components/common/";
import {
  CommentFilters,
  CommentList,
  UserBan,
} from "../../components/comments";

class CommentComponent extends Component {
  state = {
    comments: [],
    bans: [],
    loading: true,
    loadingError: false,
    end: false,
    filters: this.props.filters,
  };

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.filters, state.filters)) {
      return {
        filters: props.filters,
        comments: [],
        bans: [],
        loading: true,
        end: false,
      };
    }
    return null;
  }

  loadMore = () => {
    const { comments, bans, loading } = this.state;

    if (!loading) {
      this.setState({ loading: true });
    }

    this.props.load({ comments, bans }).then((e) => this.setState(e));
  };

  appendComment = (data) => {
    const comment = JSON.parse(data.message);
    if (this.props.filters.type === "bad" && !comment.bad) {
      return;
    }

    this.setState({ comments: [comment, ...this.state.comments] });
  };

  render() {
    const { comments, bans, loading, loadingError, end } = this.state;
    const { filters, socketUrl } = this.props;
    const live = localStorage.getItem("liveInComments") === "true";
    const banAction = filters.type === "actions" && filters.action === "ban";
    const showBanReason = filters.type === "actions";

    return (
      <div className="container mt-lg-5 mt-3 pb-lg-5 pb-3">
        <PageHeader />

        {filters.type === "actions" && (
          <div className="secondary-nav moderator-actions mb-lg-4 mb-2">
            <nav>
              <NavLink
                exact
                to={`${baseUrl}/comments/actions`}
                activeClassName="active"
                isActive={(m, l) => !l.search.includes("ban")}
              >
                Комментарии
              </NavLink>
              <NavLink
                exact
                to={`${baseUrl}/comments/actions?ban`}
                activeClassName="active"
                isActive={(m, l) => l.search.includes("ban")}
              >
                Баны
              </NavLink>
            </nav>
          </div>
        )}

        <div className="row p-static">
          <ResponsiveSidebar mobileTitle="Показать фильтры">
            <CommentFilters />
          </ResponsiveSidebar>

          <div className="col-lg-8 col-12 order-lg-1">
            {["all", "bad"].includes(filters.type) && live && (
              <ActionCableProvider url={socketUrl}>
                <ActionCable
                  channel={{ channel: "CommentsChannel" }}
                  onReceived={this.appendComment}
                />
              </ActionCableProvider>
            )}

            {banAction ? (
              bans.map((ban) => (
                <UserBan
                  key={ban.id}
                  id={ban.id}
                  createdAt={ban.created_at}
                  banReason={ban.reason}
                  comment={ban.comment}
                  actingUser={ban.acting_user}
                  bannedUser={ban.banned_user}
                />
              ))
            ) : (
              <CommentList comments={comments} showBanReason={showBanReason} />
            )}

            {!end && !loadingError && (
              <VisibilitySensor
                onChange={(isVisible) => isVisible && this.loadMore()}
                children={<div className="visibility_sensor" />}
              />
            )}

            {loading && <Loading />}

            {loadingError && <LoadingError retry={this.loadMore} />}

            {!loading &&
              !loadingError &&
              (banAction ? !bans[0] : !comments[0]) && <EmptyList />}

            {(banAction ? bans.length : comments.length) > 0 && end && (
              <EndOfList />
            )}
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    load: PropTypes.func.isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    socketUrl: PropTypes.string.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.comments,
  socketUrl: state.user.socketUrl,
});

const mapDispatchToProps = (dispatch) => ({
  load: (data = {}) => dispatch(loadComments(data)),
});

export const CommentIndex = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentComponent);
