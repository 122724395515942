import React from "react";

import { fullDate } from "../../libs/";
import Settings from "./settings";

const RewardTitles = {
  complain_comment: "За жалобу, если комментарий скрыт",
  post_secret: "Создание секрета",
  like_comment: "Лайк комментария",
  top_post_day: "Попадание поста в топ дня",
  top_post_week: "Попадание поста в топ недели",
  top_post_month: "Попадание поста в топ месяца",
  self_registred: "Регистрация",
  location_setup: "Установка города",
  vip_purchase: "Покупка расширенного аккаунта",
  all_underside: "Просмотр всех секретов изнанки",
};

export class GeneralSettings extends Settings {
  handleRatingRewardChange = (e) => {
    const data = { ...this.state.data };
    const { name, value } = e.target;
    // eslint-disable-next-line no-unused-vars
    const [_, rewardType, rank] = name.split(".");
    const newValue = !["", "-"].includes(value) ? parseInt(value, 10) : value;

    data.rating_rewards.forEach((reward, i) => {
      if (rewardType !== reward.type) return;

      if (rewardType === "all_underside") {
        const newByRank = reward.by_rank;
        newByRank[rank] = newValue;
        data.rating_rewards[i] = { type: rewardType, by_rank: newByRank };
      } else if (rewardType === "keeper_underside") {
        const value = document.getElementById("keeper_underside_value").value;
        const threshold = document.getElementById(
          "keeper_underside_threshold"
        ).value;
        data.rating_rewards[i] = {
          type: rewardType,
          value: value ? parseInt(value, 10) : 0,
          threshold: threshold ? parseInt(threshold, 10) : 0,
        };
      } else {
        data.rating_rewards[i] = { type: rewardType, value: newValue };
      }
    });

    this.setState({ data });
  };

  handleSeasonalImageUpload = (e) => {
    const data = { ...this.state.data };

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const reader = new FileReader();

      reader.onloadend = () => {
        data.seasonal_deer_image = reader.result;
        this.setState({ data });
      };

      reader.readAsDataURL(file);
    }
  };

  render() {
    const { data } = this.state;

    return (
      <div className="settings-item bg-white p-2">
        <h5>Общие настройки</h5>

        <div className="row align-items-center mt-3">
          <div className="col">Максимальное количество секретов в день</div>
          <div className="col-sm-5 col-6">
            {this.input("secrets_per_day", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Максимальное количество лайков комментариев в день
          </div>
          <div className="col-sm-5 col-6">
            {this.input("comment_likes_per_day", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Максимальное количество лайков комментариев в день для VIP
          </div>
          <div className="col-sm-5 col-6">
            {this.input("comment_likes_per_day_for_vip", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Максимальное количество лайков комментариев одного пользователя в
            день
          </div>
          <div className="col-sm-5 col-6">
            {this.input("user_comment_likes_per_day", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Максимальное количество дизлайков комментариев в день
          </div>
          <div className="col-sm-5 col-6">
            {this.input("comment_dislikes_per_day", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Максимальное количество дизлайков комментариев в день для VIP
          </div>
          <div className="col-sm-5 col-6">
            {this.input("comment_dislikes_per_day_for_vip", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Максимальное количество дизлайков комментариев одного пользователя в
            день
          </div>
          <div className="col-sm-5 col-6">
            {this.input("user_comment_dislikes_per_day", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Максимальное количество дизлайков комментариев одного поста за 10
            минут
          </div>
          <div className="col-sm-5 col-6">
            {this.input("post_comment_dislikes_per_period", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Максимальное количество жалоб на комментарии в день
          </div>
          <div className="col-sm-5 col-6">
            {this.input("comment_complains_per_day", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Максимальное количество жалоб на комментарии в день для VIP
          </div>
          <div className="col-sm-5 col-6">
            {this.input("comment_complains_per_day_for_vip", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">Максимальное количество новых чатов в день</div>
          <div className="col-sm-5 col-6">
            {this.input("new_chats_per_day", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Настройка режима фейковых дизлайков (лимит,период,время действия)
          </div>
          <div className="col-sm-5 col-6">
            {this.input("comment_dislikes_fake_mode")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Рейтинг комментария, при котором он скрывается (отрицательный)
          </div>
          <div className="col-sm-5 col-6">
            {this.input("comment_critical_rating", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Рейтинг пользователя (отрицательный), при котором он не может
            комментировать и лайкать комментарии
          </div>
          <div className="col-sm-5 col-6">
            {this.input("user_cannot_comment_critical_rating", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Выводить имена авторов секретов в главной ленте
          </div>
          <div className="col-sm-5 col-6">
            {this.booleanRadio("show_post_author", ["Да", "Нет"])}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">
            Разрешать пользователям с неподтверждённой почтой оставлять
            комментарии
            {!data.unverified_user_can_comment && (
              <sub>
                <br />
                (Распостраняется на пользователей, зарегистрированных после{" "}
                {fullDate(data.unverified_user_since)})
              </sub>
            )}
          </div>
          <div className="col-sm-5 col-6">
            {this.booleanRadio("unverified_user_can_comment", ["Да", "Нет"])}
          </div>
        </div>

        <h5 className="mt-7">Настройки включения &quot;огня&quot; в посте</h5>

        <div className="row align-items-center mt-3">
          <div className="col">
            Кол-во комментариев, нужное для включения &quot;огня&quot;
          </div>
          <div className="col-sm-5 col-6">
            {this.input("hot_discussion_amount", "integer")}
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col">Период измерения (минуты)</div>
          <div className="col-sm-5 col-6">
            {this.input("hot_discussion_period", "integer")}
          </div>
        </div>

        <h5 className="mt-7">Настройки регистрации пользователей</h5>

        <div className="row align-items-center mt-3">
          <div className="col">
            Автоматическое подтверждение email при регистрации для доверенных
            доменов
            <sub>
              <br />
              (например: gmail.com, yandex.ru)
            </sub>
          </div>
          <div className="col-sm-5 col-6">
            {this.booleanRadio("email_verifier_enabled", ["Да", "Нет"])}
          </div>
        </div>

        <div className="row align-items-center mt-3">
          <div className="col">Чёрный список email доменов</div>
          <div className="col-sm-5 col-6">
            {this.textarea("email_domains_black_list")}
          </div>
        </div>

        <h5 className="mt-7">Настройки начисления рейтинга</h5>

        {data.rating_rewards.map((reward) => (
          <div className="row align-items-center mt-3" key={reward.type}>
            <div className="col">
              {reward.type === "keeper_underside" ? (
                <React.Fragment>
                  Просмотр каждых
                  <input
                    id="keeper_underside_threshold"
                    type="text"
                    className="form-control form-control-sm"
                    style={{
                      display: "inline-block",
                      width: "45px",
                      margin: "0 0.75rem",
                    }}
                    name="reward.keeper_underside"
                    value={reward.threshold}
                    onChange={this.handleRatingRewardChange}
                  />
                  секретов изнанки Хранителем
                </React.Fragment>
              ) : (
                RewardTitles[reward.type]
              )}
            </div>
            <div className="col-sm-5 col-6">
              {reward.type !== "all_underside" ? (
                <input
                  id={
                    reward.type === "keeper_underside"
                      ? "keeper_underside_value"
                      : undefined
                  }
                  type="text"
                  className="form-control"
                  name={`reward.${reward.type}`}
                  value={reward.value}
                  onChange={this.handleRatingRewardChange}
                />
              ) : (
                Object.keys(reward.by_rank).map((rank) => (
                  <div
                    className="row align-items-center mt-1"
                    key={`rank${rank}`}
                  >
                    <div className="col-md-4 col-12 mb-md-0 mb-1 text-muted">
                      Ранг {rank}
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        name={`reward.${reward.type}.${rank}`}
                        value={reward.by_rank[rank]}
                        onChange={this.handleRatingRewardChange}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        ))}

        <div className="row align-items-center mt-3">
          <div className="col">
            Олень в сайдбаре сайта (
            <a
              href={
                data.seasonal_deer_image_url
                  ? data.seasonal_deer_image_url.split("?")[0]
                  : undefined
              }
              target="_blank"
              rel="noreferrer"
            >
              текущий
            </a>
            )
          </div>
          <div className="col-sm-5 col-6">
            <div className="input-file">
              <input
                type="file"
                accept="image/png"
                onChange={this.handleSeasonalImageUpload}
              />
              <span className="placeholder-simple">Изменить</span>
            </div>
          </div>
        </div>

        <div className="mt-7 text-center">
          <button
            className="btn btn-primary"
            onClick={() => this.save("general")}
          >
            Сохранить
          </button>
        </div>
      </div>
    );
  }
}
