import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";

import { exportPostsToCsv } from "../../actions/";
import { BtnDropdown, CategoryDropdown, Modal } from "../common/";
import { SvgDown } from "../../svg";

const sortingOptions = [
  { value: "created_at_asc", title: "по дате публикации ▲" },
  { value: "created_at_desc", title: "по дате публикации ▼" },
  { value: "likes_count_asc", title: "по лайкам ▲" },
  { value: "likes_count_desc", title: "по лайкам ▼" },
  { value: "comments_count_asc", title: "по комментариям ▲" },
  { value: "comments_count_desc", title: "по комментариям ▼" },
  { value: "bookmark_count_asc", title: "по закладкам ▲" },
  { value: "bookmark_count_desc", title: "по закладкам ▼" },
];

class ExportModal extends Component {
  state = {
    categoryId: 0,
    dateFrom: "",
    dateTo: "",
    sortBy: "likes_count_asc",
  };

  getSortByTitle = (value) => {
    const item = sortingOptions.find((el) => el.value === value);
    return item ? item.title : null;
  };

  export = async () => {
    const { categoryId, dateFrom, dateTo, sortBy } = this.state;

    document.body.classList.add("loader");

    try {
      const res = await exportPostsToCsv({
        categoryId,
        dateFrom,
        dateTo,
        sortBy,
      });

      let filename =
        res.headers["content-disposition"]?.match('filename="(.+)"')?.[1];

      const blob = await res.blob();

      if (!filename || filename === ".csv") {
        filename = "export.csv";
      }

      const a = document.createElement("a");

      a.href = URL.createObjectURL(new Blob([blob]));
      a.setAttribute("download", filename);
      a.click();

      this.props.close();
    } catch (error) {
      console.log(error);
    } finally {
      document.body.classList.remove("loader");
    }
  };

  render() {
    const { categoryId, dateFrom, dateTo, sortBy } = this.state;
    const { close } = this.props;
    const sortByText = (
      <span>
        {this.getSortByTitle(sortBy)}
        <SvgDown width={9} height={6} className="toggler" />
      </span>
    );

    return (
      <Modal title="Экспорт постов в csv файл" toggleModal={close} xs>
        <Fragment>
          <div className="row justify-content-between mb-2">
            <div className="col">Категория</div>
            <div className="col-auto">
              <CategoryDropdown
                selectedId={categoryId}
                onChange={(v) => this.setState({ categoryId: v })}
              />
            </div>
          </div>

          <div className="row justify-content-between mb-2">
            <div className="col">Дата создания</div>
            <div className="col-auto">
              <DatePicker
                name="date_from"
                selected={dateFrom || ""}
                onChange={(v) => {
                  this.setState({
                    dateFrom: v || "",
                  });
                }}
                placeholderText="с"
                className="form-control custom-select"
              />
              <DatePicker
                name="date_to"
                selected={dateTo || ""}
                onChange={(v) => {
                  this.setState({
                    dateTo: v || "",
                  });
                }}
                placeholderText="по"
                className="form-control custom-select"
              />
            </div>
          </div>

          <div className="row justify-content-between mb-3">
            <div className="col">Сортировка</div>
            <div className="col-auto">
              <BtnDropdown btnClass="btn btn-link p-0" btnText={sortByText}>
                <ul className="dropdown">
                  {sortingOptions.map((option) => (
                    <li key={option.value}>
                      <button
                        type="button"
                        className="btn btn-link p-0"
                        onClick={() => this.setState({ sortBy: option.value })}
                      >
                        {option.title}
                      </button>
                    </li>
                  ))}
                </ul>
              </BtnDropdown>
            </div>
          </div>

          <div className="row">
            <div className="col text-right">
              <button className="btn btn-primary" onClick={close}>
                Отменить
              </button>
              <button
                className="btn btn-success ml-1"
                onClick={this.export}
                disabled={dateFrom === ""}
              >
                Скачать
              </button>
            </div>
          </div>
        </Fragment>
      </Modal>
    );
  }

  static propTypes = {
    close: PropTypes.func.isRequired,
  };
}

export const PostExportModal = connect(null, {})(ExportModal);
