import _ from "lodash";

import { DataLayer } from "../../../libs/";

export const loadPublisherPosts =
  ({ publisherId, posts = [] }) =>
  async (__, getState) => {
    const { type, category, period } = getState().filters.publishers;
    const params = [`publisher_id=${publisherId}`];
    const newState = {
      loading: false,
      loadingError: false,
    };

    if (category) {
      params.push(`category_id=${category}`);
    }
    if (period) {
      params.push(`period=${period}`);
    }
    if (posts.length > 0) {
      params.push(`from=${_.last(posts).id}`);
    }

    await DataLayer.get({
      path:
        "/posts/" +
        type +
        ".json" +
        (params.length ? "?" + params.join("&") : ""),
    })
      .then((response) => {
        newState.posts = [...posts, ...response.posts];
        newState.end = !response.posts.length;
      })
      .catch(() => {
        newState.loadingError = true;
      });

    return newState;
  };
