import { push } from "connected-react-router";

export const toggleQueryParam =
  ({ name, show, value }) =>
  (dispatch, getState) => {
    const { search } = getState().router.location;
    const params = new URLSearchParams(search);

    params.delete("page");

    if (show) {
      params.set(name, value);
    } else {
      params.delete(name);
    }

    dispatch(push(`?${params.toString()}`));
  };
