import React, { Component } from "react";
import PropTypes from "prop-types";

import { digObject, updateObject } from "../../libs";

export default class Settings extends Component {
  state = {
    data: this.props.data,
    prevData: this.props.data,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.prevData) {
      return { prevData: props.data, data: props.data };
    }
    return null;
  }

  handleInputChange = (e, type) => {
    const { name, value } = e.target;
    const newValue =
      type === "integer" && !["", "-"].includes(value)
        ? parseInt(value, 10)
        : value;
    this.setState({
      data: updateObject({ ...this.state.data }, name, newValue),
    });
  };

  handleCheckboxChange = (e) => {
    this.setState({
      data: updateObject(
        { ...this.state.data },
        e.target.name,
        e.target.checked
      ),
    });
  };

  handleRadioChange = (e) => {
    this.setState({
      data: updateObject(
        { ...this.state.data },
        e.target.name,
        e.target.value === "true"
      ),
    });
  };

  input = (name, type) => {
    const value = digObject(this.state.data, name);
    return (
      <input
        type="text"
        className="form-control"
        name={name}
        value={value !== undefined && value !== null ? value : ""}
        onChange={(e) => this.handleInputChange(e, type)}
      />
    );
  };

  textarea = (name) => (
    <textarea
      className="form-control"
      rows="4"
      name={name}
      value={digObject(this.state.data, name) || ""}
      onChange={this.handleInputChange}
    />
  );

  checkbox = (name, title) => (
    <label htmlFor={`checkbox_${name}`} key={`checkbox_${name}`}>
      <input
        type="checkbox"
        name={name}
        id={`checkbox_${name}`}
        checked={digObject(this.state.data, name) || false}
        onChange={this.handleCheckboxChange}
      />
      <span>{title}</span>
    </label>
  );

  booleanRadio = (name, titles) => (
    <div className="custom-radio-box">
      <label htmlFor={`${name}_yes`}>
        <input
          type="radio"
          name={name}
          value={true}
          id={`${name}_yes`}
          checked={digObject(this.state.data, name)}
          onChange={this.handleRadioChange}
        />
        <span>{titles[0]}</span>
      </label>
      <label htmlFor={`${name}_no`}>
        <input
          type="radio"
          name={name}
          value={false}
          id={`${name}_no`}
          checked={!digObject(this.state.data, name)}
          onChange={this.handleRadioChange}
        />
        <span>{titles[1]}</span>
      </label>
    </div>
  );

  save = (type) => {
    const settings = {};
    settings[type] = this.state.data;
    this.props.saveCallback(settings);
  };

  render() {
    return "";
  }

  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    saveCallback: PropTypes.func.isRequired,
  };
}
