import { DataLayer } from "../../libs/";

export const toggleSocialAccountBan =
  ({ id, banned }) =>
  () => {
    return DataLayer.put({
      path: `/social_accounts/${id}.json`,
      body: { banned },
      loader: true,
    });
  };
