import _ from "lodash";

import { DataLayer } from "../../../libs/";

export const loadUserPosts =
  ({ userId, posts = [] }) =>
  async (__, getState) => {
    const { category, removed } = getState().filters.posts;
    const params = [];
    const newState = {
      loading: false,
      loadingError: false,
    };

    if (category) {
      params.push(`category_id=${category}`);
    }
    if (removed) {
      params.push(`removed=true`);
    }
    if (posts.length > 0) {
      params.push(`from=${_.last(posts).id}`);
    }

    await DataLayer.get({
      path:
        "/users/" +
        userId +
        "/posts.json" +
        (params.length ? "?" + params.join("&") : ""),
    })
      .then((response) => {
        newState.posts = [...posts, ...response.posts];
        newState.end = !response.posts.length;
      })
      .catch(() => {
        newState.loadingError = true;
      });

    return newState;
  };
