import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import _ from "lodash";

import { City, CityForm } from "./";
import { loadCities, setQueryParam } from "../../actions/";
import {
  BtnDropdown,
  Loading,
  LoadingError,
  EmptyList,
  EndOfList,
} from "../common/";

class _CitySettings extends Component {
  state = {
    showAddForm: false,
    cities: [],
    page: 1,
    country: this.props.filters.country,
    end: false,
    loading: true,
    loadingError: false,
  };

  componentDidMount() {
    this.props.load({}).then((e) => this.setState(e));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.country !== this.state.country) {
      this.props.load({}).then((e) => this.setState(e));
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.filters.country !== state.country) {
      return {
        country: props.filters.country,
        cities: [],
        loading: true,
        end: false,
      };
    }
    return null;
  }

  loadMore = () => {
    const { cities, page } = this.state;

    this.setState({ loading: true });
    this.props.load({ cities, page }).then((e) => this.setState(e));
  };

  toggleForm = () => {
    this.setState({ showAddForm: !this.state.showAddForm });
  };

  updateList = (cities) => {
    this.setState({ cities });
  };

  getCountryName = (id) => {
    const { countries } = this.props;
    if (!countries) return "Любая";
    const country = _.find(countries, ["id", id]);
    return country ? country.name : "Любая";
  };

  changeCountry = (e, value) => {
    e.preventDefault();
    this.props.setQueryParam({ name: "country", value, defaultValue: "" });
  };

  render() {
    const { showAddForm, cities, loading, loadingError, end } = this.state;
    const {
      countries,
      filters: { country: selectedCountry },
    } = this.props;

    return (
      <div className="settings-item bg-white p-2">
        <div className="row align-items-center mb-4">
          <h5 className="col">Города</h5>

          <div className="col">
            <b className="mr-2">Страна:</b>
            <BtnDropdown
              btnClass="btn btn-link p-0"
              hasIcon
              btnText={this.getCountryName(selectedCountry)}
            >
              <ul className="dropdown">
                <li>
                  <a href="" onClick={(e) => this.changeCountry(e, "")}>
                    {this.getCountryName(0)}
                  </a>
                </li>

                {countries.map((country) => (
                  <li key={country.id}>
                    <a
                      href=""
                      onClick={(e) => this.changeCountry(e, country.id)}
                    >
                      {country.name}
                    </a>
                  </li>
                ))}
              </ul>
            </BtnDropdown>
          </div>

          <div className="col-auto">
            <button className="btn btn-primary" onClick={this.toggleForm}>
              Добавить город
            </button>
          </div>
        </div>

        <table className="table table-sm table-hover">
          <colgroup>
            <col width="90" />
            <col />
            <col />
            <col />
            <col width="1" />
            <col width="90" />
          </colgroup>
          <thead>
            <tr>
              <th className="text-center">Позиция</th>
              <th>Название</th>
              <th>Регион</th>
              <th>Страна</th>
              <th>Пользователи</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {showAddForm && (
              <CityForm
                key="add"
                city={{}}
                countries={countries}
                saveCallback={this.updateList}
                close={this.toggleForm}
              />
            )}

            {countries.length > 0 &&
              cities.map((city) => (
                <City
                  key={city.id}
                  city={city}
                  countries={countries}
                  saveCallback={this.updateList}
                />
              ))}

            <tr>
              <td colSpan={6}>
                {cities.length > 0 && !end && !loadingError && (
                  <VisibilitySensor
                    onChange={(isVisible) => isVisible && this.loadMore()}
                    children={<div className="visibility_sensor" />}
                  />
                )}
                {loading && <Loading />}
                {loadingError && <LoadingError retry={this.loadMore} />}
                {!loading && !loadingError && !cities[0] && <EmptyList />}
                {cities.length > 0 && end && <EndOfList />}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  static propTypes = {
    filters: PropTypes.object.isRequired,
    countries: PropTypes.arrayOf(PropTypes.object).isRequired,
    load: PropTypes.func.isRequired,
    setQueryParam: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  filters: state.filters.cities,
  countries: state.countries,
});

const mapDispatchToProps = (dispatch) => ({
  load: (data) => dispatch(loadCities(data)),
  setQueryParam: (data) => dispatch(setQueryParam(data)),
});

export const CitySettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(_CitySettings);
