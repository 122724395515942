import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { baseUrl } from "../../libs/";
import { SvgCrown } from "../../svg/";
import { BanButton, Modal } from "../common/";
import { ActionsDropdown, ProfileModalForm } from "./";

const ProfileModalConst = ({ user, toggleModal, hasEditorAccess }) => (
  <Modal xs toggleModal={toggleModal}>
    <div className="profile-modal">
      <div className="row no-gutters">
        <div className="col-auto mr-2">
          <div className={`user-avatar${user.keeper ? " keeper" : ""}`}>
            <a
              href={`${baseUrl}/users/${user.id}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={user.avatar}
                alt=""
                width={60}
                height={60}
                className="rounded-circle"
              />
              {user.vip && (
                <div className="badge">
                  <SvgCrown width={12} height={12} />
                </div>
              )}
            </a>
          </div>
        </div>
        <div className="col">
          <h3 className="mb-1 mr-2">
            <a
              href={`${baseUrl}/users/${user.id}`}
              className={`user-name${
                user.keeper ? " keeper-profile-link" : ""
              }`}
              target="_blank"
              rel="noreferrer"
            >
              {user.fullname_or_anonymus}
            </a>
          </h3>
          <BanButton user={user} showUnBanButton />
          <ActionsDropdown user={user} />
        </div>
      </div>

      <ProfileModalForm user={user} />

      <h6 className="mt-25 mb-1">Общая статистика</h6>
      <div className="row">
        <div className="col">
          {hasEditorAccess ? (
            <div className="h3 text-primary">
              <Fragment>
                <a
                  href={`${baseUrl}/users/${user.id}/posts`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {user.post_count}
                </a>
                {user.removed_posts_count !== user.post_count && (
                  <Fragment>
                    {" ("}
                    <a
                      href={`${baseUrl}/users/${user.id}/posts/removed`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {user.removed_posts_count}
                    </a>
                    {")"}
                  </Fragment>
                )}
                {" / "}
                <a
                  href={`${baseUrl}/users/${user.id}/secrets`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {user.secret_count}
                </a>
              </Fragment>
            </div>
          ) : (
            <div className="h3 text-muted">{user.post_count}</div>
          )}
          <div className="text-muted mt-1">Посты</div>
        </div>
        <div className="col">
          <div className="h3 text-primary">
            <a
              href={`${baseUrl}/users/${user.id}/comments`}
              target="_blank"
              rel="noreferrer"
            >
              {user.commented_count}
            </a>
          </div>
          <div className="text-muted mt-1">Комментарии</div>
        </div>
        <div className="col">
          <div className="h3 text-primary">
            <a
              href={`${baseUrl}/users/${user.id}/bans`}
              target="_blank"
              rel="noreferrer"
            >
              {user.banned_count}
            </a>
            {user.banned_count !== user.bans_count && (
              <span> ({user.bans_count})</span>
            )}
          </div>
          <div className="text-muted mt-1">Баны</div>
        </div>
      </div>
    </div>
  </Modal>
);

ProfileModalConst.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleModal: PropTypes.func.isRequired,
  hasEditorAccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  hasEditorAccess: state.user.hasEditorAccess,
});

export const ProfileModal = connect(mapStateToProps)(ProfileModalConst);
